// GENERATED CODE! Do not hand-modify
/* eslint-disable prettier/prettier */

import { Subject } from "rxjs";
import { DetectorConfigReq, DetectorConfigResp, DetectorConfigListReq, DetectorConfigListResp } from "src/app/generated-protos/detector-config-msgs";
import { DetectedDiffractionPeaksReq, DetectedDiffractionPeaksResp } from "src/app/generated-protos/diffraction-detected-peak-msgs";
import { DiffractionPeakManualDeleteReq, DiffractionPeakManualDeleteResp, DiffractionPeakManualInsertReq, DiffractionPeakManualInsertResp, DiffractionPeakManualListReq, DiffractionPeakManualListResp } from "src/app/generated-protos/diffraction-manual-msgs";
import { DiffractionPeakStatusDeleteReq, DiffractionPeakStatusDeleteResp, DiffractionPeakStatusListReq, DiffractionPeakStatusListResp, DiffractionPeakStatusWriteReq, DiffractionPeakStatusWriteResp } from "src/app/generated-protos/diffraction-status-msgs";
import { PublishExpressionToZenodoReq, PublishExpressionToZenodoResp, ZenodoDOIGetReq, ZenodoDOIGetResp } from "src/app/generated-protos/doi-msgs";
import { ElementSetDeleteReq, ElementSetDeleteResp, ElementSetGetReq, ElementSetGetResp, ElementSetListReq, ElementSetListResp, ElementSetWriteReq, ElementSetWriteResp } from "src/app/generated-protos/element-set-msgs";
import { ExportFilesReq, ExportFilesResp } from "src/app/generated-protos/export-msgs";
import { ExpressionGroupDeleteReq, ExpressionGroupDeleteResp, ExpressionGroupGetReq, ExpressionGroupGetResp, ExpressionGroupListReq, ExpressionGroupListResp, ExpressionGroupWriteReq, ExpressionGroupWriteResp } from "src/app/generated-protos/expression-group-msgs";
import { ExpressionDeleteReq, ExpressionDeleteResp, ExpressionDisplaySettingsGetReq, ExpressionDisplaySettingsGetResp, ExpressionDisplaySettingsWriteReq, ExpressionDisplaySettingsWriteResp, ExpressionGetReq, ExpressionGetResp, ExpressionListReq, ExpressionListResp, ExpressionWriteReq, ExpressionWriteResp, ExpressionWriteExecStatReq, ExpressionWriteExecStatResp } from "src/app/generated-protos/expression-msgs";
import { ImageBeamLocationVersionsReq, ImageBeamLocationVersionsResp, ImageBeamLocationsReq, ImageBeamLocationsResp } from "src/app/generated-protos/image-beam-location-msgs";
import { ImportMarsViewerImageReq, ImportMarsViewerImageResp, ImportMarsViewerImageUpd } from "src/app/generated-protos/image-coreg-msgs";
import { ImageDeleteReq, ImageDeleteResp, ImageGetReq, ImageGetResp, ImageGetDefaultReq, ImageGetDefaultResp, ImageListReq, ImageListResp, ImageListUpd, ImageSetDefaultReq, ImageSetDefaultResp, ImageSetMatchTransformReq, ImageSetMatchTransformResp } from "src/app/generated-protos/image-msgs";
import { JobListReq, JobListResp, JobListUpd } from "src/app/generated-protos/job-msgs";
import { LogGetLevelReq, LogGetLevelResp, LogReadReq, LogReadResp, LogSetLevelReq, LogSetLevelResp } from "src/app/generated-protos/log-msgs";
import { MemoiseGetReq, MemoiseGetResp, MemoiseWriteReq, MemoiseWriteResp } from "src/app/generated-protos/memoisation-msgs";
import { DataModuleAddVersionReq, DataModuleAddVersionResp, DataModuleGetReq, DataModuleGetResp, DataModuleListReq, DataModuleListResp, DataModuleWriteReq, DataModuleWriteResp } from "src/app/generated-protos/module-msgs";
import { NotificationReq, NotificationResp, NotificationUpd, NotificationDismissReq, NotificationDismissResp, SendUserNotificationReq, SendUserNotificationResp } from "src/app/generated-protos/notification-msgs";
import { GetOwnershipReq, GetOwnershipResp, ObjectEditAccessReq, ObjectEditAccessResp } from "src/app/generated-protos/ownership-access-msgs";
import { PiquantConfigListReq, PiquantConfigListResp, PiquantConfigVersionReq, PiquantConfigVersionResp, PiquantConfigVersionsListReq, PiquantConfigVersionsListResp, PiquantCurrentVersionReq, PiquantCurrentVersionResp, PiquantVersionListReq, PiquantVersionListResp, PiquantWriteCurrentVersionReq, PiquantWriteCurrentVersionResp } from "src/app/generated-protos/piquant-msgs";
import { PseudoIntensityReq, PseudoIntensityResp } from "src/app/generated-protos/pseudo-intensities-msgs";
import { QuantCreateReq, QuantCreateResp, QuantCreateUpd } from "src/app/generated-protos/quantification-create";
import { QuantBlessReq, QuantBlessResp, QuantDeleteReq, QuantDeleteResp, QuantPublishReq, QuantPublishResp } from "src/app/generated-protos/quantification-management-msgs";
import { MultiQuantCompareReq, MultiQuantCompareResp, QuantCombineReq, QuantCombineResp, QuantCombineListGetReq, QuantCombineListGetResp, QuantCombineListWriteReq, QuantCombineListWriteResp } from "src/app/generated-protos/quantification-multi-msgs";
import { QuantGetReq, QuantGetResp, QuantLastOutputGetReq, QuantLastOutputGetResp, QuantListReq, QuantListResp, QuantLogGetReq, QuantLogGetResp, QuantLogListReq, QuantLogListResp, QuantRawDataGetReq, QuantRawDataGetResp } from "src/app/generated-protos/quantification-retrieval-msgs";
import { QuantUploadReq, QuantUploadResp } from "src/app/generated-protos/quantification-upload-msgs";
import { RegionOfInterestBulkDuplicateReq, RegionOfInterestBulkDuplicateResp, RegionOfInterestBulkWriteReq, RegionOfInterestBulkWriteResp, RegionOfInterestDeleteReq, RegionOfInterestDeleteResp, RegionOfInterestDisplaySettingsGetReq, RegionOfInterestDisplaySettingsGetResp, RegionOfInterestDisplaySettingsWriteReq, RegionOfInterestDisplaySettingsWriteResp, RegionOfInterestGetReq, RegionOfInterestGetResp, RegionOfInterestListReq, RegionOfInterestListResp, RegionOfInterestWriteReq, RegionOfInterestWriteResp } from "src/app/generated-protos/roi-msgs";
import { ScanBeamLocationsReq, ScanBeamLocationsResp } from "src/app/generated-protos/scan-beam-location-msgs";
import { ScanEntryMetadataReq, ScanEntryMetadataResp } from "src/app/generated-protos/scan-entry-metadata-msgs";
import { ScanEntryReq, ScanEntryResp } from "src/app/generated-protos/scan-entry-msgs";
import { ScanAutoShareReq, ScanAutoShareResp, ScanAutoShareWriteReq, ScanAutoShareWriteResp, ScanDeleteReq, ScanDeleteResp, ScanGetReq, ScanGetResp, ScanListReq, ScanListResp, ScanListUpd, ScanMetaLabelsAndTypesReq, ScanMetaLabelsAndTypesResp, ScanMetaWriteReq, ScanMetaWriteResp, ScanTriggerAutoQuantReq, ScanTriggerAutoQuantResp, ScanTriggerReImportReq, ScanTriggerReImportResp, ScanTriggerReImportUpd, ScanUploadReq, ScanUploadResp, ScanUploadUpd } from "src/app/generated-protos/scan-msgs";
import { ScreenConfigurationDeleteReq, ScreenConfigurationDeleteResp, ScreenConfigurationGetReq, ScreenConfigurationGetResp, ScreenConfigurationListReq, ScreenConfigurationListResp, ScreenConfigurationWriteReq, ScreenConfigurationWriteResp } from "src/app/generated-protos/screen-configuration-msgs";
import { SelectedScanEntriesReq, SelectedScanEntriesResp, SelectedScanEntriesWriteReq, SelectedScanEntriesWriteResp } from "src/app/generated-protos/selection-entry-msgs";
import { SelectedImagePixelsReq, SelectedImagePixelsResp, SelectedImagePixelsWriteReq, SelectedImagePixelsWriteResp } from "src/app/generated-protos/selection-pixel-msgs";
import { SpectrumReq, SpectrumResp } from "src/app/generated-protos/spectrum-msgs";
import { BackupDBReq, BackupDBResp, BackupDBUpd, DBAdminConfigGetReq, DBAdminConfigGetResp, RestoreDBReq, RestoreDBResp } from "src/app/generated-protos/system";
import { TagCreateReq, TagCreateResp, TagDeleteReq, TagDeleteResp, TagListReq, TagListResp } from "src/app/generated-protos/tag-msgs";
import { RunTestReq, RunTestResp } from "src/app/generated-protos/test-msgs";
import { UserGroupAddAdminReq, UserGroupAddAdminResp, UserGroupDeleteAdminReq, UserGroupDeleteAdminResp } from "src/app/generated-protos/user-group-admins-msgs";
import { UserGroupIgnoreJoinReq, UserGroupIgnoreJoinResp, UserGroupJoinReq, UserGroupJoinResp, UserGroupJoinListReq, UserGroupJoinListResp } from "src/app/generated-protos/user-group-joining-msgs";
import { UserGroupCreateReq, UserGroupCreateResp, UserGroupDeleteReq, UserGroupDeleteResp, UserGroupEditDetailsReq, UserGroupEditDetailsResp } from "src/app/generated-protos/user-group-management-msgs";
import { UserGroupAddMemberReq, UserGroupAddMemberResp, UserGroupAddViewerReq, UserGroupAddViewerResp, UserGroupDeleteMemberReq, UserGroupDeleteMemberResp, UserGroupDeleteViewerReq, UserGroupDeleteViewerResp } from "src/app/generated-protos/user-group-membership-msgs";
import { UserGroupReq, UserGroupResp, UserGroupListReq, UserGroupListResp, UserGroupListJoinableReq, UserGroupListJoinableResp } from "src/app/generated-protos/user-group-retrieval-msgs";
import { ReviewerMagicLinkCreateReq, ReviewerMagicLinkCreateResp, ReviewerMagicLinkLoginReq, ReviewerMagicLinkLoginResp, UserAddRoleReq, UserAddRoleResp, UserDeleteRoleReq, UserDeleteRoleResp, UserImpersonateReq, UserImpersonateResp, UserImpersonateGetReq, UserImpersonateGetResp, UserListReq, UserListResp, UserRoleListReq, UserRoleListResp, UserRolesListReq, UserRolesListResp } from "src/app/generated-protos/user-management-msgs";
import { UserDetailsReq, UserDetailsResp, UserDetailsWriteReq, UserDetailsWriteResp, UserSearchReq, UserSearchResp } from "src/app/generated-protos/user-msgs";
import { UserNotificationSettingsReq, UserNotificationSettingsResp, UserNotificationSettingsUpd, UserNotificationSettingsWriteReq, UserNotificationSettingsWriteResp } from "src/app/generated-protos/user-notification-setting-msgs";
import { WidgetDataGetReq, WidgetDataGetResp, WidgetDataWriteReq, WidgetDataWriteResp } from "src/app/generated-protos/widget-data-msgs";
import { WSMessage, ResponseStatus, responseStatusToJSON } from "src/app/generated-protos/websocket";

export class WSError extends Error {
  constructor(
    public status: ResponseStatus,
    public errorText: string,
    public messageName: string
  ) {
    super(errorText + " [" + messageName + ": " + responseStatusToJSON(status) + "]");
    this.name = "WSError";
  }
}

export class WSOustandingReq {
  private _createTime: number = 0;
  constructor(public req: WSMessage, public sub: Subject<any>) {
    this.resetCreateTime();
  }

  resetCreateTime() {
    this._createTime = performance.now();
  }

  get createTime(): number {
    return this._createTime;
  }
}

// Type-specific request send functions which return the right type of response
export abstract class WSMessageHandler {
  protected abstract sendRequest(msg: WSMessage, subj: Subject<any>): void;
  protected _outstandingRequests = new Map<number, WSOustandingReq>();

  public backupDBUpd$ = new Subject<BackupDBUpd>();
  public imageListUpd$ = new Subject<ImageListUpd>();
  public importMarsViewerImageUpd$ = new Subject<ImportMarsViewerImageUpd>();
  public jobListUpd$ = new Subject<JobListUpd>();
  public notificationUpd$ = new Subject<NotificationUpd>();
  public quantCreateUpd$ = new Subject<QuantCreateUpd>();
  public scanListUpd$ = new Subject<ScanListUpd>();
  public scanTriggerReImportUpd$ = new Subject<ScanTriggerReImportUpd>();
  public scanUploadUpd$ = new Subject<ScanUploadUpd>();
  public userNotificationSettingsUpd$ = new Subject<UserNotificationSettingsUpd>();

  sendBackupDBRequest(req: BackupDBReq): Subject<BackupDBResp> {
    const subj = new Subject<BackupDBResp>();
    this.sendRequest(WSMessage.create({ backupDBReq: req }), subj);
    return subj;
  }

  sendDBAdminConfigGetRequest(req: DBAdminConfigGetReq): Subject<DBAdminConfigGetResp> {
    const subj = new Subject<DBAdminConfigGetResp>();
    this.sendRequest(WSMessage.create({ dBAdminConfigGetReq: req }), subj);
    return subj;
  }

  sendDataModuleAddVersionRequest(req: DataModuleAddVersionReq): Subject<DataModuleAddVersionResp> {
    const subj = new Subject<DataModuleAddVersionResp>();
    this.sendRequest(WSMessage.create({ dataModuleAddVersionReq: req }), subj);
    return subj;
  }

  sendDataModuleGetRequest(req: DataModuleGetReq): Subject<DataModuleGetResp> {
    const subj = new Subject<DataModuleGetResp>();
    this.sendRequest(WSMessage.create({ dataModuleGetReq: req }), subj);
    return subj;
  }

  sendDataModuleListRequest(req: DataModuleListReq): Subject<DataModuleListResp> {
    const subj = new Subject<DataModuleListResp>();
    this.sendRequest(WSMessage.create({ dataModuleListReq: req }), subj);
    return subj;
  }

  sendDataModuleWriteRequest(req: DataModuleWriteReq): Subject<DataModuleWriteResp> {
    const subj = new Subject<DataModuleWriteResp>();
    this.sendRequest(WSMessage.create({ dataModuleWriteReq: req }), subj);
    return subj;
  }

  sendDetectedDiffractionPeaksRequest(req: DetectedDiffractionPeaksReq): Subject<DetectedDiffractionPeaksResp> {
    const subj = new Subject<DetectedDiffractionPeaksResp>();
    this.sendRequest(WSMessage.create({ detectedDiffractionPeaksReq: req }), subj);
    return subj;
  }

  sendDetectorConfigRequest(req: DetectorConfigReq): Subject<DetectorConfigResp> {
    const subj = new Subject<DetectorConfigResp>();
    this.sendRequest(WSMessage.create({ detectorConfigReq: req }), subj);
    return subj;
  }

  sendDetectorConfigListRequest(req: DetectorConfigListReq): Subject<DetectorConfigListResp> {
    const subj = new Subject<DetectorConfigListResp>();
    this.sendRequest(WSMessage.create({ detectorConfigListReq: req }), subj);
    return subj;
  }

  sendDiffractionPeakManualDeleteRequest(req: DiffractionPeakManualDeleteReq): Subject<DiffractionPeakManualDeleteResp> {
    const subj = new Subject<DiffractionPeakManualDeleteResp>();
    this.sendRequest(WSMessage.create({ diffractionPeakManualDeleteReq: req }), subj);
    return subj;
  }

  sendDiffractionPeakManualInsertRequest(req: DiffractionPeakManualInsertReq): Subject<DiffractionPeakManualInsertResp> {
    const subj = new Subject<DiffractionPeakManualInsertResp>();
    this.sendRequest(WSMessage.create({ diffractionPeakManualInsertReq: req }), subj);
    return subj;
  }

  sendDiffractionPeakManualListRequest(req: DiffractionPeakManualListReq): Subject<DiffractionPeakManualListResp> {
    const subj = new Subject<DiffractionPeakManualListResp>();
    this.sendRequest(WSMessage.create({ diffractionPeakManualListReq: req }), subj);
    return subj;
  }

  sendDiffractionPeakStatusDeleteRequest(req: DiffractionPeakStatusDeleteReq): Subject<DiffractionPeakStatusDeleteResp> {
    const subj = new Subject<DiffractionPeakStatusDeleteResp>();
    this.sendRequest(WSMessage.create({ diffractionPeakStatusDeleteReq: req }), subj);
    return subj;
  }

  sendDiffractionPeakStatusListRequest(req: DiffractionPeakStatusListReq): Subject<DiffractionPeakStatusListResp> {
    const subj = new Subject<DiffractionPeakStatusListResp>();
    this.sendRequest(WSMessage.create({ diffractionPeakStatusListReq: req }), subj);
    return subj;
  }

  sendDiffractionPeakStatusWriteRequest(req: DiffractionPeakStatusWriteReq): Subject<DiffractionPeakStatusWriteResp> {
    const subj = new Subject<DiffractionPeakStatusWriteResp>();
    this.sendRequest(WSMessage.create({ diffractionPeakStatusWriteReq: req }), subj);
    return subj;
  }

  sendElementSetDeleteRequest(req: ElementSetDeleteReq): Subject<ElementSetDeleteResp> {
    const subj = new Subject<ElementSetDeleteResp>();
    this.sendRequest(WSMessage.create({ elementSetDeleteReq: req }), subj);
    return subj;
  }

  sendElementSetGetRequest(req: ElementSetGetReq): Subject<ElementSetGetResp> {
    const subj = new Subject<ElementSetGetResp>();
    this.sendRequest(WSMessage.create({ elementSetGetReq: req }), subj);
    return subj;
  }

  sendElementSetListRequest(req: ElementSetListReq): Subject<ElementSetListResp> {
    const subj = new Subject<ElementSetListResp>();
    this.sendRequest(WSMessage.create({ elementSetListReq: req }), subj);
    return subj;
  }

  sendElementSetWriteRequest(req: ElementSetWriteReq): Subject<ElementSetWriteResp> {
    const subj = new Subject<ElementSetWriteResp>();
    this.sendRequest(WSMessage.create({ elementSetWriteReq: req }), subj);
    return subj;
  }

  sendExportFilesRequest(req: ExportFilesReq): Subject<ExportFilesResp> {
    const subj = new Subject<ExportFilesResp>();
    this.sendRequest(WSMessage.create({ exportFilesReq: req }), subj);
    return subj;
  }

  sendExpressionDeleteRequest(req: ExpressionDeleteReq): Subject<ExpressionDeleteResp> {
    const subj = new Subject<ExpressionDeleteResp>();
    this.sendRequest(WSMessage.create({ expressionDeleteReq: req }), subj);
    return subj;
  }

  sendExpressionDisplaySettingsGetRequest(req: ExpressionDisplaySettingsGetReq): Subject<ExpressionDisplaySettingsGetResp> {
    const subj = new Subject<ExpressionDisplaySettingsGetResp>();
    this.sendRequest(WSMessage.create({ expressionDisplaySettingsGetReq: req }), subj);
    return subj;
  }

  sendExpressionDisplaySettingsWriteRequest(req: ExpressionDisplaySettingsWriteReq): Subject<ExpressionDisplaySettingsWriteResp> {
    const subj = new Subject<ExpressionDisplaySettingsWriteResp>();
    this.sendRequest(WSMessage.create({ expressionDisplaySettingsWriteReq: req }), subj);
    return subj;
  }

  sendExpressionGetRequest(req: ExpressionGetReq): Subject<ExpressionGetResp> {
    const subj = new Subject<ExpressionGetResp>();
    this.sendRequest(WSMessage.create({ expressionGetReq: req }), subj);
    return subj;
  }

  sendExpressionGroupDeleteRequest(req: ExpressionGroupDeleteReq): Subject<ExpressionGroupDeleteResp> {
    const subj = new Subject<ExpressionGroupDeleteResp>();
    this.sendRequest(WSMessage.create({ expressionGroupDeleteReq: req }), subj);
    return subj;
  }

  sendExpressionGroupGetRequest(req: ExpressionGroupGetReq): Subject<ExpressionGroupGetResp> {
    const subj = new Subject<ExpressionGroupGetResp>();
    this.sendRequest(WSMessage.create({ expressionGroupGetReq: req }), subj);
    return subj;
  }

  sendExpressionGroupListRequest(req: ExpressionGroupListReq): Subject<ExpressionGroupListResp> {
    const subj = new Subject<ExpressionGroupListResp>();
    this.sendRequest(WSMessage.create({ expressionGroupListReq: req }), subj);
    return subj;
  }

  sendExpressionGroupWriteRequest(req: ExpressionGroupWriteReq): Subject<ExpressionGroupWriteResp> {
    const subj = new Subject<ExpressionGroupWriteResp>();
    this.sendRequest(WSMessage.create({ expressionGroupWriteReq: req }), subj);
    return subj;
  }

  sendExpressionListRequest(req: ExpressionListReq): Subject<ExpressionListResp> {
    const subj = new Subject<ExpressionListResp>();
    this.sendRequest(WSMessage.create({ expressionListReq: req }), subj);
    return subj;
  }

  sendExpressionWriteRequest(req: ExpressionWriteReq): Subject<ExpressionWriteResp> {
    const subj = new Subject<ExpressionWriteResp>();
    this.sendRequest(WSMessage.create({ expressionWriteReq: req }), subj);
    return subj;
  }

  sendExpressionWriteExecStatRequest(req: ExpressionWriteExecStatReq): Subject<ExpressionWriteExecStatResp> {
    const subj = new Subject<ExpressionWriteExecStatResp>();
    this.sendRequest(WSMessage.create({ expressionWriteExecStatReq: req }), subj);
    return subj;
  }

  sendGetOwnershipRequest(req: GetOwnershipReq): Subject<GetOwnershipResp> {
    const subj = new Subject<GetOwnershipResp>();
    this.sendRequest(WSMessage.create({ getOwnershipReq: req }), subj);
    return subj;
  }

  sendImageBeamLocationVersionsRequest(req: ImageBeamLocationVersionsReq): Subject<ImageBeamLocationVersionsResp> {
    const subj = new Subject<ImageBeamLocationVersionsResp>();
    this.sendRequest(WSMessage.create({ imageBeamLocationVersionsReq: req }), subj);
    return subj;
  }

  sendImageBeamLocationsRequest(req: ImageBeamLocationsReq): Subject<ImageBeamLocationsResp> {
    const subj = new Subject<ImageBeamLocationsResp>();
    this.sendRequest(WSMessage.create({ imageBeamLocationsReq: req }), subj);
    return subj;
  }

  sendImageDeleteRequest(req: ImageDeleteReq): Subject<ImageDeleteResp> {
    const subj = new Subject<ImageDeleteResp>();
    this.sendRequest(WSMessage.create({ imageDeleteReq: req }), subj);
    return subj;
  }

  sendImageGetRequest(req: ImageGetReq): Subject<ImageGetResp> {
    const subj = new Subject<ImageGetResp>();
    this.sendRequest(WSMessage.create({ imageGetReq: req }), subj);
    return subj;
  }

  sendImageGetDefaultRequest(req: ImageGetDefaultReq): Subject<ImageGetDefaultResp> {
    const subj = new Subject<ImageGetDefaultResp>();
    this.sendRequest(WSMessage.create({ imageGetDefaultReq: req }), subj);
    return subj;
  }

  sendImageListRequest(req: ImageListReq): Subject<ImageListResp> {
    const subj = new Subject<ImageListResp>();
    this.sendRequest(WSMessage.create({ imageListReq: req }), subj);
    return subj;
  }

  sendImageSetDefaultRequest(req: ImageSetDefaultReq): Subject<ImageSetDefaultResp> {
    const subj = new Subject<ImageSetDefaultResp>();
    this.sendRequest(WSMessage.create({ imageSetDefaultReq: req }), subj);
    return subj;
  }

  sendImageSetMatchTransformRequest(req: ImageSetMatchTransformReq): Subject<ImageSetMatchTransformResp> {
    const subj = new Subject<ImageSetMatchTransformResp>();
    this.sendRequest(WSMessage.create({ imageSetMatchTransformReq: req }), subj);
    return subj;
  }

  sendImportMarsViewerImageRequest(req: ImportMarsViewerImageReq): Subject<ImportMarsViewerImageResp> {
    const subj = new Subject<ImportMarsViewerImageResp>();
    this.sendRequest(WSMessage.create({ importMarsViewerImageReq: req }), subj);
    return subj;
  }

  sendJobListRequest(req: JobListReq): Subject<JobListResp> {
    const subj = new Subject<JobListResp>();
    this.sendRequest(WSMessage.create({ jobListReq: req }), subj);
    return subj;
  }

  sendLogGetLevelRequest(req: LogGetLevelReq): Subject<LogGetLevelResp> {
    const subj = new Subject<LogGetLevelResp>();
    this.sendRequest(WSMessage.create({ logGetLevelReq: req }), subj);
    return subj;
  }

  sendLogReadRequest(req: LogReadReq): Subject<LogReadResp> {
    const subj = new Subject<LogReadResp>();
    this.sendRequest(WSMessage.create({ logReadReq: req }), subj);
    return subj;
  }

  sendLogSetLevelRequest(req: LogSetLevelReq): Subject<LogSetLevelResp> {
    const subj = new Subject<LogSetLevelResp>();
    this.sendRequest(WSMessage.create({ logSetLevelReq: req }), subj);
    return subj;
  }

  sendMemoiseGetRequest(req: MemoiseGetReq): Subject<MemoiseGetResp> {
    const subj = new Subject<MemoiseGetResp>();
    this.sendRequest(WSMessage.create({ memoiseGetReq: req }), subj);
    return subj;
  }

  sendMemoiseWriteRequest(req: MemoiseWriteReq): Subject<MemoiseWriteResp> {
    const subj = new Subject<MemoiseWriteResp>();
    this.sendRequest(WSMessage.create({ memoiseWriteReq: req }), subj);
    return subj;
  }

  sendMultiQuantCompareRequest(req: MultiQuantCompareReq): Subject<MultiQuantCompareResp> {
    const subj = new Subject<MultiQuantCompareResp>();
    this.sendRequest(WSMessage.create({ multiQuantCompareReq: req }), subj);
    return subj;
  }

  sendNotificationRequest(req: NotificationReq): Subject<NotificationResp> {
    const subj = new Subject<NotificationResp>();
    this.sendRequest(WSMessage.create({ notificationReq: req }), subj);
    return subj;
  }

  sendNotificationDismissRequest(req: NotificationDismissReq): Subject<NotificationDismissResp> {
    const subj = new Subject<NotificationDismissResp>();
    this.sendRequest(WSMessage.create({ notificationDismissReq: req }), subj);
    return subj;
  }

  sendObjectEditAccessRequest(req: ObjectEditAccessReq): Subject<ObjectEditAccessResp> {
    const subj = new Subject<ObjectEditAccessResp>();
    this.sendRequest(WSMessage.create({ objectEditAccessReq: req }), subj);
    return subj;
  }

  sendPiquantConfigListRequest(req: PiquantConfigListReq): Subject<PiquantConfigListResp> {
    const subj = new Subject<PiquantConfigListResp>();
    this.sendRequest(WSMessage.create({ piquantConfigListReq: req }), subj);
    return subj;
  }

  sendPiquantConfigVersionRequest(req: PiquantConfigVersionReq): Subject<PiquantConfigVersionResp> {
    const subj = new Subject<PiquantConfigVersionResp>();
    this.sendRequest(WSMessage.create({ piquantConfigVersionReq: req }), subj);
    return subj;
  }

  sendPiquantConfigVersionsListRequest(req: PiquantConfigVersionsListReq): Subject<PiquantConfigVersionsListResp> {
    const subj = new Subject<PiquantConfigVersionsListResp>();
    this.sendRequest(WSMessage.create({ piquantConfigVersionsListReq: req }), subj);
    return subj;
  }

  sendPiquantCurrentVersionRequest(req: PiquantCurrentVersionReq): Subject<PiquantCurrentVersionResp> {
    const subj = new Subject<PiquantCurrentVersionResp>();
    this.sendRequest(WSMessage.create({ piquantCurrentVersionReq: req }), subj);
    return subj;
  }

  sendPiquantVersionListRequest(req: PiquantVersionListReq): Subject<PiquantVersionListResp> {
    const subj = new Subject<PiquantVersionListResp>();
    this.sendRequest(WSMessage.create({ piquantVersionListReq: req }), subj);
    return subj;
  }

  sendPiquantWriteCurrentVersionRequest(req: PiquantWriteCurrentVersionReq): Subject<PiquantWriteCurrentVersionResp> {
    const subj = new Subject<PiquantWriteCurrentVersionResp>();
    this.sendRequest(WSMessage.create({ piquantWriteCurrentVersionReq: req }), subj);
    return subj;
  }

  sendPseudoIntensityRequest(req: PseudoIntensityReq): Subject<PseudoIntensityResp> {
    const subj = new Subject<PseudoIntensityResp>();
    this.sendRequest(WSMessage.create({ pseudoIntensityReq: req }), subj);
    return subj;
  }

  sendPublishExpressionToZenodoRequest(req: PublishExpressionToZenodoReq): Subject<PublishExpressionToZenodoResp> {
    const subj = new Subject<PublishExpressionToZenodoResp>();
    this.sendRequest(WSMessage.create({ publishExpressionToZenodoReq: req }), subj);
    return subj;
  }

  sendQuantBlessRequest(req: QuantBlessReq): Subject<QuantBlessResp> {
    const subj = new Subject<QuantBlessResp>();
    this.sendRequest(WSMessage.create({ quantBlessReq: req }), subj);
    return subj;
  }

  sendQuantCombineRequest(req: QuantCombineReq): Subject<QuantCombineResp> {
    const subj = new Subject<QuantCombineResp>();
    this.sendRequest(WSMessage.create({ quantCombineReq: req }), subj);
    return subj;
  }

  sendQuantCombineListGetRequest(req: QuantCombineListGetReq): Subject<QuantCombineListGetResp> {
    const subj = new Subject<QuantCombineListGetResp>();
    this.sendRequest(WSMessage.create({ quantCombineListGetReq: req }), subj);
    return subj;
  }

  sendQuantCombineListWriteRequest(req: QuantCombineListWriteReq): Subject<QuantCombineListWriteResp> {
    const subj = new Subject<QuantCombineListWriteResp>();
    this.sendRequest(WSMessage.create({ quantCombineListWriteReq: req }), subj);
    return subj;
  }

  sendQuantCreateRequest(req: QuantCreateReq): Subject<QuantCreateResp> {
    const subj = new Subject<QuantCreateResp>();
    this.sendRequest(WSMessage.create({ quantCreateReq: req }), subj);
    return subj;
  }

  sendQuantDeleteRequest(req: QuantDeleteReq): Subject<QuantDeleteResp> {
    const subj = new Subject<QuantDeleteResp>();
    this.sendRequest(WSMessage.create({ quantDeleteReq: req }), subj);
    return subj;
  }

  sendQuantGetRequest(req: QuantGetReq): Subject<QuantGetResp> {
    const subj = new Subject<QuantGetResp>();
    this.sendRequest(WSMessage.create({ quantGetReq: req }), subj);
    return subj;
  }

  sendQuantLastOutputGetRequest(req: QuantLastOutputGetReq): Subject<QuantLastOutputGetResp> {
    const subj = new Subject<QuantLastOutputGetResp>();
    this.sendRequest(WSMessage.create({ quantLastOutputGetReq: req }), subj);
    return subj;
  }

  sendQuantListRequest(req: QuantListReq): Subject<QuantListResp> {
    const subj = new Subject<QuantListResp>();
    this.sendRequest(WSMessage.create({ quantListReq: req }), subj);
    return subj;
  }

  sendQuantLogGetRequest(req: QuantLogGetReq): Subject<QuantLogGetResp> {
    const subj = new Subject<QuantLogGetResp>();
    this.sendRequest(WSMessage.create({ quantLogGetReq: req }), subj);
    return subj;
  }

  sendQuantLogListRequest(req: QuantLogListReq): Subject<QuantLogListResp> {
    const subj = new Subject<QuantLogListResp>();
    this.sendRequest(WSMessage.create({ quantLogListReq: req }), subj);
    return subj;
  }

  sendQuantPublishRequest(req: QuantPublishReq): Subject<QuantPublishResp> {
    const subj = new Subject<QuantPublishResp>();
    this.sendRequest(WSMessage.create({ quantPublishReq: req }), subj);
    return subj;
  }

  sendQuantRawDataGetRequest(req: QuantRawDataGetReq): Subject<QuantRawDataGetResp> {
    const subj = new Subject<QuantRawDataGetResp>();
    this.sendRequest(WSMessage.create({ quantRawDataGetReq: req }), subj);
    return subj;
  }

  sendQuantUploadRequest(req: QuantUploadReq): Subject<QuantUploadResp> {
    const subj = new Subject<QuantUploadResp>();
    this.sendRequest(WSMessage.create({ quantUploadReq: req }), subj);
    return subj;
  }

  sendRegionOfInterestBulkDuplicateRequest(req: RegionOfInterestBulkDuplicateReq): Subject<RegionOfInterestBulkDuplicateResp> {
    const subj = new Subject<RegionOfInterestBulkDuplicateResp>();
    this.sendRequest(WSMessage.create({ regionOfInterestBulkDuplicateReq: req }), subj);
    return subj;
  }

  sendRegionOfInterestBulkWriteRequest(req: RegionOfInterestBulkWriteReq): Subject<RegionOfInterestBulkWriteResp> {
    const subj = new Subject<RegionOfInterestBulkWriteResp>();
    this.sendRequest(WSMessage.create({ regionOfInterestBulkWriteReq: req }), subj);
    return subj;
  }

  sendRegionOfInterestDeleteRequest(req: RegionOfInterestDeleteReq): Subject<RegionOfInterestDeleteResp> {
    const subj = new Subject<RegionOfInterestDeleteResp>();
    this.sendRequest(WSMessage.create({ regionOfInterestDeleteReq: req }), subj);
    return subj;
  }

  sendRegionOfInterestDisplaySettingsGetRequest(req: RegionOfInterestDisplaySettingsGetReq): Subject<RegionOfInterestDisplaySettingsGetResp> {
    const subj = new Subject<RegionOfInterestDisplaySettingsGetResp>();
    this.sendRequest(WSMessage.create({ regionOfInterestDisplaySettingsGetReq: req }), subj);
    return subj;
  }

  sendRegionOfInterestDisplaySettingsWriteRequest(req: RegionOfInterestDisplaySettingsWriteReq): Subject<RegionOfInterestDisplaySettingsWriteResp> {
    const subj = new Subject<RegionOfInterestDisplaySettingsWriteResp>();
    this.sendRequest(WSMessage.create({ regionOfInterestDisplaySettingsWriteReq: req }), subj);
    return subj;
  }

  sendRegionOfInterestGetRequest(req: RegionOfInterestGetReq): Subject<RegionOfInterestGetResp> {
    const subj = new Subject<RegionOfInterestGetResp>();
    this.sendRequest(WSMessage.create({ regionOfInterestGetReq: req }), subj);
    return subj;
  }

  sendRegionOfInterestListRequest(req: RegionOfInterestListReq): Subject<RegionOfInterestListResp> {
    const subj = new Subject<RegionOfInterestListResp>();
    this.sendRequest(WSMessage.create({ regionOfInterestListReq: req }), subj);
    return subj;
  }

  sendRegionOfInterestWriteRequest(req: RegionOfInterestWriteReq): Subject<RegionOfInterestWriteResp> {
    const subj = new Subject<RegionOfInterestWriteResp>();
    this.sendRequest(WSMessage.create({ regionOfInterestWriteReq: req }), subj);
    return subj;
  }

  sendRestoreDBRequest(req: RestoreDBReq): Subject<RestoreDBResp> {
    const subj = new Subject<RestoreDBResp>();
    this.sendRequest(WSMessage.create({ restoreDBReq: req }), subj);
    return subj;
  }

  sendReviewerMagicLinkCreateRequest(req: ReviewerMagicLinkCreateReq): Subject<ReviewerMagicLinkCreateResp> {
    const subj = new Subject<ReviewerMagicLinkCreateResp>();
    this.sendRequest(WSMessage.create({ reviewerMagicLinkCreateReq: req }), subj);
    return subj;
  }

  sendReviewerMagicLinkLoginRequest(req: ReviewerMagicLinkLoginReq): Subject<ReviewerMagicLinkLoginResp> {
    const subj = new Subject<ReviewerMagicLinkLoginResp>();
    this.sendRequest(WSMessage.create({ reviewerMagicLinkLoginReq: req }), subj);
    return subj;
  }

  sendRunTestRequest(req: RunTestReq): Subject<RunTestResp> {
    const subj = new Subject<RunTestResp>();
    this.sendRequest(WSMessage.create({ runTestReq: req }), subj);
    return subj;
  }

  sendScanAutoShareRequest(req: ScanAutoShareReq): Subject<ScanAutoShareResp> {
    const subj = new Subject<ScanAutoShareResp>();
    this.sendRequest(WSMessage.create({ scanAutoShareReq: req }), subj);
    return subj;
  }

  sendScanAutoShareWriteRequest(req: ScanAutoShareWriteReq): Subject<ScanAutoShareWriteResp> {
    const subj = new Subject<ScanAutoShareWriteResp>();
    this.sendRequest(WSMessage.create({ scanAutoShareWriteReq: req }), subj);
    return subj;
  }

  sendScanBeamLocationsRequest(req: ScanBeamLocationsReq): Subject<ScanBeamLocationsResp> {
    const subj = new Subject<ScanBeamLocationsResp>();
    this.sendRequest(WSMessage.create({ scanBeamLocationsReq: req }), subj);
    return subj;
  }

  sendScanDeleteRequest(req: ScanDeleteReq): Subject<ScanDeleteResp> {
    const subj = new Subject<ScanDeleteResp>();
    this.sendRequest(WSMessage.create({ scanDeleteReq: req }), subj);
    return subj;
  }

  sendScanEntryRequest(req: ScanEntryReq): Subject<ScanEntryResp> {
    const subj = new Subject<ScanEntryResp>();
    this.sendRequest(WSMessage.create({ scanEntryReq: req }), subj);
    return subj;
  }

  sendScanEntryMetadataRequest(req: ScanEntryMetadataReq): Subject<ScanEntryMetadataResp> {
    const subj = new Subject<ScanEntryMetadataResp>();
    this.sendRequest(WSMessage.create({ scanEntryMetadataReq: req }), subj);
    return subj;
  }

  sendScanGetRequest(req: ScanGetReq): Subject<ScanGetResp> {
    const subj = new Subject<ScanGetResp>();
    this.sendRequest(WSMessage.create({ scanGetReq: req }), subj);
    return subj;
  }

  sendScanListRequest(req: ScanListReq): Subject<ScanListResp> {
    const subj = new Subject<ScanListResp>();
    this.sendRequest(WSMessage.create({ scanListReq: req }), subj);
    return subj;
  }

  sendScanMetaLabelsAndTypesRequest(req: ScanMetaLabelsAndTypesReq): Subject<ScanMetaLabelsAndTypesResp> {
    const subj = new Subject<ScanMetaLabelsAndTypesResp>();
    this.sendRequest(WSMessage.create({ scanMetaLabelsAndTypesReq: req }), subj);
    return subj;
  }

  sendScanMetaWriteRequest(req: ScanMetaWriteReq): Subject<ScanMetaWriteResp> {
    const subj = new Subject<ScanMetaWriteResp>();
    this.sendRequest(WSMessage.create({ scanMetaWriteReq: req }), subj);
    return subj;
  }

  sendScanTriggerAutoQuantRequest(req: ScanTriggerAutoQuantReq): Subject<ScanTriggerAutoQuantResp> {
    const subj = new Subject<ScanTriggerAutoQuantResp>();
    this.sendRequest(WSMessage.create({ scanTriggerAutoQuantReq: req }), subj);
    return subj;
  }

  sendScanTriggerReImportRequest(req: ScanTriggerReImportReq): Subject<ScanTriggerReImportResp> {
    const subj = new Subject<ScanTriggerReImportResp>();
    this.sendRequest(WSMessage.create({ scanTriggerReImportReq: req }), subj);
    return subj;
  }

  sendScanUploadRequest(req: ScanUploadReq): Subject<ScanUploadResp> {
    const subj = new Subject<ScanUploadResp>();
    this.sendRequest(WSMessage.create({ scanUploadReq: req }), subj);
    return subj;
  }

  sendScreenConfigurationDeleteRequest(req: ScreenConfigurationDeleteReq): Subject<ScreenConfigurationDeleteResp> {
    const subj = new Subject<ScreenConfigurationDeleteResp>();
    this.sendRequest(WSMessage.create({ screenConfigurationDeleteReq: req }), subj);
    return subj;
  }

  sendScreenConfigurationGetRequest(req: ScreenConfigurationGetReq): Subject<ScreenConfigurationGetResp> {
    const subj = new Subject<ScreenConfigurationGetResp>();
    this.sendRequest(WSMessage.create({ screenConfigurationGetReq: req }), subj);
    return subj;
  }

  sendScreenConfigurationListRequest(req: ScreenConfigurationListReq): Subject<ScreenConfigurationListResp> {
    const subj = new Subject<ScreenConfigurationListResp>();
    this.sendRequest(WSMessage.create({ screenConfigurationListReq: req }), subj);
    return subj;
  }

  sendScreenConfigurationWriteRequest(req: ScreenConfigurationWriteReq): Subject<ScreenConfigurationWriteResp> {
    const subj = new Subject<ScreenConfigurationWriteResp>();
    this.sendRequest(WSMessage.create({ screenConfigurationWriteReq: req }), subj);
    return subj;
  }

  sendSelectedImagePixelsRequest(req: SelectedImagePixelsReq): Subject<SelectedImagePixelsResp> {
    const subj = new Subject<SelectedImagePixelsResp>();
    this.sendRequest(WSMessage.create({ selectedImagePixelsReq: req }), subj);
    return subj;
  }

  sendSelectedImagePixelsWriteRequest(req: SelectedImagePixelsWriteReq): Subject<SelectedImagePixelsWriteResp> {
    const subj = new Subject<SelectedImagePixelsWriteResp>();
    this.sendRequest(WSMessage.create({ selectedImagePixelsWriteReq: req }), subj);
    return subj;
  }

  sendSelectedScanEntriesRequest(req: SelectedScanEntriesReq): Subject<SelectedScanEntriesResp> {
    const subj = new Subject<SelectedScanEntriesResp>();
    this.sendRequest(WSMessage.create({ selectedScanEntriesReq: req }), subj);
    return subj;
  }

  sendSelectedScanEntriesWriteRequest(req: SelectedScanEntriesWriteReq): Subject<SelectedScanEntriesWriteResp> {
    const subj = new Subject<SelectedScanEntriesWriteResp>();
    this.sendRequest(WSMessage.create({ selectedScanEntriesWriteReq: req }), subj);
    return subj;
  }

  sendSendUserNotificationRequest(req: SendUserNotificationReq): Subject<SendUserNotificationResp> {
    const subj = new Subject<SendUserNotificationResp>();
    this.sendRequest(WSMessage.create({ sendUserNotificationReq: req }), subj);
    return subj;
  }

  sendSpectrumRequest(req: SpectrumReq): Subject<SpectrumResp> {
    const subj = new Subject<SpectrumResp>();
    this.sendRequest(WSMessage.create({ spectrumReq: req }), subj);
    return subj;
  }

  sendTagCreateRequest(req: TagCreateReq): Subject<TagCreateResp> {
    const subj = new Subject<TagCreateResp>();
    this.sendRequest(WSMessage.create({ tagCreateReq: req }), subj);
    return subj;
  }

  sendTagDeleteRequest(req: TagDeleteReq): Subject<TagDeleteResp> {
    const subj = new Subject<TagDeleteResp>();
    this.sendRequest(WSMessage.create({ tagDeleteReq: req }), subj);
    return subj;
  }

  sendTagListRequest(req: TagListReq): Subject<TagListResp> {
    const subj = new Subject<TagListResp>();
    this.sendRequest(WSMessage.create({ tagListReq: req }), subj);
    return subj;
  }

  sendUserAddRoleRequest(req: UserAddRoleReq): Subject<UserAddRoleResp> {
    const subj = new Subject<UserAddRoleResp>();
    this.sendRequest(WSMessage.create({ userAddRoleReq: req }), subj);
    return subj;
  }

  sendUserDeleteRoleRequest(req: UserDeleteRoleReq): Subject<UserDeleteRoleResp> {
    const subj = new Subject<UserDeleteRoleResp>();
    this.sendRequest(WSMessage.create({ userDeleteRoleReq: req }), subj);
    return subj;
  }

  sendUserDetailsRequest(req: UserDetailsReq): Subject<UserDetailsResp> {
    const subj = new Subject<UserDetailsResp>();
    this.sendRequest(WSMessage.create({ userDetailsReq: req }), subj);
    return subj;
  }

  sendUserDetailsWriteRequest(req: UserDetailsWriteReq): Subject<UserDetailsWriteResp> {
    const subj = new Subject<UserDetailsWriteResp>();
    this.sendRequest(WSMessage.create({ userDetailsWriteReq: req }), subj);
    return subj;
  }

  sendUserGroupRequest(req: UserGroupReq): Subject<UserGroupResp> {
    const subj = new Subject<UserGroupResp>();
    this.sendRequest(WSMessage.create({ userGroupReq: req }), subj);
    return subj;
  }

  sendUserGroupAddAdminRequest(req: UserGroupAddAdminReq): Subject<UserGroupAddAdminResp> {
    const subj = new Subject<UserGroupAddAdminResp>();
    this.sendRequest(WSMessage.create({ userGroupAddAdminReq: req }), subj);
    return subj;
  }

  sendUserGroupAddMemberRequest(req: UserGroupAddMemberReq): Subject<UserGroupAddMemberResp> {
    const subj = new Subject<UserGroupAddMemberResp>();
    this.sendRequest(WSMessage.create({ userGroupAddMemberReq: req }), subj);
    return subj;
  }

  sendUserGroupAddViewerRequest(req: UserGroupAddViewerReq): Subject<UserGroupAddViewerResp> {
    const subj = new Subject<UserGroupAddViewerResp>();
    this.sendRequest(WSMessage.create({ userGroupAddViewerReq: req }), subj);
    return subj;
  }

  sendUserGroupCreateRequest(req: UserGroupCreateReq): Subject<UserGroupCreateResp> {
    const subj = new Subject<UserGroupCreateResp>();
    this.sendRequest(WSMessage.create({ userGroupCreateReq: req }), subj);
    return subj;
  }

  sendUserGroupDeleteRequest(req: UserGroupDeleteReq): Subject<UserGroupDeleteResp> {
    const subj = new Subject<UserGroupDeleteResp>();
    this.sendRequest(WSMessage.create({ userGroupDeleteReq: req }), subj);
    return subj;
  }

  sendUserGroupDeleteAdminRequest(req: UserGroupDeleteAdminReq): Subject<UserGroupDeleteAdminResp> {
    const subj = new Subject<UserGroupDeleteAdminResp>();
    this.sendRequest(WSMessage.create({ userGroupDeleteAdminReq: req }), subj);
    return subj;
  }

  sendUserGroupDeleteMemberRequest(req: UserGroupDeleteMemberReq): Subject<UserGroupDeleteMemberResp> {
    const subj = new Subject<UserGroupDeleteMemberResp>();
    this.sendRequest(WSMessage.create({ userGroupDeleteMemberReq: req }), subj);
    return subj;
  }

  sendUserGroupDeleteViewerRequest(req: UserGroupDeleteViewerReq): Subject<UserGroupDeleteViewerResp> {
    const subj = new Subject<UserGroupDeleteViewerResp>();
    this.sendRequest(WSMessage.create({ userGroupDeleteViewerReq: req }), subj);
    return subj;
  }

  sendUserGroupEditDetailsRequest(req: UserGroupEditDetailsReq): Subject<UserGroupEditDetailsResp> {
    const subj = new Subject<UserGroupEditDetailsResp>();
    this.sendRequest(WSMessage.create({ userGroupEditDetailsReq: req }), subj);
    return subj;
  }

  sendUserGroupIgnoreJoinRequest(req: UserGroupIgnoreJoinReq): Subject<UserGroupIgnoreJoinResp> {
    const subj = new Subject<UserGroupIgnoreJoinResp>();
    this.sendRequest(WSMessage.create({ userGroupIgnoreJoinReq: req }), subj);
    return subj;
  }

  sendUserGroupJoinRequest(req: UserGroupJoinReq): Subject<UserGroupJoinResp> {
    const subj = new Subject<UserGroupJoinResp>();
    this.sendRequest(WSMessage.create({ userGroupJoinReq: req }), subj);
    return subj;
  }

  sendUserGroupJoinListRequest(req: UserGroupJoinListReq): Subject<UserGroupJoinListResp> {
    const subj = new Subject<UserGroupJoinListResp>();
    this.sendRequest(WSMessage.create({ userGroupJoinListReq: req }), subj);
    return subj;
  }

  sendUserGroupListRequest(req: UserGroupListReq): Subject<UserGroupListResp> {
    const subj = new Subject<UserGroupListResp>();
    this.sendRequest(WSMessage.create({ userGroupListReq: req }), subj);
    return subj;
  }

  sendUserGroupListJoinableRequest(req: UserGroupListJoinableReq): Subject<UserGroupListJoinableResp> {
    const subj = new Subject<UserGroupListJoinableResp>();
    this.sendRequest(WSMessage.create({ userGroupListJoinableReq: req }), subj);
    return subj;
  }

  sendUserImpersonateRequest(req: UserImpersonateReq): Subject<UserImpersonateResp> {
    const subj = new Subject<UserImpersonateResp>();
    this.sendRequest(WSMessage.create({ userImpersonateReq: req }), subj);
    return subj;
  }

  sendUserImpersonateGetRequest(req: UserImpersonateGetReq): Subject<UserImpersonateGetResp> {
    const subj = new Subject<UserImpersonateGetResp>();
    this.sendRequest(WSMessage.create({ userImpersonateGetReq: req }), subj);
    return subj;
  }

  sendUserListRequest(req: UserListReq): Subject<UserListResp> {
    const subj = new Subject<UserListResp>();
    this.sendRequest(WSMessage.create({ userListReq: req }), subj);
    return subj;
  }

  sendUserNotificationSettingsRequest(req: UserNotificationSettingsReq): Subject<UserNotificationSettingsResp> {
    const subj = new Subject<UserNotificationSettingsResp>();
    this.sendRequest(WSMessage.create({ userNotificationSettingsReq: req }), subj);
    return subj;
  }

  sendUserNotificationSettingsWriteRequest(req: UserNotificationSettingsWriteReq): Subject<UserNotificationSettingsWriteResp> {
    const subj = new Subject<UserNotificationSettingsWriteResp>();
    this.sendRequest(WSMessage.create({ userNotificationSettingsWriteReq: req }), subj);
    return subj;
  }

  sendUserRoleListRequest(req: UserRoleListReq): Subject<UserRoleListResp> {
    const subj = new Subject<UserRoleListResp>();
    this.sendRequest(WSMessage.create({ userRoleListReq: req }), subj);
    return subj;
  }

  sendUserRolesListRequest(req: UserRolesListReq): Subject<UserRolesListResp> {
    const subj = new Subject<UserRolesListResp>();
    this.sendRequest(WSMessage.create({ userRolesListReq: req }), subj);
    return subj;
  }

  sendUserSearchRequest(req: UserSearchReq): Subject<UserSearchResp> {
    const subj = new Subject<UserSearchResp>();
    this.sendRequest(WSMessage.create({ userSearchReq: req }), subj);
    return subj;
  }

  sendWidgetDataGetRequest(req: WidgetDataGetReq): Subject<WidgetDataGetResp> {
    const subj = new Subject<WidgetDataGetResp>();
    this.sendRequest(WSMessage.create({ widgetDataGetReq: req }), subj);
    return subj;
  }

  sendWidgetDataWriteRequest(req: WidgetDataWriteReq): Subject<WidgetDataWriteResp> {
    const subj = new Subject<WidgetDataWriteResp>();
    this.sendRequest(WSMessage.create({ widgetDataWriteReq: req }), subj);
    return subj;
  }

  sendZenodoDOIGetRequest(req: ZenodoDOIGetReq): Subject<ZenodoDOIGetResp> {
    const subj = new Subject<ZenodoDOIGetResp>();
    this.sendRequest(WSMessage.create({ zenodoDOIGetReq: req }), subj);
    return subj;
  }

  protected dispatchResponse(wsmsg: WSMessage): boolean {
    const outstanding = this._outstandingRequests.get(wsmsg.msgId);
    if (!outstanding) {
      return false; // Expected to find this outstanding request
    }

    // We have someone waiting for it, check if it's an error
    if (wsmsg.status != ResponseStatus.WS_OK) {
      outstanding.sub.error(new WSError(wsmsg.status, wsmsg.errorText, getMessageName(wsmsg)));
    }

    if (wsmsg.backupDBResp) { outstanding.sub.next(wsmsg.backupDBResp); }
     else if (wsmsg.dBAdminConfigGetResp) { outstanding.sub.next(wsmsg.dBAdminConfigGetResp); }
     else if (wsmsg.dataModuleAddVersionResp) { outstanding.sub.next(wsmsg.dataModuleAddVersionResp); }
     else if (wsmsg.dataModuleGetResp) { outstanding.sub.next(wsmsg.dataModuleGetResp); }
     else if (wsmsg.dataModuleListResp) { outstanding.sub.next(wsmsg.dataModuleListResp); }
     else if (wsmsg.dataModuleWriteResp) { outstanding.sub.next(wsmsg.dataModuleWriteResp); }
     else if (wsmsg.detectedDiffractionPeaksResp) { outstanding.sub.next(wsmsg.detectedDiffractionPeaksResp); }
     else if (wsmsg.detectorConfigResp) { outstanding.sub.next(wsmsg.detectorConfigResp); }
     else if (wsmsg.detectorConfigListResp) { outstanding.sub.next(wsmsg.detectorConfigListResp); }
     else if (wsmsg.diffractionPeakManualDeleteResp) { outstanding.sub.next(wsmsg.diffractionPeakManualDeleteResp); }
     else if (wsmsg.diffractionPeakManualInsertResp) { outstanding.sub.next(wsmsg.diffractionPeakManualInsertResp); }
     else if (wsmsg.diffractionPeakManualListResp) { outstanding.sub.next(wsmsg.diffractionPeakManualListResp); }
     else if (wsmsg.diffractionPeakStatusDeleteResp) { outstanding.sub.next(wsmsg.diffractionPeakStatusDeleteResp); }
     else if (wsmsg.diffractionPeakStatusListResp) { outstanding.sub.next(wsmsg.diffractionPeakStatusListResp); }
     else if (wsmsg.diffractionPeakStatusWriteResp) { outstanding.sub.next(wsmsg.diffractionPeakStatusWriteResp); }
     else if (wsmsg.elementSetDeleteResp) { outstanding.sub.next(wsmsg.elementSetDeleteResp); }
     else if (wsmsg.elementSetGetResp) { outstanding.sub.next(wsmsg.elementSetGetResp); }
     else if (wsmsg.elementSetListResp) { outstanding.sub.next(wsmsg.elementSetListResp); }
     else if (wsmsg.elementSetWriteResp) { outstanding.sub.next(wsmsg.elementSetWriteResp); }
     else if (wsmsg.exportFilesResp) { outstanding.sub.next(wsmsg.exportFilesResp); }
     else if (wsmsg.expressionDeleteResp) { outstanding.sub.next(wsmsg.expressionDeleteResp); }
     else if (wsmsg.expressionDisplaySettingsGetResp) { outstanding.sub.next(wsmsg.expressionDisplaySettingsGetResp); }
     else if (wsmsg.expressionDisplaySettingsWriteResp) { outstanding.sub.next(wsmsg.expressionDisplaySettingsWriteResp); }
     else if (wsmsg.expressionGetResp) { outstanding.sub.next(wsmsg.expressionGetResp); }
     else if (wsmsg.expressionGroupDeleteResp) { outstanding.sub.next(wsmsg.expressionGroupDeleteResp); }
     else if (wsmsg.expressionGroupGetResp) { outstanding.sub.next(wsmsg.expressionGroupGetResp); }
     else if (wsmsg.expressionGroupListResp) { outstanding.sub.next(wsmsg.expressionGroupListResp); }
     else if (wsmsg.expressionGroupWriteResp) { outstanding.sub.next(wsmsg.expressionGroupWriteResp); }
     else if (wsmsg.expressionListResp) { outstanding.sub.next(wsmsg.expressionListResp); }
     else if (wsmsg.expressionWriteResp) { outstanding.sub.next(wsmsg.expressionWriteResp); }
     else if (wsmsg.expressionWriteExecStatResp) { outstanding.sub.next(wsmsg.expressionWriteExecStatResp); }
     else if (wsmsg.getOwnershipResp) { outstanding.sub.next(wsmsg.getOwnershipResp); }
     else if (wsmsg.imageBeamLocationVersionsResp) { outstanding.sub.next(wsmsg.imageBeamLocationVersionsResp); }
     else if (wsmsg.imageBeamLocationsResp) { outstanding.sub.next(wsmsg.imageBeamLocationsResp); }
     else if (wsmsg.imageDeleteResp) { outstanding.sub.next(wsmsg.imageDeleteResp); }
     else if (wsmsg.imageGetResp) { outstanding.sub.next(wsmsg.imageGetResp); }
     else if (wsmsg.imageGetDefaultResp) { outstanding.sub.next(wsmsg.imageGetDefaultResp); }
     else if (wsmsg.imageListResp) { outstanding.sub.next(wsmsg.imageListResp); }
     else if (wsmsg.imageSetDefaultResp) { outstanding.sub.next(wsmsg.imageSetDefaultResp); }
     else if (wsmsg.imageSetMatchTransformResp) { outstanding.sub.next(wsmsg.imageSetMatchTransformResp); }
     else if (wsmsg.importMarsViewerImageResp) { outstanding.sub.next(wsmsg.importMarsViewerImageResp); }
     else if (wsmsg.jobListResp) { outstanding.sub.next(wsmsg.jobListResp); }
     else if (wsmsg.logGetLevelResp) { outstanding.sub.next(wsmsg.logGetLevelResp); }
     else if (wsmsg.logReadResp) { outstanding.sub.next(wsmsg.logReadResp); }
     else if (wsmsg.logSetLevelResp) { outstanding.sub.next(wsmsg.logSetLevelResp); }
     else if (wsmsg.memoiseGetResp) { outstanding.sub.next(wsmsg.memoiseGetResp); }
     else if (wsmsg.memoiseWriteResp) { outstanding.sub.next(wsmsg.memoiseWriteResp); }
     else if (wsmsg.multiQuantCompareResp) { outstanding.sub.next(wsmsg.multiQuantCompareResp); }
     else if (wsmsg.notificationResp) { outstanding.sub.next(wsmsg.notificationResp); }
     else if (wsmsg.notificationDismissResp) { outstanding.sub.next(wsmsg.notificationDismissResp); }
     else if (wsmsg.objectEditAccessResp) { outstanding.sub.next(wsmsg.objectEditAccessResp); }
     else if (wsmsg.piquantConfigListResp) { outstanding.sub.next(wsmsg.piquantConfigListResp); }
     else if (wsmsg.piquantConfigVersionResp) { outstanding.sub.next(wsmsg.piquantConfigVersionResp); }
     else if (wsmsg.piquantConfigVersionsListResp) { outstanding.sub.next(wsmsg.piquantConfigVersionsListResp); }
     else if (wsmsg.piquantCurrentVersionResp) { outstanding.sub.next(wsmsg.piquantCurrentVersionResp); }
     else if (wsmsg.piquantVersionListResp) { outstanding.sub.next(wsmsg.piquantVersionListResp); }
     else if (wsmsg.piquantWriteCurrentVersionResp) { outstanding.sub.next(wsmsg.piquantWriteCurrentVersionResp); }
     else if (wsmsg.pseudoIntensityResp) { outstanding.sub.next(wsmsg.pseudoIntensityResp); }
     else if (wsmsg.publishExpressionToZenodoResp) { outstanding.sub.next(wsmsg.publishExpressionToZenodoResp); }
     else if (wsmsg.quantBlessResp) { outstanding.sub.next(wsmsg.quantBlessResp); }
     else if (wsmsg.quantCombineResp) { outstanding.sub.next(wsmsg.quantCombineResp); }
     else if (wsmsg.quantCombineListGetResp) { outstanding.sub.next(wsmsg.quantCombineListGetResp); }
     else if (wsmsg.quantCombineListWriteResp) { outstanding.sub.next(wsmsg.quantCombineListWriteResp); }
     else if (wsmsg.quantCreateResp) { outstanding.sub.next(wsmsg.quantCreateResp); }
     else if (wsmsg.quantDeleteResp) { outstanding.sub.next(wsmsg.quantDeleteResp); }
     else if (wsmsg.quantGetResp) { outstanding.sub.next(wsmsg.quantGetResp); }
     else if (wsmsg.quantLastOutputGetResp) { outstanding.sub.next(wsmsg.quantLastOutputGetResp); }
     else if (wsmsg.quantListResp) { outstanding.sub.next(wsmsg.quantListResp); }
     else if (wsmsg.quantLogGetResp) { outstanding.sub.next(wsmsg.quantLogGetResp); }
     else if (wsmsg.quantLogListResp) { outstanding.sub.next(wsmsg.quantLogListResp); }
     else if (wsmsg.quantPublishResp) { outstanding.sub.next(wsmsg.quantPublishResp); }
     else if (wsmsg.quantRawDataGetResp) { outstanding.sub.next(wsmsg.quantRawDataGetResp); }
     else if (wsmsg.quantUploadResp) { outstanding.sub.next(wsmsg.quantUploadResp); }
     else if (wsmsg.regionOfInterestBulkDuplicateResp) { outstanding.sub.next(wsmsg.regionOfInterestBulkDuplicateResp); }
     else if (wsmsg.regionOfInterestBulkWriteResp) { outstanding.sub.next(wsmsg.regionOfInterestBulkWriteResp); }
     else if (wsmsg.regionOfInterestDeleteResp) { outstanding.sub.next(wsmsg.regionOfInterestDeleteResp); }
     else if (wsmsg.regionOfInterestDisplaySettingsGetResp) { outstanding.sub.next(wsmsg.regionOfInterestDisplaySettingsGetResp); }
     else if (wsmsg.regionOfInterestDisplaySettingsWriteResp) { outstanding.sub.next(wsmsg.regionOfInterestDisplaySettingsWriteResp); }
     else if (wsmsg.regionOfInterestGetResp) { outstanding.sub.next(wsmsg.regionOfInterestGetResp); }
     else if (wsmsg.regionOfInterestListResp) { outstanding.sub.next(wsmsg.regionOfInterestListResp); }
     else if (wsmsg.regionOfInterestWriteResp) { outstanding.sub.next(wsmsg.regionOfInterestWriteResp); }
     else if (wsmsg.restoreDBResp) { outstanding.sub.next(wsmsg.restoreDBResp); }
     else if (wsmsg.reviewerMagicLinkCreateResp) { outstanding.sub.next(wsmsg.reviewerMagicLinkCreateResp); }
     else if (wsmsg.reviewerMagicLinkLoginResp) { outstanding.sub.next(wsmsg.reviewerMagicLinkLoginResp); }
     else if (wsmsg.runTestResp) { outstanding.sub.next(wsmsg.runTestResp); }
     else if (wsmsg.scanAutoShareResp) { outstanding.sub.next(wsmsg.scanAutoShareResp); }
     else if (wsmsg.scanAutoShareWriteResp) { outstanding.sub.next(wsmsg.scanAutoShareWriteResp); }
     else if (wsmsg.scanBeamLocationsResp) { outstanding.sub.next(wsmsg.scanBeamLocationsResp); }
     else if (wsmsg.scanDeleteResp) { outstanding.sub.next(wsmsg.scanDeleteResp); }
     else if (wsmsg.scanEntryResp) { outstanding.sub.next(wsmsg.scanEntryResp); }
     else if (wsmsg.scanEntryMetadataResp) { outstanding.sub.next(wsmsg.scanEntryMetadataResp); }
     else if (wsmsg.scanGetResp) { outstanding.sub.next(wsmsg.scanGetResp); }
     else if (wsmsg.scanListResp) { outstanding.sub.next(wsmsg.scanListResp); }
     else if (wsmsg.scanMetaLabelsAndTypesResp) { outstanding.sub.next(wsmsg.scanMetaLabelsAndTypesResp); }
     else if (wsmsg.scanMetaWriteResp) { outstanding.sub.next(wsmsg.scanMetaWriteResp); }
     else if (wsmsg.scanTriggerAutoQuantResp) { outstanding.sub.next(wsmsg.scanTriggerAutoQuantResp); }
     else if (wsmsg.scanTriggerReImportResp) { outstanding.sub.next(wsmsg.scanTriggerReImportResp); }
     else if (wsmsg.scanUploadResp) { outstanding.sub.next(wsmsg.scanUploadResp); }
     else if (wsmsg.screenConfigurationDeleteResp) { outstanding.sub.next(wsmsg.screenConfigurationDeleteResp); }
     else if (wsmsg.screenConfigurationGetResp) { outstanding.sub.next(wsmsg.screenConfigurationGetResp); }
     else if (wsmsg.screenConfigurationListResp) { outstanding.sub.next(wsmsg.screenConfigurationListResp); }
     else if (wsmsg.screenConfigurationWriteResp) { outstanding.sub.next(wsmsg.screenConfigurationWriteResp); }
     else if (wsmsg.selectedImagePixelsResp) { outstanding.sub.next(wsmsg.selectedImagePixelsResp); }
     else if (wsmsg.selectedImagePixelsWriteResp) { outstanding.sub.next(wsmsg.selectedImagePixelsWriteResp); }
     else if (wsmsg.selectedScanEntriesResp) { outstanding.sub.next(wsmsg.selectedScanEntriesResp); }
     else if (wsmsg.selectedScanEntriesWriteResp) { outstanding.sub.next(wsmsg.selectedScanEntriesWriteResp); }
     else if (wsmsg.sendUserNotificationResp) { outstanding.sub.next(wsmsg.sendUserNotificationResp); }
     else if (wsmsg.spectrumResp) { outstanding.sub.next(wsmsg.spectrumResp); }
     else if (wsmsg.tagCreateResp) { outstanding.sub.next(wsmsg.tagCreateResp); }
     else if (wsmsg.tagDeleteResp) { outstanding.sub.next(wsmsg.tagDeleteResp); }
     else if (wsmsg.tagListResp) { outstanding.sub.next(wsmsg.tagListResp); }
     else if (wsmsg.userAddRoleResp) { outstanding.sub.next(wsmsg.userAddRoleResp); }
     else if (wsmsg.userDeleteRoleResp) { outstanding.sub.next(wsmsg.userDeleteRoleResp); }
     else if (wsmsg.userDetailsResp) { outstanding.sub.next(wsmsg.userDetailsResp); }
     else if (wsmsg.userDetailsWriteResp) { outstanding.sub.next(wsmsg.userDetailsWriteResp); }
     else if (wsmsg.userGroupResp) { outstanding.sub.next(wsmsg.userGroupResp); }
     else if (wsmsg.userGroupAddAdminResp) { outstanding.sub.next(wsmsg.userGroupAddAdminResp); }
     else if (wsmsg.userGroupAddMemberResp) { outstanding.sub.next(wsmsg.userGroupAddMemberResp); }
     else if (wsmsg.userGroupAddViewerResp) { outstanding.sub.next(wsmsg.userGroupAddViewerResp); }
     else if (wsmsg.userGroupCreateResp) { outstanding.sub.next(wsmsg.userGroupCreateResp); }
     else if (wsmsg.userGroupDeleteResp) { outstanding.sub.next(wsmsg.userGroupDeleteResp); }
     else if (wsmsg.userGroupDeleteAdminResp) { outstanding.sub.next(wsmsg.userGroupDeleteAdminResp); }
     else if (wsmsg.userGroupDeleteMemberResp) { outstanding.sub.next(wsmsg.userGroupDeleteMemberResp); }
     else if (wsmsg.userGroupDeleteViewerResp) { outstanding.sub.next(wsmsg.userGroupDeleteViewerResp); }
     else if (wsmsg.userGroupEditDetailsResp) { outstanding.sub.next(wsmsg.userGroupEditDetailsResp); }
     else if (wsmsg.userGroupIgnoreJoinResp) { outstanding.sub.next(wsmsg.userGroupIgnoreJoinResp); }
     else if (wsmsg.userGroupJoinResp) { outstanding.sub.next(wsmsg.userGroupJoinResp); }
     else if (wsmsg.userGroupJoinListResp) { outstanding.sub.next(wsmsg.userGroupJoinListResp); }
     else if (wsmsg.userGroupListResp) { outstanding.sub.next(wsmsg.userGroupListResp); }
     else if (wsmsg.userGroupListJoinableResp) { outstanding.sub.next(wsmsg.userGroupListJoinableResp); }
     else if (wsmsg.userImpersonateResp) { outstanding.sub.next(wsmsg.userImpersonateResp); }
     else if (wsmsg.userImpersonateGetResp) { outstanding.sub.next(wsmsg.userImpersonateGetResp); }
     else if (wsmsg.userListResp) { outstanding.sub.next(wsmsg.userListResp); }
     else if (wsmsg.userNotificationSettingsResp) { outstanding.sub.next(wsmsg.userNotificationSettingsResp); }
     else if (wsmsg.userNotificationSettingsWriteResp) { outstanding.sub.next(wsmsg.userNotificationSettingsWriteResp); }
     else if (wsmsg.userRoleListResp) { outstanding.sub.next(wsmsg.userRoleListResp); }
     else if (wsmsg.userRolesListResp) { outstanding.sub.next(wsmsg.userRolesListResp); }
     else if (wsmsg.userSearchResp) { outstanding.sub.next(wsmsg.userSearchResp); }
     else if (wsmsg.widgetDataGetResp) { outstanding.sub.next(wsmsg.widgetDataGetResp); }
     else if (wsmsg.widgetDataWriteResp) { outstanding.sub.next(wsmsg.widgetDataWriteResp); }
     else if (wsmsg.zenodoDOIGetResp) { outstanding.sub.next(wsmsg.zenodoDOIGetResp); }
 
    outstanding.sub.complete();
    this._outstandingRequests.delete(wsmsg.msgId);

    return true;
  }

  protected dispatchUpdate(wsmsg: WSMessage): boolean {
    if (wsmsg.backupDBUpd) {
      this.backupDBUpd$.next(wsmsg.backupDBUpd);
      return true;
    } else if (wsmsg.imageListUpd) {
      this.imageListUpd$.next(wsmsg.imageListUpd);
      return true;
    } else if (wsmsg.importMarsViewerImageUpd) {
      this.importMarsViewerImageUpd$.next(wsmsg.importMarsViewerImageUpd);
      return true;
    } else if (wsmsg.jobListUpd) {
      this.jobListUpd$.next(wsmsg.jobListUpd);
      return true;
    } else if (wsmsg.notificationUpd) {
      this.notificationUpd$.next(wsmsg.notificationUpd);
      return true;
    } else if (wsmsg.quantCreateUpd) {
      this.quantCreateUpd$.next(wsmsg.quantCreateUpd);
      return true;
    } else if (wsmsg.scanListUpd) {
      this.scanListUpd$.next(wsmsg.scanListUpd);
      return true;
    } else if (wsmsg.scanTriggerReImportUpd) {
      this.scanTriggerReImportUpd$.next(wsmsg.scanTriggerReImportUpd);
      return true;
    } else if (wsmsg.scanUploadUpd) {
      this.scanUploadUpd$.next(wsmsg.scanUploadUpd);
      return true;
    } else if (wsmsg.userNotificationSettingsUpd) {
      this.userNotificationSettingsUpd$.next(wsmsg.userNotificationSettingsUpd);
      return true;
    }
    return false;
  }
}

export function getMessageName(wsmsg: WSMessage): string {
  if (wsmsg.backupDBReq) { return "backupDBReq"; }
  if (wsmsg.backupDBResp) { return "backupDBResp"; }
  if (wsmsg.backupDBUpd) { return "backupDBUpd"; }
  if (wsmsg.dBAdminConfigGetReq) { return "dBAdminConfigGetReq"; }
  if (wsmsg.dBAdminConfigGetResp) { return "dBAdminConfigGetResp"; }
  if (wsmsg.dataModuleAddVersionReq) { return "dataModuleAddVersionReq"; }
  if (wsmsg.dataModuleAddVersionResp) { return "dataModuleAddVersionResp"; }
  if (wsmsg.dataModuleGetReq) { return "dataModuleGetReq"; }
  if (wsmsg.dataModuleGetResp) { return "dataModuleGetResp"; }
  if (wsmsg.dataModuleListReq) { return "dataModuleListReq"; }
  if (wsmsg.dataModuleListResp) { return "dataModuleListResp"; }
  if (wsmsg.dataModuleWriteReq) { return "dataModuleWriteReq"; }
  if (wsmsg.dataModuleWriteResp) { return "dataModuleWriteResp"; }
  if (wsmsg.detectedDiffractionPeaksReq) { return "detectedDiffractionPeaksReq"; }
  if (wsmsg.detectedDiffractionPeaksResp) { return "detectedDiffractionPeaksResp"; }
  if (wsmsg.detectorConfigReq) { return "detectorConfigReq"; }
  if (wsmsg.detectorConfigResp) { return "detectorConfigResp"; }
  if (wsmsg.detectorConfigListReq) { return "detectorConfigListReq"; }
  if (wsmsg.detectorConfigListResp) { return "detectorConfigListResp"; }
  if (wsmsg.diffractionPeakManualDeleteReq) { return "diffractionPeakManualDeleteReq"; }
  if (wsmsg.diffractionPeakManualDeleteResp) { return "diffractionPeakManualDeleteResp"; }
  if (wsmsg.diffractionPeakManualInsertReq) { return "diffractionPeakManualInsertReq"; }
  if (wsmsg.diffractionPeakManualInsertResp) { return "diffractionPeakManualInsertResp"; }
  if (wsmsg.diffractionPeakManualListReq) { return "diffractionPeakManualListReq"; }
  if (wsmsg.diffractionPeakManualListResp) { return "diffractionPeakManualListResp"; }
  if (wsmsg.diffractionPeakStatusDeleteReq) { return "diffractionPeakStatusDeleteReq"; }
  if (wsmsg.diffractionPeakStatusDeleteResp) { return "diffractionPeakStatusDeleteResp"; }
  if (wsmsg.diffractionPeakStatusListReq) { return "diffractionPeakStatusListReq"; }
  if (wsmsg.diffractionPeakStatusListResp) { return "diffractionPeakStatusListResp"; }
  if (wsmsg.diffractionPeakStatusWriteReq) { return "diffractionPeakStatusWriteReq"; }
  if (wsmsg.diffractionPeakStatusWriteResp) { return "diffractionPeakStatusWriteResp"; }
  if (wsmsg.elementSetDeleteReq) { return "elementSetDeleteReq"; }
  if (wsmsg.elementSetDeleteResp) { return "elementSetDeleteResp"; }
  if (wsmsg.elementSetGetReq) { return "elementSetGetReq"; }
  if (wsmsg.elementSetGetResp) { return "elementSetGetResp"; }
  if (wsmsg.elementSetListReq) { return "elementSetListReq"; }
  if (wsmsg.elementSetListResp) { return "elementSetListResp"; }
  if (wsmsg.elementSetWriteReq) { return "elementSetWriteReq"; }
  if (wsmsg.elementSetWriteResp) { return "elementSetWriteResp"; }
  if (wsmsg.exportFilesReq) { return "exportFilesReq"; }
  if (wsmsg.exportFilesResp) { return "exportFilesResp"; }
  if (wsmsg.expressionDeleteReq) { return "expressionDeleteReq"; }
  if (wsmsg.expressionDeleteResp) { return "expressionDeleteResp"; }
  if (wsmsg.expressionDisplaySettingsGetReq) { return "expressionDisplaySettingsGetReq"; }
  if (wsmsg.expressionDisplaySettingsGetResp) { return "expressionDisplaySettingsGetResp"; }
  if (wsmsg.expressionDisplaySettingsWriteReq) { return "expressionDisplaySettingsWriteReq"; }
  if (wsmsg.expressionDisplaySettingsWriteResp) { return "expressionDisplaySettingsWriteResp"; }
  if (wsmsg.expressionGetReq) { return "expressionGetReq"; }
  if (wsmsg.expressionGetResp) { return "expressionGetResp"; }
  if (wsmsg.expressionGroupDeleteReq) { return "expressionGroupDeleteReq"; }
  if (wsmsg.expressionGroupDeleteResp) { return "expressionGroupDeleteResp"; }
  if (wsmsg.expressionGroupGetReq) { return "expressionGroupGetReq"; }
  if (wsmsg.expressionGroupGetResp) { return "expressionGroupGetResp"; }
  if (wsmsg.expressionGroupListReq) { return "expressionGroupListReq"; }
  if (wsmsg.expressionGroupListResp) { return "expressionGroupListResp"; }
  if (wsmsg.expressionGroupWriteReq) { return "expressionGroupWriteReq"; }
  if (wsmsg.expressionGroupWriteResp) { return "expressionGroupWriteResp"; }
  if (wsmsg.expressionListReq) { return "expressionListReq"; }
  if (wsmsg.expressionListResp) { return "expressionListResp"; }
  if (wsmsg.expressionWriteReq) { return "expressionWriteReq"; }
  if (wsmsg.expressionWriteResp) { return "expressionWriteResp"; }
  if (wsmsg.expressionWriteExecStatReq) { return "expressionWriteExecStatReq"; }
  if (wsmsg.expressionWriteExecStatResp) { return "expressionWriteExecStatResp"; }
  if (wsmsg.getOwnershipReq) { return "getOwnershipReq"; }
  if (wsmsg.getOwnershipResp) { return "getOwnershipResp"; }
  if (wsmsg.imageBeamLocationVersionsReq) { return "imageBeamLocationVersionsReq"; }
  if (wsmsg.imageBeamLocationVersionsResp) { return "imageBeamLocationVersionsResp"; }
  if (wsmsg.imageBeamLocationsReq) { return "imageBeamLocationsReq"; }
  if (wsmsg.imageBeamLocationsResp) { return "imageBeamLocationsResp"; }
  if (wsmsg.imageDeleteReq) { return "imageDeleteReq"; }
  if (wsmsg.imageDeleteResp) { return "imageDeleteResp"; }
  if (wsmsg.imageGetReq) { return "imageGetReq"; }
  if (wsmsg.imageGetResp) { return "imageGetResp"; }
  if (wsmsg.imageGetDefaultReq) { return "imageGetDefaultReq"; }
  if (wsmsg.imageGetDefaultResp) { return "imageGetDefaultResp"; }
  if (wsmsg.imageListReq) { return "imageListReq"; }
  if (wsmsg.imageListResp) { return "imageListResp"; }
  if (wsmsg.imageListUpd) { return "imageListUpd"; }
  if (wsmsg.imageSetDefaultReq) { return "imageSetDefaultReq"; }
  if (wsmsg.imageSetDefaultResp) { return "imageSetDefaultResp"; }
  if (wsmsg.imageSetMatchTransformReq) { return "imageSetMatchTransformReq"; }
  if (wsmsg.imageSetMatchTransformResp) { return "imageSetMatchTransformResp"; }
  if (wsmsg.importMarsViewerImageReq) { return "importMarsViewerImageReq"; }
  if (wsmsg.importMarsViewerImageResp) { return "importMarsViewerImageResp"; }
  if (wsmsg.importMarsViewerImageUpd) { return "importMarsViewerImageUpd"; }
  if (wsmsg.jobListReq) { return "jobListReq"; }
  if (wsmsg.jobListResp) { return "jobListResp"; }
  if (wsmsg.jobListUpd) { return "jobListUpd"; }
  if (wsmsg.logGetLevelReq) { return "logGetLevelReq"; }
  if (wsmsg.logGetLevelResp) { return "logGetLevelResp"; }
  if (wsmsg.logReadReq) { return "logReadReq"; }
  if (wsmsg.logReadResp) { return "logReadResp"; }
  if (wsmsg.logSetLevelReq) { return "logSetLevelReq"; }
  if (wsmsg.logSetLevelResp) { return "logSetLevelResp"; }
  if (wsmsg.memoiseGetReq) { return "memoiseGetReq"; }
  if (wsmsg.memoiseGetResp) { return "memoiseGetResp"; }
  if (wsmsg.memoiseWriteReq) { return "memoiseWriteReq"; }
  if (wsmsg.memoiseWriteResp) { return "memoiseWriteResp"; }
  if (wsmsg.multiQuantCompareReq) { return "multiQuantCompareReq"; }
  if (wsmsg.multiQuantCompareResp) { return "multiQuantCompareResp"; }
  if (wsmsg.notificationReq) { return "notificationReq"; }
  if (wsmsg.notificationResp) { return "notificationResp"; }
  if (wsmsg.notificationUpd) { return "notificationUpd"; }
  if (wsmsg.notificationDismissReq) { return "notificationDismissReq"; }
  if (wsmsg.notificationDismissResp) { return "notificationDismissResp"; }
  if (wsmsg.objectEditAccessReq) { return "objectEditAccessReq"; }
  if (wsmsg.objectEditAccessResp) { return "objectEditAccessResp"; }
  if (wsmsg.piquantConfigListReq) { return "piquantConfigListReq"; }
  if (wsmsg.piquantConfigListResp) { return "piquantConfigListResp"; }
  if (wsmsg.piquantConfigVersionReq) { return "piquantConfigVersionReq"; }
  if (wsmsg.piquantConfigVersionResp) { return "piquantConfigVersionResp"; }
  if (wsmsg.piquantConfigVersionsListReq) { return "piquantConfigVersionsListReq"; }
  if (wsmsg.piquantConfigVersionsListResp) { return "piquantConfigVersionsListResp"; }
  if (wsmsg.piquantCurrentVersionReq) { return "piquantCurrentVersionReq"; }
  if (wsmsg.piquantCurrentVersionResp) { return "piquantCurrentVersionResp"; }
  if (wsmsg.piquantVersionListReq) { return "piquantVersionListReq"; }
  if (wsmsg.piquantVersionListResp) { return "piquantVersionListResp"; }
  if (wsmsg.piquantWriteCurrentVersionReq) { return "piquantWriteCurrentVersionReq"; }
  if (wsmsg.piquantWriteCurrentVersionResp) { return "piquantWriteCurrentVersionResp"; }
  if (wsmsg.pseudoIntensityReq) { return "pseudoIntensityReq"; }
  if (wsmsg.pseudoIntensityResp) { return "pseudoIntensityResp"; }
  if (wsmsg.publishExpressionToZenodoReq) { return "publishExpressionToZenodoReq"; }
  if (wsmsg.publishExpressionToZenodoResp) { return "publishExpressionToZenodoResp"; }
  if (wsmsg.quantBlessReq) { return "quantBlessReq"; }
  if (wsmsg.quantBlessResp) { return "quantBlessResp"; }
  if (wsmsg.quantCombineReq) { return "quantCombineReq"; }
  if (wsmsg.quantCombineResp) { return "quantCombineResp"; }
  if (wsmsg.quantCombineListGetReq) { return "quantCombineListGetReq"; }
  if (wsmsg.quantCombineListGetResp) { return "quantCombineListGetResp"; }
  if (wsmsg.quantCombineListWriteReq) { return "quantCombineListWriteReq"; }
  if (wsmsg.quantCombineListWriteResp) { return "quantCombineListWriteResp"; }
  if (wsmsg.quantCreateReq) { return "quantCreateReq"; }
  if (wsmsg.quantCreateResp) { return "quantCreateResp"; }
  if (wsmsg.quantCreateUpd) { return "quantCreateUpd"; }
  if (wsmsg.quantDeleteReq) { return "quantDeleteReq"; }
  if (wsmsg.quantDeleteResp) { return "quantDeleteResp"; }
  if (wsmsg.quantGetReq) { return "quantGetReq"; }
  if (wsmsg.quantGetResp) { return "quantGetResp"; }
  if (wsmsg.quantLastOutputGetReq) { return "quantLastOutputGetReq"; }
  if (wsmsg.quantLastOutputGetResp) { return "quantLastOutputGetResp"; }
  if (wsmsg.quantListReq) { return "quantListReq"; }
  if (wsmsg.quantListResp) { return "quantListResp"; }
  if (wsmsg.quantLogGetReq) { return "quantLogGetReq"; }
  if (wsmsg.quantLogGetResp) { return "quantLogGetResp"; }
  if (wsmsg.quantLogListReq) { return "quantLogListReq"; }
  if (wsmsg.quantLogListResp) { return "quantLogListResp"; }
  if (wsmsg.quantPublishReq) { return "quantPublishReq"; }
  if (wsmsg.quantPublishResp) { return "quantPublishResp"; }
  if (wsmsg.quantRawDataGetReq) { return "quantRawDataGetReq"; }
  if (wsmsg.quantRawDataGetResp) { return "quantRawDataGetResp"; }
  if (wsmsg.quantUploadReq) { return "quantUploadReq"; }
  if (wsmsg.quantUploadResp) { return "quantUploadResp"; }
  if (wsmsg.regionOfInterestBulkDuplicateReq) { return "regionOfInterestBulkDuplicateReq"; }
  if (wsmsg.regionOfInterestBulkDuplicateResp) { return "regionOfInterestBulkDuplicateResp"; }
  if (wsmsg.regionOfInterestBulkWriteReq) { return "regionOfInterestBulkWriteReq"; }
  if (wsmsg.regionOfInterestBulkWriteResp) { return "regionOfInterestBulkWriteResp"; }
  if (wsmsg.regionOfInterestDeleteReq) { return "regionOfInterestDeleteReq"; }
  if (wsmsg.regionOfInterestDeleteResp) { return "regionOfInterestDeleteResp"; }
  if (wsmsg.regionOfInterestDisplaySettingsGetReq) { return "regionOfInterestDisplaySettingsGetReq"; }
  if (wsmsg.regionOfInterestDisplaySettingsGetResp) { return "regionOfInterestDisplaySettingsGetResp"; }
  if (wsmsg.regionOfInterestDisplaySettingsWriteReq) { return "regionOfInterestDisplaySettingsWriteReq"; }
  if (wsmsg.regionOfInterestDisplaySettingsWriteResp) { return "regionOfInterestDisplaySettingsWriteResp"; }
  if (wsmsg.regionOfInterestGetReq) { return "regionOfInterestGetReq"; }
  if (wsmsg.regionOfInterestGetResp) { return "regionOfInterestGetResp"; }
  if (wsmsg.regionOfInterestListReq) { return "regionOfInterestListReq"; }
  if (wsmsg.regionOfInterestListResp) { return "regionOfInterestListResp"; }
  if (wsmsg.regionOfInterestWriteReq) { return "regionOfInterestWriteReq"; }
  if (wsmsg.regionOfInterestWriteResp) { return "regionOfInterestWriteResp"; }
  if (wsmsg.restoreDBReq) { return "restoreDBReq"; }
  if (wsmsg.restoreDBResp) { return "restoreDBResp"; }
  if (wsmsg.reviewerMagicLinkCreateReq) { return "reviewerMagicLinkCreateReq"; }
  if (wsmsg.reviewerMagicLinkCreateResp) { return "reviewerMagicLinkCreateResp"; }
  if (wsmsg.reviewerMagicLinkLoginReq) { return "reviewerMagicLinkLoginReq"; }
  if (wsmsg.reviewerMagicLinkLoginResp) { return "reviewerMagicLinkLoginResp"; }
  if (wsmsg.runTestReq) { return "runTestReq"; }
  if (wsmsg.runTestResp) { return "runTestResp"; }
  if (wsmsg.scanAutoShareReq) { return "scanAutoShareReq"; }
  if (wsmsg.scanAutoShareResp) { return "scanAutoShareResp"; }
  if (wsmsg.scanAutoShareWriteReq) { return "scanAutoShareWriteReq"; }
  if (wsmsg.scanAutoShareWriteResp) { return "scanAutoShareWriteResp"; }
  if (wsmsg.scanBeamLocationsReq) { return "scanBeamLocationsReq"; }
  if (wsmsg.scanBeamLocationsResp) { return "scanBeamLocationsResp"; }
  if (wsmsg.scanDeleteReq) { return "scanDeleteReq"; }
  if (wsmsg.scanDeleteResp) { return "scanDeleteResp"; }
  if (wsmsg.scanEntryReq) { return "scanEntryReq"; }
  if (wsmsg.scanEntryResp) { return "scanEntryResp"; }
  if (wsmsg.scanEntryMetadataReq) { return "scanEntryMetadataReq"; }
  if (wsmsg.scanEntryMetadataResp) { return "scanEntryMetadataResp"; }
  if (wsmsg.scanGetReq) { return "scanGetReq"; }
  if (wsmsg.scanGetResp) { return "scanGetResp"; }
  if (wsmsg.scanListReq) { return "scanListReq"; }
  if (wsmsg.scanListResp) { return "scanListResp"; }
  if (wsmsg.scanListUpd) { return "scanListUpd"; }
  if (wsmsg.scanMetaLabelsAndTypesReq) { return "scanMetaLabelsAndTypesReq"; }
  if (wsmsg.scanMetaLabelsAndTypesResp) { return "scanMetaLabelsAndTypesResp"; }
  if (wsmsg.scanMetaWriteReq) { return "scanMetaWriteReq"; }
  if (wsmsg.scanMetaWriteResp) { return "scanMetaWriteResp"; }
  if (wsmsg.scanTriggerAutoQuantReq) { return "scanTriggerAutoQuantReq"; }
  if (wsmsg.scanTriggerAutoQuantResp) { return "scanTriggerAutoQuantResp"; }
  if (wsmsg.scanTriggerReImportReq) { return "scanTriggerReImportReq"; }
  if (wsmsg.scanTriggerReImportResp) { return "scanTriggerReImportResp"; }
  if (wsmsg.scanTriggerReImportUpd) { return "scanTriggerReImportUpd"; }
  if (wsmsg.scanUploadReq) { return "scanUploadReq"; }
  if (wsmsg.scanUploadResp) { return "scanUploadResp"; }
  if (wsmsg.scanUploadUpd) { return "scanUploadUpd"; }
  if (wsmsg.screenConfigurationDeleteReq) { return "screenConfigurationDeleteReq"; }
  if (wsmsg.screenConfigurationDeleteResp) { return "screenConfigurationDeleteResp"; }
  if (wsmsg.screenConfigurationGetReq) { return "screenConfigurationGetReq"; }
  if (wsmsg.screenConfigurationGetResp) { return "screenConfigurationGetResp"; }
  if (wsmsg.screenConfigurationListReq) { return "screenConfigurationListReq"; }
  if (wsmsg.screenConfigurationListResp) { return "screenConfigurationListResp"; }
  if (wsmsg.screenConfigurationWriteReq) { return "screenConfigurationWriteReq"; }
  if (wsmsg.screenConfigurationWriteResp) { return "screenConfigurationWriteResp"; }
  if (wsmsg.selectedImagePixelsReq) { return "selectedImagePixelsReq"; }
  if (wsmsg.selectedImagePixelsResp) { return "selectedImagePixelsResp"; }
  if (wsmsg.selectedImagePixelsWriteReq) { return "selectedImagePixelsWriteReq"; }
  if (wsmsg.selectedImagePixelsWriteResp) { return "selectedImagePixelsWriteResp"; }
  if (wsmsg.selectedScanEntriesReq) { return "selectedScanEntriesReq"; }
  if (wsmsg.selectedScanEntriesResp) { return "selectedScanEntriesResp"; }
  if (wsmsg.selectedScanEntriesWriteReq) { return "selectedScanEntriesWriteReq"; }
  if (wsmsg.selectedScanEntriesWriteResp) { return "selectedScanEntriesWriteResp"; }
  if (wsmsg.sendUserNotificationReq) { return "sendUserNotificationReq"; }
  if (wsmsg.sendUserNotificationResp) { return "sendUserNotificationResp"; }
  if (wsmsg.spectrumReq) { return "spectrumReq"; }
  if (wsmsg.spectrumResp) { return "spectrumResp"; }
  if (wsmsg.tagCreateReq) { return "tagCreateReq"; }
  if (wsmsg.tagCreateResp) { return "tagCreateResp"; }
  if (wsmsg.tagDeleteReq) { return "tagDeleteReq"; }
  if (wsmsg.tagDeleteResp) { return "tagDeleteResp"; }
  if (wsmsg.tagListReq) { return "tagListReq"; }
  if (wsmsg.tagListResp) { return "tagListResp"; }
  if (wsmsg.userAddRoleReq) { return "userAddRoleReq"; }
  if (wsmsg.userAddRoleResp) { return "userAddRoleResp"; }
  if (wsmsg.userDeleteRoleReq) { return "userDeleteRoleReq"; }
  if (wsmsg.userDeleteRoleResp) { return "userDeleteRoleResp"; }
  if (wsmsg.userDetailsReq) { return "userDetailsReq"; }
  if (wsmsg.userDetailsResp) { return "userDetailsResp"; }
  if (wsmsg.userDetailsWriteReq) { return "userDetailsWriteReq"; }
  if (wsmsg.userDetailsWriteResp) { return "userDetailsWriteResp"; }
  if (wsmsg.userGroupReq) { return "userGroupReq"; }
  if (wsmsg.userGroupResp) { return "userGroupResp"; }
  if (wsmsg.userGroupAddAdminReq) { return "userGroupAddAdminReq"; }
  if (wsmsg.userGroupAddAdminResp) { return "userGroupAddAdminResp"; }
  if (wsmsg.userGroupAddMemberReq) { return "userGroupAddMemberReq"; }
  if (wsmsg.userGroupAddMemberResp) { return "userGroupAddMemberResp"; }
  if (wsmsg.userGroupAddViewerReq) { return "userGroupAddViewerReq"; }
  if (wsmsg.userGroupAddViewerResp) { return "userGroupAddViewerResp"; }
  if (wsmsg.userGroupCreateReq) { return "userGroupCreateReq"; }
  if (wsmsg.userGroupCreateResp) { return "userGroupCreateResp"; }
  if (wsmsg.userGroupDeleteReq) { return "userGroupDeleteReq"; }
  if (wsmsg.userGroupDeleteResp) { return "userGroupDeleteResp"; }
  if (wsmsg.userGroupDeleteAdminReq) { return "userGroupDeleteAdminReq"; }
  if (wsmsg.userGroupDeleteAdminResp) { return "userGroupDeleteAdminResp"; }
  if (wsmsg.userGroupDeleteMemberReq) { return "userGroupDeleteMemberReq"; }
  if (wsmsg.userGroupDeleteMemberResp) { return "userGroupDeleteMemberResp"; }
  if (wsmsg.userGroupDeleteViewerReq) { return "userGroupDeleteViewerReq"; }
  if (wsmsg.userGroupDeleteViewerResp) { return "userGroupDeleteViewerResp"; }
  if (wsmsg.userGroupEditDetailsReq) { return "userGroupEditDetailsReq"; }
  if (wsmsg.userGroupEditDetailsResp) { return "userGroupEditDetailsResp"; }
  if (wsmsg.userGroupIgnoreJoinReq) { return "userGroupIgnoreJoinReq"; }
  if (wsmsg.userGroupIgnoreJoinResp) { return "userGroupIgnoreJoinResp"; }
  if (wsmsg.userGroupJoinReq) { return "userGroupJoinReq"; }
  if (wsmsg.userGroupJoinResp) { return "userGroupJoinResp"; }
  if (wsmsg.userGroupJoinListReq) { return "userGroupJoinListReq"; }
  if (wsmsg.userGroupJoinListResp) { return "userGroupJoinListResp"; }
  if (wsmsg.userGroupListReq) { return "userGroupListReq"; }
  if (wsmsg.userGroupListResp) { return "userGroupListResp"; }
  if (wsmsg.userGroupListJoinableReq) { return "userGroupListJoinableReq"; }
  if (wsmsg.userGroupListJoinableResp) { return "userGroupListJoinableResp"; }
  if (wsmsg.userImpersonateReq) { return "userImpersonateReq"; }
  if (wsmsg.userImpersonateResp) { return "userImpersonateResp"; }
  if (wsmsg.userImpersonateGetReq) { return "userImpersonateGetReq"; }
  if (wsmsg.userImpersonateGetResp) { return "userImpersonateGetResp"; }
  if (wsmsg.userListReq) { return "userListReq"; }
  if (wsmsg.userListResp) { return "userListResp"; }
  if (wsmsg.userNotificationSettingsReq) { return "userNotificationSettingsReq"; }
  if (wsmsg.userNotificationSettingsResp) { return "userNotificationSettingsResp"; }
  if (wsmsg.userNotificationSettingsUpd) { return "userNotificationSettingsUpd"; }
  if (wsmsg.userNotificationSettingsWriteReq) { return "userNotificationSettingsWriteReq"; }
  if (wsmsg.userNotificationSettingsWriteResp) { return "userNotificationSettingsWriteResp"; }
  if (wsmsg.userRoleListReq) { return "userRoleListReq"; }
  if (wsmsg.userRoleListResp) { return "userRoleListResp"; }
  if (wsmsg.userRolesListReq) { return "userRolesListReq"; }
  if (wsmsg.userRolesListResp) { return "userRolesListResp"; }
  if (wsmsg.userSearchReq) { return "userSearchReq"; }
  if (wsmsg.userSearchResp) { return "userSearchResp"; }
  if (wsmsg.widgetDataGetReq) { return "widgetDataGetReq"; }
  if (wsmsg.widgetDataGetResp) { return "widgetDataGetResp"; }
  if (wsmsg.widgetDataWriteReq) { return "widgetDataWriteReq"; }
  if (wsmsg.widgetDataWriteResp) { return "widgetDataWriteResp"; }
  if (wsmsg.zenodoDOIGetReq) { return "zenodoDOIGetReq"; }
  if (wsmsg.zenodoDOIGetResp) { return "zenodoDOIGetResp"; }
  return "?";
}
