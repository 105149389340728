/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import {
  DetectorConfigListReq,
  DetectorConfigListResp,
  DetectorConfigReq,
  DetectorConfigResp,
} from "./detector-config-msgs";
import { DetectedDiffractionPeaksReq, DetectedDiffractionPeaksResp } from "./diffraction-detected-peak-msgs";
import {
  DiffractionPeakManualDeleteReq,
  DiffractionPeakManualDeleteResp,
  DiffractionPeakManualInsertReq,
  DiffractionPeakManualInsertResp,
  DiffractionPeakManualListReq,
  DiffractionPeakManualListResp,
} from "./diffraction-manual-msgs";
import {
  DiffractionPeakStatusDeleteReq,
  DiffractionPeakStatusDeleteResp,
  DiffractionPeakStatusListReq,
  DiffractionPeakStatusListResp,
  DiffractionPeakStatusWriteReq,
  DiffractionPeakStatusWriteResp,
} from "./diffraction-status-msgs";
import {
  PublishExpressionToZenodoReq,
  PublishExpressionToZenodoResp,
  ZenodoDOIGetReq,
  ZenodoDOIGetResp,
} from "./doi-msgs";
import {
  ElementSetDeleteReq,
  ElementSetDeleteResp,
  ElementSetGetReq,
  ElementSetGetResp,
  ElementSetListReq,
  ElementSetListResp,
  ElementSetWriteReq,
  ElementSetWriteResp,
} from "./element-set-msgs";
import { ExportFilesReq, ExportFilesResp } from "./export-msgs";
import {
  ExpressionGroupDeleteReq,
  ExpressionGroupDeleteResp,
  ExpressionGroupGetReq,
  ExpressionGroupGetResp,
  ExpressionGroupListReq,
  ExpressionGroupListResp,
  ExpressionGroupWriteReq,
  ExpressionGroupWriteResp,
} from "./expression-group-msgs";
import {
  ExpressionDeleteReq,
  ExpressionDeleteResp,
  ExpressionDisplaySettingsGetReq,
  ExpressionDisplaySettingsGetResp,
  ExpressionDisplaySettingsWriteReq,
  ExpressionDisplaySettingsWriteResp,
  ExpressionGetReq,
  ExpressionGetResp,
  ExpressionListReq,
  ExpressionListResp,
  ExpressionWriteExecStatReq,
  ExpressionWriteExecStatResp,
  ExpressionWriteReq,
  ExpressionWriteResp,
} from "./expression-msgs";
import {
  ImageBeamLocationsReq,
  ImageBeamLocationsResp,
  ImageBeamLocationVersionsReq,
  ImageBeamLocationVersionsResp,
} from "./image-beam-location-msgs";
import { ImportMarsViewerImageReq, ImportMarsViewerImageResp, ImportMarsViewerImageUpd } from "./image-coreg-msgs";
import {
  ImageDeleteReq,
  ImageDeleteResp,
  ImageGetDefaultReq,
  ImageGetDefaultResp,
  ImageGetReq,
  ImageGetResp,
  ImageListReq,
  ImageListResp,
  ImageListUpd,
  ImageSetDefaultReq,
  ImageSetDefaultResp,
  ImageSetMatchTransformReq,
  ImageSetMatchTransformResp,
} from "./image-msgs";
import { JobListReq, JobListResp, JobListUpd } from "./job-msgs";
import { LogGetLevelReq, LogGetLevelResp, LogReadReq, LogReadResp, LogSetLevelReq, LogSetLevelResp } from "./log-msgs";
import { MemoiseGetReq, MemoiseGetResp, MemoiseWriteReq, MemoiseWriteResp } from "./memoisation-msgs";
import {
  DataModuleAddVersionReq,
  DataModuleAddVersionResp,
  DataModuleGetReq,
  DataModuleGetResp,
  DataModuleListReq,
  DataModuleListResp,
  DataModuleWriteReq,
  DataModuleWriteResp,
} from "./module-msgs";
import {
  NotificationDismissReq,
  NotificationDismissResp,
  NotificationReq,
  NotificationResp,
  NotificationUpd,
  SendUserNotificationReq,
  SendUserNotificationResp,
} from "./notification-msgs";
import { GetOwnershipReq, GetOwnershipResp, ObjectEditAccessReq, ObjectEditAccessResp } from "./ownership-access-msgs";
import {
  PiquantConfigListReq,
  PiquantConfigListResp,
  PiquantConfigVersionReq,
  PiquantConfigVersionResp,
  PiquantConfigVersionsListReq,
  PiquantConfigVersionsListResp,
  PiquantCurrentVersionReq,
  PiquantCurrentVersionResp,
  PiquantVersionListReq,
  PiquantVersionListResp,
  PiquantWriteCurrentVersionReq,
  PiquantWriteCurrentVersionResp,
} from "./piquant-msgs";
import { PseudoIntensityReq, PseudoIntensityResp } from "./pseudo-intensities-msgs";
import { QuantCreateReq, QuantCreateResp, QuantCreateUpd } from "./quantification-create";
import {
  QuantBlessReq,
  QuantBlessResp,
  QuantDeleteReq,
  QuantDeleteResp,
  QuantPublishReq,
  QuantPublishResp,
} from "./quantification-management-msgs";
import {
  MultiQuantCompareReq,
  MultiQuantCompareResp,
  QuantCombineListGetReq,
  QuantCombineListGetResp,
  QuantCombineListWriteReq,
  QuantCombineListWriteResp,
  QuantCombineReq,
  QuantCombineResp,
} from "./quantification-multi-msgs";
import {
  QuantGetReq,
  QuantGetResp,
  QuantLastOutputGetReq,
  QuantLastOutputGetResp,
  QuantListReq,
  QuantListResp,
  QuantLogGetReq,
  QuantLogGetResp,
  QuantLogListReq,
  QuantLogListResp,
  QuantRawDataGetReq,
  QuantRawDataGetResp,
} from "./quantification-retrieval-msgs";
import { QuantUploadReq, QuantUploadResp } from "./quantification-upload-msgs";
import {
  RegionOfInterestBulkDuplicateReq,
  RegionOfInterestBulkDuplicateResp,
  RegionOfInterestBulkWriteReq,
  RegionOfInterestBulkWriteResp,
  RegionOfInterestDeleteReq,
  RegionOfInterestDeleteResp,
  RegionOfInterestDisplaySettingsGetReq,
  RegionOfInterestDisplaySettingsGetResp,
  RegionOfInterestDisplaySettingsWriteReq,
  RegionOfInterestDisplaySettingsWriteResp,
  RegionOfInterestGetReq,
  RegionOfInterestGetResp,
  RegionOfInterestListReq,
  RegionOfInterestListResp,
  RegionOfInterestWriteReq,
  RegionOfInterestWriteResp,
} from "./roi-msgs";
import { ScanBeamLocationsReq, ScanBeamLocationsResp } from "./scan-beam-location-msgs";
import { ScanEntryMetadataReq, ScanEntryMetadataResp } from "./scan-entry-metadata-msgs";
import { ScanEntryReq, ScanEntryResp } from "./scan-entry-msgs";
import {
  ScanAutoShareReq,
  ScanAutoShareResp,
  ScanAutoShareWriteReq,
  ScanAutoShareWriteResp,
  ScanDeleteReq,
  ScanDeleteResp,
  ScanGetReq,
  ScanGetResp,
  ScanListReq,
  ScanListResp,
  ScanListUpd,
  ScanMetaLabelsAndTypesReq,
  ScanMetaLabelsAndTypesResp,
  ScanMetaWriteReq,
  ScanMetaWriteResp,
  ScanTriggerAutoQuantReq,
  ScanTriggerAutoQuantResp,
  ScanTriggerReImportReq,
  ScanTriggerReImportResp,
  ScanTriggerReImportUpd,
  ScanUploadReq,
  ScanUploadResp,
  ScanUploadUpd,
} from "./scan-msgs";
import {
  ScreenConfigurationDeleteReq,
  ScreenConfigurationDeleteResp,
  ScreenConfigurationGetReq,
  ScreenConfigurationGetResp,
  ScreenConfigurationListReq,
  ScreenConfigurationListResp,
  ScreenConfigurationWriteReq,
  ScreenConfigurationWriteResp,
} from "./screen-configuration-msgs";
import {
  SelectedScanEntriesReq,
  SelectedScanEntriesResp,
  SelectedScanEntriesWriteReq,
  SelectedScanEntriesWriteResp,
} from "./selection-entry-msgs";
import {
  SelectedImagePixelsReq,
  SelectedImagePixelsResp,
  SelectedImagePixelsWriteReq,
  SelectedImagePixelsWriteResp,
} from "./selection-pixel-msgs";
import { SpectrumReq, SpectrumResp } from "./spectrum-msgs";
import {
  BackupDBReq,
  BackupDBResp,
  BackupDBUpd,
  DBAdminConfigGetReq,
  DBAdminConfigGetResp,
  RestoreDBReq,
  RestoreDBResp,
} from "./system";
import { TagCreateReq, TagCreateResp, TagDeleteReq, TagDeleteResp, TagListReq, TagListResp } from "./tag-msgs";
import { RunTestReq, RunTestResp } from "./test-msgs";
import {
  UserGroupAddAdminReq,
  UserGroupAddAdminResp,
  UserGroupDeleteAdminReq,
  UserGroupDeleteAdminResp,
} from "./user-group-admins-msgs";
import {
  UserGroupIgnoreJoinReq,
  UserGroupIgnoreJoinResp,
  UserGroupJoinListReq,
  UserGroupJoinListResp,
  UserGroupJoinReq,
  UserGroupJoinResp,
} from "./user-group-joining-msgs";
import {
  UserGroupCreateReq,
  UserGroupCreateResp,
  UserGroupDeleteReq,
  UserGroupDeleteResp,
  UserGroupEditDetailsReq,
  UserGroupEditDetailsResp,
} from "./user-group-management-msgs";
import {
  UserGroupAddMemberReq,
  UserGroupAddMemberResp,
  UserGroupAddViewerReq,
  UserGroupAddViewerResp,
  UserGroupDeleteMemberReq,
  UserGroupDeleteMemberResp,
  UserGroupDeleteViewerReq,
  UserGroupDeleteViewerResp,
} from "./user-group-membership-msgs";
import {
  UserGroupListJoinableReq,
  UserGroupListJoinableResp,
  UserGroupListReq,
  UserGroupListResp,
  UserGroupReq,
  UserGroupResp,
} from "./user-group-retrieval-msgs";
import {
  ReviewerMagicLinkCreateReq,
  ReviewerMagicLinkCreateResp,
  ReviewerMagicLinkLoginReq,
  ReviewerMagicLinkLoginResp,
  UserAddRoleReq,
  UserAddRoleResp,
  UserDeleteRoleReq,
  UserDeleteRoleResp,
  UserImpersonateGetReq,
  UserImpersonateGetResp,
  UserImpersonateReq,
  UserImpersonateResp,
  UserListReq,
  UserListResp,
  UserRoleListReq,
  UserRoleListResp,
  UserRolesListReq,
  UserRolesListResp,
} from "./user-management-msgs";
import {
  UserDetailsReq,
  UserDetailsResp,
  UserDetailsWriteReq,
  UserDetailsWriteResp,
  UserSearchReq,
  UserSearchResp,
} from "./user-msgs";
import {
  UserNotificationSettingsReq,
  UserNotificationSettingsResp,
  UserNotificationSettingsUpd,
  UserNotificationSettingsWriteReq,
  UserNotificationSettingsWriteResp,
} from "./user-notification-setting-msgs";
import { WidgetDataGetReq, WidgetDataGetResp, WidgetDataWriteReq, WidgetDataWriteResp } from "./widget-data-msgs";

export const protobufPackage = "";

/**
 * This is expected to appear in all Resp messages. It is
 * basically a replacement for HTTP status codes
 */
export enum ResponseStatus {
  WS_UNDEFINED = 0,
  WS_OK = 1,
  WS_NOT_FOUND = 2,
  WS_BAD_REQUEST = 3,
  WS_NO_PERMISSION = 4,
  WS_SERVER_ERROR = 5,
  /**
   * WS_TIMEOUT - Really, this is likely a client-side error but included here
   * so it's in the enum
   */
  WS_TIMEOUT = 6,
  UNRECOGNIZED = -1,
}

export function responseStatusFromJSON(object: any): ResponseStatus {
  switch (object) {
    case 0:
    case "WS_UNDEFINED":
      return ResponseStatus.WS_UNDEFINED;
    case 1:
    case "WS_OK":
      return ResponseStatus.WS_OK;
    case 2:
    case "WS_NOT_FOUND":
      return ResponseStatus.WS_NOT_FOUND;
    case 3:
    case "WS_BAD_REQUEST":
      return ResponseStatus.WS_BAD_REQUEST;
    case 4:
    case "WS_NO_PERMISSION":
      return ResponseStatus.WS_NO_PERMISSION;
    case 5:
    case "WS_SERVER_ERROR":
      return ResponseStatus.WS_SERVER_ERROR;
    case 6:
    case "WS_TIMEOUT":
      return ResponseStatus.WS_TIMEOUT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResponseStatus.UNRECOGNIZED;
  }
}

export function responseStatusToJSON(object: ResponseStatus): string {
  switch (object) {
    case ResponseStatus.WS_UNDEFINED:
      return "WS_UNDEFINED";
    case ResponseStatus.WS_OK:
      return "WS_OK";
    case ResponseStatus.WS_NOT_FOUND:
      return "WS_NOT_FOUND";
    case ResponseStatus.WS_BAD_REQUEST:
      return "WS_BAD_REQUEST";
    case ResponseStatus.WS_NO_PERMISSION:
      return "WS_NO_PERMISSION";
    case ResponseStatus.WS_SERVER_ERROR:
      return "WS_SERVER_ERROR";
    case ResponseStatus.WS_TIMEOUT:
      return "WS_TIMEOUT";
    case ResponseStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** The overall wrapper WSMessage */
export interface WSMessage {
  /**
   * Message ID - think of this as a request ID to help associate requests and responses.
   * Should be a number counting up for each request sent from client, responses should include the same number
   * Other messages can leave this empty
   */
  msgId: number;
  /**
   * Response Status - only needs to be set on a response going back out of the API
   * This replaces the HTTP status code, so we don't have to define this field
   * in every response message (initially started doing that, but what if you need
   * more fields, see the following one)
   */
  status: ResponseStatus;
  /** Error text - if the response status is not WS_OK, this can contain any error string we want to send back */
  errorText: string;
  backupDBReq?: BackupDBReq | undefined;
  backupDBResp?: BackupDBResp | undefined;
  backupDBUpd?: BackupDBUpd | undefined;
  dBAdminConfigGetReq?: DBAdminConfigGetReq | undefined;
  dBAdminConfigGetResp?: DBAdminConfigGetResp | undefined;
  dataModuleAddVersionReq?: DataModuleAddVersionReq | undefined;
  dataModuleAddVersionResp?: DataModuleAddVersionResp | undefined;
  dataModuleGetReq?: DataModuleGetReq | undefined;
  dataModuleGetResp?: DataModuleGetResp | undefined;
  dataModuleListReq?: DataModuleListReq | undefined;
  dataModuleListResp?: DataModuleListResp | undefined;
  dataModuleWriteReq?: DataModuleWriteReq | undefined;
  dataModuleWriteResp?: DataModuleWriteResp | undefined;
  detectedDiffractionPeaksReq?: DetectedDiffractionPeaksReq | undefined;
  detectedDiffractionPeaksResp?: DetectedDiffractionPeaksResp | undefined;
  detectorConfigListReq?: DetectorConfigListReq | undefined;
  detectorConfigListResp?: DetectorConfigListResp | undefined;
  detectorConfigReq?: DetectorConfigReq | undefined;
  detectorConfigResp?: DetectorConfigResp | undefined;
  diffractionPeakManualDeleteReq?: DiffractionPeakManualDeleteReq | undefined;
  diffractionPeakManualDeleteResp?: DiffractionPeakManualDeleteResp | undefined;
  diffractionPeakManualInsertReq?: DiffractionPeakManualInsertReq | undefined;
  diffractionPeakManualInsertResp?: DiffractionPeakManualInsertResp | undefined;
  diffractionPeakManualListReq?: DiffractionPeakManualListReq | undefined;
  diffractionPeakManualListResp?: DiffractionPeakManualListResp | undefined;
  diffractionPeakStatusDeleteReq?: DiffractionPeakStatusDeleteReq | undefined;
  diffractionPeakStatusDeleteResp?: DiffractionPeakStatusDeleteResp | undefined;
  diffractionPeakStatusListReq?: DiffractionPeakStatusListReq | undefined;
  diffractionPeakStatusListResp?: DiffractionPeakStatusListResp | undefined;
  diffractionPeakStatusWriteReq?: DiffractionPeakStatusWriteReq | undefined;
  diffractionPeakStatusWriteResp?: DiffractionPeakStatusWriteResp | undefined;
  elementSetDeleteReq?: ElementSetDeleteReq | undefined;
  elementSetDeleteResp?: ElementSetDeleteResp | undefined;
  elementSetGetReq?: ElementSetGetReq | undefined;
  elementSetGetResp?: ElementSetGetResp | undefined;
  elementSetListReq?: ElementSetListReq | undefined;
  elementSetListResp?: ElementSetListResp | undefined;
  elementSetWriteReq?: ElementSetWriteReq | undefined;
  elementSetWriteResp?: ElementSetWriteResp | undefined;
  exportFilesReq?: ExportFilesReq | undefined;
  exportFilesResp?: ExportFilesResp | undefined;
  expressionDeleteReq?: ExpressionDeleteReq | undefined;
  expressionDeleteResp?: ExpressionDeleteResp | undefined;
  expressionDisplaySettingsGetReq?: ExpressionDisplaySettingsGetReq | undefined;
  expressionDisplaySettingsGetResp?: ExpressionDisplaySettingsGetResp | undefined;
  expressionDisplaySettingsWriteReq?: ExpressionDisplaySettingsWriteReq | undefined;
  expressionDisplaySettingsWriteResp?: ExpressionDisplaySettingsWriteResp | undefined;
  expressionGetReq?: ExpressionGetReq | undefined;
  expressionGetResp?: ExpressionGetResp | undefined;
  expressionGroupDeleteReq?: ExpressionGroupDeleteReq | undefined;
  expressionGroupDeleteResp?: ExpressionGroupDeleteResp | undefined;
  expressionGroupGetReq?: ExpressionGroupGetReq | undefined;
  expressionGroupGetResp?: ExpressionGroupGetResp | undefined;
  expressionGroupListReq?: ExpressionGroupListReq | undefined;
  expressionGroupListResp?: ExpressionGroupListResp | undefined;
  expressionGroupWriteReq?: ExpressionGroupWriteReq | undefined;
  expressionGroupWriteResp?: ExpressionGroupWriteResp | undefined;
  expressionListReq?: ExpressionListReq | undefined;
  expressionListResp?: ExpressionListResp | undefined;
  expressionWriteExecStatReq?: ExpressionWriteExecStatReq | undefined;
  expressionWriteExecStatResp?: ExpressionWriteExecStatResp | undefined;
  expressionWriteReq?: ExpressionWriteReq | undefined;
  expressionWriteResp?: ExpressionWriteResp | undefined;
  getOwnershipReq?: GetOwnershipReq | undefined;
  getOwnershipResp?: GetOwnershipResp | undefined;
  imageBeamLocationVersionsReq?: ImageBeamLocationVersionsReq | undefined;
  imageBeamLocationVersionsResp?: ImageBeamLocationVersionsResp | undefined;
  imageBeamLocationsReq?: ImageBeamLocationsReq | undefined;
  imageBeamLocationsResp?: ImageBeamLocationsResp | undefined;
  imageDeleteReq?: ImageDeleteReq | undefined;
  imageDeleteResp?: ImageDeleteResp | undefined;
  imageGetDefaultReq?: ImageGetDefaultReq | undefined;
  imageGetDefaultResp?: ImageGetDefaultResp | undefined;
  imageGetReq?: ImageGetReq | undefined;
  imageGetResp?: ImageGetResp | undefined;
  imageListReq?: ImageListReq | undefined;
  imageListResp?: ImageListResp | undefined;
  imageListUpd?: ImageListUpd | undefined;
  imageSetDefaultReq?: ImageSetDefaultReq | undefined;
  imageSetDefaultResp?: ImageSetDefaultResp | undefined;
  imageSetMatchTransformReq?: ImageSetMatchTransformReq | undefined;
  imageSetMatchTransformResp?: ImageSetMatchTransformResp | undefined;
  importMarsViewerImageReq?: ImportMarsViewerImageReq | undefined;
  importMarsViewerImageResp?: ImportMarsViewerImageResp | undefined;
  importMarsViewerImageUpd?: ImportMarsViewerImageUpd | undefined;
  jobListReq?: JobListReq | undefined;
  jobListResp?: JobListResp | undefined;
  jobListUpd?: JobListUpd | undefined;
  logGetLevelReq?: LogGetLevelReq | undefined;
  logGetLevelResp?: LogGetLevelResp | undefined;
  logReadReq?: LogReadReq | undefined;
  logReadResp?: LogReadResp | undefined;
  logSetLevelReq?: LogSetLevelReq | undefined;
  logSetLevelResp?: LogSetLevelResp | undefined;
  memoiseGetReq?: MemoiseGetReq | undefined;
  memoiseGetResp?: MemoiseGetResp | undefined;
  memoiseWriteReq?: MemoiseWriteReq | undefined;
  memoiseWriteResp?: MemoiseWriteResp | undefined;
  multiQuantCompareReq?: MultiQuantCompareReq | undefined;
  multiQuantCompareResp?: MultiQuantCompareResp | undefined;
  notificationDismissReq?: NotificationDismissReq | undefined;
  notificationDismissResp?: NotificationDismissResp | undefined;
  notificationReq?: NotificationReq | undefined;
  notificationResp?: NotificationResp | undefined;
  notificationUpd?: NotificationUpd | undefined;
  objectEditAccessReq?: ObjectEditAccessReq | undefined;
  objectEditAccessResp?: ObjectEditAccessResp | undefined;
  piquantConfigListReq?: PiquantConfigListReq | undefined;
  piquantConfigListResp?: PiquantConfigListResp | undefined;
  piquantConfigVersionReq?: PiquantConfigVersionReq | undefined;
  piquantConfigVersionResp?: PiquantConfigVersionResp | undefined;
  piquantConfigVersionsListReq?: PiquantConfigVersionsListReq | undefined;
  piquantConfigVersionsListResp?: PiquantConfigVersionsListResp | undefined;
  piquantCurrentVersionReq?: PiquantCurrentVersionReq | undefined;
  piquantCurrentVersionResp?: PiquantCurrentVersionResp | undefined;
  piquantVersionListReq?: PiquantVersionListReq | undefined;
  piquantVersionListResp?: PiquantVersionListResp | undefined;
  piquantWriteCurrentVersionReq?: PiquantWriteCurrentVersionReq | undefined;
  piquantWriteCurrentVersionResp?: PiquantWriteCurrentVersionResp | undefined;
  pseudoIntensityReq?: PseudoIntensityReq | undefined;
  pseudoIntensityResp?: PseudoIntensityResp | undefined;
  publishExpressionToZenodoReq?: PublishExpressionToZenodoReq | undefined;
  publishExpressionToZenodoResp?: PublishExpressionToZenodoResp | undefined;
  quantBlessReq?: QuantBlessReq | undefined;
  quantBlessResp?: QuantBlessResp | undefined;
  quantCombineListGetReq?: QuantCombineListGetReq | undefined;
  quantCombineListGetResp?: QuantCombineListGetResp | undefined;
  quantCombineListWriteReq?: QuantCombineListWriteReq | undefined;
  quantCombineListWriteResp?: QuantCombineListWriteResp | undefined;
  quantCombineReq?: QuantCombineReq | undefined;
  quantCombineResp?: QuantCombineResp | undefined;
  quantCreateReq?: QuantCreateReq | undefined;
  quantCreateResp?: QuantCreateResp | undefined;
  quantCreateUpd?: QuantCreateUpd | undefined;
  quantDeleteReq?: QuantDeleteReq | undefined;
  quantDeleteResp?: QuantDeleteResp | undefined;
  quantGetReq?: QuantGetReq | undefined;
  quantGetResp?: QuantGetResp | undefined;
  quantLastOutputGetReq?: QuantLastOutputGetReq | undefined;
  quantLastOutputGetResp?: QuantLastOutputGetResp | undefined;
  quantListReq?: QuantListReq | undefined;
  quantListResp?: QuantListResp | undefined;
  quantLogGetReq?: QuantLogGetReq | undefined;
  quantLogGetResp?: QuantLogGetResp | undefined;
  quantLogListReq?: QuantLogListReq | undefined;
  quantLogListResp?: QuantLogListResp | undefined;
  quantPublishReq?: QuantPublishReq | undefined;
  quantPublishResp?: QuantPublishResp | undefined;
  quantRawDataGetReq?: QuantRawDataGetReq | undefined;
  quantRawDataGetResp?: QuantRawDataGetResp | undefined;
  quantUploadReq?: QuantUploadReq | undefined;
  quantUploadResp?: QuantUploadResp | undefined;
  regionOfInterestBulkDuplicateReq?: RegionOfInterestBulkDuplicateReq | undefined;
  regionOfInterestBulkDuplicateResp?: RegionOfInterestBulkDuplicateResp | undefined;
  regionOfInterestBulkWriteReq?: RegionOfInterestBulkWriteReq | undefined;
  regionOfInterestBulkWriteResp?: RegionOfInterestBulkWriteResp | undefined;
  regionOfInterestDeleteReq?: RegionOfInterestDeleteReq | undefined;
  regionOfInterestDeleteResp?: RegionOfInterestDeleteResp | undefined;
  regionOfInterestDisplaySettingsGetReq?: RegionOfInterestDisplaySettingsGetReq | undefined;
  regionOfInterestDisplaySettingsGetResp?: RegionOfInterestDisplaySettingsGetResp | undefined;
  regionOfInterestDisplaySettingsWriteReq?: RegionOfInterestDisplaySettingsWriteReq | undefined;
  regionOfInterestDisplaySettingsWriteResp?: RegionOfInterestDisplaySettingsWriteResp | undefined;
  regionOfInterestGetReq?: RegionOfInterestGetReq | undefined;
  regionOfInterestGetResp?: RegionOfInterestGetResp | undefined;
  regionOfInterestListReq?: RegionOfInterestListReq | undefined;
  regionOfInterestListResp?: RegionOfInterestListResp | undefined;
  regionOfInterestWriteReq?: RegionOfInterestWriteReq | undefined;
  regionOfInterestWriteResp?: RegionOfInterestWriteResp | undefined;
  restoreDBReq?: RestoreDBReq | undefined;
  restoreDBResp?: RestoreDBResp | undefined;
  reviewerMagicLinkCreateReq?: ReviewerMagicLinkCreateReq | undefined;
  reviewerMagicLinkCreateResp?: ReviewerMagicLinkCreateResp | undefined;
  reviewerMagicLinkLoginReq?: ReviewerMagicLinkLoginReq | undefined;
  reviewerMagicLinkLoginResp?: ReviewerMagicLinkLoginResp | undefined;
  runTestReq?: RunTestReq | undefined;
  runTestResp?: RunTestResp | undefined;
  scanAutoShareReq?: ScanAutoShareReq | undefined;
  scanAutoShareResp?: ScanAutoShareResp | undefined;
  scanAutoShareWriteReq?: ScanAutoShareWriteReq | undefined;
  scanAutoShareWriteResp?: ScanAutoShareWriteResp | undefined;
  scanBeamLocationsReq?: ScanBeamLocationsReq | undefined;
  scanBeamLocationsResp?: ScanBeamLocationsResp | undefined;
  scanDeleteReq?: ScanDeleteReq | undefined;
  scanDeleteResp?: ScanDeleteResp | undefined;
  scanEntryMetadataReq?: ScanEntryMetadataReq | undefined;
  scanEntryMetadataResp?: ScanEntryMetadataResp | undefined;
  scanEntryReq?: ScanEntryReq | undefined;
  scanEntryResp?: ScanEntryResp | undefined;
  scanGetReq?: ScanGetReq | undefined;
  scanGetResp?: ScanGetResp | undefined;
  scanListReq?: ScanListReq | undefined;
  scanListResp?: ScanListResp | undefined;
  scanListUpd?: ScanListUpd | undefined;
  scanMetaLabelsAndTypesReq?: ScanMetaLabelsAndTypesReq | undefined;
  scanMetaLabelsAndTypesResp?: ScanMetaLabelsAndTypesResp | undefined;
  scanMetaWriteReq?: ScanMetaWriteReq | undefined;
  scanMetaWriteResp?: ScanMetaWriteResp | undefined;
  scanTriggerAutoQuantReq?: ScanTriggerAutoQuantReq | undefined;
  scanTriggerAutoQuantResp?: ScanTriggerAutoQuantResp | undefined;
  scanTriggerReImportReq?: ScanTriggerReImportReq | undefined;
  scanTriggerReImportResp?: ScanTriggerReImportResp | undefined;
  scanTriggerReImportUpd?: ScanTriggerReImportUpd | undefined;
  scanUploadReq?: ScanUploadReq | undefined;
  scanUploadResp?: ScanUploadResp | undefined;
  scanUploadUpd?: ScanUploadUpd | undefined;
  screenConfigurationDeleteReq?: ScreenConfigurationDeleteReq | undefined;
  screenConfigurationDeleteResp?: ScreenConfigurationDeleteResp | undefined;
  screenConfigurationGetReq?: ScreenConfigurationGetReq | undefined;
  screenConfigurationGetResp?: ScreenConfigurationGetResp | undefined;
  screenConfigurationListReq?: ScreenConfigurationListReq | undefined;
  screenConfigurationListResp?: ScreenConfigurationListResp | undefined;
  screenConfigurationWriteReq?: ScreenConfigurationWriteReq | undefined;
  screenConfigurationWriteResp?: ScreenConfigurationWriteResp | undefined;
  selectedImagePixelsReq?: SelectedImagePixelsReq | undefined;
  selectedImagePixelsResp?: SelectedImagePixelsResp | undefined;
  selectedImagePixelsWriteReq?: SelectedImagePixelsWriteReq | undefined;
  selectedImagePixelsWriteResp?: SelectedImagePixelsWriteResp | undefined;
  selectedScanEntriesReq?: SelectedScanEntriesReq | undefined;
  selectedScanEntriesResp?: SelectedScanEntriesResp | undefined;
  selectedScanEntriesWriteReq?: SelectedScanEntriesWriteReq | undefined;
  selectedScanEntriesWriteResp?: SelectedScanEntriesWriteResp | undefined;
  sendUserNotificationReq?: SendUserNotificationReq | undefined;
  sendUserNotificationResp?: SendUserNotificationResp | undefined;
  spectrumReq?: SpectrumReq | undefined;
  spectrumResp?: SpectrumResp | undefined;
  tagCreateReq?: TagCreateReq | undefined;
  tagCreateResp?: TagCreateResp | undefined;
  tagDeleteReq?: TagDeleteReq | undefined;
  tagDeleteResp?: TagDeleteResp | undefined;
  tagListReq?: TagListReq | undefined;
  tagListResp?: TagListResp | undefined;
  userAddRoleReq?: UserAddRoleReq | undefined;
  userAddRoleResp?: UserAddRoleResp | undefined;
  userDeleteRoleReq?: UserDeleteRoleReq | undefined;
  userDeleteRoleResp?: UserDeleteRoleResp | undefined;
  userDetailsReq?: UserDetailsReq | undefined;
  userDetailsResp?: UserDetailsResp | undefined;
  userDetailsWriteReq?: UserDetailsWriteReq | undefined;
  userDetailsWriteResp?: UserDetailsWriteResp | undefined;
  userGroupAddAdminReq?: UserGroupAddAdminReq | undefined;
  userGroupAddAdminResp?: UserGroupAddAdminResp | undefined;
  userGroupAddMemberReq?: UserGroupAddMemberReq | undefined;
  userGroupAddMemberResp?: UserGroupAddMemberResp | undefined;
  userGroupAddViewerReq?: UserGroupAddViewerReq | undefined;
  userGroupAddViewerResp?: UserGroupAddViewerResp | undefined;
  userGroupCreateReq?: UserGroupCreateReq | undefined;
  userGroupCreateResp?: UserGroupCreateResp | undefined;
  userGroupDeleteAdminReq?: UserGroupDeleteAdminReq | undefined;
  userGroupDeleteAdminResp?: UserGroupDeleteAdminResp | undefined;
  userGroupDeleteMemberReq?: UserGroupDeleteMemberReq | undefined;
  userGroupDeleteMemberResp?: UserGroupDeleteMemberResp | undefined;
  userGroupDeleteReq?: UserGroupDeleteReq | undefined;
  userGroupDeleteResp?: UserGroupDeleteResp | undefined;
  userGroupDeleteViewerReq?: UserGroupDeleteViewerReq | undefined;
  userGroupDeleteViewerResp?: UserGroupDeleteViewerResp | undefined;
  userGroupEditDetailsReq?: UserGroupEditDetailsReq | undefined;
  userGroupEditDetailsResp?: UserGroupEditDetailsResp | undefined;
  userGroupIgnoreJoinReq?: UserGroupIgnoreJoinReq | undefined;
  userGroupIgnoreJoinResp?: UserGroupIgnoreJoinResp | undefined;
  userGroupJoinListReq?: UserGroupJoinListReq | undefined;
  userGroupJoinListResp?: UserGroupJoinListResp | undefined;
  userGroupJoinReq?: UserGroupJoinReq | undefined;
  userGroupJoinResp?: UserGroupJoinResp | undefined;
  userGroupListJoinableReq?: UserGroupListJoinableReq | undefined;
  userGroupListJoinableResp?: UserGroupListJoinableResp | undefined;
  userGroupListReq?: UserGroupListReq | undefined;
  userGroupListResp?: UserGroupListResp | undefined;
  userGroupReq?: UserGroupReq | undefined;
  userGroupResp?: UserGroupResp | undefined;
  userImpersonateGetReq?: UserImpersonateGetReq | undefined;
  userImpersonateGetResp?: UserImpersonateGetResp | undefined;
  userImpersonateReq?: UserImpersonateReq | undefined;
  userImpersonateResp?: UserImpersonateResp | undefined;
  userListReq?: UserListReq | undefined;
  userListResp?: UserListResp | undefined;
  userNotificationSettingsReq?: UserNotificationSettingsReq | undefined;
  userNotificationSettingsResp?: UserNotificationSettingsResp | undefined;
  userNotificationSettingsUpd?: UserNotificationSettingsUpd | undefined;
  userNotificationSettingsWriteReq?: UserNotificationSettingsWriteReq | undefined;
  userNotificationSettingsWriteResp?: UserNotificationSettingsWriteResp | undefined;
  userRoleListReq?: UserRoleListReq | undefined;
  userRoleListResp?: UserRoleListResp | undefined;
  userRolesListReq?: UserRolesListReq | undefined;
  userRolesListResp?: UserRolesListResp | undefined;
  userSearchReq?: UserSearchReq | undefined;
  userSearchResp?: UserSearchResp | undefined;
  widgetDataGetReq?: WidgetDataGetReq | undefined;
  widgetDataGetResp?: WidgetDataGetResp | undefined;
  widgetDataWriteReq?: WidgetDataWriteReq | undefined;
  widgetDataWriteResp?: WidgetDataWriteResp | undefined;
  zenodoDOIGetReq?: ZenodoDOIGetReq | undefined;
  zenodoDOIGetResp?: ZenodoDOIGetResp | undefined;
}

function createBaseWSMessage(): WSMessage {
  return {
    msgId: 0,
    status: 0,
    errorText: "",
    backupDBReq: undefined,
    backupDBResp: undefined,
    backupDBUpd: undefined,
    dBAdminConfigGetReq: undefined,
    dBAdminConfigGetResp: undefined,
    dataModuleAddVersionReq: undefined,
    dataModuleAddVersionResp: undefined,
    dataModuleGetReq: undefined,
    dataModuleGetResp: undefined,
    dataModuleListReq: undefined,
    dataModuleListResp: undefined,
    dataModuleWriteReq: undefined,
    dataModuleWriteResp: undefined,
    detectedDiffractionPeaksReq: undefined,
    detectedDiffractionPeaksResp: undefined,
    detectorConfigListReq: undefined,
    detectorConfigListResp: undefined,
    detectorConfigReq: undefined,
    detectorConfigResp: undefined,
    diffractionPeakManualDeleteReq: undefined,
    diffractionPeakManualDeleteResp: undefined,
    diffractionPeakManualInsertReq: undefined,
    diffractionPeakManualInsertResp: undefined,
    diffractionPeakManualListReq: undefined,
    diffractionPeakManualListResp: undefined,
    diffractionPeakStatusDeleteReq: undefined,
    diffractionPeakStatusDeleteResp: undefined,
    diffractionPeakStatusListReq: undefined,
    diffractionPeakStatusListResp: undefined,
    diffractionPeakStatusWriteReq: undefined,
    diffractionPeakStatusWriteResp: undefined,
    elementSetDeleteReq: undefined,
    elementSetDeleteResp: undefined,
    elementSetGetReq: undefined,
    elementSetGetResp: undefined,
    elementSetListReq: undefined,
    elementSetListResp: undefined,
    elementSetWriteReq: undefined,
    elementSetWriteResp: undefined,
    exportFilesReq: undefined,
    exportFilesResp: undefined,
    expressionDeleteReq: undefined,
    expressionDeleteResp: undefined,
    expressionDisplaySettingsGetReq: undefined,
    expressionDisplaySettingsGetResp: undefined,
    expressionDisplaySettingsWriteReq: undefined,
    expressionDisplaySettingsWriteResp: undefined,
    expressionGetReq: undefined,
    expressionGetResp: undefined,
    expressionGroupDeleteReq: undefined,
    expressionGroupDeleteResp: undefined,
    expressionGroupGetReq: undefined,
    expressionGroupGetResp: undefined,
    expressionGroupListReq: undefined,
    expressionGroupListResp: undefined,
    expressionGroupWriteReq: undefined,
    expressionGroupWriteResp: undefined,
    expressionListReq: undefined,
    expressionListResp: undefined,
    expressionWriteExecStatReq: undefined,
    expressionWriteExecStatResp: undefined,
    expressionWriteReq: undefined,
    expressionWriteResp: undefined,
    getOwnershipReq: undefined,
    getOwnershipResp: undefined,
    imageBeamLocationVersionsReq: undefined,
    imageBeamLocationVersionsResp: undefined,
    imageBeamLocationsReq: undefined,
    imageBeamLocationsResp: undefined,
    imageDeleteReq: undefined,
    imageDeleteResp: undefined,
    imageGetDefaultReq: undefined,
    imageGetDefaultResp: undefined,
    imageGetReq: undefined,
    imageGetResp: undefined,
    imageListReq: undefined,
    imageListResp: undefined,
    imageListUpd: undefined,
    imageSetDefaultReq: undefined,
    imageSetDefaultResp: undefined,
    imageSetMatchTransformReq: undefined,
    imageSetMatchTransformResp: undefined,
    importMarsViewerImageReq: undefined,
    importMarsViewerImageResp: undefined,
    importMarsViewerImageUpd: undefined,
    jobListReq: undefined,
    jobListResp: undefined,
    jobListUpd: undefined,
    logGetLevelReq: undefined,
    logGetLevelResp: undefined,
    logReadReq: undefined,
    logReadResp: undefined,
    logSetLevelReq: undefined,
    logSetLevelResp: undefined,
    memoiseGetReq: undefined,
    memoiseGetResp: undefined,
    memoiseWriteReq: undefined,
    memoiseWriteResp: undefined,
    multiQuantCompareReq: undefined,
    multiQuantCompareResp: undefined,
    notificationDismissReq: undefined,
    notificationDismissResp: undefined,
    notificationReq: undefined,
    notificationResp: undefined,
    notificationUpd: undefined,
    objectEditAccessReq: undefined,
    objectEditAccessResp: undefined,
    piquantConfigListReq: undefined,
    piquantConfigListResp: undefined,
    piquantConfigVersionReq: undefined,
    piquantConfigVersionResp: undefined,
    piquantConfigVersionsListReq: undefined,
    piquantConfigVersionsListResp: undefined,
    piquantCurrentVersionReq: undefined,
    piquantCurrentVersionResp: undefined,
    piquantVersionListReq: undefined,
    piquantVersionListResp: undefined,
    piquantWriteCurrentVersionReq: undefined,
    piquantWriteCurrentVersionResp: undefined,
    pseudoIntensityReq: undefined,
    pseudoIntensityResp: undefined,
    publishExpressionToZenodoReq: undefined,
    publishExpressionToZenodoResp: undefined,
    quantBlessReq: undefined,
    quantBlessResp: undefined,
    quantCombineListGetReq: undefined,
    quantCombineListGetResp: undefined,
    quantCombineListWriteReq: undefined,
    quantCombineListWriteResp: undefined,
    quantCombineReq: undefined,
    quantCombineResp: undefined,
    quantCreateReq: undefined,
    quantCreateResp: undefined,
    quantCreateUpd: undefined,
    quantDeleteReq: undefined,
    quantDeleteResp: undefined,
    quantGetReq: undefined,
    quantGetResp: undefined,
    quantLastOutputGetReq: undefined,
    quantLastOutputGetResp: undefined,
    quantListReq: undefined,
    quantListResp: undefined,
    quantLogGetReq: undefined,
    quantLogGetResp: undefined,
    quantLogListReq: undefined,
    quantLogListResp: undefined,
    quantPublishReq: undefined,
    quantPublishResp: undefined,
    quantRawDataGetReq: undefined,
    quantRawDataGetResp: undefined,
    quantUploadReq: undefined,
    quantUploadResp: undefined,
    regionOfInterestBulkDuplicateReq: undefined,
    regionOfInterestBulkDuplicateResp: undefined,
    regionOfInterestBulkWriteReq: undefined,
    regionOfInterestBulkWriteResp: undefined,
    regionOfInterestDeleteReq: undefined,
    regionOfInterestDeleteResp: undefined,
    regionOfInterestDisplaySettingsGetReq: undefined,
    regionOfInterestDisplaySettingsGetResp: undefined,
    regionOfInterestDisplaySettingsWriteReq: undefined,
    regionOfInterestDisplaySettingsWriteResp: undefined,
    regionOfInterestGetReq: undefined,
    regionOfInterestGetResp: undefined,
    regionOfInterestListReq: undefined,
    regionOfInterestListResp: undefined,
    regionOfInterestWriteReq: undefined,
    regionOfInterestWriteResp: undefined,
    restoreDBReq: undefined,
    restoreDBResp: undefined,
    reviewerMagicLinkCreateReq: undefined,
    reviewerMagicLinkCreateResp: undefined,
    reviewerMagicLinkLoginReq: undefined,
    reviewerMagicLinkLoginResp: undefined,
    runTestReq: undefined,
    runTestResp: undefined,
    scanAutoShareReq: undefined,
    scanAutoShareResp: undefined,
    scanAutoShareWriteReq: undefined,
    scanAutoShareWriteResp: undefined,
    scanBeamLocationsReq: undefined,
    scanBeamLocationsResp: undefined,
    scanDeleteReq: undefined,
    scanDeleteResp: undefined,
    scanEntryMetadataReq: undefined,
    scanEntryMetadataResp: undefined,
    scanEntryReq: undefined,
    scanEntryResp: undefined,
    scanGetReq: undefined,
    scanGetResp: undefined,
    scanListReq: undefined,
    scanListResp: undefined,
    scanListUpd: undefined,
    scanMetaLabelsAndTypesReq: undefined,
    scanMetaLabelsAndTypesResp: undefined,
    scanMetaWriteReq: undefined,
    scanMetaWriteResp: undefined,
    scanTriggerAutoQuantReq: undefined,
    scanTriggerAutoQuantResp: undefined,
    scanTriggerReImportReq: undefined,
    scanTriggerReImportResp: undefined,
    scanTriggerReImportUpd: undefined,
    scanUploadReq: undefined,
    scanUploadResp: undefined,
    scanUploadUpd: undefined,
    screenConfigurationDeleteReq: undefined,
    screenConfigurationDeleteResp: undefined,
    screenConfigurationGetReq: undefined,
    screenConfigurationGetResp: undefined,
    screenConfigurationListReq: undefined,
    screenConfigurationListResp: undefined,
    screenConfigurationWriteReq: undefined,
    screenConfigurationWriteResp: undefined,
    selectedImagePixelsReq: undefined,
    selectedImagePixelsResp: undefined,
    selectedImagePixelsWriteReq: undefined,
    selectedImagePixelsWriteResp: undefined,
    selectedScanEntriesReq: undefined,
    selectedScanEntriesResp: undefined,
    selectedScanEntriesWriteReq: undefined,
    selectedScanEntriesWriteResp: undefined,
    sendUserNotificationReq: undefined,
    sendUserNotificationResp: undefined,
    spectrumReq: undefined,
    spectrumResp: undefined,
    tagCreateReq: undefined,
    tagCreateResp: undefined,
    tagDeleteReq: undefined,
    tagDeleteResp: undefined,
    tagListReq: undefined,
    tagListResp: undefined,
    userAddRoleReq: undefined,
    userAddRoleResp: undefined,
    userDeleteRoleReq: undefined,
    userDeleteRoleResp: undefined,
    userDetailsReq: undefined,
    userDetailsResp: undefined,
    userDetailsWriteReq: undefined,
    userDetailsWriteResp: undefined,
    userGroupAddAdminReq: undefined,
    userGroupAddAdminResp: undefined,
    userGroupAddMemberReq: undefined,
    userGroupAddMemberResp: undefined,
    userGroupAddViewerReq: undefined,
    userGroupAddViewerResp: undefined,
    userGroupCreateReq: undefined,
    userGroupCreateResp: undefined,
    userGroupDeleteAdminReq: undefined,
    userGroupDeleteAdminResp: undefined,
    userGroupDeleteMemberReq: undefined,
    userGroupDeleteMemberResp: undefined,
    userGroupDeleteReq: undefined,
    userGroupDeleteResp: undefined,
    userGroupDeleteViewerReq: undefined,
    userGroupDeleteViewerResp: undefined,
    userGroupEditDetailsReq: undefined,
    userGroupEditDetailsResp: undefined,
    userGroupIgnoreJoinReq: undefined,
    userGroupIgnoreJoinResp: undefined,
    userGroupJoinListReq: undefined,
    userGroupJoinListResp: undefined,
    userGroupJoinReq: undefined,
    userGroupJoinResp: undefined,
    userGroupListJoinableReq: undefined,
    userGroupListJoinableResp: undefined,
    userGroupListReq: undefined,
    userGroupListResp: undefined,
    userGroupReq: undefined,
    userGroupResp: undefined,
    userImpersonateGetReq: undefined,
    userImpersonateGetResp: undefined,
    userImpersonateReq: undefined,
    userImpersonateResp: undefined,
    userListReq: undefined,
    userListResp: undefined,
    userNotificationSettingsReq: undefined,
    userNotificationSettingsResp: undefined,
    userNotificationSettingsUpd: undefined,
    userNotificationSettingsWriteReq: undefined,
    userNotificationSettingsWriteResp: undefined,
    userRoleListReq: undefined,
    userRoleListResp: undefined,
    userRolesListReq: undefined,
    userRolesListResp: undefined,
    userSearchReq: undefined,
    userSearchResp: undefined,
    widgetDataGetReq: undefined,
    widgetDataGetResp: undefined,
    widgetDataWriteReq: undefined,
    widgetDataWriteResp: undefined,
    zenodoDOIGetReq: undefined,
    zenodoDOIGetResp: undefined,
  };
}

export const WSMessage = {
  encode(message: WSMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.msgId !== 0) {
      writer.uint32(8).uint32(message.msgId);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.errorText !== "") {
      writer.uint32(26).string(message.errorText);
    }
    if (message.backupDBReq !== undefined) {
      BackupDBReq.encode(message.backupDBReq, writer.uint32(2490).fork()).ldelim();
    }
    if (message.backupDBResp !== undefined) {
      BackupDBResp.encode(message.backupDBResp, writer.uint32(2498).fork()).ldelim();
    }
    if (message.backupDBUpd !== undefined) {
      BackupDBUpd.encode(message.backupDBUpd, writer.uint32(2506).fork()).ldelim();
    }
    if (message.dBAdminConfigGetReq !== undefined) {
      DBAdminConfigGetReq.encode(message.dBAdminConfigGetReq, writer.uint32(2514).fork()).ldelim();
    }
    if (message.dBAdminConfigGetResp !== undefined) {
      DBAdminConfigGetResp.encode(message.dBAdminConfigGetResp, writer.uint32(2522).fork()).ldelim();
    }
    if (message.dataModuleAddVersionReq !== undefined) {
      DataModuleAddVersionReq.encode(message.dataModuleAddVersionReq, writer.uint32(1586).fork()).ldelim();
    }
    if (message.dataModuleAddVersionResp !== undefined) {
      DataModuleAddVersionResp.encode(message.dataModuleAddVersionResp, writer.uint32(1594).fork()).ldelim();
    }
    if (message.dataModuleGetReq !== undefined) {
      DataModuleGetReq.encode(message.dataModuleGetReq, writer.uint32(98).fork()).ldelim();
    }
    if (message.dataModuleGetResp !== undefined) {
      DataModuleGetResp.encode(message.dataModuleGetResp, writer.uint32(106).fork()).ldelim();
    }
    if (message.dataModuleListReq !== undefined) {
      DataModuleListReq.encode(message.dataModuleListReq, writer.uint32(82).fork()).ldelim();
    }
    if (message.dataModuleListResp !== undefined) {
      DataModuleListResp.encode(message.dataModuleListResp, writer.uint32(90).fork()).ldelim();
    }
    if (message.dataModuleWriteReq !== undefined) {
      DataModuleWriteReq.encode(message.dataModuleWriteReq, writer.uint32(114).fork()).ldelim();
    }
    if (message.dataModuleWriteResp !== undefined) {
      DataModuleWriteResp.encode(message.dataModuleWriteResp, writer.uint32(122).fork()).ldelim();
    }
    if (message.detectedDiffractionPeaksReq !== undefined) {
      DetectedDiffractionPeaksReq.encode(message.detectedDiffractionPeaksReq, writer.uint32(1794).fork()).ldelim();
    }
    if (message.detectedDiffractionPeaksResp !== undefined) {
      DetectedDiffractionPeaksResp.encode(message.detectedDiffractionPeaksResp, writer.uint32(1802).fork()).ldelim();
    }
    if (message.detectorConfigListReq !== undefined) {
      DetectorConfigListReq.encode(message.detectorConfigListReq, writer.uint32(2066).fork()).ldelim();
    }
    if (message.detectorConfigListResp !== undefined) {
      DetectorConfigListResp.encode(message.detectorConfigListResp, writer.uint32(2074).fork()).ldelim();
    }
    if (message.detectorConfigReq !== undefined) {
      DetectorConfigReq.encode(message.detectorConfigReq, writer.uint32(130).fork()).ldelim();
    }
    if (message.detectorConfigResp !== undefined) {
      DetectorConfigResp.encode(message.detectorConfigResp, writer.uint32(138).fork()).ldelim();
    }
    if (message.diffractionPeakManualDeleteReq !== undefined) {
      DiffractionPeakManualDeleteReq.encode(message.diffractionPeakManualDeleteReq, writer.uint32(146).fork()).ldelim();
    }
    if (message.diffractionPeakManualDeleteResp !== undefined) {
      DiffractionPeakManualDeleteResp.encode(message.diffractionPeakManualDeleteResp, writer.uint32(154).fork())
        .ldelim();
    }
    if (message.diffractionPeakManualInsertReq !== undefined) {
      DiffractionPeakManualInsertReq.encode(message.diffractionPeakManualInsertReq, writer.uint32(178).fork()).ldelim();
    }
    if (message.diffractionPeakManualInsertResp !== undefined) {
      DiffractionPeakManualInsertResp.encode(message.diffractionPeakManualInsertResp, writer.uint32(186).fork())
        .ldelim();
    }
    if (message.diffractionPeakManualListReq !== undefined) {
      DiffractionPeakManualListReq.encode(message.diffractionPeakManualListReq, writer.uint32(162).fork()).ldelim();
    }
    if (message.diffractionPeakManualListResp !== undefined) {
      DiffractionPeakManualListResp.encode(message.diffractionPeakManualListResp, writer.uint32(170).fork()).ldelim();
    }
    if (message.diffractionPeakStatusDeleteReq !== undefined) {
      DiffractionPeakStatusDeleteReq.encode(message.diffractionPeakStatusDeleteReq, writer.uint32(194).fork()).ldelim();
    }
    if (message.diffractionPeakStatusDeleteResp !== undefined) {
      DiffractionPeakStatusDeleteResp.encode(message.diffractionPeakStatusDeleteResp, writer.uint32(202).fork())
        .ldelim();
    }
    if (message.diffractionPeakStatusListReq !== undefined) {
      DiffractionPeakStatusListReq.encode(message.diffractionPeakStatusListReq, writer.uint32(210).fork()).ldelim();
    }
    if (message.diffractionPeakStatusListResp !== undefined) {
      DiffractionPeakStatusListResp.encode(message.diffractionPeakStatusListResp, writer.uint32(218).fork()).ldelim();
    }
    if (message.diffractionPeakStatusWriteReq !== undefined) {
      DiffractionPeakStatusWriteReq.encode(message.diffractionPeakStatusWriteReq, writer.uint32(226).fork()).ldelim();
    }
    if (message.diffractionPeakStatusWriteResp !== undefined) {
      DiffractionPeakStatusWriteResp.encode(message.diffractionPeakStatusWriteResp, writer.uint32(234).fork()).ldelim();
    }
    if (message.elementSetDeleteReq !== undefined) {
      ElementSetDeleteReq.encode(message.elementSetDeleteReq, writer.uint32(242).fork()).ldelim();
    }
    if (message.elementSetDeleteResp !== undefined) {
      ElementSetDeleteResp.encode(message.elementSetDeleteResp, writer.uint32(250).fork()).ldelim();
    }
    if (message.elementSetGetReq !== undefined) {
      ElementSetGetReq.encode(message.elementSetGetReq, writer.uint32(258).fork()).ldelim();
    }
    if (message.elementSetGetResp !== undefined) {
      ElementSetGetResp.encode(message.elementSetGetResp, writer.uint32(266).fork()).ldelim();
    }
    if (message.elementSetListReq !== undefined) {
      ElementSetListReq.encode(message.elementSetListReq, writer.uint32(274).fork()).ldelim();
    }
    if (message.elementSetListResp !== undefined) {
      ElementSetListResp.encode(message.elementSetListResp, writer.uint32(282).fork()).ldelim();
    }
    if (message.elementSetWriteReq !== undefined) {
      ElementSetWriteReq.encode(message.elementSetWriteReq, writer.uint32(290).fork()).ldelim();
    }
    if (message.elementSetWriteResp !== undefined) {
      ElementSetWriteResp.encode(message.elementSetWriteResp, writer.uint32(298).fork()).ldelim();
    }
    if (message.exportFilesReq !== undefined) {
      ExportFilesReq.encode(message.exportFilesReq, writer.uint32(306).fork()).ldelim();
    }
    if (message.exportFilesResp !== undefined) {
      ExportFilesResp.encode(message.exportFilesResp, writer.uint32(314).fork()).ldelim();
    }
    if (message.expressionDeleteReq !== undefined) {
      ExpressionDeleteReq.encode(message.expressionDeleteReq, writer.uint32(322).fork()).ldelim();
    }
    if (message.expressionDeleteResp !== undefined) {
      ExpressionDeleteResp.encode(message.expressionDeleteResp, writer.uint32(330).fork()).ldelim();
    }
    if (message.expressionDisplaySettingsGetReq !== undefined) {
      ExpressionDisplaySettingsGetReq.encode(message.expressionDisplaySettingsGetReq, writer.uint32(2306).fork())
        .ldelim();
    }
    if (message.expressionDisplaySettingsGetResp !== undefined) {
      ExpressionDisplaySettingsGetResp.encode(message.expressionDisplaySettingsGetResp, writer.uint32(2314).fork())
        .ldelim();
    }
    if (message.expressionDisplaySettingsWriteReq !== undefined) {
      ExpressionDisplaySettingsWriteReq.encode(message.expressionDisplaySettingsWriteReq, writer.uint32(2322).fork())
        .ldelim();
    }
    if (message.expressionDisplaySettingsWriteResp !== undefined) {
      ExpressionDisplaySettingsWriteResp.encode(message.expressionDisplaySettingsWriteResp, writer.uint32(2330).fork())
        .ldelim();
    }
    if (message.expressionGetReq !== undefined) {
      ExpressionGetReq.encode(message.expressionGetReq, writer.uint32(402).fork()).ldelim();
    }
    if (message.expressionGetResp !== undefined) {
      ExpressionGetResp.encode(message.expressionGetResp, writer.uint32(410).fork()).ldelim();
    }
    if (message.expressionGroupDeleteReq !== undefined) {
      ExpressionGroupDeleteReq.encode(message.expressionGroupDeleteReq, writer.uint32(338).fork()).ldelim();
    }
    if (message.expressionGroupDeleteResp !== undefined) {
      ExpressionGroupDeleteResp.encode(message.expressionGroupDeleteResp, writer.uint32(346).fork()).ldelim();
    }
    if (message.expressionGroupGetReq !== undefined) {
      ExpressionGroupGetReq.encode(message.expressionGroupGetReq, writer.uint32(1410).fork()).ldelim();
    }
    if (message.expressionGroupGetResp !== undefined) {
      ExpressionGroupGetResp.encode(message.expressionGroupGetResp, writer.uint32(1418).fork()).ldelim();
    }
    if (message.expressionGroupListReq !== undefined) {
      ExpressionGroupListReq.encode(message.expressionGroupListReq, writer.uint32(354).fork()).ldelim();
    }
    if (message.expressionGroupListResp !== undefined) {
      ExpressionGroupListResp.encode(message.expressionGroupListResp, writer.uint32(362).fork()).ldelim();
    }
    if (message.expressionGroupWriteReq !== undefined) {
      ExpressionGroupWriteReq.encode(message.expressionGroupWriteReq, writer.uint32(370).fork()).ldelim();
    }
    if (message.expressionGroupWriteResp !== undefined) {
      ExpressionGroupWriteResp.encode(message.expressionGroupWriteResp, writer.uint32(378).fork()).ldelim();
    }
    if (message.expressionListReq !== undefined) {
      ExpressionListReq.encode(message.expressionListReq, writer.uint32(386).fork()).ldelim();
    }
    if (message.expressionListResp !== undefined) {
      ExpressionListResp.encode(message.expressionListResp, writer.uint32(394).fork()).ldelim();
    }
    if (message.expressionWriteExecStatReq !== undefined) {
      ExpressionWriteExecStatReq.encode(message.expressionWriteExecStatReq, writer.uint32(418).fork()).ldelim();
    }
    if (message.expressionWriteExecStatResp !== undefined) {
      ExpressionWriteExecStatResp.encode(message.expressionWriteExecStatResp, writer.uint32(426).fork()).ldelim();
    }
    if (message.expressionWriteReq !== undefined) {
      ExpressionWriteReq.encode(message.expressionWriteReq, writer.uint32(434).fork()).ldelim();
    }
    if (message.expressionWriteResp !== undefined) {
      ExpressionWriteResp.encode(message.expressionWriteResp, writer.uint32(442).fork()).ldelim();
    }
    if (message.getOwnershipReq !== undefined) {
      GetOwnershipReq.encode(message.getOwnershipReq, writer.uint32(1378).fork()).ldelim();
    }
    if (message.getOwnershipResp !== undefined) {
      GetOwnershipResp.encode(message.getOwnershipResp, writer.uint32(1386).fork()).ldelim();
    }
    if (message.imageBeamLocationVersionsReq !== undefined) {
      ImageBeamLocationVersionsReq.encode(message.imageBeamLocationVersionsReq, writer.uint32(2394).fork()).ldelim();
    }
    if (message.imageBeamLocationVersionsResp !== undefined) {
      ImageBeamLocationVersionsResp.encode(message.imageBeamLocationVersionsResp, writer.uint32(2402).fork()).ldelim();
    }
    if (message.imageBeamLocationsReq !== undefined) {
      ImageBeamLocationsReq.encode(message.imageBeamLocationsReq, writer.uint32(1522).fork()).ldelim();
    }
    if (message.imageBeamLocationsResp !== undefined) {
      ImageBeamLocationsResp.encode(message.imageBeamLocationsResp, writer.uint32(1530).fork()).ldelim();
    }
    if (message.imageDeleteReq !== undefined) {
      ImageDeleteReq.encode(message.imageDeleteReq, writer.uint32(466).fork()).ldelim();
    }
    if (message.imageDeleteResp !== undefined) {
      ImageDeleteResp.encode(message.imageDeleteResp, writer.uint32(474).fork()).ldelim();
    }
    if (message.imageGetDefaultReq !== undefined) {
      ImageGetDefaultReq.encode(message.imageGetDefaultReq, writer.uint32(2098).fork()).ldelim();
    }
    if (message.imageGetDefaultResp !== undefined) {
      ImageGetDefaultResp.encode(message.imageGetDefaultResp, writer.uint32(2106).fork()).ldelim();
    }
    if (message.imageGetReq !== undefined) {
      ImageGetReq.encode(message.imageGetReq, writer.uint32(2114).fork()).ldelim();
    }
    if (message.imageGetResp !== undefined) {
      ImageGetResp.encode(message.imageGetResp, writer.uint32(2122).fork()).ldelim();
    }
    if (message.imageListReq !== undefined) {
      ImageListReq.encode(message.imageListReq, writer.uint32(482).fork()).ldelim();
    }
    if (message.imageListResp !== undefined) {
      ImageListResp.encode(message.imageListResp, writer.uint32(490).fork()).ldelim();
    }
    if (message.imageListUpd !== undefined) {
      ImageListUpd.encode(message.imageListUpd, writer.uint32(2226).fork()).ldelim();
    }
    if (message.imageSetDefaultReq !== undefined) {
      ImageSetDefaultReq.encode(message.imageSetDefaultReq, writer.uint32(506).fork()).ldelim();
    }
    if (message.imageSetDefaultResp !== undefined) {
      ImageSetDefaultResp.encode(message.imageSetDefaultResp, writer.uint32(514).fork()).ldelim();
    }
    if (message.imageSetMatchTransformReq !== undefined) {
      ImageSetMatchTransformReq.encode(message.imageSetMatchTransformReq, writer.uint32(2130).fork()).ldelim();
    }
    if (message.imageSetMatchTransformResp !== undefined) {
      ImageSetMatchTransformResp.encode(message.imageSetMatchTransformResp, writer.uint32(2138).fork()).ldelim();
    }
    if (message.importMarsViewerImageReq !== undefined) {
      ImportMarsViewerImageReq.encode(message.importMarsViewerImageReq, writer.uint32(2186).fork()).ldelim();
    }
    if (message.importMarsViewerImageResp !== undefined) {
      ImportMarsViewerImageResp.encode(message.importMarsViewerImageResp, writer.uint32(2194).fork()).ldelim();
    }
    if (message.importMarsViewerImageUpd !== undefined) {
      ImportMarsViewerImageUpd.encode(message.importMarsViewerImageUpd, writer.uint32(2202).fork()).ldelim();
    }
    if (message.jobListReq !== undefined) {
      JobListReq.encode(message.jobListReq, writer.uint32(2370).fork()).ldelim();
    }
    if (message.jobListResp !== undefined) {
      JobListResp.encode(message.jobListResp, writer.uint32(2378).fork()).ldelim();
    }
    if (message.jobListUpd !== undefined) {
      JobListUpd.encode(message.jobListUpd, writer.uint32(2386).fork()).ldelim();
    }
    if (message.logGetLevelReq !== undefined) {
      LogGetLevelReq.encode(message.logGetLevelReq, writer.uint32(538).fork()).ldelim();
    }
    if (message.logGetLevelResp !== undefined) {
      LogGetLevelResp.encode(message.logGetLevelResp, writer.uint32(546).fork()).ldelim();
    }
    if (message.logReadReq !== undefined) {
      LogReadReq.encode(message.logReadReq, writer.uint32(554).fork()).ldelim();
    }
    if (message.logReadResp !== undefined) {
      LogReadResp.encode(message.logReadResp, writer.uint32(562).fork()).ldelim();
    }
    if (message.logSetLevelReq !== undefined) {
      LogSetLevelReq.encode(message.logSetLevelReq, writer.uint32(570).fork()).ldelim();
    }
    if (message.logSetLevelResp !== undefined) {
      LogSetLevelResp.encode(message.logSetLevelResp, writer.uint32(578).fork()).ldelim();
    }
    if (message.memoiseGetReq !== undefined) {
      MemoiseGetReq.encode(message.memoiseGetReq, writer.uint32(2266).fork()).ldelim();
    }
    if (message.memoiseGetResp !== undefined) {
      MemoiseGetResp.encode(message.memoiseGetResp, writer.uint32(2274).fork()).ldelim();
    }
    if (message.memoiseWriteReq !== undefined) {
      MemoiseWriteReq.encode(message.memoiseWriteReq, writer.uint32(450).fork()).ldelim();
    }
    if (message.memoiseWriteResp !== undefined) {
      MemoiseWriteResp.encode(message.memoiseWriteResp, writer.uint32(458).fork()).ldelim();
    }
    if (message.multiQuantCompareReq !== undefined) {
      MultiQuantCompareReq.encode(message.multiQuantCompareReq, writer.uint32(1602).fork()).ldelim();
    }
    if (message.multiQuantCompareResp !== undefined) {
      MultiQuantCompareResp.encode(message.multiQuantCompareResp, writer.uint32(1610).fork()).ldelim();
    }
    if (message.notificationDismissReq !== undefined) {
      NotificationDismissReq.encode(message.notificationDismissReq, writer.uint32(2290).fork()).ldelim();
    }
    if (message.notificationDismissResp !== undefined) {
      NotificationDismissResp.encode(message.notificationDismissResp, writer.uint32(2298).fork()).ldelim();
    }
    if (message.notificationReq !== undefined) {
      NotificationReq.encode(message.notificationReq, writer.uint32(1122).fork()).ldelim();
    }
    if (message.notificationResp !== undefined) {
      NotificationResp.encode(message.notificationResp, writer.uint32(1130).fork()).ldelim();
    }
    if (message.notificationUpd !== undefined) {
      NotificationUpd.encode(message.notificationUpd, writer.uint32(1178).fork()).ldelim();
    }
    if (message.objectEditAccessReq !== undefined) {
      ObjectEditAccessReq.encode(message.objectEditAccessReq, writer.uint32(1394).fork()).ldelim();
    }
    if (message.objectEditAccessResp !== undefined) {
      ObjectEditAccessResp.encode(message.objectEditAccessResp, writer.uint32(1402).fork()).ldelim();
    }
    if (message.piquantConfigListReq !== undefined) {
      PiquantConfigListReq.encode(message.piquantConfigListReq, writer.uint32(586).fork()).ldelim();
    }
    if (message.piquantConfigListResp !== undefined) {
      PiquantConfigListResp.encode(message.piquantConfigListResp, writer.uint32(594).fork()).ldelim();
    }
    if (message.piquantConfigVersionReq !== undefined) {
      PiquantConfigVersionReq.encode(message.piquantConfigVersionReq, writer.uint32(602).fork()).ldelim();
    }
    if (message.piquantConfigVersionResp !== undefined) {
      PiquantConfigVersionResp.encode(message.piquantConfigVersionResp, writer.uint32(610).fork()).ldelim();
    }
    if (message.piquantConfigVersionsListReq !== undefined) {
      PiquantConfigVersionsListReq.encode(message.piquantConfigVersionsListReq, writer.uint32(618).fork()).ldelim();
    }
    if (message.piquantConfigVersionsListResp !== undefined) {
      PiquantConfigVersionsListResp.encode(message.piquantConfigVersionsListResp, writer.uint32(626).fork()).ldelim();
    }
    if (message.piquantCurrentVersionReq !== undefined) {
      PiquantCurrentVersionReq.encode(message.piquantCurrentVersionReq, writer.uint32(634).fork()).ldelim();
    }
    if (message.piquantCurrentVersionResp !== undefined) {
      PiquantCurrentVersionResp.encode(message.piquantCurrentVersionResp, writer.uint32(642).fork()).ldelim();
    }
    if (message.piquantVersionListReq !== undefined) {
      PiquantVersionListReq.encode(message.piquantVersionListReq, writer.uint32(650).fork()).ldelim();
    }
    if (message.piquantVersionListResp !== undefined) {
      PiquantVersionListResp.encode(message.piquantVersionListResp, writer.uint32(658).fork()).ldelim();
    }
    if (message.piquantWriteCurrentVersionReq !== undefined) {
      PiquantWriteCurrentVersionReq.encode(message.piquantWriteCurrentVersionReq, writer.uint32(666).fork()).ldelim();
    }
    if (message.piquantWriteCurrentVersionResp !== undefined) {
      PiquantWriteCurrentVersionResp.encode(message.piquantWriteCurrentVersionResp, writer.uint32(674).fork()).ldelim();
    }
    if (message.pseudoIntensityReq !== undefined) {
      PseudoIntensityReq.encode(message.pseudoIntensityReq, writer.uint32(682).fork()).ldelim();
    }
    if (message.pseudoIntensityResp !== undefined) {
      PseudoIntensityResp.encode(message.pseudoIntensityResp, writer.uint32(690).fork()).ldelim();
    }
    if (message.publishExpressionToZenodoReq !== undefined) {
      PublishExpressionToZenodoReq.encode(message.publishExpressionToZenodoReq, writer.uint32(1938).fork()).ldelim();
    }
    if (message.publishExpressionToZenodoResp !== undefined) {
      PublishExpressionToZenodoResp.encode(message.publishExpressionToZenodoResp, writer.uint32(1946).fork()).ldelim();
    }
    if (message.quantBlessReq !== undefined) {
      QuantBlessReq.encode(message.quantBlessReq, writer.uint32(1618).fork()).ldelim();
    }
    if (message.quantBlessResp !== undefined) {
      QuantBlessResp.encode(message.quantBlessResp, writer.uint32(1626).fork()).ldelim();
    }
    if (message.quantCombineListGetReq !== undefined) {
      QuantCombineListGetReq.encode(message.quantCombineListGetReq, writer.uint32(1634).fork()).ldelim();
    }
    if (message.quantCombineListGetResp !== undefined) {
      QuantCombineListGetResp.encode(message.quantCombineListGetResp, writer.uint32(1642).fork()).ldelim();
    }
    if (message.quantCombineListWriteReq !== undefined) {
      QuantCombineListWriteReq.encode(message.quantCombineListWriteReq, writer.uint32(1650).fork()).ldelim();
    }
    if (message.quantCombineListWriteResp !== undefined) {
      QuantCombineListWriteResp.encode(message.quantCombineListWriteResp, writer.uint32(1658).fork()).ldelim();
    }
    if (message.quantCombineReq !== undefined) {
      QuantCombineReq.encode(message.quantCombineReq, writer.uint32(1666).fork()).ldelim();
    }
    if (message.quantCombineResp !== undefined) {
      QuantCombineResp.encode(message.quantCombineResp, writer.uint32(1674).fork()).ldelim();
    }
    if (message.quantCreateReq !== undefined) {
      QuantCreateReq.encode(message.quantCreateReq, writer.uint32(2162).fork()).ldelim();
    }
    if (message.quantCreateResp !== undefined) {
      QuantCreateResp.encode(message.quantCreateResp, writer.uint32(2170).fork()).ldelim();
    }
    if (message.quantCreateUpd !== undefined) {
      QuantCreateUpd.encode(message.quantCreateUpd, writer.uint32(2178).fork()).ldelim();
    }
    if (message.quantDeleteReq !== undefined) {
      QuantDeleteReq.encode(message.quantDeleteReq, writer.uint32(1682).fork()).ldelim();
    }
    if (message.quantDeleteResp !== undefined) {
      QuantDeleteResp.encode(message.quantDeleteResp, writer.uint32(1690).fork()).ldelim();
    }
    if (message.quantGetReq !== undefined) {
      QuantGetReq.encode(message.quantGetReq, writer.uint32(1698).fork()).ldelim();
    }
    if (message.quantGetResp !== undefined) {
      QuantGetResp.encode(message.quantGetResp, writer.uint32(1706).fork()).ldelim();
    }
    if (message.quantLastOutputGetReq !== undefined) {
      QuantLastOutputGetReq.encode(message.quantLastOutputGetReq, writer.uint32(1714).fork()).ldelim();
    }
    if (message.quantLastOutputGetResp !== undefined) {
      QuantLastOutputGetResp.encode(message.quantLastOutputGetResp, writer.uint32(1722).fork()).ldelim();
    }
    if (message.quantListReq !== undefined) {
      QuantListReq.encode(message.quantListReq, writer.uint32(1730).fork()).ldelim();
    }
    if (message.quantListResp !== undefined) {
      QuantListResp.encode(message.quantListResp, writer.uint32(1738).fork()).ldelim();
    }
    if (message.quantLogGetReq !== undefined) {
      QuantLogGetReq.encode(message.quantLogGetReq, writer.uint32(2410).fork()).ldelim();
    }
    if (message.quantLogGetResp !== undefined) {
      QuantLogGetResp.encode(message.quantLogGetResp, writer.uint32(2418).fork()).ldelim();
    }
    if (message.quantLogListReq !== undefined) {
      QuantLogListReq.encode(message.quantLogListReq, writer.uint32(2426).fork()).ldelim();
    }
    if (message.quantLogListResp !== undefined) {
      QuantLogListResp.encode(message.quantLogListResp, writer.uint32(2434).fork()).ldelim();
    }
    if (message.quantPublishReq !== undefined) {
      QuantPublishReq.encode(message.quantPublishReq, writer.uint32(1746).fork()).ldelim();
    }
    if (message.quantPublishResp !== undefined) {
      QuantPublishResp.encode(message.quantPublishResp, writer.uint32(1754).fork()).ldelim();
    }
    if (message.quantRawDataGetReq !== undefined) {
      QuantRawDataGetReq.encode(message.quantRawDataGetReq, writer.uint32(2442).fork()).ldelim();
    }
    if (message.quantRawDataGetResp !== undefined) {
      QuantRawDataGetResp.encode(message.quantRawDataGetResp, writer.uint32(2450).fork()).ldelim();
    }
    if (message.quantUploadReq !== undefined) {
      QuantUploadReq.encode(message.quantUploadReq, writer.uint32(1762).fork()).ldelim();
    }
    if (message.quantUploadResp !== undefined) {
      QuantUploadResp.encode(message.quantUploadResp, writer.uint32(1770).fork()).ldelim();
    }
    if (message.regionOfInterestBulkDuplicateReq !== undefined) {
      RegionOfInterestBulkDuplicateReq.encode(message.regionOfInterestBulkDuplicateReq, writer.uint32(1906).fork())
        .ldelim();
    }
    if (message.regionOfInterestBulkDuplicateResp !== undefined) {
      RegionOfInterestBulkDuplicateResp.encode(message.regionOfInterestBulkDuplicateResp, writer.uint32(1914).fork())
        .ldelim();
    }
    if (message.regionOfInterestBulkWriteReq !== undefined) {
      RegionOfInterestBulkWriteReq.encode(message.regionOfInterestBulkWriteReq, writer.uint32(1890).fork()).ldelim();
    }
    if (message.regionOfInterestBulkWriteResp !== undefined) {
      RegionOfInterestBulkWriteResp.encode(message.regionOfInterestBulkWriteResp, writer.uint32(1898).fork()).ldelim();
    }
    if (message.regionOfInterestDeleteReq !== undefined) {
      RegionOfInterestDeleteReq.encode(message.regionOfInterestDeleteReq, writer.uint32(698).fork()).ldelim();
    }
    if (message.regionOfInterestDeleteResp !== undefined) {
      RegionOfInterestDeleteResp.encode(message.regionOfInterestDeleteResp, writer.uint32(706).fork()).ldelim();
    }
    if (message.regionOfInterestDisplaySettingsGetReq !== undefined) {
      RegionOfInterestDisplaySettingsGetReq.encode(
        message.regionOfInterestDisplaySettingsGetReq,
        writer.uint32(1970).fork(),
      ).ldelim();
    }
    if (message.regionOfInterestDisplaySettingsGetResp !== undefined) {
      RegionOfInterestDisplaySettingsGetResp.encode(
        message.regionOfInterestDisplaySettingsGetResp,
        writer.uint32(1978).fork(),
      ).ldelim();
    }
    if (message.regionOfInterestDisplaySettingsWriteReq !== undefined) {
      RegionOfInterestDisplaySettingsWriteReq.encode(
        message.regionOfInterestDisplaySettingsWriteReq,
        writer.uint32(1954).fork(),
      ).ldelim();
    }
    if (message.regionOfInterestDisplaySettingsWriteResp !== undefined) {
      RegionOfInterestDisplaySettingsWriteResp.encode(
        message.regionOfInterestDisplaySettingsWriteResp,
        writer.uint32(1962).fork(),
      ).ldelim();
    }
    if (message.regionOfInterestGetReq !== undefined) {
      RegionOfInterestGetReq.encode(message.regionOfInterestGetReq, writer.uint32(730).fork()).ldelim();
    }
    if (message.regionOfInterestGetResp !== undefined) {
      RegionOfInterestGetResp.encode(message.regionOfInterestGetResp, writer.uint32(738).fork()).ldelim();
    }
    if (message.regionOfInterestListReq !== undefined) {
      RegionOfInterestListReq.encode(message.regionOfInterestListReq, writer.uint32(714).fork()).ldelim();
    }
    if (message.regionOfInterestListResp !== undefined) {
      RegionOfInterestListResp.encode(message.regionOfInterestListResp, writer.uint32(722).fork()).ldelim();
    }
    if (message.regionOfInterestWriteReq !== undefined) {
      RegionOfInterestWriteReq.encode(message.regionOfInterestWriteReq, writer.uint32(746).fork()).ldelim();
    }
    if (message.regionOfInterestWriteResp !== undefined) {
      RegionOfInterestWriteResp.encode(message.regionOfInterestWriteResp, writer.uint32(754).fork()).ldelim();
    }
    if (message.restoreDBReq !== undefined) {
      RestoreDBReq.encode(message.restoreDBReq, writer.uint32(2530).fork()).ldelim();
    }
    if (message.restoreDBResp !== undefined) {
      RestoreDBResp.encode(message.restoreDBResp, writer.uint32(2538).fork()).ldelim();
    }
    if (message.reviewerMagicLinkCreateReq !== undefined) {
      ReviewerMagicLinkCreateReq.encode(message.reviewerMagicLinkCreateReq, writer.uint32(2546).fork()).ldelim();
    }
    if (message.reviewerMagicLinkCreateResp !== undefined) {
      ReviewerMagicLinkCreateResp.encode(message.reviewerMagicLinkCreateResp, writer.uint32(2554).fork()).ldelim();
    }
    if (message.reviewerMagicLinkLoginReq !== undefined) {
      ReviewerMagicLinkLoginReq.encode(message.reviewerMagicLinkLoginReq, writer.uint32(2562).fork()).ldelim();
    }
    if (message.reviewerMagicLinkLoginResp !== undefined) {
      ReviewerMagicLinkLoginResp.encode(message.reviewerMagicLinkLoginResp, writer.uint32(2570).fork()).ldelim();
    }
    if (message.runTestReq !== undefined) {
      RunTestReq.encode(message.runTestReq, writer.uint32(762).fork()).ldelim();
    }
    if (message.runTestResp !== undefined) {
      RunTestResp.encode(message.runTestResp, writer.uint32(770).fork()).ldelim();
    }
    if (message.scanAutoShareReq !== undefined) {
      ScanAutoShareReq.encode(message.scanAutoShareReq, writer.uint32(2234).fork()).ldelim();
    }
    if (message.scanAutoShareResp !== undefined) {
      ScanAutoShareResp.encode(message.scanAutoShareResp, writer.uint32(2242).fork()).ldelim();
    }
    if (message.scanAutoShareWriteReq !== undefined) {
      ScanAutoShareWriteReq.encode(message.scanAutoShareWriteReq, writer.uint32(2250).fork()).ldelim();
    }
    if (message.scanAutoShareWriteResp !== undefined) {
      ScanAutoShareWriteResp.encode(message.scanAutoShareWriteResp, writer.uint32(2258).fork()).ldelim();
    }
    if (message.scanBeamLocationsReq !== undefined) {
      ScanBeamLocationsReq.encode(message.scanBeamLocationsReq, writer.uint32(1538).fork()).ldelim();
    }
    if (message.scanBeamLocationsResp !== undefined) {
      ScanBeamLocationsResp.encode(message.scanBeamLocationsResp, writer.uint32(1546).fork()).ldelim();
    }
    if (message.scanDeleteReq !== undefined) {
      ScanDeleteReq.encode(message.scanDeleteReq, writer.uint32(2210).fork()).ldelim();
    }
    if (message.scanDeleteResp !== undefined) {
      ScanDeleteResp.encode(message.scanDeleteResp, writer.uint32(2218).fork()).ldelim();
    }
    if (message.scanEntryMetadataReq !== undefined) {
      ScanEntryMetadataReq.encode(message.scanEntryMetadataReq, writer.uint32(1554).fork()).ldelim();
    }
    if (message.scanEntryMetadataResp !== undefined) {
      ScanEntryMetadataResp.encode(message.scanEntryMetadataResp, writer.uint32(1562).fork()).ldelim();
    }
    if (message.scanEntryReq !== undefined) {
      ScanEntryReq.encode(message.scanEntryReq, writer.uint32(1570).fork()).ldelim();
    }
    if (message.scanEntryResp !== undefined) {
      ScanEntryResp.encode(message.scanEntryResp, writer.uint32(1578).fork()).ldelim();
    }
    if (message.scanGetReq !== undefined) {
      ScanGetReq.encode(message.scanGetReq, writer.uint32(2338).fork()).ldelim();
    }
    if (message.scanGetResp !== undefined) {
      ScanGetResp.encode(message.scanGetResp, writer.uint32(2346).fork()).ldelim();
    }
    if (message.scanListReq !== undefined) {
      ScanListReq.encode(message.scanListReq, writer.uint32(794).fork()).ldelim();
    }
    if (message.scanListResp !== undefined) {
      ScanListResp.encode(message.scanListResp, writer.uint32(802).fork()).ldelim();
    }
    if (message.scanListUpd !== undefined) {
      ScanListUpd.encode(message.scanListUpd, writer.uint32(810).fork()).ldelim();
    }
    if (message.scanMetaLabelsAndTypesReq !== undefined) {
      ScanMetaLabelsAndTypesReq.encode(message.scanMetaLabelsAndTypesReq, writer.uint32(834).fork()).ldelim();
    }
    if (message.scanMetaLabelsAndTypesResp !== undefined) {
      ScanMetaLabelsAndTypesResp.encode(message.scanMetaLabelsAndTypesResp, writer.uint32(842).fork()).ldelim();
    }
    if (message.scanMetaWriteReq !== undefined) {
      ScanMetaWriteReq.encode(message.scanMetaWriteReq, writer.uint32(850).fork()).ldelim();
    }
    if (message.scanMetaWriteResp !== undefined) {
      ScanMetaWriteResp.encode(message.scanMetaWriteResp, writer.uint32(858).fork()).ldelim();
    }
    if (message.scanTriggerAutoQuantReq !== undefined) {
      ScanTriggerAutoQuantReq.encode(message.scanTriggerAutoQuantReq, writer.uint32(2354).fork()).ldelim();
    }
    if (message.scanTriggerAutoQuantResp !== undefined) {
      ScanTriggerAutoQuantResp.encode(message.scanTriggerAutoQuantResp, writer.uint32(2362).fork()).ldelim();
    }
    if (message.scanTriggerReImportReq !== undefined) {
      ScanTriggerReImportReq.encode(message.scanTriggerReImportReq, writer.uint32(866).fork()).ldelim();
    }
    if (message.scanTriggerReImportResp !== undefined) {
      ScanTriggerReImportResp.encode(message.scanTriggerReImportResp, writer.uint32(874).fork()).ldelim();
    }
    if (message.scanTriggerReImportUpd !== undefined) {
      ScanTriggerReImportUpd.encode(message.scanTriggerReImportUpd, writer.uint32(2154).fork()).ldelim();
    }
    if (message.scanUploadReq !== undefined) {
      ScanUploadReq.encode(message.scanUploadReq, writer.uint32(882).fork()).ldelim();
    }
    if (message.scanUploadResp !== undefined) {
      ScanUploadResp.encode(message.scanUploadResp, writer.uint32(890).fork()).ldelim();
    }
    if (message.scanUploadUpd !== undefined) {
      ScanUploadUpd.encode(message.scanUploadUpd, writer.uint32(2146).fork()).ldelim();
    }
    if (message.screenConfigurationDeleteReq !== undefined) {
      ScreenConfigurationDeleteReq.encode(message.screenConfigurationDeleteReq, writer.uint32(2082).fork()).ldelim();
    }
    if (message.screenConfigurationDeleteResp !== undefined) {
      ScreenConfigurationDeleteResp.encode(message.screenConfigurationDeleteResp, writer.uint32(2090).fork()).ldelim();
    }
    if (message.screenConfigurationGetReq !== undefined) {
      ScreenConfigurationGetReq.encode(message.screenConfigurationGetReq, writer.uint32(1986).fork()).ldelim();
    }
    if (message.screenConfigurationGetResp !== undefined) {
      ScreenConfigurationGetResp.encode(message.screenConfigurationGetResp, writer.uint32(1994).fork()).ldelim();
    }
    if (message.screenConfigurationListReq !== undefined) {
      ScreenConfigurationListReq.encode(message.screenConfigurationListReq, writer.uint32(2002).fork()).ldelim();
    }
    if (message.screenConfigurationListResp !== undefined) {
      ScreenConfigurationListResp.encode(message.screenConfigurationListResp, writer.uint32(2010).fork()).ldelim();
    }
    if (message.screenConfigurationWriteReq !== undefined) {
      ScreenConfigurationWriteReq.encode(message.screenConfigurationWriteReq, writer.uint32(2018).fork()).ldelim();
    }
    if (message.screenConfigurationWriteResp !== undefined) {
      ScreenConfigurationWriteResp.encode(message.screenConfigurationWriteResp, writer.uint32(2026).fork()).ldelim();
    }
    if (message.selectedImagePixelsReq !== undefined) {
      SelectedImagePixelsReq.encode(message.selectedImagePixelsReq, writer.uint32(1810).fork()).ldelim();
    }
    if (message.selectedImagePixelsResp !== undefined) {
      SelectedImagePixelsResp.encode(message.selectedImagePixelsResp, writer.uint32(1818).fork()).ldelim();
    }
    if (message.selectedImagePixelsWriteReq !== undefined) {
      SelectedImagePixelsWriteReq.encode(message.selectedImagePixelsWriteReq, writer.uint32(1826).fork()).ldelim();
    }
    if (message.selectedImagePixelsWriteResp !== undefined) {
      SelectedImagePixelsWriteResp.encode(message.selectedImagePixelsWriteResp, writer.uint32(1834).fork()).ldelim();
    }
    if (message.selectedScanEntriesReq !== undefined) {
      SelectedScanEntriesReq.encode(message.selectedScanEntriesReq, writer.uint32(1842).fork()).ldelim();
    }
    if (message.selectedScanEntriesResp !== undefined) {
      SelectedScanEntriesResp.encode(message.selectedScanEntriesResp, writer.uint32(1850).fork()).ldelim();
    }
    if (message.selectedScanEntriesWriteReq !== undefined) {
      SelectedScanEntriesWriteReq.encode(message.selectedScanEntriesWriteReq, writer.uint32(1858).fork()).ldelim();
    }
    if (message.selectedScanEntriesWriteResp !== undefined) {
      SelectedScanEntriesWriteResp.encode(message.selectedScanEntriesWriteResp, writer.uint32(1866).fork()).ldelim();
    }
    if (message.sendUserNotificationReq !== undefined) {
      SendUserNotificationReq.encode(message.sendUserNotificationReq, writer.uint32(898).fork()).ldelim();
    }
    if (message.sendUserNotificationResp !== undefined) {
      SendUserNotificationResp.encode(message.sendUserNotificationResp, writer.uint32(906).fork()).ldelim();
    }
    if (message.spectrumReq !== undefined) {
      SpectrumReq.encode(message.spectrumReq, writer.uint32(914).fork()).ldelim();
    }
    if (message.spectrumResp !== undefined) {
      SpectrumResp.encode(message.spectrumResp, writer.uint32(922).fork()).ldelim();
    }
    if (message.tagCreateReq !== undefined) {
      TagCreateReq.encode(message.tagCreateReq, writer.uint32(930).fork()).ldelim();
    }
    if (message.tagCreateResp !== undefined) {
      TagCreateResp.encode(message.tagCreateResp, writer.uint32(938).fork()).ldelim();
    }
    if (message.tagDeleteReq !== undefined) {
      TagDeleteReq.encode(message.tagDeleteReq, writer.uint32(946).fork()).ldelim();
    }
    if (message.tagDeleteResp !== undefined) {
      TagDeleteResp.encode(message.tagDeleteResp, writer.uint32(954).fork()).ldelim();
    }
    if (message.tagListReq !== undefined) {
      TagListReq.encode(message.tagListReq, writer.uint32(962).fork()).ldelim();
    }
    if (message.tagListResp !== undefined) {
      TagListResp.encode(message.tagListResp, writer.uint32(970).fork()).ldelim();
    }
    if (message.userAddRoleReq !== undefined) {
      UserAddRoleReq.encode(message.userAddRoleReq, writer.uint32(978).fork()).ldelim();
    }
    if (message.userAddRoleResp !== undefined) {
      UserAddRoleResp.encode(message.userAddRoleResp, writer.uint32(986).fork()).ldelim();
    }
    if (message.userDeleteRoleReq !== undefined) {
      UserDeleteRoleReq.encode(message.userDeleteRoleReq, writer.uint32(994).fork()).ldelim();
    }
    if (message.userDeleteRoleResp !== undefined) {
      UserDeleteRoleResp.encode(message.userDeleteRoleResp, writer.uint32(1002).fork()).ldelim();
    }
    if (message.userDetailsReq !== undefined) {
      UserDetailsReq.encode(message.userDetailsReq, writer.uint32(1010).fork()).ldelim();
    }
    if (message.userDetailsResp !== undefined) {
      UserDetailsResp.encode(message.userDetailsResp, writer.uint32(1018).fork()).ldelim();
    }
    if (message.userDetailsWriteReq !== undefined) {
      UserDetailsWriteReq.encode(message.userDetailsWriteReq, writer.uint32(1034).fork()).ldelim();
    }
    if (message.userDetailsWriteResp !== undefined) {
      UserDetailsWriteResp.encode(message.userDetailsWriteResp, writer.uint32(1042).fork()).ldelim();
    }
    if (message.userGroupAddAdminReq !== undefined) {
      UserGroupAddAdminReq.encode(message.userGroupAddAdminReq, writer.uint32(1250).fork()).ldelim();
    }
    if (message.userGroupAddAdminResp !== undefined) {
      UserGroupAddAdminResp.encode(message.userGroupAddAdminResp, writer.uint32(1258).fork()).ldelim();
    }
    if (message.userGroupAddMemberReq !== undefined) {
      UserGroupAddMemberReq.encode(message.userGroupAddMemberReq, writer.uint32(1266).fork()).ldelim();
    }
    if (message.userGroupAddMemberResp !== undefined) {
      UserGroupAddMemberResp.encode(message.userGroupAddMemberResp, writer.uint32(1274).fork()).ldelim();
    }
    if (message.userGroupAddViewerReq !== undefined) {
      UserGroupAddViewerReq.encode(message.userGroupAddViewerReq, writer.uint32(1442).fork()).ldelim();
    }
    if (message.userGroupAddViewerResp !== undefined) {
      UserGroupAddViewerResp.encode(message.userGroupAddViewerResp, writer.uint32(1450).fork()).ldelim();
    }
    if (message.userGroupCreateReq !== undefined) {
      UserGroupCreateReq.encode(message.userGroupCreateReq, writer.uint32(1282).fork()).ldelim();
    }
    if (message.userGroupCreateResp !== undefined) {
      UserGroupCreateResp.encode(message.userGroupCreateResp, writer.uint32(1290).fork()).ldelim();
    }
    if (message.userGroupDeleteAdminReq !== undefined) {
      UserGroupDeleteAdminReq.encode(message.userGroupDeleteAdminReq, writer.uint32(1298).fork()).ldelim();
    }
    if (message.userGroupDeleteAdminResp !== undefined) {
      UserGroupDeleteAdminResp.encode(message.userGroupDeleteAdminResp, writer.uint32(1306).fork()).ldelim();
    }
    if (message.userGroupDeleteMemberReq !== undefined) {
      UserGroupDeleteMemberReq.encode(message.userGroupDeleteMemberReq, writer.uint32(1314).fork()).ldelim();
    }
    if (message.userGroupDeleteMemberResp !== undefined) {
      UserGroupDeleteMemberResp.encode(message.userGroupDeleteMemberResp, writer.uint32(1322).fork()).ldelim();
    }
    if (message.userGroupDeleteReq !== undefined) {
      UserGroupDeleteReq.encode(message.userGroupDeleteReq, writer.uint32(1330).fork()).ldelim();
    }
    if (message.userGroupDeleteResp !== undefined) {
      UserGroupDeleteResp.encode(message.userGroupDeleteResp, writer.uint32(1338).fork()).ldelim();
    }
    if (message.userGroupDeleteViewerReq !== undefined) {
      UserGroupDeleteViewerReq.encode(message.userGroupDeleteViewerReq, writer.uint32(1426).fork()).ldelim();
    }
    if (message.userGroupDeleteViewerResp !== undefined) {
      UserGroupDeleteViewerResp.encode(message.userGroupDeleteViewerResp, writer.uint32(1434).fork()).ldelim();
    }
    if (message.userGroupEditDetailsReq !== undefined) {
      UserGroupEditDetailsReq.encode(message.userGroupEditDetailsReq, writer.uint32(1362).fork()).ldelim();
    }
    if (message.userGroupEditDetailsResp !== undefined) {
      UserGroupEditDetailsResp.encode(message.userGroupEditDetailsResp, writer.uint32(1370).fork()).ldelim();
    }
    if (message.userGroupIgnoreJoinReq !== undefined) {
      UserGroupIgnoreJoinReq.encode(message.userGroupIgnoreJoinReq, writer.uint32(1458).fork()).ldelim();
    }
    if (message.userGroupIgnoreJoinResp !== undefined) {
      UserGroupIgnoreJoinResp.encode(message.userGroupIgnoreJoinResp, writer.uint32(1466).fork()).ldelim();
    }
    if (message.userGroupJoinListReq !== undefined) {
      UserGroupJoinListReq.encode(message.userGroupJoinListReq, writer.uint32(1474).fork()).ldelim();
    }
    if (message.userGroupJoinListResp !== undefined) {
      UserGroupJoinListResp.encode(message.userGroupJoinListResp, writer.uint32(1482).fork()).ldelim();
    }
    if (message.userGroupJoinReq !== undefined) {
      UserGroupJoinReq.encode(message.userGroupJoinReq, writer.uint32(1490).fork()).ldelim();
    }
    if (message.userGroupJoinResp !== undefined) {
      UserGroupJoinResp.encode(message.userGroupJoinResp, writer.uint32(1498).fork()).ldelim();
    }
    if (message.userGroupListJoinableReq !== undefined) {
      UserGroupListJoinableReq.encode(message.userGroupListJoinableReq, writer.uint32(1874).fork()).ldelim();
    }
    if (message.userGroupListJoinableResp !== undefined) {
      UserGroupListJoinableResp.encode(message.userGroupListJoinableResp, writer.uint32(1882).fork()).ldelim();
    }
    if (message.userGroupListReq !== undefined) {
      UserGroupListReq.encode(message.userGroupListReq, writer.uint32(1346).fork()).ldelim();
    }
    if (message.userGroupListResp !== undefined) {
      UserGroupListResp.encode(message.userGroupListResp, writer.uint32(1354).fork()).ldelim();
    }
    if (message.userGroupReq !== undefined) {
      UserGroupReq.encode(message.userGroupReq, writer.uint32(1506).fork()).ldelim();
    }
    if (message.userGroupResp !== undefined) {
      UserGroupResp.encode(message.userGroupResp, writer.uint32(1514).fork()).ldelim();
    }
    if (message.userImpersonateGetReq !== undefined) {
      UserImpersonateGetReq.encode(message.userImpersonateGetReq, writer.uint32(2474).fork()).ldelim();
    }
    if (message.userImpersonateGetResp !== undefined) {
      UserImpersonateGetResp.encode(message.userImpersonateGetResp, writer.uint32(2482).fork()).ldelim();
    }
    if (message.userImpersonateReq !== undefined) {
      UserImpersonateReq.encode(message.userImpersonateReq, writer.uint32(2458).fork()).ldelim();
    }
    if (message.userImpersonateResp !== undefined) {
      UserImpersonateResp.encode(message.userImpersonateResp, writer.uint32(2466).fork()).ldelim();
    }
    if (message.userListReq !== undefined) {
      UserListReq.encode(message.userListReq, writer.uint32(1106).fork()).ldelim();
    }
    if (message.userListResp !== undefined) {
      UserListResp.encode(message.userListResp, writer.uint32(1114).fork()).ldelim();
    }
    if (message.userNotificationSettingsReq !== undefined) {
      UserNotificationSettingsReq.encode(message.userNotificationSettingsReq, writer.uint32(1138).fork()).ldelim();
    }
    if (message.userNotificationSettingsResp !== undefined) {
      UserNotificationSettingsResp.encode(message.userNotificationSettingsResp, writer.uint32(1146).fork()).ldelim();
    }
    if (message.userNotificationSettingsUpd !== undefined) {
      UserNotificationSettingsUpd.encode(message.userNotificationSettingsUpd, writer.uint32(1154).fork()).ldelim();
    }
    if (message.userNotificationSettingsWriteReq !== undefined) {
      UserNotificationSettingsWriteReq.encode(message.userNotificationSettingsWriteReq, writer.uint32(1162).fork())
        .ldelim();
    }
    if (message.userNotificationSettingsWriteResp !== undefined) {
      UserNotificationSettingsWriteResp.encode(message.userNotificationSettingsWriteResp, writer.uint32(1170).fork())
        .ldelim();
    }
    if (message.userRoleListReq !== undefined) {
      UserRoleListReq.encode(message.userRoleListReq, writer.uint32(1186).fork()).ldelim();
    }
    if (message.userRoleListResp !== undefined) {
      UserRoleListResp.encode(message.userRoleListResp, writer.uint32(1194).fork()).ldelim();
    }
    if (message.userRolesListReq !== undefined) {
      UserRolesListReq.encode(message.userRolesListReq, writer.uint32(1202).fork()).ldelim();
    }
    if (message.userRolesListResp !== undefined) {
      UserRolesListResp.encode(message.userRolesListResp, writer.uint32(1210).fork()).ldelim();
    }
    if (message.userSearchReq !== undefined) {
      UserSearchReq.encode(message.userSearchReq, writer.uint32(1778).fork()).ldelim();
    }
    if (message.userSearchResp !== undefined) {
      UserSearchResp.encode(message.userSearchResp, writer.uint32(1786).fork()).ldelim();
    }
    if (message.widgetDataGetReq !== undefined) {
      WidgetDataGetReq.encode(message.widgetDataGetReq, writer.uint32(2034).fork()).ldelim();
    }
    if (message.widgetDataGetResp !== undefined) {
      WidgetDataGetResp.encode(message.widgetDataGetResp, writer.uint32(2042).fork()).ldelim();
    }
    if (message.widgetDataWriteReq !== undefined) {
      WidgetDataWriteReq.encode(message.widgetDataWriteReq, writer.uint32(2050).fork()).ldelim();
    }
    if (message.widgetDataWriteResp !== undefined) {
      WidgetDataWriteResp.encode(message.widgetDataWriteResp, writer.uint32(2058).fork()).ldelim();
    }
    if (message.zenodoDOIGetReq !== undefined) {
      ZenodoDOIGetReq.encode(message.zenodoDOIGetReq, writer.uint32(1922).fork()).ldelim();
    }
    if (message.zenodoDOIGetResp !== undefined) {
      ZenodoDOIGetResp.encode(message.zenodoDOIGetResp, writer.uint32(1930).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WSMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWSMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.msgId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorText = reader.string();
          continue;
        case 311:
          if (tag !== 2490) {
            break;
          }

          message.backupDBReq = BackupDBReq.decode(reader, reader.uint32());
          continue;
        case 312:
          if (tag !== 2498) {
            break;
          }

          message.backupDBResp = BackupDBResp.decode(reader, reader.uint32());
          continue;
        case 313:
          if (tag !== 2506) {
            break;
          }

          message.backupDBUpd = BackupDBUpd.decode(reader, reader.uint32());
          continue;
        case 314:
          if (tag !== 2514) {
            break;
          }

          message.dBAdminConfigGetReq = DBAdminConfigGetReq.decode(reader, reader.uint32());
          continue;
        case 315:
          if (tag !== 2522) {
            break;
          }

          message.dBAdminConfigGetResp = DBAdminConfigGetResp.decode(reader, reader.uint32());
          continue;
        case 198:
          if (tag !== 1586) {
            break;
          }

          message.dataModuleAddVersionReq = DataModuleAddVersionReq.decode(reader, reader.uint32());
          continue;
        case 199:
          if (tag !== 1594) {
            break;
          }

          message.dataModuleAddVersionResp = DataModuleAddVersionResp.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.dataModuleGetReq = DataModuleGetReq.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.dataModuleGetResp = DataModuleGetResp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.dataModuleListReq = DataModuleListReq.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.dataModuleListResp = DataModuleListResp.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.dataModuleWriteReq = DataModuleWriteReq.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.dataModuleWriteResp = DataModuleWriteResp.decode(reader, reader.uint32());
          continue;
        case 224:
          if (tag !== 1794) {
            break;
          }

          message.detectedDiffractionPeaksReq = DetectedDiffractionPeaksReq.decode(reader, reader.uint32());
          continue;
        case 225:
          if (tag !== 1802) {
            break;
          }

          message.detectedDiffractionPeaksResp = DetectedDiffractionPeaksResp.decode(reader, reader.uint32());
          continue;
        case 258:
          if (tag !== 2066) {
            break;
          }

          message.detectorConfigListReq = DetectorConfigListReq.decode(reader, reader.uint32());
          continue;
        case 259:
          if (tag !== 2074) {
            break;
          }

          message.detectorConfigListResp = DetectorConfigListResp.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.detectorConfigReq = DetectorConfigReq.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.detectorConfigResp = DetectorConfigResp.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.diffractionPeakManualDeleteReq = DiffractionPeakManualDeleteReq.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.diffractionPeakManualDeleteResp = DiffractionPeakManualDeleteResp.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.diffractionPeakManualInsertReq = DiffractionPeakManualInsertReq.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.diffractionPeakManualInsertResp = DiffractionPeakManualInsertResp.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.diffractionPeakManualListReq = DiffractionPeakManualListReq.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.diffractionPeakManualListResp = DiffractionPeakManualListResp.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.diffractionPeakStatusDeleteReq = DiffractionPeakStatusDeleteReq.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.diffractionPeakStatusDeleteResp = DiffractionPeakStatusDeleteResp.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.diffractionPeakStatusListReq = DiffractionPeakStatusListReq.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.diffractionPeakStatusListResp = DiffractionPeakStatusListResp.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.diffractionPeakStatusWriteReq = DiffractionPeakStatusWriteReq.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.diffractionPeakStatusWriteResp = DiffractionPeakStatusWriteResp.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.elementSetDeleteReq = ElementSetDeleteReq.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.elementSetDeleteResp = ElementSetDeleteResp.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.elementSetGetReq = ElementSetGetReq.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.elementSetGetResp = ElementSetGetResp.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.elementSetListReq = ElementSetListReq.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.elementSetListResp = ElementSetListResp.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.elementSetWriteReq = ElementSetWriteReq.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.elementSetWriteResp = ElementSetWriteResp.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.exportFilesReq = ExportFilesReq.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.exportFilesResp = ExportFilesResp.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.expressionDeleteReq = ExpressionDeleteReq.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.expressionDeleteResp = ExpressionDeleteResp.decode(reader, reader.uint32());
          continue;
        case 288:
          if (tag !== 2306) {
            break;
          }

          message.expressionDisplaySettingsGetReq = ExpressionDisplaySettingsGetReq.decode(reader, reader.uint32());
          continue;
        case 289:
          if (tag !== 2314) {
            break;
          }

          message.expressionDisplaySettingsGetResp = ExpressionDisplaySettingsGetResp.decode(reader, reader.uint32());
          continue;
        case 290:
          if (tag !== 2322) {
            break;
          }

          message.expressionDisplaySettingsWriteReq = ExpressionDisplaySettingsWriteReq.decode(reader, reader.uint32());
          continue;
        case 291:
          if (tag !== 2330) {
            break;
          }

          message.expressionDisplaySettingsWriteResp = ExpressionDisplaySettingsWriteResp.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.expressionGetReq = ExpressionGetReq.decode(reader, reader.uint32());
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.expressionGetResp = ExpressionGetResp.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.expressionGroupDeleteReq = ExpressionGroupDeleteReq.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.expressionGroupDeleteResp = ExpressionGroupDeleteResp.decode(reader, reader.uint32());
          continue;
        case 176:
          if (tag !== 1410) {
            break;
          }

          message.expressionGroupGetReq = ExpressionGroupGetReq.decode(reader, reader.uint32());
          continue;
        case 177:
          if (tag !== 1418) {
            break;
          }

          message.expressionGroupGetResp = ExpressionGroupGetResp.decode(reader, reader.uint32());
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.expressionGroupListReq = ExpressionGroupListReq.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.expressionGroupListResp = ExpressionGroupListResp.decode(reader, reader.uint32());
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.expressionGroupWriteReq = ExpressionGroupWriteReq.decode(reader, reader.uint32());
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.expressionGroupWriteResp = ExpressionGroupWriteResp.decode(reader, reader.uint32());
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.expressionListReq = ExpressionListReq.decode(reader, reader.uint32());
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.expressionListResp = ExpressionListResp.decode(reader, reader.uint32());
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.expressionWriteExecStatReq = ExpressionWriteExecStatReq.decode(reader, reader.uint32());
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.expressionWriteExecStatResp = ExpressionWriteExecStatResp.decode(reader, reader.uint32());
          continue;
        case 54:
          if (tag !== 434) {
            break;
          }

          message.expressionWriteReq = ExpressionWriteReq.decode(reader, reader.uint32());
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.expressionWriteResp = ExpressionWriteResp.decode(reader, reader.uint32());
          continue;
        case 172:
          if (tag !== 1378) {
            break;
          }

          message.getOwnershipReq = GetOwnershipReq.decode(reader, reader.uint32());
          continue;
        case 173:
          if (tag !== 1386) {
            break;
          }

          message.getOwnershipResp = GetOwnershipResp.decode(reader, reader.uint32());
          continue;
        case 299:
          if (tag !== 2394) {
            break;
          }

          message.imageBeamLocationVersionsReq = ImageBeamLocationVersionsReq.decode(reader, reader.uint32());
          continue;
        case 300:
          if (tag !== 2402) {
            break;
          }

          message.imageBeamLocationVersionsResp = ImageBeamLocationVersionsResp.decode(reader, reader.uint32());
          continue;
        case 190:
          if (tag !== 1522) {
            break;
          }

          message.imageBeamLocationsReq = ImageBeamLocationsReq.decode(reader, reader.uint32());
          continue;
        case 191:
          if (tag !== 1530) {
            break;
          }

          message.imageBeamLocationsResp = ImageBeamLocationsResp.decode(reader, reader.uint32());
          continue;
        case 58:
          if (tag !== 466) {
            break;
          }

          message.imageDeleteReq = ImageDeleteReq.decode(reader, reader.uint32());
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.imageDeleteResp = ImageDeleteResp.decode(reader, reader.uint32());
          continue;
        case 262:
          if (tag !== 2098) {
            break;
          }

          message.imageGetDefaultReq = ImageGetDefaultReq.decode(reader, reader.uint32());
          continue;
        case 263:
          if (tag !== 2106) {
            break;
          }

          message.imageGetDefaultResp = ImageGetDefaultResp.decode(reader, reader.uint32());
          continue;
        case 264:
          if (tag !== 2114) {
            break;
          }

          message.imageGetReq = ImageGetReq.decode(reader, reader.uint32());
          continue;
        case 265:
          if (tag !== 2122) {
            break;
          }

          message.imageGetResp = ImageGetResp.decode(reader, reader.uint32());
          continue;
        case 60:
          if (tag !== 482) {
            break;
          }

          message.imageListReq = ImageListReq.decode(reader, reader.uint32());
          continue;
        case 61:
          if (tag !== 490) {
            break;
          }

          message.imageListResp = ImageListResp.decode(reader, reader.uint32());
          continue;
        case 278:
          if (tag !== 2226) {
            break;
          }

          message.imageListUpd = ImageListUpd.decode(reader, reader.uint32());
          continue;
        case 63:
          if (tag !== 506) {
            break;
          }

          message.imageSetDefaultReq = ImageSetDefaultReq.decode(reader, reader.uint32());
          continue;
        case 64:
          if (tag !== 514) {
            break;
          }

          message.imageSetDefaultResp = ImageSetDefaultResp.decode(reader, reader.uint32());
          continue;
        case 266:
          if (tag !== 2130) {
            break;
          }

          message.imageSetMatchTransformReq = ImageSetMatchTransformReq.decode(reader, reader.uint32());
          continue;
        case 267:
          if (tag !== 2138) {
            break;
          }

          message.imageSetMatchTransformResp = ImageSetMatchTransformResp.decode(reader, reader.uint32());
          continue;
        case 273:
          if (tag !== 2186) {
            break;
          }

          message.importMarsViewerImageReq = ImportMarsViewerImageReq.decode(reader, reader.uint32());
          continue;
        case 274:
          if (tag !== 2194) {
            break;
          }

          message.importMarsViewerImageResp = ImportMarsViewerImageResp.decode(reader, reader.uint32());
          continue;
        case 275:
          if (tag !== 2202) {
            break;
          }

          message.importMarsViewerImageUpd = ImportMarsViewerImageUpd.decode(reader, reader.uint32());
          continue;
        case 296:
          if (tag !== 2370) {
            break;
          }

          message.jobListReq = JobListReq.decode(reader, reader.uint32());
          continue;
        case 297:
          if (tag !== 2378) {
            break;
          }

          message.jobListResp = JobListResp.decode(reader, reader.uint32());
          continue;
        case 298:
          if (tag !== 2386) {
            break;
          }

          message.jobListUpd = JobListUpd.decode(reader, reader.uint32());
          continue;
        case 67:
          if (tag !== 538) {
            break;
          }

          message.logGetLevelReq = LogGetLevelReq.decode(reader, reader.uint32());
          continue;
        case 68:
          if (tag !== 546) {
            break;
          }

          message.logGetLevelResp = LogGetLevelResp.decode(reader, reader.uint32());
          continue;
        case 69:
          if (tag !== 554) {
            break;
          }

          message.logReadReq = LogReadReq.decode(reader, reader.uint32());
          continue;
        case 70:
          if (tag !== 562) {
            break;
          }

          message.logReadResp = LogReadResp.decode(reader, reader.uint32());
          continue;
        case 71:
          if (tag !== 570) {
            break;
          }

          message.logSetLevelReq = LogSetLevelReq.decode(reader, reader.uint32());
          continue;
        case 72:
          if (tag !== 578) {
            break;
          }

          message.logSetLevelResp = LogSetLevelResp.decode(reader, reader.uint32());
          continue;
        case 283:
          if (tag !== 2266) {
            break;
          }

          message.memoiseGetReq = MemoiseGetReq.decode(reader, reader.uint32());
          continue;
        case 284:
          if (tag !== 2274) {
            break;
          }

          message.memoiseGetResp = MemoiseGetResp.decode(reader, reader.uint32());
          continue;
        case 56:
          if (tag !== 450) {
            break;
          }

          message.memoiseWriteReq = MemoiseWriteReq.decode(reader, reader.uint32());
          continue;
        case 57:
          if (tag !== 458) {
            break;
          }

          message.memoiseWriteResp = MemoiseWriteResp.decode(reader, reader.uint32());
          continue;
        case 200:
          if (tag !== 1602) {
            break;
          }

          message.multiQuantCompareReq = MultiQuantCompareReq.decode(reader, reader.uint32());
          continue;
        case 201:
          if (tag !== 1610) {
            break;
          }

          message.multiQuantCompareResp = MultiQuantCompareResp.decode(reader, reader.uint32());
          continue;
        case 286:
          if (tag !== 2290) {
            break;
          }

          message.notificationDismissReq = NotificationDismissReq.decode(reader, reader.uint32());
          continue;
        case 287:
          if (tag !== 2298) {
            break;
          }

          message.notificationDismissResp = NotificationDismissResp.decode(reader, reader.uint32());
          continue;
        case 140:
          if (tag !== 1122) {
            break;
          }

          message.notificationReq = NotificationReq.decode(reader, reader.uint32());
          continue;
        case 141:
          if (tag !== 1130) {
            break;
          }

          message.notificationResp = NotificationResp.decode(reader, reader.uint32());
          continue;
        case 147:
          if (tag !== 1178) {
            break;
          }

          message.notificationUpd = NotificationUpd.decode(reader, reader.uint32());
          continue;
        case 174:
          if (tag !== 1394) {
            break;
          }

          message.objectEditAccessReq = ObjectEditAccessReq.decode(reader, reader.uint32());
          continue;
        case 175:
          if (tag !== 1402) {
            break;
          }

          message.objectEditAccessResp = ObjectEditAccessResp.decode(reader, reader.uint32());
          continue;
        case 73:
          if (tag !== 586) {
            break;
          }

          message.piquantConfigListReq = PiquantConfigListReq.decode(reader, reader.uint32());
          continue;
        case 74:
          if (tag !== 594) {
            break;
          }

          message.piquantConfigListResp = PiquantConfigListResp.decode(reader, reader.uint32());
          continue;
        case 75:
          if (tag !== 602) {
            break;
          }

          message.piquantConfigVersionReq = PiquantConfigVersionReq.decode(reader, reader.uint32());
          continue;
        case 76:
          if (tag !== 610) {
            break;
          }

          message.piquantConfigVersionResp = PiquantConfigVersionResp.decode(reader, reader.uint32());
          continue;
        case 77:
          if (tag !== 618) {
            break;
          }

          message.piquantConfigVersionsListReq = PiquantConfigVersionsListReq.decode(reader, reader.uint32());
          continue;
        case 78:
          if (tag !== 626) {
            break;
          }

          message.piquantConfigVersionsListResp = PiquantConfigVersionsListResp.decode(reader, reader.uint32());
          continue;
        case 79:
          if (tag !== 634) {
            break;
          }

          message.piquantCurrentVersionReq = PiquantCurrentVersionReq.decode(reader, reader.uint32());
          continue;
        case 80:
          if (tag !== 642) {
            break;
          }

          message.piquantCurrentVersionResp = PiquantCurrentVersionResp.decode(reader, reader.uint32());
          continue;
        case 81:
          if (tag !== 650) {
            break;
          }

          message.piquantVersionListReq = PiquantVersionListReq.decode(reader, reader.uint32());
          continue;
        case 82:
          if (tag !== 658) {
            break;
          }

          message.piquantVersionListResp = PiquantVersionListResp.decode(reader, reader.uint32());
          continue;
        case 83:
          if (tag !== 666) {
            break;
          }

          message.piquantWriteCurrentVersionReq = PiquantWriteCurrentVersionReq.decode(reader, reader.uint32());
          continue;
        case 84:
          if (tag !== 674) {
            break;
          }

          message.piquantWriteCurrentVersionResp = PiquantWriteCurrentVersionResp.decode(reader, reader.uint32());
          continue;
        case 85:
          if (tag !== 682) {
            break;
          }

          message.pseudoIntensityReq = PseudoIntensityReq.decode(reader, reader.uint32());
          continue;
        case 86:
          if (tag !== 690) {
            break;
          }

          message.pseudoIntensityResp = PseudoIntensityResp.decode(reader, reader.uint32());
          continue;
        case 242:
          if (tag !== 1938) {
            break;
          }

          message.publishExpressionToZenodoReq = PublishExpressionToZenodoReq.decode(reader, reader.uint32());
          continue;
        case 243:
          if (tag !== 1946) {
            break;
          }

          message.publishExpressionToZenodoResp = PublishExpressionToZenodoResp.decode(reader, reader.uint32());
          continue;
        case 202:
          if (tag !== 1618) {
            break;
          }

          message.quantBlessReq = QuantBlessReq.decode(reader, reader.uint32());
          continue;
        case 203:
          if (tag !== 1626) {
            break;
          }

          message.quantBlessResp = QuantBlessResp.decode(reader, reader.uint32());
          continue;
        case 204:
          if (tag !== 1634) {
            break;
          }

          message.quantCombineListGetReq = QuantCombineListGetReq.decode(reader, reader.uint32());
          continue;
        case 205:
          if (tag !== 1642) {
            break;
          }

          message.quantCombineListGetResp = QuantCombineListGetResp.decode(reader, reader.uint32());
          continue;
        case 206:
          if (tag !== 1650) {
            break;
          }

          message.quantCombineListWriteReq = QuantCombineListWriteReq.decode(reader, reader.uint32());
          continue;
        case 207:
          if (tag !== 1658) {
            break;
          }

          message.quantCombineListWriteResp = QuantCombineListWriteResp.decode(reader, reader.uint32());
          continue;
        case 208:
          if (tag !== 1666) {
            break;
          }

          message.quantCombineReq = QuantCombineReq.decode(reader, reader.uint32());
          continue;
        case 209:
          if (tag !== 1674) {
            break;
          }

          message.quantCombineResp = QuantCombineResp.decode(reader, reader.uint32());
          continue;
        case 270:
          if (tag !== 2162) {
            break;
          }

          message.quantCreateReq = QuantCreateReq.decode(reader, reader.uint32());
          continue;
        case 271:
          if (tag !== 2170) {
            break;
          }

          message.quantCreateResp = QuantCreateResp.decode(reader, reader.uint32());
          continue;
        case 272:
          if (tag !== 2178) {
            break;
          }

          message.quantCreateUpd = QuantCreateUpd.decode(reader, reader.uint32());
          continue;
        case 210:
          if (tag !== 1682) {
            break;
          }

          message.quantDeleteReq = QuantDeleteReq.decode(reader, reader.uint32());
          continue;
        case 211:
          if (tag !== 1690) {
            break;
          }

          message.quantDeleteResp = QuantDeleteResp.decode(reader, reader.uint32());
          continue;
        case 212:
          if (tag !== 1698) {
            break;
          }

          message.quantGetReq = QuantGetReq.decode(reader, reader.uint32());
          continue;
        case 213:
          if (tag !== 1706) {
            break;
          }

          message.quantGetResp = QuantGetResp.decode(reader, reader.uint32());
          continue;
        case 214:
          if (tag !== 1714) {
            break;
          }

          message.quantLastOutputGetReq = QuantLastOutputGetReq.decode(reader, reader.uint32());
          continue;
        case 215:
          if (tag !== 1722) {
            break;
          }

          message.quantLastOutputGetResp = QuantLastOutputGetResp.decode(reader, reader.uint32());
          continue;
        case 216:
          if (tag !== 1730) {
            break;
          }

          message.quantListReq = QuantListReq.decode(reader, reader.uint32());
          continue;
        case 217:
          if (tag !== 1738) {
            break;
          }

          message.quantListResp = QuantListResp.decode(reader, reader.uint32());
          continue;
        case 301:
          if (tag !== 2410) {
            break;
          }

          message.quantLogGetReq = QuantLogGetReq.decode(reader, reader.uint32());
          continue;
        case 302:
          if (tag !== 2418) {
            break;
          }

          message.quantLogGetResp = QuantLogGetResp.decode(reader, reader.uint32());
          continue;
        case 303:
          if (tag !== 2426) {
            break;
          }

          message.quantLogListReq = QuantLogListReq.decode(reader, reader.uint32());
          continue;
        case 304:
          if (tag !== 2434) {
            break;
          }

          message.quantLogListResp = QuantLogListResp.decode(reader, reader.uint32());
          continue;
        case 218:
          if (tag !== 1746) {
            break;
          }

          message.quantPublishReq = QuantPublishReq.decode(reader, reader.uint32());
          continue;
        case 219:
          if (tag !== 1754) {
            break;
          }

          message.quantPublishResp = QuantPublishResp.decode(reader, reader.uint32());
          continue;
        case 305:
          if (tag !== 2442) {
            break;
          }

          message.quantRawDataGetReq = QuantRawDataGetReq.decode(reader, reader.uint32());
          continue;
        case 306:
          if (tag !== 2450) {
            break;
          }

          message.quantRawDataGetResp = QuantRawDataGetResp.decode(reader, reader.uint32());
          continue;
        case 220:
          if (tag !== 1762) {
            break;
          }

          message.quantUploadReq = QuantUploadReq.decode(reader, reader.uint32());
          continue;
        case 221:
          if (tag !== 1770) {
            break;
          }

          message.quantUploadResp = QuantUploadResp.decode(reader, reader.uint32());
          continue;
        case 238:
          if (tag !== 1906) {
            break;
          }

          message.regionOfInterestBulkDuplicateReq = RegionOfInterestBulkDuplicateReq.decode(reader, reader.uint32());
          continue;
        case 239:
          if (tag !== 1914) {
            break;
          }

          message.regionOfInterestBulkDuplicateResp = RegionOfInterestBulkDuplicateResp.decode(reader, reader.uint32());
          continue;
        case 236:
          if (tag !== 1890) {
            break;
          }

          message.regionOfInterestBulkWriteReq = RegionOfInterestBulkWriteReq.decode(reader, reader.uint32());
          continue;
        case 237:
          if (tag !== 1898) {
            break;
          }

          message.regionOfInterestBulkWriteResp = RegionOfInterestBulkWriteResp.decode(reader, reader.uint32());
          continue;
        case 87:
          if (tag !== 698) {
            break;
          }

          message.regionOfInterestDeleteReq = RegionOfInterestDeleteReq.decode(reader, reader.uint32());
          continue;
        case 88:
          if (tag !== 706) {
            break;
          }

          message.regionOfInterestDeleteResp = RegionOfInterestDeleteResp.decode(reader, reader.uint32());
          continue;
        case 246:
          if (tag !== 1970) {
            break;
          }

          message.regionOfInterestDisplaySettingsGetReq = RegionOfInterestDisplaySettingsGetReq.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 247:
          if (tag !== 1978) {
            break;
          }

          message.regionOfInterestDisplaySettingsGetResp = RegionOfInterestDisplaySettingsGetResp.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 244:
          if (tag !== 1954) {
            break;
          }

          message.regionOfInterestDisplaySettingsWriteReq = RegionOfInterestDisplaySettingsWriteReq.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 245:
          if (tag !== 1962) {
            break;
          }

          message.regionOfInterestDisplaySettingsWriteResp = RegionOfInterestDisplaySettingsWriteResp.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 91:
          if (tag !== 730) {
            break;
          }

          message.regionOfInterestGetReq = RegionOfInterestGetReq.decode(reader, reader.uint32());
          continue;
        case 92:
          if (tag !== 738) {
            break;
          }

          message.regionOfInterestGetResp = RegionOfInterestGetResp.decode(reader, reader.uint32());
          continue;
        case 89:
          if (tag !== 714) {
            break;
          }

          message.regionOfInterestListReq = RegionOfInterestListReq.decode(reader, reader.uint32());
          continue;
        case 90:
          if (tag !== 722) {
            break;
          }

          message.regionOfInterestListResp = RegionOfInterestListResp.decode(reader, reader.uint32());
          continue;
        case 93:
          if (tag !== 746) {
            break;
          }

          message.regionOfInterestWriteReq = RegionOfInterestWriteReq.decode(reader, reader.uint32());
          continue;
        case 94:
          if (tag !== 754) {
            break;
          }

          message.regionOfInterestWriteResp = RegionOfInterestWriteResp.decode(reader, reader.uint32());
          continue;
        case 316:
          if (tag !== 2530) {
            break;
          }

          message.restoreDBReq = RestoreDBReq.decode(reader, reader.uint32());
          continue;
        case 317:
          if (tag !== 2538) {
            break;
          }

          message.restoreDBResp = RestoreDBResp.decode(reader, reader.uint32());
          continue;
        case 318:
          if (tag !== 2546) {
            break;
          }

          message.reviewerMagicLinkCreateReq = ReviewerMagicLinkCreateReq.decode(reader, reader.uint32());
          continue;
        case 319:
          if (tag !== 2554) {
            break;
          }

          message.reviewerMagicLinkCreateResp = ReviewerMagicLinkCreateResp.decode(reader, reader.uint32());
          continue;
        case 320:
          if (tag !== 2562) {
            break;
          }

          message.reviewerMagicLinkLoginReq = ReviewerMagicLinkLoginReq.decode(reader, reader.uint32());
          continue;
        case 321:
          if (tag !== 2570) {
            break;
          }

          message.reviewerMagicLinkLoginResp = ReviewerMagicLinkLoginResp.decode(reader, reader.uint32());
          continue;
        case 95:
          if (tag !== 762) {
            break;
          }

          message.runTestReq = RunTestReq.decode(reader, reader.uint32());
          continue;
        case 96:
          if (tag !== 770) {
            break;
          }

          message.runTestResp = RunTestResp.decode(reader, reader.uint32());
          continue;
        case 279:
          if (tag !== 2234) {
            break;
          }

          message.scanAutoShareReq = ScanAutoShareReq.decode(reader, reader.uint32());
          continue;
        case 280:
          if (tag !== 2242) {
            break;
          }

          message.scanAutoShareResp = ScanAutoShareResp.decode(reader, reader.uint32());
          continue;
        case 281:
          if (tag !== 2250) {
            break;
          }

          message.scanAutoShareWriteReq = ScanAutoShareWriteReq.decode(reader, reader.uint32());
          continue;
        case 282:
          if (tag !== 2258) {
            break;
          }

          message.scanAutoShareWriteResp = ScanAutoShareWriteResp.decode(reader, reader.uint32());
          continue;
        case 192:
          if (tag !== 1538) {
            break;
          }

          message.scanBeamLocationsReq = ScanBeamLocationsReq.decode(reader, reader.uint32());
          continue;
        case 193:
          if (tag !== 1546) {
            break;
          }

          message.scanBeamLocationsResp = ScanBeamLocationsResp.decode(reader, reader.uint32());
          continue;
        case 276:
          if (tag !== 2210) {
            break;
          }

          message.scanDeleteReq = ScanDeleteReq.decode(reader, reader.uint32());
          continue;
        case 277:
          if (tag !== 2218) {
            break;
          }

          message.scanDeleteResp = ScanDeleteResp.decode(reader, reader.uint32());
          continue;
        case 194:
          if (tag !== 1554) {
            break;
          }

          message.scanEntryMetadataReq = ScanEntryMetadataReq.decode(reader, reader.uint32());
          continue;
        case 195:
          if (tag !== 1562) {
            break;
          }

          message.scanEntryMetadataResp = ScanEntryMetadataResp.decode(reader, reader.uint32());
          continue;
        case 196:
          if (tag !== 1570) {
            break;
          }

          message.scanEntryReq = ScanEntryReq.decode(reader, reader.uint32());
          continue;
        case 197:
          if (tag !== 1578) {
            break;
          }

          message.scanEntryResp = ScanEntryResp.decode(reader, reader.uint32());
          continue;
        case 292:
          if (tag !== 2338) {
            break;
          }

          message.scanGetReq = ScanGetReq.decode(reader, reader.uint32());
          continue;
        case 293:
          if (tag !== 2346) {
            break;
          }

          message.scanGetResp = ScanGetResp.decode(reader, reader.uint32());
          continue;
        case 99:
          if (tag !== 794) {
            break;
          }

          message.scanListReq = ScanListReq.decode(reader, reader.uint32());
          continue;
        case 100:
          if (tag !== 802) {
            break;
          }

          message.scanListResp = ScanListResp.decode(reader, reader.uint32());
          continue;
        case 101:
          if (tag !== 810) {
            break;
          }

          message.scanListUpd = ScanListUpd.decode(reader, reader.uint32());
          continue;
        case 104:
          if (tag !== 834) {
            break;
          }

          message.scanMetaLabelsAndTypesReq = ScanMetaLabelsAndTypesReq.decode(reader, reader.uint32());
          continue;
        case 105:
          if (tag !== 842) {
            break;
          }

          message.scanMetaLabelsAndTypesResp = ScanMetaLabelsAndTypesResp.decode(reader, reader.uint32());
          continue;
        case 106:
          if (tag !== 850) {
            break;
          }

          message.scanMetaWriteReq = ScanMetaWriteReq.decode(reader, reader.uint32());
          continue;
        case 107:
          if (tag !== 858) {
            break;
          }

          message.scanMetaWriteResp = ScanMetaWriteResp.decode(reader, reader.uint32());
          continue;
        case 294:
          if (tag !== 2354) {
            break;
          }

          message.scanTriggerAutoQuantReq = ScanTriggerAutoQuantReq.decode(reader, reader.uint32());
          continue;
        case 295:
          if (tag !== 2362) {
            break;
          }

          message.scanTriggerAutoQuantResp = ScanTriggerAutoQuantResp.decode(reader, reader.uint32());
          continue;
        case 108:
          if (tag !== 866) {
            break;
          }

          message.scanTriggerReImportReq = ScanTriggerReImportReq.decode(reader, reader.uint32());
          continue;
        case 109:
          if (tag !== 874) {
            break;
          }

          message.scanTriggerReImportResp = ScanTriggerReImportResp.decode(reader, reader.uint32());
          continue;
        case 269:
          if (tag !== 2154) {
            break;
          }

          message.scanTriggerReImportUpd = ScanTriggerReImportUpd.decode(reader, reader.uint32());
          continue;
        case 110:
          if (tag !== 882) {
            break;
          }

          message.scanUploadReq = ScanUploadReq.decode(reader, reader.uint32());
          continue;
        case 111:
          if (tag !== 890) {
            break;
          }

          message.scanUploadResp = ScanUploadResp.decode(reader, reader.uint32());
          continue;
        case 268:
          if (tag !== 2146) {
            break;
          }

          message.scanUploadUpd = ScanUploadUpd.decode(reader, reader.uint32());
          continue;
        case 260:
          if (tag !== 2082) {
            break;
          }

          message.screenConfigurationDeleteReq = ScreenConfigurationDeleteReq.decode(reader, reader.uint32());
          continue;
        case 261:
          if (tag !== 2090) {
            break;
          }

          message.screenConfigurationDeleteResp = ScreenConfigurationDeleteResp.decode(reader, reader.uint32());
          continue;
        case 248:
          if (tag !== 1986) {
            break;
          }

          message.screenConfigurationGetReq = ScreenConfigurationGetReq.decode(reader, reader.uint32());
          continue;
        case 249:
          if (tag !== 1994) {
            break;
          }

          message.screenConfigurationGetResp = ScreenConfigurationGetResp.decode(reader, reader.uint32());
          continue;
        case 250:
          if (tag !== 2002) {
            break;
          }

          message.screenConfigurationListReq = ScreenConfigurationListReq.decode(reader, reader.uint32());
          continue;
        case 251:
          if (tag !== 2010) {
            break;
          }

          message.screenConfigurationListResp = ScreenConfigurationListResp.decode(reader, reader.uint32());
          continue;
        case 252:
          if (tag !== 2018) {
            break;
          }

          message.screenConfigurationWriteReq = ScreenConfigurationWriteReq.decode(reader, reader.uint32());
          continue;
        case 253:
          if (tag !== 2026) {
            break;
          }

          message.screenConfigurationWriteResp = ScreenConfigurationWriteResp.decode(reader, reader.uint32());
          continue;
        case 226:
          if (tag !== 1810) {
            break;
          }

          message.selectedImagePixelsReq = SelectedImagePixelsReq.decode(reader, reader.uint32());
          continue;
        case 227:
          if (tag !== 1818) {
            break;
          }

          message.selectedImagePixelsResp = SelectedImagePixelsResp.decode(reader, reader.uint32());
          continue;
        case 228:
          if (tag !== 1826) {
            break;
          }

          message.selectedImagePixelsWriteReq = SelectedImagePixelsWriteReq.decode(reader, reader.uint32());
          continue;
        case 229:
          if (tag !== 1834) {
            break;
          }

          message.selectedImagePixelsWriteResp = SelectedImagePixelsWriteResp.decode(reader, reader.uint32());
          continue;
        case 230:
          if (tag !== 1842) {
            break;
          }

          message.selectedScanEntriesReq = SelectedScanEntriesReq.decode(reader, reader.uint32());
          continue;
        case 231:
          if (tag !== 1850) {
            break;
          }

          message.selectedScanEntriesResp = SelectedScanEntriesResp.decode(reader, reader.uint32());
          continue;
        case 232:
          if (tag !== 1858) {
            break;
          }

          message.selectedScanEntriesWriteReq = SelectedScanEntriesWriteReq.decode(reader, reader.uint32());
          continue;
        case 233:
          if (tag !== 1866) {
            break;
          }

          message.selectedScanEntriesWriteResp = SelectedScanEntriesWriteResp.decode(reader, reader.uint32());
          continue;
        case 112:
          if (tag !== 898) {
            break;
          }

          message.sendUserNotificationReq = SendUserNotificationReq.decode(reader, reader.uint32());
          continue;
        case 113:
          if (tag !== 906) {
            break;
          }

          message.sendUserNotificationResp = SendUserNotificationResp.decode(reader, reader.uint32());
          continue;
        case 114:
          if (tag !== 914) {
            break;
          }

          message.spectrumReq = SpectrumReq.decode(reader, reader.uint32());
          continue;
        case 115:
          if (tag !== 922) {
            break;
          }

          message.spectrumResp = SpectrumResp.decode(reader, reader.uint32());
          continue;
        case 116:
          if (tag !== 930) {
            break;
          }

          message.tagCreateReq = TagCreateReq.decode(reader, reader.uint32());
          continue;
        case 117:
          if (tag !== 938) {
            break;
          }

          message.tagCreateResp = TagCreateResp.decode(reader, reader.uint32());
          continue;
        case 118:
          if (tag !== 946) {
            break;
          }

          message.tagDeleteReq = TagDeleteReq.decode(reader, reader.uint32());
          continue;
        case 119:
          if (tag !== 954) {
            break;
          }

          message.tagDeleteResp = TagDeleteResp.decode(reader, reader.uint32());
          continue;
        case 120:
          if (tag !== 962) {
            break;
          }

          message.tagListReq = TagListReq.decode(reader, reader.uint32());
          continue;
        case 121:
          if (tag !== 970) {
            break;
          }

          message.tagListResp = TagListResp.decode(reader, reader.uint32());
          continue;
        case 122:
          if (tag !== 978) {
            break;
          }

          message.userAddRoleReq = UserAddRoleReq.decode(reader, reader.uint32());
          continue;
        case 123:
          if (tag !== 986) {
            break;
          }

          message.userAddRoleResp = UserAddRoleResp.decode(reader, reader.uint32());
          continue;
        case 124:
          if (tag !== 994) {
            break;
          }

          message.userDeleteRoleReq = UserDeleteRoleReq.decode(reader, reader.uint32());
          continue;
        case 125:
          if (tag !== 1002) {
            break;
          }

          message.userDeleteRoleResp = UserDeleteRoleResp.decode(reader, reader.uint32());
          continue;
        case 126:
          if (tag !== 1010) {
            break;
          }

          message.userDetailsReq = UserDetailsReq.decode(reader, reader.uint32());
          continue;
        case 127:
          if (tag !== 1018) {
            break;
          }

          message.userDetailsResp = UserDetailsResp.decode(reader, reader.uint32());
          continue;
        case 129:
          if (tag !== 1034) {
            break;
          }

          message.userDetailsWriteReq = UserDetailsWriteReq.decode(reader, reader.uint32());
          continue;
        case 130:
          if (tag !== 1042) {
            break;
          }

          message.userDetailsWriteResp = UserDetailsWriteResp.decode(reader, reader.uint32());
          continue;
        case 156:
          if (tag !== 1250) {
            break;
          }

          message.userGroupAddAdminReq = UserGroupAddAdminReq.decode(reader, reader.uint32());
          continue;
        case 157:
          if (tag !== 1258) {
            break;
          }

          message.userGroupAddAdminResp = UserGroupAddAdminResp.decode(reader, reader.uint32());
          continue;
        case 158:
          if (tag !== 1266) {
            break;
          }

          message.userGroupAddMemberReq = UserGroupAddMemberReq.decode(reader, reader.uint32());
          continue;
        case 159:
          if (tag !== 1274) {
            break;
          }

          message.userGroupAddMemberResp = UserGroupAddMemberResp.decode(reader, reader.uint32());
          continue;
        case 180:
          if (tag !== 1442) {
            break;
          }

          message.userGroupAddViewerReq = UserGroupAddViewerReq.decode(reader, reader.uint32());
          continue;
        case 181:
          if (tag !== 1450) {
            break;
          }

          message.userGroupAddViewerResp = UserGroupAddViewerResp.decode(reader, reader.uint32());
          continue;
        case 160:
          if (tag !== 1282) {
            break;
          }

          message.userGroupCreateReq = UserGroupCreateReq.decode(reader, reader.uint32());
          continue;
        case 161:
          if (tag !== 1290) {
            break;
          }

          message.userGroupCreateResp = UserGroupCreateResp.decode(reader, reader.uint32());
          continue;
        case 162:
          if (tag !== 1298) {
            break;
          }

          message.userGroupDeleteAdminReq = UserGroupDeleteAdminReq.decode(reader, reader.uint32());
          continue;
        case 163:
          if (tag !== 1306) {
            break;
          }

          message.userGroupDeleteAdminResp = UserGroupDeleteAdminResp.decode(reader, reader.uint32());
          continue;
        case 164:
          if (tag !== 1314) {
            break;
          }

          message.userGroupDeleteMemberReq = UserGroupDeleteMemberReq.decode(reader, reader.uint32());
          continue;
        case 165:
          if (tag !== 1322) {
            break;
          }

          message.userGroupDeleteMemberResp = UserGroupDeleteMemberResp.decode(reader, reader.uint32());
          continue;
        case 166:
          if (tag !== 1330) {
            break;
          }

          message.userGroupDeleteReq = UserGroupDeleteReq.decode(reader, reader.uint32());
          continue;
        case 167:
          if (tag !== 1338) {
            break;
          }

          message.userGroupDeleteResp = UserGroupDeleteResp.decode(reader, reader.uint32());
          continue;
        case 178:
          if (tag !== 1426) {
            break;
          }

          message.userGroupDeleteViewerReq = UserGroupDeleteViewerReq.decode(reader, reader.uint32());
          continue;
        case 179:
          if (tag !== 1434) {
            break;
          }

          message.userGroupDeleteViewerResp = UserGroupDeleteViewerResp.decode(reader, reader.uint32());
          continue;
        case 170:
          if (tag !== 1362) {
            break;
          }

          message.userGroupEditDetailsReq = UserGroupEditDetailsReq.decode(reader, reader.uint32());
          continue;
        case 171:
          if (tag !== 1370) {
            break;
          }

          message.userGroupEditDetailsResp = UserGroupEditDetailsResp.decode(reader, reader.uint32());
          continue;
        case 182:
          if (tag !== 1458) {
            break;
          }

          message.userGroupIgnoreJoinReq = UserGroupIgnoreJoinReq.decode(reader, reader.uint32());
          continue;
        case 183:
          if (tag !== 1466) {
            break;
          }

          message.userGroupIgnoreJoinResp = UserGroupIgnoreJoinResp.decode(reader, reader.uint32());
          continue;
        case 184:
          if (tag !== 1474) {
            break;
          }

          message.userGroupJoinListReq = UserGroupJoinListReq.decode(reader, reader.uint32());
          continue;
        case 185:
          if (tag !== 1482) {
            break;
          }

          message.userGroupJoinListResp = UserGroupJoinListResp.decode(reader, reader.uint32());
          continue;
        case 186:
          if (tag !== 1490) {
            break;
          }

          message.userGroupJoinReq = UserGroupJoinReq.decode(reader, reader.uint32());
          continue;
        case 187:
          if (tag !== 1498) {
            break;
          }

          message.userGroupJoinResp = UserGroupJoinResp.decode(reader, reader.uint32());
          continue;
        case 234:
          if (tag !== 1874) {
            break;
          }

          message.userGroupListJoinableReq = UserGroupListJoinableReq.decode(reader, reader.uint32());
          continue;
        case 235:
          if (tag !== 1882) {
            break;
          }

          message.userGroupListJoinableResp = UserGroupListJoinableResp.decode(reader, reader.uint32());
          continue;
        case 168:
          if (tag !== 1346) {
            break;
          }

          message.userGroupListReq = UserGroupListReq.decode(reader, reader.uint32());
          continue;
        case 169:
          if (tag !== 1354) {
            break;
          }

          message.userGroupListResp = UserGroupListResp.decode(reader, reader.uint32());
          continue;
        case 188:
          if (tag !== 1506) {
            break;
          }

          message.userGroupReq = UserGroupReq.decode(reader, reader.uint32());
          continue;
        case 189:
          if (tag !== 1514) {
            break;
          }

          message.userGroupResp = UserGroupResp.decode(reader, reader.uint32());
          continue;
        case 309:
          if (tag !== 2474) {
            break;
          }

          message.userImpersonateGetReq = UserImpersonateGetReq.decode(reader, reader.uint32());
          continue;
        case 310:
          if (tag !== 2482) {
            break;
          }

          message.userImpersonateGetResp = UserImpersonateGetResp.decode(reader, reader.uint32());
          continue;
        case 307:
          if (tag !== 2458) {
            break;
          }

          message.userImpersonateReq = UserImpersonateReq.decode(reader, reader.uint32());
          continue;
        case 308:
          if (tag !== 2466) {
            break;
          }

          message.userImpersonateResp = UserImpersonateResp.decode(reader, reader.uint32());
          continue;
        case 138:
          if (tag !== 1106) {
            break;
          }

          message.userListReq = UserListReq.decode(reader, reader.uint32());
          continue;
        case 139:
          if (tag !== 1114) {
            break;
          }

          message.userListResp = UserListResp.decode(reader, reader.uint32());
          continue;
        case 142:
          if (tag !== 1138) {
            break;
          }

          message.userNotificationSettingsReq = UserNotificationSettingsReq.decode(reader, reader.uint32());
          continue;
        case 143:
          if (tag !== 1146) {
            break;
          }

          message.userNotificationSettingsResp = UserNotificationSettingsResp.decode(reader, reader.uint32());
          continue;
        case 144:
          if (tag !== 1154) {
            break;
          }

          message.userNotificationSettingsUpd = UserNotificationSettingsUpd.decode(reader, reader.uint32());
          continue;
        case 145:
          if (tag !== 1162) {
            break;
          }

          message.userNotificationSettingsWriteReq = UserNotificationSettingsWriteReq.decode(reader, reader.uint32());
          continue;
        case 146:
          if (tag !== 1170) {
            break;
          }

          message.userNotificationSettingsWriteResp = UserNotificationSettingsWriteResp.decode(reader, reader.uint32());
          continue;
        case 148:
          if (tag !== 1186) {
            break;
          }

          message.userRoleListReq = UserRoleListReq.decode(reader, reader.uint32());
          continue;
        case 149:
          if (tag !== 1194) {
            break;
          }

          message.userRoleListResp = UserRoleListResp.decode(reader, reader.uint32());
          continue;
        case 150:
          if (tag !== 1202) {
            break;
          }

          message.userRolesListReq = UserRolesListReq.decode(reader, reader.uint32());
          continue;
        case 151:
          if (tag !== 1210) {
            break;
          }

          message.userRolesListResp = UserRolesListResp.decode(reader, reader.uint32());
          continue;
        case 222:
          if (tag !== 1778) {
            break;
          }

          message.userSearchReq = UserSearchReq.decode(reader, reader.uint32());
          continue;
        case 223:
          if (tag !== 1786) {
            break;
          }

          message.userSearchResp = UserSearchResp.decode(reader, reader.uint32());
          continue;
        case 254:
          if (tag !== 2034) {
            break;
          }

          message.widgetDataGetReq = WidgetDataGetReq.decode(reader, reader.uint32());
          continue;
        case 255:
          if (tag !== 2042) {
            break;
          }

          message.widgetDataGetResp = WidgetDataGetResp.decode(reader, reader.uint32());
          continue;
        case 256:
          if (tag !== 2050) {
            break;
          }

          message.widgetDataWriteReq = WidgetDataWriteReq.decode(reader, reader.uint32());
          continue;
        case 257:
          if (tag !== 2058) {
            break;
          }

          message.widgetDataWriteResp = WidgetDataWriteResp.decode(reader, reader.uint32());
          continue;
        case 240:
          if (tag !== 1922) {
            break;
          }

          message.zenodoDOIGetReq = ZenodoDOIGetReq.decode(reader, reader.uint32());
          continue;
        case 241:
          if (tag !== 1930) {
            break;
          }

          message.zenodoDOIGetResp = ZenodoDOIGetResp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WSMessage {
    return {
      msgId: isSet(object.msgId) ? Number(object.msgId) : 0,
      status: isSet(object.status) ? responseStatusFromJSON(object.status) : 0,
      errorText: isSet(object.errorText) ? String(object.errorText) : "",
      backupDBReq: isSet(object.backupDBReq) ? BackupDBReq.fromJSON(object.backupDBReq) : undefined,
      backupDBResp: isSet(object.backupDBResp) ? BackupDBResp.fromJSON(object.backupDBResp) : undefined,
      backupDBUpd: isSet(object.backupDBUpd) ? BackupDBUpd.fromJSON(object.backupDBUpd) : undefined,
      dBAdminConfigGetReq: isSet(object.dBAdminConfigGetReq)
        ? DBAdminConfigGetReq.fromJSON(object.dBAdminConfigGetReq)
        : undefined,
      dBAdminConfigGetResp: isSet(object.dBAdminConfigGetResp)
        ? DBAdminConfigGetResp.fromJSON(object.dBAdminConfigGetResp)
        : undefined,
      dataModuleAddVersionReq: isSet(object.dataModuleAddVersionReq)
        ? DataModuleAddVersionReq.fromJSON(object.dataModuleAddVersionReq)
        : undefined,
      dataModuleAddVersionResp: isSet(object.dataModuleAddVersionResp)
        ? DataModuleAddVersionResp.fromJSON(object.dataModuleAddVersionResp)
        : undefined,
      dataModuleGetReq: isSet(object.dataModuleGetReq) ? DataModuleGetReq.fromJSON(object.dataModuleGetReq) : undefined,
      dataModuleGetResp: isSet(object.dataModuleGetResp)
        ? DataModuleGetResp.fromJSON(object.dataModuleGetResp)
        : undefined,
      dataModuleListReq: isSet(object.dataModuleListReq)
        ? DataModuleListReq.fromJSON(object.dataModuleListReq)
        : undefined,
      dataModuleListResp: isSet(object.dataModuleListResp)
        ? DataModuleListResp.fromJSON(object.dataModuleListResp)
        : undefined,
      dataModuleWriteReq: isSet(object.dataModuleWriteReq)
        ? DataModuleWriteReq.fromJSON(object.dataModuleWriteReq)
        : undefined,
      dataModuleWriteResp: isSet(object.dataModuleWriteResp)
        ? DataModuleWriteResp.fromJSON(object.dataModuleWriteResp)
        : undefined,
      detectedDiffractionPeaksReq: isSet(object.detectedDiffractionPeaksReq)
        ? DetectedDiffractionPeaksReq.fromJSON(object.detectedDiffractionPeaksReq)
        : undefined,
      detectedDiffractionPeaksResp: isSet(object.detectedDiffractionPeaksResp)
        ? DetectedDiffractionPeaksResp.fromJSON(object.detectedDiffractionPeaksResp)
        : undefined,
      detectorConfigListReq: isSet(object.detectorConfigListReq)
        ? DetectorConfigListReq.fromJSON(object.detectorConfigListReq)
        : undefined,
      detectorConfigListResp: isSet(object.detectorConfigListResp)
        ? DetectorConfigListResp.fromJSON(object.detectorConfigListResp)
        : undefined,
      detectorConfigReq: isSet(object.detectorConfigReq)
        ? DetectorConfigReq.fromJSON(object.detectorConfigReq)
        : undefined,
      detectorConfigResp: isSet(object.detectorConfigResp)
        ? DetectorConfigResp.fromJSON(object.detectorConfigResp)
        : undefined,
      diffractionPeakManualDeleteReq: isSet(object.diffractionPeakManualDeleteReq)
        ? DiffractionPeakManualDeleteReq.fromJSON(object.diffractionPeakManualDeleteReq)
        : undefined,
      diffractionPeakManualDeleteResp: isSet(object.diffractionPeakManualDeleteResp)
        ? DiffractionPeakManualDeleteResp.fromJSON(object.diffractionPeakManualDeleteResp)
        : undefined,
      diffractionPeakManualInsertReq: isSet(object.diffractionPeakManualInsertReq)
        ? DiffractionPeakManualInsertReq.fromJSON(object.diffractionPeakManualInsertReq)
        : undefined,
      diffractionPeakManualInsertResp: isSet(object.diffractionPeakManualInsertResp)
        ? DiffractionPeakManualInsertResp.fromJSON(object.diffractionPeakManualInsertResp)
        : undefined,
      diffractionPeakManualListReq: isSet(object.diffractionPeakManualListReq)
        ? DiffractionPeakManualListReq.fromJSON(object.diffractionPeakManualListReq)
        : undefined,
      diffractionPeakManualListResp: isSet(object.diffractionPeakManualListResp)
        ? DiffractionPeakManualListResp.fromJSON(object.diffractionPeakManualListResp)
        : undefined,
      diffractionPeakStatusDeleteReq: isSet(object.diffractionPeakStatusDeleteReq)
        ? DiffractionPeakStatusDeleteReq.fromJSON(object.diffractionPeakStatusDeleteReq)
        : undefined,
      diffractionPeakStatusDeleteResp: isSet(object.diffractionPeakStatusDeleteResp)
        ? DiffractionPeakStatusDeleteResp.fromJSON(object.diffractionPeakStatusDeleteResp)
        : undefined,
      diffractionPeakStatusListReq: isSet(object.diffractionPeakStatusListReq)
        ? DiffractionPeakStatusListReq.fromJSON(object.diffractionPeakStatusListReq)
        : undefined,
      diffractionPeakStatusListResp: isSet(object.diffractionPeakStatusListResp)
        ? DiffractionPeakStatusListResp.fromJSON(object.diffractionPeakStatusListResp)
        : undefined,
      diffractionPeakStatusWriteReq: isSet(object.diffractionPeakStatusWriteReq)
        ? DiffractionPeakStatusWriteReq.fromJSON(object.diffractionPeakStatusWriteReq)
        : undefined,
      diffractionPeakStatusWriteResp: isSet(object.diffractionPeakStatusWriteResp)
        ? DiffractionPeakStatusWriteResp.fromJSON(object.diffractionPeakStatusWriteResp)
        : undefined,
      elementSetDeleteReq: isSet(object.elementSetDeleteReq)
        ? ElementSetDeleteReq.fromJSON(object.elementSetDeleteReq)
        : undefined,
      elementSetDeleteResp: isSet(object.elementSetDeleteResp)
        ? ElementSetDeleteResp.fromJSON(object.elementSetDeleteResp)
        : undefined,
      elementSetGetReq: isSet(object.elementSetGetReq) ? ElementSetGetReq.fromJSON(object.elementSetGetReq) : undefined,
      elementSetGetResp: isSet(object.elementSetGetResp)
        ? ElementSetGetResp.fromJSON(object.elementSetGetResp)
        : undefined,
      elementSetListReq: isSet(object.elementSetListReq)
        ? ElementSetListReq.fromJSON(object.elementSetListReq)
        : undefined,
      elementSetListResp: isSet(object.elementSetListResp)
        ? ElementSetListResp.fromJSON(object.elementSetListResp)
        : undefined,
      elementSetWriteReq: isSet(object.elementSetWriteReq)
        ? ElementSetWriteReq.fromJSON(object.elementSetWriteReq)
        : undefined,
      elementSetWriteResp: isSet(object.elementSetWriteResp)
        ? ElementSetWriteResp.fromJSON(object.elementSetWriteResp)
        : undefined,
      exportFilesReq: isSet(object.exportFilesReq) ? ExportFilesReq.fromJSON(object.exportFilesReq) : undefined,
      exportFilesResp: isSet(object.exportFilesResp) ? ExportFilesResp.fromJSON(object.exportFilesResp) : undefined,
      expressionDeleteReq: isSet(object.expressionDeleteReq)
        ? ExpressionDeleteReq.fromJSON(object.expressionDeleteReq)
        : undefined,
      expressionDeleteResp: isSet(object.expressionDeleteResp)
        ? ExpressionDeleteResp.fromJSON(object.expressionDeleteResp)
        : undefined,
      expressionDisplaySettingsGetReq: isSet(object.expressionDisplaySettingsGetReq)
        ? ExpressionDisplaySettingsGetReq.fromJSON(object.expressionDisplaySettingsGetReq)
        : undefined,
      expressionDisplaySettingsGetResp: isSet(object.expressionDisplaySettingsGetResp)
        ? ExpressionDisplaySettingsGetResp.fromJSON(object.expressionDisplaySettingsGetResp)
        : undefined,
      expressionDisplaySettingsWriteReq: isSet(object.expressionDisplaySettingsWriteReq)
        ? ExpressionDisplaySettingsWriteReq.fromJSON(object.expressionDisplaySettingsWriteReq)
        : undefined,
      expressionDisplaySettingsWriteResp: isSet(object.expressionDisplaySettingsWriteResp)
        ? ExpressionDisplaySettingsWriteResp.fromJSON(object.expressionDisplaySettingsWriteResp)
        : undefined,
      expressionGetReq: isSet(object.expressionGetReq) ? ExpressionGetReq.fromJSON(object.expressionGetReq) : undefined,
      expressionGetResp: isSet(object.expressionGetResp)
        ? ExpressionGetResp.fromJSON(object.expressionGetResp)
        : undefined,
      expressionGroupDeleteReq: isSet(object.expressionGroupDeleteReq)
        ? ExpressionGroupDeleteReq.fromJSON(object.expressionGroupDeleteReq)
        : undefined,
      expressionGroupDeleteResp: isSet(object.expressionGroupDeleteResp)
        ? ExpressionGroupDeleteResp.fromJSON(object.expressionGroupDeleteResp)
        : undefined,
      expressionGroupGetReq: isSet(object.expressionGroupGetReq)
        ? ExpressionGroupGetReq.fromJSON(object.expressionGroupGetReq)
        : undefined,
      expressionGroupGetResp: isSet(object.expressionGroupGetResp)
        ? ExpressionGroupGetResp.fromJSON(object.expressionGroupGetResp)
        : undefined,
      expressionGroupListReq: isSet(object.expressionGroupListReq)
        ? ExpressionGroupListReq.fromJSON(object.expressionGroupListReq)
        : undefined,
      expressionGroupListResp: isSet(object.expressionGroupListResp)
        ? ExpressionGroupListResp.fromJSON(object.expressionGroupListResp)
        : undefined,
      expressionGroupWriteReq: isSet(object.expressionGroupWriteReq)
        ? ExpressionGroupWriteReq.fromJSON(object.expressionGroupWriteReq)
        : undefined,
      expressionGroupWriteResp: isSet(object.expressionGroupWriteResp)
        ? ExpressionGroupWriteResp.fromJSON(object.expressionGroupWriteResp)
        : undefined,
      expressionListReq: isSet(object.expressionListReq)
        ? ExpressionListReq.fromJSON(object.expressionListReq)
        : undefined,
      expressionListResp: isSet(object.expressionListResp)
        ? ExpressionListResp.fromJSON(object.expressionListResp)
        : undefined,
      expressionWriteExecStatReq: isSet(object.expressionWriteExecStatReq)
        ? ExpressionWriteExecStatReq.fromJSON(object.expressionWriteExecStatReq)
        : undefined,
      expressionWriteExecStatResp: isSet(object.expressionWriteExecStatResp)
        ? ExpressionWriteExecStatResp.fromJSON(object.expressionWriteExecStatResp)
        : undefined,
      expressionWriteReq: isSet(object.expressionWriteReq)
        ? ExpressionWriteReq.fromJSON(object.expressionWriteReq)
        : undefined,
      expressionWriteResp: isSet(object.expressionWriteResp)
        ? ExpressionWriteResp.fromJSON(object.expressionWriteResp)
        : undefined,
      getOwnershipReq: isSet(object.getOwnershipReq) ? GetOwnershipReq.fromJSON(object.getOwnershipReq) : undefined,
      getOwnershipResp: isSet(object.getOwnershipResp) ? GetOwnershipResp.fromJSON(object.getOwnershipResp) : undefined,
      imageBeamLocationVersionsReq: isSet(object.imageBeamLocationVersionsReq)
        ? ImageBeamLocationVersionsReq.fromJSON(object.imageBeamLocationVersionsReq)
        : undefined,
      imageBeamLocationVersionsResp: isSet(object.imageBeamLocationVersionsResp)
        ? ImageBeamLocationVersionsResp.fromJSON(object.imageBeamLocationVersionsResp)
        : undefined,
      imageBeamLocationsReq: isSet(object.imageBeamLocationsReq)
        ? ImageBeamLocationsReq.fromJSON(object.imageBeamLocationsReq)
        : undefined,
      imageBeamLocationsResp: isSet(object.imageBeamLocationsResp)
        ? ImageBeamLocationsResp.fromJSON(object.imageBeamLocationsResp)
        : undefined,
      imageDeleteReq: isSet(object.imageDeleteReq) ? ImageDeleteReq.fromJSON(object.imageDeleteReq) : undefined,
      imageDeleteResp: isSet(object.imageDeleteResp) ? ImageDeleteResp.fromJSON(object.imageDeleteResp) : undefined,
      imageGetDefaultReq: isSet(object.imageGetDefaultReq)
        ? ImageGetDefaultReq.fromJSON(object.imageGetDefaultReq)
        : undefined,
      imageGetDefaultResp: isSet(object.imageGetDefaultResp)
        ? ImageGetDefaultResp.fromJSON(object.imageGetDefaultResp)
        : undefined,
      imageGetReq: isSet(object.imageGetReq) ? ImageGetReq.fromJSON(object.imageGetReq) : undefined,
      imageGetResp: isSet(object.imageGetResp) ? ImageGetResp.fromJSON(object.imageGetResp) : undefined,
      imageListReq: isSet(object.imageListReq) ? ImageListReq.fromJSON(object.imageListReq) : undefined,
      imageListResp: isSet(object.imageListResp) ? ImageListResp.fromJSON(object.imageListResp) : undefined,
      imageListUpd: isSet(object.imageListUpd) ? ImageListUpd.fromJSON(object.imageListUpd) : undefined,
      imageSetDefaultReq: isSet(object.imageSetDefaultReq)
        ? ImageSetDefaultReq.fromJSON(object.imageSetDefaultReq)
        : undefined,
      imageSetDefaultResp: isSet(object.imageSetDefaultResp)
        ? ImageSetDefaultResp.fromJSON(object.imageSetDefaultResp)
        : undefined,
      imageSetMatchTransformReq: isSet(object.imageSetMatchTransformReq)
        ? ImageSetMatchTransformReq.fromJSON(object.imageSetMatchTransformReq)
        : undefined,
      imageSetMatchTransformResp: isSet(object.imageSetMatchTransformResp)
        ? ImageSetMatchTransformResp.fromJSON(object.imageSetMatchTransformResp)
        : undefined,
      importMarsViewerImageReq: isSet(object.importMarsViewerImageReq)
        ? ImportMarsViewerImageReq.fromJSON(object.importMarsViewerImageReq)
        : undefined,
      importMarsViewerImageResp: isSet(object.importMarsViewerImageResp)
        ? ImportMarsViewerImageResp.fromJSON(object.importMarsViewerImageResp)
        : undefined,
      importMarsViewerImageUpd: isSet(object.importMarsViewerImageUpd)
        ? ImportMarsViewerImageUpd.fromJSON(object.importMarsViewerImageUpd)
        : undefined,
      jobListReq: isSet(object.jobListReq) ? JobListReq.fromJSON(object.jobListReq) : undefined,
      jobListResp: isSet(object.jobListResp) ? JobListResp.fromJSON(object.jobListResp) : undefined,
      jobListUpd: isSet(object.jobListUpd) ? JobListUpd.fromJSON(object.jobListUpd) : undefined,
      logGetLevelReq: isSet(object.logGetLevelReq) ? LogGetLevelReq.fromJSON(object.logGetLevelReq) : undefined,
      logGetLevelResp: isSet(object.logGetLevelResp) ? LogGetLevelResp.fromJSON(object.logGetLevelResp) : undefined,
      logReadReq: isSet(object.logReadReq) ? LogReadReq.fromJSON(object.logReadReq) : undefined,
      logReadResp: isSet(object.logReadResp) ? LogReadResp.fromJSON(object.logReadResp) : undefined,
      logSetLevelReq: isSet(object.logSetLevelReq) ? LogSetLevelReq.fromJSON(object.logSetLevelReq) : undefined,
      logSetLevelResp: isSet(object.logSetLevelResp) ? LogSetLevelResp.fromJSON(object.logSetLevelResp) : undefined,
      memoiseGetReq: isSet(object.memoiseGetReq) ? MemoiseGetReq.fromJSON(object.memoiseGetReq) : undefined,
      memoiseGetResp: isSet(object.memoiseGetResp) ? MemoiseGetResp.fromJSON(object.memoiseGetResp) : undefined,
      memoiseWriteReq: isSet(object.memoiseWriteReq) ? MemoiseWriteReq.fromJSON(object.memoiseWriteReq) : undefined,
      memoiseWriteResp: isSet(object.memoiseWriteResp) ? MemoiseWriteResp.fromJSON(object.memoiseWriteResp) : undefined,
      multiQuantCompareReq: isSet(object.multiQuantCompareReq)
        ? MultiQuantCompareReq.fromJSON(object.multiQuantCompareReq)
        : undefined,
      multiQuantCompareResp: isSet(object.multiQuantCompareResp)
        ? MultiQuantCompareResp.fromJSON(object.multiQuantCompareResp)
        : undefined,
      notificationDismissReq: isSet(object.notificationDismissReq)
        ? NotificationDismissReq.fromJSON(object.notificationDismissReq)
        : undefined,
      notificationDismissResp: isSet(object.notificationDismissResp)
        ? NotificationDismissResp.fromJSON(object.notificationDismissResp)
        : undefined,
      notificationReq: isSet(object.notificationReq) ? NotificationReq.fromJSON(object.notificationReq) : undefined,
      notificationResp: isSet(object.notificationResp) ? NotificationResp.fromJSON(object.notificationResp) : undefined,
      notificationUpd: isSet(object.notificationUpd) ? NotificationUpd.fromJSON(object.notificationUpd) : undefined,
      objectEditAccessReq: isSet(object.objectEditAccessReq)
        ? ObjectEditAccessReq.fromJSON(object.objectEditAccessReq)
        : undefined,
      objectEditAccessResp: isSet(object.objectEditAccessResp)
        ? ObjectEditAccessResp.fromJSON(object.objectEditAccessResp)
        : undefined,
      piquantConfigListReq: isSet(object.piquantConfigListReq)
        ? PiquantConfigListReq.fromJSON(object.piquantConfigListReq)
        : undefined,
      piquantConfigListResp: isSet(object.piquantConfigListResp)
        ? PiquantConfigListResp.fromJSON(object.piquantConfigListResp)
        : undefined,
      piquantConfigVersionReq: isSet(object.piquantConfigVersionReq)
        ? PiquantConfigVersionReq.fromJSON(object.piquantConfigVersionReq)
        : undefined,
      piquantConfigVersionResp: isSet(object.piquantConfigVersionResp)
        ? PiquantConfigVersionResp.fromJSON(object.piquantConfigVersionResp)
        : undefined,
      piquantConfigVersionsListReq: isSet(object.piquantConfigVersionsListReq)
        ? PiquantConfigVersionsListReq.fromJSON(object.piquantConfigVersionsListReq)
        : undefined,
      piquantConfigVersionsListResp: isSet(object.piquantConfigVersionsListResp)
        ? PiquantConfigVersionsListResp.fromJSON(object.piquantConfigVersionsListResp)
        : undefined,
      piquantCurrentVersionReq: isSet(object.piquantCurrentVersionReq)
        ? PiquantCurrentVersionReq.fromJSON(object.piquantCurrentVersionReq)
        : undefined,
      piquantCurrentVersionResp: isSet(object.piquantCurrentVersionResp)
        ? PiquantCurrentVersionResp.fromJSON(object.piquantCurrentVersionResp)
        : undefined,
      piquantVersionListReq: isSet(object.piquantVersionListReq)
        ? PiquantVersionListReq.fromJSON(object.piquantVersionListReq)
        : undefined,
      piquantVersionListResp: isSet(object.piquantVersionListResp)
        ? PiquantVersionListResp.fromJSON(object.piquantVersionListResp)
        : undefined,
      piquantWriteCurrentVersionReq: isSet(object.piquantWriteCurrentVersionReq)
        ? PiquantWriteCurrentVersionReq.fromJSON(object.piquantWriteCurrentVersionReq)
        : undefined,
      piquantWriteCurrentVersionResp: isSet(object.piquantWriteCurrentVersionResp)
        ? PiquantWriteCurrentVersionResp.fromJSON(object.piquantWriteCurrentVersionResp)
        : undefined,
      pseudoIntensityReq: isSet(object.pseudoIntensityReq)
        ? PseudoIntensityReq.fromJSON(object.pseudoIntensityReq)
        : undefined,
      pseudoIntensityResp: isSet(object.pseudoIntensityResp)
        ? PseudoIntensityResp.fromJSON(object.pseudoIntensityResp)
        : undefined,
      publishExpressionToZenodoReq: isSet(object.publishExpressionToZenodoReq)
        ? PublishExpressionToZenodoReq.fromJSON(object.publishExpressionToZenodoReq)
        : undefined,
      publishExpressionToZenodoResp: isSet(object.publishExpressionToZenodoResp)
        ? PublishExpressionToZenodoResp.fromJSON(object.publishExpressionToZenodoResp)
        : undefined,
      quantBlessReq: isSet(object.quantBlessReq) ? QuantBlessReq.fromJSON(object.quantBlessReq) : undefined,
      quantBlessResp: isSet(object.quantBlessResp) ? QuantBlessResp.fromJSON(object.quantBlessResp) : undefined,
      quantCombineListGetReq: isSet(object.quantCombineListGetReq)
        ? QuantCombineListGetReq.fromJSON(object.quantCombineListGetReq)
        : undefined,
      quantCombineListGetResp: isSet(object.quantCombineListGetResp)
        ? QuantCombineListGetResp.fromJSON(object.quantCombineListGetResp)
        : undefined,
      quantCombineListWriteReq: isSet(object.quantCombineListWriteReq)
        ? QuantCombineListWriteReq.fromJSON(object.quantCombineListWriteReq)
        : undefined,
      quantCombineListWriteResp: isSet(object.quantCombineListWriteResp)
        ? QuantCombineListWriteResp.fromJSON(object.quantCombineListWriteResp)
        : undefined,
      quantCombineReq: isSet(object.quantCombineReq) ? QuantCombineReq.fromJSON(object.quantCombineReq) : undefined,
      quantCombineResp: isSet(object.quantCombineResp) ? QuantCombineResp.fromJSON(object.quantCombineResp) : undefined,
      quantCreateReq: isSet(object.quantCreateReq) ? QuantCreateReq.fromJSON(object.quantCreateReq) : undefined,
      quantCreateResp: isSet(object.quantCreateResp) ? QuantCreateResp.fromJSON(object.quantCreateResp) : undefined,
      quantCreateUpd: isSet(object.quantCreateUpd) ? QuantCreateUpd.fromJSON(object.quantCreateUpd) : undefined,
      quantDeleteReq: isSet(object.quantDeleteReq) ? QuantDeleteReq.fromJSON(object.quantDeleteReq) : undefined,
      quantDeleteResp: isSet(object.quantDeleteResp) ? QuantDeleteResp.fromJSON(object.quantDeleteResp) : undefined,
      quantGetReq: isSet(object.quantGetReq) ? QuantGetReq.fromJSON(object.quantGetReq) : undefined,
      quantGetResp: isSet(object.quantGetResp) ? QuantGetResp.fromJSON(object.quantGetResp) : undefined,
      quantLastOutputGetReq: isSet(object.quantLastOutputGetReq)
        ? QuantLastOutputGetReq.fromJSON(object.quantLastOutputGetReq)
        : undefined,
      quantLastOutputGetResp: isSet(object.quantLastOutputGetResp)
        ? QuantLastOutputGetResp.fromJSON(object.quantLastOutputGetResp)
        : undefined,
      quantListReq: isSet(object.quantListReq) ? QuantListReq.fromJSON(object.quantListReq) : undefined,
      quantListResp: isSet(object.quantListResp) ? QuantListResp.fromJSON(object.quantListResp) : undefined,
      quantLogGetReq: isSet(object.quantLogGetReq) ? QuantLogGetReq.fromJSON(object.quantLogGetReq) : undefined,
      quantLogGetResp: isSet(object.quantLogGetResp) ? QuantLogGetResp.fromJSON(object.quantLogGetResp) : undefined,
      quantLogListReq: isSet(object.quantLogListReq) ? QuantLogListReq.fromJSON(object.quantLogListReq) : undefined,
      quantLogListResp: isSet(object.quantLogListResp) ? QuantLogListResp.fromJSON(object.quantLogListResp) : undefined,
      quantPublishReq: isSet(object.quantPublishReq) ? QuantPublishReq.fromJSON(object.quantPublishReq) : undefined,
      quantPublishResp: isSet(object.quantPublishResp) ? QuantPublishResp.fromJSON(object.quantPublishResp) : undefined,
      quantRawDataGetReq: isSet(object.quantRawDataGetReq)
        ? QuantRawDataGetReq.fromJSON(object.quantRawDataGetReq)
        : undefined,
      quantRawDataGetResp: isSet(object.quantRawDataGetResp)
        ? QuantRawDataGetResp.fromJSON(object.quantRawDataGetResp)
        : undefined,
      quantUploadReq: isSet(object.quantUploadReq) ? QuantUploadReq.fromJSON(object.quantUploadReq) : undefined,
      quantUploadResp: isSet(object.quantUploadResp) ? QuantUploadResp.fromJSON(object.quantUploadResp) : undefined,
      regionOfInterestBulkDuplicateReq: isSet(object.regionOfInterestBulkDuplicateReq)
        ? RegionOfInterestBulkDuplicateReq.fromJSON(object.regionOfInterestBulkDuplicateReq)
        : undefined,
      regionOfInterestBulkDuplicateResp: isSet(object.regionOfInterestBulkDuplicateResp)
        ? RegionOfInterestBulkDuplicateResp.fromJSON(object.regionOfInterestBulkDuplicateResp)
        : undefined,
      regionOfInterestBulkWriteReq: isSet(object.regionOfInterestBulkWriteReq)
        ? RegionOfInterestBulkWriteReq.fromJSON(object.regionOfInterestBulkWriteReq)
        : undefined,
      regionOfInterestBulkWriteResp: isSet(object.regionOfInterestBulkWriteResp)
        ? RegionOfInterestBulkWriteResp.fromJSON(object.regionOfInterestBulkWriteResp)
        : undefined,
      regionOfInterestDeleteReq: isSet(object.regionOfInterestDeleteReq)
        ? RegionOfInterestDeleteReq.fromJSON(object.regionOfInterestDeleteReq)
        : undefined,
      regionOfInterestDeleteResp: isSet(object.regionOfInterestDeleteResp)
        ? RegionOfInterestDeleteResp.fromJSON(object.regionOfInterestDeleteResp)
        : undefined,
      regionOfInterestDisplaySettingsGetReq: isSet(object.regionOfInterestDisplaySettingsGetReq)
        ? RegionOfInterestDisplaySettingsGetReq.fromJSON(object.regionOfInterestDisplaySettingsGetReq)
        : undefined,
      regionOfInterestDisplaySettingsGetResp: isSet(object.regionOfInterestDisplaySettingsGetResp)
        ? RegionOfInterestDisplaySettingsGetResp.fromJSON(object.regionOfInterestDisplaySettingsGetResp)
        : undefined,
      regionOfInterestDisplaySettingsWriteReq: isSet(object.regionOfInterestDisplaySettingsWriteReq)
        ? RegionOfInterestDisplaySettingsWriteReq.fromJSON(object.regionOfInterestDisplaySettingsWriteReq)
        : undefined,
      regionOfInterestDisplaySettingsWriteResp: isSet(object.regionOfInterestDisplaySettingsWriteResp)
        ? RegionOfInterestDisplaySettingsWriteResp.fromJSON(object.regionOfInterestDisplaySettingsWriteResp)
        : undefined,
      regionOfInterestGetReq: isSet(object.regionOfInterestGetReq)
        ? RegionOfInterestGetReq.fromJSON(object.regionOfInterestGetReq)
        : undefined,
      regionOfInterestGetResp: isSet(object.regionOfInterestGetResp)
        ? RegionOfInterestGetResp.fromJSON(object.regionOfInterestGetResp)
        : undefined,
      regionOfInterestListReq: isSet(object.regionOfInterestListReq)
        ? RegionOfInterestListReq.fromJSON(object.regionOfInterestListReq)
        : undefined,
      regionOfInterestListResp: isSet(object.regionOfInterestListResp)
        ? RegionOfInterestListResp.fromJSON(object.regionOfInterestListResp)
        : undefined,
      regionOfInterestWriteReq: isSet(object.regionOfInterestWriteReq)
        ? RegionOfInterestWriteReq.fromJSON(object.regionOfInterestWriteReq)
        : undefined,
      regionOfInterestWriteResp: isSet(object.regionOfInterestWriteResp)
        ? RegionOfInterestWriteResp.fromJSON(object.regionOfInterestWriteResp)
        : undefined,
      restoreDBReq: isSet(object.restoreDBReq) ? RestoreDBReq.fromJSON(object.restoreDBReq) : undefined,
      restoreDBResp: isSet(object.restoreDBResp) ? RestoreDBResp.fromJSON(object.restoreDBResp) : undefined,
      reviewerMagicLinkCreateReq: isSet(object.reviewerMagicLinkCreateReq)
        ? ReviewerMagicLinkCreateReq.fromJSON(object.reviewerMagicLinkCreateReq)
        : undefined,
      reviewerMagicLinkCreateResp: isSet(object.reviewerMagicLinkCreateResp)
        ? ReviewerMagicLinkCreateResp.fromJSON(object.reviewerMagicLinkCreateResp)
        : undefined,
      reviewerMagicLinkLoginReq: isSet(object.reviewerMagicLinkLoginReq)
        ? ReviewerMagicLinkLoginReq.fromJSON(object.reviewerMagicLinkLoginReq)
        : undefined,
      reviewerMagicLinkLoginResp: isSet(object.reviewerMagicLinkLoginResp)
        ? ReviewerMagicLinkLoginResp.fromJSON(object.reviewerMagicLinkLoginResp)
        : undefined,
      runTestReq: isSet(object.runTestReq) ? RunTestReq.fromJSON(object.runTestReq) : undefined,
      runTestResp: isSet(object.runTestResp) ? RunTestResp.fromJSON(object.runTestResp) : undefined,
      scanAutoShareReq: isSet(object.scanAutoShareReq) ? ScanAutoShareReq.fromJSON(object.scanAutoShareReq) : undefined,
      scanAutoShareResp: isSet(object.scanAutoShareResp)
        ? ScanAutoShareResp.fromJSON(object.scanAutoShareResp)
        : undefined,
      scanAutoShareWriteReq: isSet(object.scanAutoShareWriteReq)
        ? ScanAutoShareWriteReq.fromJSON(object.scanAutoShareWriteReq)
        : undefined,
      scanAutoShareWriteResp: isSet(object.scanAutoShareWriteResp)
        ? ScanAutoShareWriteResp.fromJSON(object.scanAutoShareWriteResp)
        : undefined,
      scanBeamLocationsReq: isSet(object.scanBeamLocationsReq)
        ? ScanBeamLocationsReq.fromJSON(object.scanBeamLocationsReq)
        : undefined,
      scanBeamLocationsResp: isSet(object.scanBeamLocationsResp)
        ? ScanBeamLocationsResp.fromJSON(object.scanBeamLocationsResp)
        : undefined,
      scanDeleteReq: isSet(object.scanDeleteReq) ? ScanDeleteReq.fromJSON(object.scanDeleteReq) : undefined,
      scanDeleteResp: isSet(object.scanDeleteResp) ? ScanDeleteResp.fromJSON(object.scanDeleteResp) : undefined,
      scanEntryMetadataReq: isSet(object.scanEntryMetadataReq)
        ? ScanEntryMetadataReq.fromJSON(object.scanEntryMetadataReq)
        : undefined,
      scanEntryMetadataResp: isSet(object.scanEntryMetadataResp)
        ? ScanEntryMetadataResp.fromJSON(object.scanEntryMetadataResp)
        : undefined,
      scanEntryReq: isSet(object.scanEntryReq) ? ScanEntryReq.fromJSON(object.scanEntryReq) : undefined,
      scanEntryResp: isSet(object.scanEntryResp) ? ScanEntryResp.fromJSON(object.scanEntryResp) : undefined,
      scanGetReq: isSet(object.scanGetReq) ? ScanGetReq.fromJSON(object.scanGetReq) : undefined,
      scanGetResp: isSet(object.scanGetResp) ? ScanGetResp.fromJSON(object.scanGetResp) : undefined,
      scanListReq: isSet(object.scanListReq) ? ScanListReq.fromJSON(object.scanListReq) : undefined,
      scanListResp: isSet(object.scanListResp) ? ScanListResp.fromJSON(object.scanListResp) : undefined,
      scanListUpd: isSet(object.scanListUpd) ? ScanListUpd.fromJSON(object.scanListUpd) : undefined,
      scanMetaLabelsAndTypesReq: isSet(object.scanMetaLabelsAndTypesReq)
        ? ScanMetaLabelsAndTypesReq.fromJSON(object.scanMetaLabelsAndTypesReq)
        : undefined,
      scanMetaLabelsAndTypesResp: isSet(object.scanMetaLabelsAndTypesResp)
        ? ScanMetaLabelsAndTypesResp.fromJSON(object.scanMetaLabelsAndTypesResp)
        : undefined,
      scanMetaWriteReq: isSet(object.scanMetaWriteReq) ? ScanMetaWriteReq.fromJSON(object.scanMetaWriteReq) : undefined,
      scanMetaWriteResp: isSet(object.scanMetaWriteResp)
        ? ScanMetaWriteResp.fromJSON(object.scanMetaWriteResp)
        : undefined,
      scanTriggerAutoQuantReq: isSet(object.scanTriggerAutoQuantReq)
        ? ScanTriggerAutoQuantReq.fromJSON(object.scanTriggerAutoQuantReq)
        : undefined,
      scanTriggerAutoQuantResp: isSet(object.scanTriggerAutoQuantResp)
        ? ScanTriggerAutoQuantResp.fromJSON(object.scanTriggerAutoQuantResp)
        : undefined,
      scanTriggerReImportReq: isSet(object.scanTriggerReImportReq)
        ? ScanTriggerReImportReq.fromJSON(object.scanTriggerReImportReq)
        : undefined,
      scanTriggerReImportResp: isSet(object.scanTriggerReImportResp)
        ? ScanTriggerReImportResp.fromJSON(object.scanTriggerReImportResp)
        : undefined,
      scanTriggerReImportUpd: isSet(object.scanTriggerReImportUpd)
        ? ScanTriggerReImportUpd.fromJSON(object.scanTriggerReImportUpd)
        : undefined,
      scanUploadReq: isSet(object.scanUploadReq) ? ScanUploadReq.fromJSON(object.scanUploadReq) : undefined,
      scanUploadResp: isSet(object.scanUploadResp) ? ScanUploadResp.fromJSON(object.scanUploadResp) : undefined,
      scanUploadUpd: isSet(object.scanUploadUpd) ? ScanUploadUpd.fromJSON(object.scanUploadUpd) : undefined,
      screenConfigurationDeleteReq: isSet(object.screenConfigurationDeleteReq)
        ? ScreenConfigurationDeleteReq.fromJSON(object.screenConfigurationDeleteReq)
        : undefined,
      screenConfigurationDeleteResp: isSet(object.screenConfigurationDeleteResp)
        ? ScreenConfigurationDeleteResp.fromJSON(object.screenConfigurationDeleteResp)
        : undefined,
      screenConfigurationGetReq: isSet(object.screenConfigurationGetReq)
        ? ScreenConfigurationGetReq.fromJSON(object.screenConfigurationGetReq)
        : undefined,
      screenConfigurationGetResp: isSet(object.screenConfigurationGetResp)
        ? ScreenConfigurationGetResp.fromJSON(object.screenConfigurationGetResp)
        : undefined,
      screenConfigurationListReq: isSet(object.screenConfigurationListReq)
        ? ScreenConfigurationListReq.fromJSON(object.screenConfigurationListReq)
        : undefined,
      screenConfigurationListResp: isSet(object.screenConfigurationListResp)
        ? ScreenConfigurationListResp.fromJSON(object.screenConfigurationListResp)
        : undefined,
      screenConfigurationWriteReq: isSet(object.screenConfigurationWriteReq)
        ? ScreenConfigurationWriteReq.fromJSON(object.screenConfigurationWriteReq)
        : undefined,
      screenConfigurationWriteResp: isSet(object.screenConfigurationWriteResp)
        ? ScreenConfigurationWriteResp.fromJSON(object.screenConfigurationWriteResp)
        : undefined,
      selectedImagePixelsReq: isSet(object.selectedImagePixelsReq)
        ? SelectedImagePixelsReq.fromJSON(object.selectedImagePixelsReq)
        : undefined,
      selectedImagePixelsResp: isSet(object.selectedImagePixelsResp)
        ? SelectedImagePixelsResp.fromJSON(object.selectedImagePixelsResp)
        : undefined,
      selectedImagePixelsWriteReq: isSet(object.selectedImagePixelsWriteReq)
        ? SelectedImagePixelsWriteReq.fromJSON(object.selectedImagePixelsWriteReq)
        : undefined,
      selectedImagePixelsWriteResp: isSet(object.selectedImagePixelsWriteResp)
        ? SelectedImagePixelsWriteResp.fromJSON(object.selectedImagePixelsWriteResp)
        : undefined,
      selectedScanEntriesReq: isSet(object.selectedScanEntriesReq)
        ? SelectedScanEntriesReq.fromJSON(object.selectedScanEntriesReq)
        : undefined,
      selectedScanEntriesResp: isSet(object.selectedScanEntriesResp)
        ? SelectedScanEntriesResp.fromJSON(object.selectedScanEntriesResp)
        : undefined,
      selectedScanEntriesWriteReq: isSet(object.selectedScanEntriesWriteReq)
        ? SelectedScanEntriesWriteReq.fromJSON(object.selectedScanEntriesWriteReq)
        : undefined,
      selectedScanEntriesWriteResp: isSet(object.selectedScanEntriesWriteResp)
        ? SelectedScanEntriesWriteResp.fromJSON(object.selectedScanEntriesWriteResp)
        : undefined,
      sendUserNotificationReq: isSet(object.sendUserNotificationReq)
        ? SendUserNotificationReq.fromJSON(object.sendUserNotificationReq)
        : undefined,
      sendUserNotificationResp: isSet(object.sendUserNotificationResp)
        ? SendUserNotificationResp.fromJSON(object.sendUserNotificationResp)
        : undefined,
      spectrumReq: isSet(object.spectrumReq) ? SpectrumReq.fromJSON(object.spectrumReq) : undefined,
      spectrumResp: isSet(object.spectrumResp) ? SpectrumResp.fromJSON(object.spectrumResp) : undefined,
      tagCreateReq: isSet(object.tagCreateReq) ? TagCreateReq.fromJSON(object.tagCreateReq) : undefined,
      tagCreateResp: isSet(object.tagCreateResp) ? TagCreateResp.fromJSON(object.tagCreateResp) : undefined,
      tagDeleteReq: isSet(object.tagDeleteReq) ? TagDeleteReq.fromJSON(object.tagDeleteReq) : undefined,
      tagDeleteResp: isSet(object.tagDeleteResp) ? TagDeleteResp.fromJSON(object.tagDeleteResp) : undefined,
      tagListReq: isSet(object.tagListReq) ? TagListReq.fromJSON(object.tagListReq) : undefined,
      tagListResp: isSet(object.tagListResp) ? TagListResp.fromJSON(object.tagListResp) : undefined,
      userAddRoleReq: isSet(object.userAddRoleReq) ? UserAddRoleReq.fromJSON(object.userAddRoleReq) : undefined,
      userAddRoleResp: isSet(object.userAddRoleResp) ? UserAddRoleResp.fromJSON(object.userAddRoleResp) : undefined,
      userDeleteRoleReq: isSet(object.userDeleteRoleReq)
        ? UserDeleteRoleReq.fromJSON(object.userDeleteRoleReq)
        : undefined,
      userDeleteRoleResp: isSet(object.userDeleteRoleResp)
        ? UserDeleteRoleResp.fromJSON(object.userDeleteRoleResp)
        : undefined,
      userDetailsReq: isSet(object.userDetailsReq) ? UserDetailsReq.fromJSON(object.userDetailsReq) : undefined,
      userDetailsResp: isSet(object.userDetailsResp) ? UserDetailsResp.fromJSON(object.userDetailsResp) : undefined,
      userDetailsWriteReq: isSet(object.userDetailsWriteReq)
        ? UserDetailsWriteReq.fromJSON(object.userDetailsWriteReq)
        : undefined,
      userDetailsWriteResp: isSet(object.userDetailsWriteResp)
        ? UserDetailsWriteResp.fromJSON(object.userDetailsWriteResp)
        : undefined,
      userGroupAddAdminReq: isSet(object.userGroupAddAdminReq)
        ? UserGroupAddAdminReq.fromJSON(object.userGroupAddAdminReq)
        : undefined,
      userGroupAddAdminResp: isSet(object.userGroupAddAdminResp)
        ? UserGroupAddAdminResp.fromJSON(object.userGroupAddAdminResp)
        : undefined,
      userGroupAddMemberReq: isSet(object.userGroupAddMemberReq)
        ? UserGroupAddMemberReq.fromJSON(object.userGroupAddMemberReq)
        : undefined,
      userGroupAddMemberResp: isSet(object.userGroupAddMemberResp)
        ? UserGroupAddMemberResp.fromJSON(object.userGroupAddMemberResp)
        : undefined,
      userGroupAddViewerReq: isSet(object.userGroupAddViewerReq)
        ? UserGroupAddViewerReq.fromJSON(object.userGroupAddViewerReq)
        : undefined,
      userGroupAddViewerResp: isSet(object.userGroupAddViewerResp)
        ? UserGroupAddViewerResp.fromJSON(object.userGroupAddViewerResp)
        : undefined,
      userGroupCreateReq: isSet(object.userGroupCreateReq)
        ? UserGroupCreateReq.fromJSON(object.userGroupCreateReq)
        : undefined,
      userGroupCreateResp: isSet(object.userGroupCreateResp)
        ? UserGroupCreateResp.fromJSON(object.userGroupCreateResp)
        : undefined,
      userGroupDeleteAdminReq: isSet(object.userGroupDeleteAdminReq)
        ? UserGroupDeleteAdminReq.fromJSON(object.userGroupDeleteAdminReq)
        : undefined,
      userGroupDeleteAdminResp: isSet(object.userGroupDeleteAdminResp)
        ? UserGroupDeleteAdminResp.fromJSON(object.userGroupDeleteAdminResp)
        : undefined,
      userGroupDeleteMemberReq: isSet(object.userGroupDeleteMemberReq)
        ? UserGroupDeleteMemberReq.fromJSON(object.userGroupDeleteMemberReq)
        : undefined,
      userGroupDeleteMemberResp: isSet(object.userGroupDeleteMemberResp)
        ? UserGroupDeleteMemberResp.fromJSON(object.userGroupDeleteMemberResp)
        : undefined,
      userGroupDeleteReq: isSet(object.userGroupDeleteReq)
        ? UserGroupDeleteReq.fromJSON(object.userGroupDeleteReq)
        : undefined,
      userGroupDeleteResp: isSet(object.userGroupDeleteResp)
        ? UserGroupDeleteResp.fromJSON(object.userGroupDeleteResp)
        : undefined,
      userGroupDeleteViewerReq: isSet(object.userGroupDeleteViewerReq)
        ? UserGroupDeleteViewerReq.fromJSON(object.userGroupDeleteViewerReq)
        : undefined,
      userGroupDeleteViewerResp: isSet(object.userGroupDeleteViewerResp)
        ? UserGroupDeleteViewerResp.fromJSON(object.userGroupDeleteViewerResp)
        : undefined,
      userGroupEditDetailsReq: isSet(object.userGroupEditDetailsReq)
        ? UserGroupEditDetailsReq.fromJSON(object.userGroupEditDetailsReq)
        : undefined,
      userGroupEditDetailsResp: isSet(object.userGroupEditDetailsResp)
        ? UserGroupEditDetailsResp.fromJSON(object.userGroupEditDetailsResp)
        : undefined,
      userGroupIgnoreJoinReq: isSet(object.userGroupIgnoreJoinReq)
        ? UserGroupIgnoreJoinReq.fromJSON(object.userGroupIgnoreJoinReq)
        : undefined,
      userGroupIgnoreJoinResp: isSet(object.userGroupIgnoreJoinResp)
        ? UserGroupIgnoreJoinResp.fromJSON(object.userGroupIgnoreJoinResp)
        : undefined,
      userGroupJoinListReq: isSet(object.userGroupJoinListReq)
        ? UserGroupJoinListReq.fromJSON(object.userGroupJoinListReq)
        : undefined,
      userGroupJoinListResp: isSet(object.userGroupJoinListResp)
        ? UserGroupJoinListResp.fromJSON(object.userGroupJoinListResp)
        : undefined,
      userGroupJoinReq: isSet(object.userGroupJoinReq) ? UserGroupJoinReq.fromJSON(object.userGroupJoinReq) : undefined,
      userGroupJoinResp: isSet(object.userGroupJoinResp)
        ? UserGroupJoinResp.fromJSON(object.userGroupJoinResp)
        : undefined,
      userGroupListJoinableReq: isSet(object.userGroupListJoinableReq)
        ? UserGroupListJoinableReq.fromJSON(object.userGroupListJoinableReq)
        : undefined,
      userGroupListJoinableResp: isSet(object.userGroupListJoinableResp)
        ? UserGroupListJoinableResp.fromJSON(object.userGroupListJoinableResp)
        : undefined,
      userGroupListReq: isSet(object.userGroupListReq) ? UserGroupListReq.fromJSON(object.userGroupListReq) : undefined,
      userGroupListResp: isSet(object.userGroupListResp)
        ? UserGroupListResp.fromJSON(object.userGroupListResp)
        : undefined,
      userGroupReq: isSet(object.userGroupReq) ? UserGroupReq.fromJSON(object.userGroupReq) : undefined,
      userGroupResp: isSet(object.userGroupResp) ? UserGroupResp.fromJSON(object.userGroupResp) : undefined,
      userImpersonateGetReq: isSet(object.userImpersonateGetReq)
        ? UserImpersonateGetReq.fromJSON(object.userImpersonateGetReq)
        : undefined,
      userImpersonateGetResp: isSet(object.userImpersonateGetResp)
        ? UserImpersonateGetResp.fromJSON(object.userImpersonateGetResp)
        : undefined,
      userImpersonateReq: isSet(object.userImpersonateReq)
        ? UserImpersonateReq.fromJSON(object.userImpersonateReq)
        : undefined,
      userImpersonateResp: isSet(object.userImpersonateResp)
        ? UserImpersonateResp.fromJSON(object.userImpersonateResp)
        : undefined,
      userListReq: isSet(object.userListReq) ? UserListReq.fromJSON(object.userListReq) : undefined,
      userListResp: isSet(object.userListResp) ? UserListResp.fromJSON(object.userListResp) : undefined,
      userNotificationSettingsReq: isSet(object.userNotificationSettingsReq)
        ? UserNotificationSettingsReq.fromJSON(object.userNotificationSettingsReq)
        : undefined,
      userNotificationSettingsResp: isSet(object.userNotificationSettingsResp)
        ? UserNotificationSettingsResp.fromJSON(object.userNotificationSettingsResp)
        : undefined,
      userNotificationSettingsUpd: isSet(object.userNotificationSettingsUpd)
        ? UserNotificationSettingsUpd.fromJSON(object.userNotificationSettingsUpd)
        : undefined,
      userNotificationSettingsWriteReq: isSet(object.userNotificationSettingsWriteReq)
        ? UserNotificationSettingsWriteReq.fromJSON(object.userNotificationSettingsWriteReq)
        : undefined,
      userNotificationSettingsWriteResp: isSet(object.userNotificationSettingsWriteResp)
        ? UserNotificationSettingsWriteResp.fromJSON(object.userNotificationSettingsWriteResp)
        : undefined,
      userRoleListReq: isSet(object.userRoleListReq) ? UserRoleListReq.fromJSON(object.userRoleListReq) : undefined,
      userRoleListResp: isSet(object.userRoleListResp) ? UserRoleListResp.fromJSON(object.userRoleListResp) : undefined,
      userRolesListReq: isSet(object.userRolesListReq) ? UserRolesListReq.fromJSON(object.userRolesListReq) : undefined,
      userRolesListResp: isSet(object.userRolesListResp)
        ? UserRolesListResp.fromJSON(object.userRolesListResp)
        : undefined,
      userSearchReq: isSet(object.userSearchReq) ? UserSearchReq.fromJSON(object.userSearchReq) : undefined,
      userSearchResp: isSet(object.userSearchResp) ? UserSearchResp.fromJSON(object.userSearchResp) : undefined,
      widgetDataGetReq: isSet(object.widgetDataGetReq) ? WidgetDataGetReq.fromJSON(object.widgetDataGetReq) : undefined,
      widgetDataGetResp: isSet(object.widgetDataGetResp)
        ? WidgetDataGetResp.fromJSON(object.widgetDataGetResp)
        : undefined,
      widgetDataWriteReq: isSet(object.widgetDataWriteReq)
        ? WidgetDataWriteReq.fromJSON(object.widgetDataWriteReq)
        : undefined,
      widgetDataWriteResp: isSet(object.widgetDataWriteResp)
        ? WidgetDataWriteResp.fromJSON(object.widgetDataWriteResp)
        : undefined,
      zenodoDOIGetReq: isSet(object.zenodoDOIGetReq) ? ZenodoDOIGetReq.fromJSON(object.zenodoDOIGetReq) : undefined,
      zenodoDOIGetResp: isSet(object.zenodoDOIGetResp) ? ZenodoDOIGetResp.fromJSON(object.zenodoDOIGetResp) : undefined,
    };
  },

  toJSON(message: WSMessage): unknown {
    const obj: any = {};
    message.msgId !== undefined && (obj.msgId = Math.round(message.msgId));
    message.status !== undefined && (obj.status = responseStatusToJSON(message.status));
    message.errorText !== undefined && (obj.errorText = message.errorText);
    message.backupDBReq !== undefined &&
      (obj.backupDBReq = message.backupDBReq ? BackupDBReq.toJSON(message.backupDBReq) : undefined);
    message.backupDBResp !== undefined &&
      (obj.backupDBResp = message.backupDBResp ? BackupDBResp.toJSON(message.backupDBResp) : undefined);
    message.backupDBUpd !== undefined &&
      (obj.backupDBUpd = message.backupDBUpd ? BackupDBUpd.toJSON(message.backupDBUpd) : undefined);
    message.dBAdminConfigGetReq !== undefined && (obj.dBAdminConfigGetReq = message.dBAdminConfigGetReq
      ? DBAdminConfigGetReq.toJSON(message.dBAdminConfigGetReq)
      : undefined);
    message.dBAdminConfigGetResp !== undefined && (obj.dBAdminConfigGetResp = message.dBAdminConfigGetResp
      ? DBAdminConfigGetResp.toJSON(message.dBAdminConfigGetResp)
      : undefined);
    message.dataModuleAddVersionReq !== undefined && (obj.dataModuleAddVersionReq = message.dataModuleAddVersionReq
      ? DataModuleAddVersionReq.toJSON(message.dataModuleAddVersionReq)
      : undefined);
    message.dataModuleAddVersionResp !== undefined && (obj.dataModuleAddVersionResp = message.dataModuleAddVersionResp
      ? DataModuleAddVersionResp.toJSON(message.dataModuleAddVersionResp)
      : undefined);
    message.dataModuleGetReq !== undefined &&
      (obj.dataModuleGetReq = message.dataModuleGetReq ? DataModuleGetReq.toJSON(message.dataModuleGetReq) : undefined);
    message.dataModuleGetResp !== undefined && (obj.dataModuleGetResp = message.dataModuleGetResp
      ? DataModuleGetResp.toJSON(message.dataModuleGetResp)
      : undefined);
    message.dataModuleListReq !== undefined && (obj.dataModuleListReq = message.dataModuleListReq
      ? DataModuleListReq.toJSON(message.dataModuleListReq)
      : undefined);
    message.dataModuleListResp !== undefined && (obj.dataModuleListResp = message.dataModuleListResp
      ? DataModuleListResp.toJSON(message.dataModuleListResp)
      : undefined);
    message.dataModuleWriteReq !== undefined && (obj.dataModuleWriteReq = message.dataModuleWriteReq
      ? DataModuleWriteReq.toJSON(message.dataModuleWriteReq)
      : undefined);
    message.dataModuleWriteResp !== undefined && (obj.dataModuleWriteResp = message.dataModuleWriteResp
      ? DataModuleWriteResp.toJSON(message.dataModuleWriteResp)
      : undefined);
    message.detectedDiffractionPeaksReq !== undefined &&
      (obj.detectedDiffractionPeaksReq = message.detectedDiffractionPeaksReq
        ? DetectedDiffractionPeaksReq.toJSON(message.detectedDiffractionPeaksReq)
        : undefined);
    message.detectedDiffractionPeaksResp !== undefined &&
      (obj.detectedDiffractionPeaksResp = message.detectedDiffractionPeaksResp
        ? DetectedDiffractionPeaksResp.toJSON(message.detectedDiffractionPeaksResp)
        : undefined);
    message.detectorConfigListReq !== undefined && (obj.detectorConfigListReq = message.detectorConfigListReq
      ? DetectorConfigListReq.toJSON(message.detectorConfigListReq)
      : undefined);
    message.detectorConfigListResp !== undefined && (obj.detectorConfigListResp = message.detectorConfigListResp
      ? DetectorConfigListResp.toJSON(message.detectorConfigListResp)
      : undefined);
    message.detectorConfigReq !== undefined && (obj.detectorConfigReq = message.detectorConfigReq
      ? DetectorConfigReq.toJSON(message.detectorConfigReq)
      : undefined);
    message.detectorConfigResp !== undefined && (obj.detectorConfigResp = message.detectorConfigResp
      ? DetectorConfigResp.toJSON(message.detectorConfigResp)
      : undefined);
    message.diffractionPeakManualDeleteReq !== undefined &&
      (obj.diffractionPeakManualDeleteReq = message.diffractionPeakManualDeleteReq
        ? DiffractionPeakManualDeleteReq.toJSON(message.diffractionPeakManualDeleteReq)
        : undefined);
    message.diffractionPeakManualDeleteResp !== undefined &&
      (obj.diffractionPeakManualDeleteResp = message.diffractionPeakManualDeleteResp
        ? DiffractionPeakManualDeleteResp.toJSON(message.diffractionPeakManualDeleteResp)
        : undefined);
    message.diffractionPeakManualInsertReq !== undefined &&
      (obj.diffractionPeakManualInsertReq = message.diffractionPeakManualInsertReq
        ? DiffractionPeakManualInsertReq.toJSON(message.diffractionPeakManualInsertReq)
        : undefined);
    message.diffractionPeakManualInsertResp !== undefined &&
      (obj.diffractionPeakManualInsertResp = message.diffractionPeakManualInsertResp
        ? DiffractionPeakManualInsertResp.toJSON(message.diffractionPeakManualInsertResp)
        : undefined);
    message.diffractionPeakManualListReq !== undefined &&
      (obj.diffractionPeakManualListReq = message.diffractionPeakManualListReq
        ? DiffractionPeakManualListReq.toJSON(message.diffractionPeakManualListReq)
        : undefined);
    message.diffractionPeakManualListResp !== undefined &&
      (obj.diffractionPeakManualListResp = message.diffractionPeakManualListResp
        ? DiffractionPeakManualListResp.toJSON(message.diffractionPeakManualListResp)
        : undefined);
    message.diffractionPeakStatusDeleteReq !== undefined &&
      (obj.diffractionPeakStatusDeleteReq = message.diffractionPeakStatusDeleteReq
        ? DiffractionPeakStatusDeleteReq.toJSON(message.diffractionPeakStatusDeleteReq)
        : undefined);
    message.diffractionPeakStatusDeleteResp !== undefined &&
      (obj.diffractionPeakStatusDeleteResp = message.diffractionPeakStatusDeleteResp
        ? DiffractionPeakStatusDeleteResp.toJSON(message.diffractionPeakStatusDeleteResp)
        : undefined);
    message.diffractionPeakStatusListReq !== undefined &&
      (obj.diffractionPeakStatusListReq = message.diffractionPeakStatusListReq
        ? DiffractionPeakStatusListReq.toJSON(message.diffractionPeakStatusListReq)
        : undefined);
    message.diffractionPeakStatusListResp !== undefined &&
      (obj.diffractionPeakStatusListResp = message.diffractionPeakStatusListResp
        ? DiffractionPeakStatusListResp.toJSON(message.diffractionPeakStatusListResp)
        : undefined);
    message.diffractionPeakStatusWriteReq !== undefined &&
      (obj.diffractionPeakStatusWriteReq = message.diffractionPeakStatusWriteReq
        ? DiffractionPeakStatusWriteReq.toJSON(message.diffractionPeakStatusWriteReq)
        : undefined);
    message.diffractionPeakStatusWriteResp !== undefined &&
      (obj.diffractionPeakStatusWriteResp = message.diffractionPeakStatusWriteResp
        ? DiffractionPeakStatusWriteResp.toJSON(message.diffractionPeakStatusWriteResp)
        : undefined);
    message.elementSetDeleteReq !== undefined && (obj.elementSetDeleteReq = message.elementSetDeleteReq
      ? ElementSetDeleteReq.toJSON(message.elementSetDeleteReq)
      : undefined);
    message.elementSetDeleteResp !== undefined && (obj.elementSetDeleteResp = message.elementSetDeleteResp
      ? ElementSetDeleteResp.toJSON(message.elementSetDeleteResp)
      : undefined);
    message.elementSetGetReq !== undefined &&
      (obj.elementSetGetReq = message.elementSetGetReq ? ElementSetGetReq.toJSON(message.elementSetGetReq) : undefined);
    message.elementSetGetResp !== undefined && (obj.elementSetGetResp = message.elementSetGetResp
      ? ElementSetGetResp.toJSON(message.elementSetGetResp)
      : undefined);
    message.elementSetListReq !== undefined && (obj.elementSetListReq = message.elementSetListReq
      ? ElementSetListReq.toJSON(message.elementSetListReq)
      : undefined);
    message.elementSetListResp !== undefined && (obj.elementSetListResp = message.elementSetListResp
      ? ElementSetListResp.toJSON(message.elementSetListResp)
      : undefined);
    message.elementSetWriteReq !== undefined && (obj.elementSetWriteReq = message.elementSetWriteReq
      ? ElementSetWriteReq.toJSON(message.elementSetWriteReq)
      : undefined);
    message.elementSetWriteResp !== undefined && (obj.elementSetWriteResp = message.elementSetWriteResp
      ? ElementSetWriteResp.toJSON(message.elementSetWriteResp)
      : undefined);
    message.exportFilesReq !== undefined &&
      (obj.exportFilesReq = message.exportFilesReq ? ExportFilesReq.toJSON(message.exportFilesReq) : undefined);
    message.exportFilesResp !== undefined &&
      (obj.exportFilesResp = message.exportFilesResp ? ExportFilesResp.toJSON(message.exportFilesResp) : undefined);
    message.expressionDeleteReq !== undefined && (obj.expressionDeleteReq = message.expressionDeleteReq
      ? ExpressionDeleteReq.toJSON(message.expressionDeleteReq)
      : undefined);
    message.expressionDeleteResp !== undefined && (obj.expressionDeleteResp = message.expressionDeleteResp
      ? ExpressionDeleteResp.toJSON(message.expressionDeleteResp)
      : undefined);
    message.expressionDisplaySettingsGetReq !== undefined &&
      (obj.expressionDisplaySettingsGetReq = message.expressionDisplaySettingsGetReq
        ? ExpressionDisplaySettingsGetReq.toJSON(message.expressionDisplaySettingsGetReq)
        : undefined);
    message.expressionDisplaySettingsGetResp !== undefined &&
      (obj.expressionDisplaySettingsGetResp = message.expressionDisplaySettingsGetResp
        ? ExpressionDisplaySettingsGetResp.toJSON(message.expressionDisplaySettingsGetResp)
        : undefined);
    message.expressionDisplaySettingsWriteReq !== undefined &&
      (obj.expressionDisplaySettingsWriteReq = message.expressionDisplaySettingsWriteReq
        ? ExpressionDisplaySettingsWriteReq.toJSON(message.expressionDisplaySettingsWriteReq)
        : undefined);
    message.expressionDisplaySettingsWriteResp !== undefined &&
      (obj.expressionDisplaySettingsWriteResp = message.expressionDisplaySettingsWriteResp
        ? ExpressionDisplaySettingsWriteResp.toJSON(message.expressionDisplaySettingsWriteResp)
        : undefined);
    message.expressionGetReq !== undefined &&
      (obj.expressionGetReq = message.expressionGetReq ? ExpressionGetReq.toJSON(message.expressionGetReq) : undefined);
    message.expressionGetResp !== undefined && (obj.expressionGetResp = message.expressionGetResp
      ? ExpressionGetResp.toJSON(message.expressionGetResp)
      : undefined);
    message.expressionGroupDeleteReq !== undefined && (obj.expressionGroupDeleteReq = message.expressionGroupDeleteReq
      ? ExpressionGroupDeleteReq.toJSON(message.expressionGroupDeleteReq)
      : undefined);
    message.expressionGroupDeleteResp !== undefined &&
      (obj.expressionGroupDeleteResp = message.expressionGroupDeleteResp
        ? ExpressionGroupDeleteResp.toJSON(message.expressionGroupDeleteResp)
        : undefined);
    message.expressionGroupGetReq !== undefined && (obj.expressionGroupGetReq = message.expressionGroupGetReq
      ? ExpressionGroupGetReq.toJSON(message.expressionGroupGetReq)
      : undefined);
    message.expressionGroupGetResp !== undefined && (obj.expressionGroupGetResp = message.expressionGroupGetResp
      ? ExpressionGroupGetResp.toJSON(message.expressionGroupGetResp)
      : undefined);
    message.expressionGroupListReq !== undefined && (obj.expressionGroupListReq = message.expressionGroupListReq
      ? ExpressionGroupListReq.toJSON(message.expressionGroupListReq)
      : undefined);
    message.expressionGroupListResp !== undefined && (obj.expressionGroupListResp = message.expressionGroupListResp
      ? ExpressionGroupListResp.toJSON(message.expressionGroupListResp)
      : undefined);
    message.expressionGroupWriteReq !== undefined && (obj.expressionGroupWriteReq = message.expressionGroupWriteReq
      ? ExpressionGroupWriteReq.toJSON(message.expressionGroupWriteReq)
      : undefined);
    message.expressionGroupWriteResp !== undefined && (obj.expressionGroupWriteResp = message.expressionGroupWriteResp
      ? ExpressionGroupWriteResp.toJSON(message.expressionGroupWriteResp)
      : undefined);
    message.expressionListReq !== undefined && (obj.expressionListReq = message.expressionListReq
      ? ExpressionListReq.toJSON(message.expressionListReq)
      : undefined);
    message.expressionListResp !== undefined && (obj.expressionListResp = message.expressionListResp
      ? ExpressionListResp.toJSON(message.expressionListResp)
      : undefined);
    message.expressionWriteExecStatReq !== undefined &&
      (obj.expressionWriteExecStatReq = message.expressionWriteExecStatReq
        ? ExpressionWriteExecStatReq.toJSON(message.expressionWriteExecStatReq)
        : undefined);
    message.expressionWriteExecStatResp !== undefined &&
      (obj.expressionWriteExecStatResp = message.expressionWriteExecStatResp
        ? ExpressionWriteExecStatResp.toJSON(message.expressionWriteExecStatResp)
        : undefined);
    message.expressionWriteReq !== undefined && (obj.expressionWriteReq = message.expressionWriteReq
      ? ExpressionWriteReq.toJSON(message.expressionWriteReq)
      : undefined);
    message.expressionWriteResp !== undefined && (obj.expressionWriteResp = message.expressionWriteResp
      ? ExpressionWriteResp.toJSON(message.expressionWriteResp)
      : undefined);
    message.getOwnershipReq !== undefined &&
      (obj.getOwnershipReq = message.getOwnershipReq ? GetOwnershipReq.toJSON(message.getOwnershipReq) : undefined);
    message.getOwnershipResp !== undefined &&
      (obj.getOwnershipResp = message.getOwnershipResp ? GetOwnershipResp.toJSON(message.getOwnershipResp) : undefined);
    message.imageBeamLocationVersionsReq !== undefined &&
      (obj.imageBeamLocationVersionsReq = message.imageBeamLocationVersionsReq
        ? ImageBeamLocationVersionsReq.toJSON(message.imageBeamLocationVersionsReq)
        : undefined);
    message.imageBeamLocationVersionsResp !== undefined &&
      (obj.imageBeamLocationVersionsResp = message.imageBeamLocationVersionsResp
        ? ImageBeamLocationVersionsResp.toJSON(message.imageBeamLocationVersionsResp)
        : undefined);
    message.imageBeamLocationsReq !== undefined && (obj.imageBeamLocationsReq = message.imageBeamLocationsReq
      ? ImageBeamLocationsReq.toJSON(message.imageBeamLocationsReq)
      : undefined);
    message.imageBeamLocationsResp !== undefined && (obj.imageBeamLocationsResp = message.imageBeamLocationsResp
      ? ImageBeamLocationsResp.toJSON(message.imageBeamLocationsResp)
      : undefined);
    message.imageDeleteReq !== undefined &&
      (obj.imageDeleteReq = message.imageDeleteReq ? ImageDeleteReq.toJSON(message.imageDeleteReq) : undefined);
    message.imageDeleteResp !== undefined &&
      (obj.imageDeleteResp = message.imageDeleteResp ? ImageDeleteResp.toJSON(message.imageDeleteResp) : undefined);
    message.imageGetDefaultReq !== undefined && (obj.imageGetDefaultReq = message.imageGetDefaultReq
      ? ImageGetDefaultReq.toJSON(message.imageGetDefaultReq)
      : undefined);
    message.imageGetDefaultResp !== undefined && (obj.imageGetDefaultResp = message.imageGetDefaultResp
      ? ImageGetDefaultResp.toJSON(message.imageGetDefaultResp)
      : undefined);
    message.imageGetReq !== undefined &&
      (obj.imageGetReq = message.imageGetReq ? ImageGetReq.toJSON(message.imageGetReq) : undefined);
    message.imageGetResp !== undefined &&
      (obj.imageGetResp = message.imageGetResp ? ImageGetResp.toJSON(message.imageGetResp) : undefined);
    message.imageListReq !== undefined &&
      (obj.imageListReq = message.imageListReq ? ImageListReq.toJSON(message.imageListReq) : undefined);
    message.imageListResp !== undefined &&
      (obj.imageListResp = message.imageListResp ? ImageListResp.toJSON(message.imageListResp) : undefined);
    message.imageListUpd !== undefined &&
      (obj.imageListUpd = message.imageListUpd ? ImageListUpd.toJSON(message.imageListUpd) : undefined);
    message.imageSetDefaultReq !== undefined && (obj.imageSetDefaultReq = message.imageSetDefaultReq
      ? ImageSetDefaultReq.toJSON(message.imageSetDefaultReq)
      : undefined);
    message.imageSetDefaultResp !== undefined && (obj.imageSetDefaultResp = message.imageSetDefaultResp
      ? ImageSetDefaultResp.toJSON(message.imageSetDefaultResp)
      : undefined);
    message.imageSetMatchTransformReq !== undefined &&
      (obj.imageSetMatchTransformReq = message.imageSetMatchTransformReq
        ? ImageSetMatchTransformReq.toJSON(message.imageSetMatchTransformReq)
        : undefined);
    message.imageSetMatchTransformResp !== undefined &&
      (obj.imageSetMatchTransformResp = message.imageSetMatchTransformResp
        ? ImageSetMatchTransformResp.toJSON(message.imageSetMatchTransformResp)
        : undefined);
    message.importMarsViewerImageReq !== undefined && (obj.importMarsViewerImageReq = message.importMarsViewerImageReq
      ? ImportMarsViewerImageReq.toJSON(message.importMarsViewerImageReq)
      : undefined);
    message.importMarsViewerImageResp !== undefined &&
      (obj.importMarsViewerImageResp = message.importMarsViewerImageResp
        ? ImportMarsViewerImageResp.toJSON(message.importMarsViewerImageResp)
        : undefined);
    message.importMarsViewerImageUpd !== undefined && (obj.importMarsViewerImageUpd = message.importMarsViewerImageUpd
      ? ImportMarsViewerImageUpd.toJSON(message.importMarsViewerImageUpd)
      : undefined);
    message.jobListReq !== undefined &&
      (obj.jobListReq = message.jobListReq ? JobListReq.toJSON(message.jobListReq) : undefined);
    message.jobListResp !== undefined &&
      (obj.jobListResp = message.jobListResp ? JobListResp.toJSON(message.jobListResp) : undefined);
    message.jobListUpd !== undefined &&
      (obj.jobListUpd = message.jobListUpd ? JobListUpd.toJSON(message.jobListUpd) : undefined);
    message.logGetLevelReq !== undefined &&
      (obj.logGetLevelReq = message.logGetLevelReq ? LogGetLevelReq.toJSON(message.logGetLevelReq) : undefined);
    message.logGetLevelResp !== undefined &&
      (obj.logGetLevelResp = message.logGetLevelResp ? LogGetLevelResp.toJSON(message.logGetLevelResp) : undefined);
    message.logReadReq !== undefined &&
      (obj.logReadReq = message.logReadReq ? LogReadReq.toJSON(message.logReadReq) : undefined);
    message.logReadResp !== undefined &&
      (obj.logReadResp = message.logReadResp ? LogReadResp.toJSON(message.logReadResp) : undefined);
    message.logSetLevelReq !== undefined &&
      (obj.logSetLevelReq = message.logSetLevelReq ? LogSetLevelReq.toJSON(message.logSetLevelReq) : undefined);
    message.logSetLevelResp !== undefined &&
      (obj.logSetLevelResp = message.logSetLevelResp ? LogSetLevelResp.toJSON(message.logSetLevelResp) : undefined);
    message.memoiseGetReq !== undefined &&
      (obj.memoiseGetReq = message.memoiseGetReq ? MemoiseGetReq.toJSON(message.memoiseGetReq) : undefined);
    message.memoiseGetResp !== undefined &&
      (obj.memoiseGetResp = message.memoiseGetResp ? MemoiseGetResp.toJSON(message.memoiseGetResp) : undefined);
    message.memoiseWriteReq !== undefined &&
      (obj.memoiseWriteReq = message.memoiseWriteReq ? MemoiseWriteReq.toJSON(message.memoiseWriteReq) : undefined);
    message.memoiseWriteResp !== undefined &&
      (obj.memoiseWriteResp = message.memoiseWriteResp ? MemoiseWriteResp.toJSON(message.memoiseWriteResp) : undefined);
    message.multiQuantCompareReq !== undefined && (obj.multiQuantCompareReq = message.multiQuantCompareReq
      ? MultiQuantCompareReq.toJSON(message.multiQuantCompareReq)
      : undefined);
    message.multiQuantCompareResp !== undefined && (obj.multiQuantCompareResp = message.multiQuantCompareResp
      ? MultiQuantCompareResp.toJSON(message.multiQuantCompareResp)
      : undefined);
    message.notificationDismissReq !== undefined && (obj.notificationDismissReq = message.notificationDismissReq
      ? NotificationDismissReq.toJSON(message.notificationDismissReq)
      : undefined);
    message.notificationDismissResp !== undefined && (obj.notificationDismissResp = message.notificationDismissResp
      ? NotificationDismissResp.toJSON(message.notificationDismissResp)
      : undefined);
    message.notificationReq !== undefined &&
      (obj.notificationReq = message.notificationReq ? NotificationReq.toJSON(message.notificationReq) : undefined);
    message.notificationResp !== undefined &&
      (obj.notificationResp = message.notificationResp ? NotificationResp.toJSON(message.notificationResp) : undefined);
    message.notificationUpd !== undefined &&
      (obj.notificationUpd = message.notificationUpd ? NotificationUpd.toJSON(message.notificationUpd) : undefined);
    message.objectEditAccessReq !== undefined && (obj.objectEditAccessReq = message.objectEditAccessReq
      ? ObjectEditAccessReq.toJSON(message.objectEditAccessReq)
      : undefined);
    message.objectEditAccessResp !== undefined && (obj.objectEditAccessResp = message.objectEditAccessResp
      ? ObjectEditAccessResp.toJSON(message.objectEditAccessResp)
      : undefined);
    message.piquantConfigListReq !== undefined && (obj.piquantConfigListReq = message.piquantConfigListReq
      ? PiquantConfigListReq.toJSON(message.piquantConfigListReq)
      : undefined);
    message.piquantConfigListResp !== undefined && (obj.piquantConfigListResp = message.piquantConfigListResp
      ? PiquantConfigListResp.toJSON(message.piquantConfigListResp)
      : undefined);
    message.piquantConfigVersionReq !== undefined && (obj.piquantConfigVersionReq = message.piquantConfigVersionReq
      ? PiquantConfigVersionReq.toJSON(message.piquantConfigVersionReq)
      : undefined);
    message.piquantConfigVersionResp !== undefined && (obj.piquantConfigVersionResp = message.piquantConfigVersionResp
      ? PiquantConfigVersionResp.toJSON(message.piquantConfigVersionResp)
      : undefined);
    message.piquantConfigVersionsListReq !== undefined &&
      (obj.piquantConfigVersionsListReq = message.piquantConfigVersionsListReq
        ? PiquantConfigVersionsListReq.toJSON(message.piquantConfigVersionsListReq)
        : undefined);
    message.piquantConfigVersionsListResp !== undefined &&
      (obj.piquantConfigVersionsListResp = message.piquantConfigVersionsListResp
        ? PiquantConfigVersionsListResp.toJSON(message.piquantConfigVersionsListResp)
        : undefined);
    message.piquantCurrentVersionReq !== undefined && (obj.piquantCurrentVersionReq = message.piquantCurrentVersionReq
      ? PiquantCurrentVersionReq.toJSON(message.piquantCurrentVersionReq)
      : undefined);
    message.piquantCurrentVersionResp !== undefined &&
      (obj.piquantCurrentVersionResp = message.piquantCurrentVersionResp
        ? PiquantCurrentVersionResp.toJSON(message.piquantCurrentVersionResp)
        : undefined);
    message.piquantVersionListReq !== undefined && (obj.piquantVersionListReq = message.piquantVersionListReq
      ? PiquantVersionListReq.toJSON(message.piquantVersionListReq)
      : undefined);
    message.piquantVersionListResp !== undefined && (obj.piquantVersionListResp = message.piquantVersionListResp
      ? PiquantVersionListResp.toJSON(message.piquantVersionListResp)
      : undefined);
    message.piquantWriteCurrentVersionReq !== undefined &&
      (obj.piquantWriteCurrentVersionReq = message.piquantWriteCurrentVersionReq
        ? PiquantWriteCurrentVersionReq.toJSON(message.piquantWriteCurrentVersionReq)
        : undefined);
    message.piquantWriteCurrentVersionResp !== undefined &&
      (obj.piquantWriteCurrentVersionResp = message.piquantWriteCurrentVersionResp
        ? PiquantWriteCurrentVersionResp.toJSON(message.piquantWriteCurrentVersionResp)
        : undefined);
    message.pseudoIntensityReq !== undefined && (obj.pseudoIntensityReq = message.pseudoIntensityReq
      ? PseudoIntensityReq.toJSON(message.pseudoIntensityReq)
      : undefined);
    message.pseudoIntensityResp !== undefined && (obj.pseudoIntensityResp = message.pseudoIntensityResp
      ? PseudoIntensityResp.toJSON(message.pseudoIntensityResp)
      : undefined);
    message.publishExpressionToZenodoReq !== undefined &&
      (obj.publishExpressionToZenodoReq = message.publishExpressionToZenodoReq
        ? PublishExpressionToZenodoReq.toJSON(message.publishExpressionToZenodoReq)
        : undefined);
    message.publishExpressionToZenodoResp !== undefined &&
      (obj.publishExpressionToZenodoResp = message.publishExpressionToZenodoResp
        ? PublishExpressionToZenodoResp.toJSON(message.publishExpressionToZenodoResp)
        : undefined);
    message.quantBlessReq !== undefined &&
      (obj.quantBlessReq = message.quantBlessReq ? QuantBlessReq.toJSON(message.quantBlessReq) : undefined);
    message.quantBlessResp !== undefined &&
      (obj.quantBlessResp = message.quantBlessResp ? QuantBlessResp.toJSON(message.quantBlessResp) : undefined);
    message.quantCombineListGetReq !== undefined && (obj.quantCombineListGetReq = message.quantCombineListGetReq
      ? QuantCombineListGetReq.toJSON(message.quantCombineListGetReq)
      : undefined);
    message.quantCombineListGetResp !== undefined && (obj.quantCombineListGetResp = message.quantCombineListGetResp
      ? QuantCombineListGetResp.toJSON(message.quantCombineListGetResp)
      : undefined);
    message.quantCombineListWriteReq !== undefined && (obj.quantCombineListWriteReq = message.quantCombineListWriteReq
      ? QuantCombineListWriteReq.toJSON(message.quantCombineListWriteReq)
      : undefined);
    message.quantCombineListWriteResp !== undefined &&
      (obj.quantCombineListWriteResp = message.quantCombineListWriteResp
        ? QuantCombineListWriteResp.toJSON(message.quantCombineListWriteResp)
        : undefined);
    message.quantCombineReq !== undefined &&
      (obj.quantCombineReq = message.quantCombineReq ? QuantCombineReq.toJSON(message.quantCombineReq) : undefined);
    message.quantCombineResp !== undefined &&
      (obj.quantCombineResp = message.quantCombineResp ? QuantCombineResp.toJSON(message.quantCombineResp) : undefined);
    message.quantCreateReq !== undefined &&
      (obj.quantCreateReq = message.quantCreateReq ? QuantCreateReq.toJSON(message.quantCreateReq) : undefined);
    message.quantCreateResp !== undefined &&
      (obj.quantCreateResp = message.quantCreateResp ? QuantCreateResp.toJSON(message.quantCreateResp) : undefined);
    message.quantCreateUpd !== undefined &&
      (obj.quantCreateUpd = message.quantCreateUpd ? QuantCreateUpd.toJSON(message.quantCreateUpd) : undefined);
    message.quantDeleteReq !== undefined &&
      (obj.quantDeleteReq = message.quantDeleteReq ? QuantDeleteReq.toJSON(message.quantDeleteReq) : undefined);
    message.quantDeleteResp !== undefined &&
      (obj.quantDeleteResp = message.quantDeleteResp ? QuantDeleteResp.toJSON(message.quantDeleteResp) : undefined);
    message.quantGetReq !== undefined &&
      (obj.quantGetReq = message.quantGetReq ? QuantGetReq.toJSON(message.quantGetReq) : undefined);
    message.quantGetResp !== undefined &&
      (obj.quantGetResp = message.quantGetResp ? QuantGetResp.toJSON(message.quantGetResp) : undefined);
    message.quantLastOutputGetReq !== undefined && (obj.quantLastOutputGetReq = message.quantLastOutputGetReq
      ? QuantLastOutputGetReq.toJSON(message.quantLastOutputGetReq)
      : undefined);
    message.quantLastOutputGetResp !== undefined && (obj.quantLastOutputGetResp = message.quantLastOutputGetResp
      ? QuantLastOutputGetResp.toJSON(message.quantLastOutputGetResp)
      : undefined);
    message.quantListReq !== undefined &&
      (obj.quantListReq = message.quantListReq ? QuantListReq.toJSON(message.quantListReq) : undefined);
    message.quantListResp !== undefined &&
      (obj.quantListResp = message.quantListResp ? QuantListResp.toJSON(message.quantListResp) : undefined);
    message.quantLogGetReq !== undefined &&
      (obj.quantLogGetReq = message.quantLogGetReq ? QuantLogGetReq.toJSON(message.quantLogGetReq) : undefined);
    message.quantLogGetResp !== undefined &&
      (obj.quantLogGetResp = message.quantLogGetResp ? QuantLogGetResp.toJSON(message.quantLogGetResp) : undefined);
    message.quantLogListReq !== undefined &&
      (obj.quantLogListReq = message.quantLogListReq ? QuantLogListReq.toJSON(message.quantLogListReq) : undefined);
    message.quantLogListResp !== undefined &&
      (obj.quantLogListResp = message.quantLogListResp ? QuantLogListResp.toJSON(message.quantLogListResp) : undefined);
    message.quantPublishReq !== undefined &&
      (obj.quantPublishReq = message.quantPublishReq ? QuantPublishReq.toJSON(message.quantPublishReq) : undefined);
    message.quantPublishResp !== undefined &&
      (obj.quantPublishResp = message.quantPublishResp ? QuantPublishResp.toJSON(message.quantPublishResp) : undefined);
    message.quantRawDataGetReq !== undefined && (obj.quantRawDataGetReq = message.quantRawDataGetReq
      ? QuantRawDataGetReq.toJSON(message.quantRawDataGetReq)
      : undefined);
    message.quantRawDataGetResp !== undefined && (obj.quantRawDataGetResp = message.quantRawDataGetResp
      ? QuantRawDataGetResp.toJSON(message.quantRawDataGetResp)
      : undefined);
    message.quantUploadReq !== undefined &&
      (obj.quantUploadReq = message.quantUploadReq ? QuantUploadReq.toJSON(message.quantUploadReq) : undefined);
    message.quantUploadResp !== undefined &&
      (obj.quantUploadResp = message.quantUploadResp ? QuantUploadResp.toJSON(message.quantUploadResp) : undefined);
    message.regionOfInterestBulkDuplicateReq !== undefined &&
      (obj.regionOfInterestBulkDuplicateReq = message.regionOfInterestBulkDuplicateReq
        ? RegionOfInterestBulkDuplicateReq.toJSON(message.regionOfInterestBulkDuplicateReq)
        : undefined);
    message.regionOfInterestBulkDuplicateResp !== undefined &&
      (obj.regionOfInterestBulkDuplicateResp = message.regionOfInterestBulkDuplicateResp
        ? RegionOfInterestBulkDuplicateResp.toJSON(message.regionOfInterestBulkDuplicateResp)
        : undefined);
    message.regionOfInterestBulkWriteReq !== undefined &&
      (obj.regionOfInterestBulkWriteReq = message.regionOfInterestBulkWriteReq
        ? RegionOfInterestBulkWriteReq.toJSON(message.regionOfInterestBulkWriteReq)
        : undefined);
    message.regionOfInterestBulkWriteResp !== undefined &&
      (obj.regionOfInterestBulkWriteResp = message.regionOfInterestBulkWriteResp
        ? RegionOfInterestBulkWriteResp.toJSON(message.regionOfInterestBulkWriteResp)
        : undefined);
    message.regionOfInterestDeleteReq !== undefined &&
      (obj.regionOfInterestDeleteReq = message.regionOfInterestDeleteReq
        ? RegionOfInterestDeleteReq.toJSON(message.regionOfInterestDeleteReq)
        : undefined);
    message.regionOfInterestDeleteResp !== undefined &&
      (obj.regionOfInterestDeleteResp = message.regionOfInterestDeleteResp
        ? RegionOfInterestDeleteResp.toJSON(message.regionOfInterestDeleteResp)
        : undefined);
    message.regionOfInterestDisplaySettingsGetReq !== undefined &&
      (obj.regionOfInterestDisplaySettingsGetReq = message.regionOfInterestDisplaySettingsGetReq
        ? RegionOfInterestDisplaySettingsGetReq.toJSON(message.regionOfInterestDisplaySettingsGetReq)
        : undefined);
    message.regionOfInterestDisplaySettingsGetResp !== undefined &&
      (obj.regionOfInterestDisplaySettingsGetResp = message.regionOfInterestDisplaySettingsGetResp
        ? RegionOfInterestDisplaySettingsGetResp.toJSON(message.regionOfInterestDisplaySettingsGetResp)
        : undefined);
    message.regionOfInterestDisplaySettingsWriteReq !== undefined &&
      (obj.regionOfInterestDisplaySettingsWriteReq = message.regionOfInterestDisplaySettingsWriteReq
        ? RegionOfInterestDisplaySettingsWriteReq.toJSON(message.regionOfInterestDisplaySettingsWriteReq)
        : undefined);
    message.regionOfInterestDisplaySettingsWriteResp !== undefined &&
      (obj.regionOfInterestDisplaySettingsWriteResp = message.regionOfInterestDisplaySettingsWriteResp
        ? RegionOfInterestDisplaySettingsWriteResp.toJSON(message.regionOfInterestDisplaySettingsWriteResp)
        : undefined);
    message.regionOfInterestGetReq !== undefined && (obj.regionOfInterestGetReq = message.regionOfInterestGetReq
      ? RegionOfInterestGetReq.toJSON(message.regionOfInterestGetReq)
      : undefined);
    message.regionOfInterestGetResp !== undefined && (obj.regionOfInterestGetResp = message.regionOfInterestGetResp
      ? RegionOfInterestGetResp.toJSON(message.regionOfInterestGetResp)
      : undefined);
    message.regionOfInterestListReq !== undefined && (obj.regionOfInterestListReq = message.regionOfInterestListReq
      ? RegionOfInterestListReq.toJSON(message.regionOfInterestListReq)
      : undefined);
    message.regionOfInterestListResp !== undefined && (obj.regionOfInterestListResp = message.regionOfInterestListResp
      ? RegionOfInterestListResp.toJSON(message.regionOfInterestListResp)
      : undefined);
    message.regionOfInterestWriteReq !== undefined && (obj.regionOfInterestWriteReq = message.regionOfInterestWriteReq
      ? RegionOfInterestWriteReq.toJSON(message.regionOfInterestWriteReq)
      : undefined);
    message.regionOfInterestWriteResp !== undefined &&
      (obj.regionOfInterestWriteResp = message.regionOfInterestWriteResp
        ? RegionOfInterestWriteResp.toJSON(message.regionOfInterestWriteResp)
        : undefined);
    message.restoreDBReq !== undefined &&
      (obj.restoreDBReq = message.restoreDBReq ? RestoreDBReq.toJSON(message.restoreDBReq) : undefined);
    message.restoreDBResp !== undefined &&
      (obj.restoreDBResp = message.restoreDBResp ? RestoreDBResp.toJSON(message.restoreDBResp) : undefined);
    message.reviewerMagicLinkCreateReq !== undefined &&
      (obj.reviewerMagicLinkCreateReq = message.reviewerMagicLinkCreateReq
        ? ReviewerMagicLinkCreateReq.toJSON(message.reviewerMagicLinkCreateReq)
        : undefined);
    message.reviewerMagicLinkCreateResp !== undefined &&
      (obj.reviewerMagicLinkCreateResp = message.reviewerMagicLinkCreateResp
        ? ReviewerMagicLinkCreateResp.toJSON(message.reviewerMagicLinkCreateResp)
        : undefined);
    message.reviewerMagicLinkLoginReq !== undefined &&
      (obj.reviewerMagicLinkLoginReq = message.reviewerMagicLinkLoginReq
        ? ReviewerMagicLinkLoginReq.toJSON(message.reviewerMagicLinkLoginReq)
        : undefined);
    message.reviewerMagicLinkLoginResp !== undefined &&
      (obj.reviewerMagicLinkLoginResp = message.reviewerMagicLinkLoginResp
        ? ReviewerMagicLinkLoginResp.toJSON(message.reviewerMagicLinkLoginResp)
        : undefined);
    message.runTestReq !== undefined &&
      (obj.runTestReq = message.runTestReq ? RunTestReq.toJSON(message.runTestReq) : undefined);
    message.runTestResp !== undefined &&
      (obj.runTestResp = message.runTestResp ? RunTestResp.toJSON(message.runTestResp) : undefined);
    message.scanAutoShareReq !== undefined &&
      (obj.scanAutoShareReq = message.scanAutoShareReq ? ScanAutoShareReq.toJSON(message.scanAutoShareReq) : undefined);
    message.scanAutoShareResp !== undefined && (obj.scanAutoShareResp = message.scanAutoShareResp
      ? ScanAutoShareResp.toJSON(message.scanAutoShareResp)
      : undefined);
    message.scanAutoShareWriteReq !== undefined && (obj.scanAutoShareWriteReq = message.scanAutoShareWriteReq
      ? ScanAutoShareWriteReq.toJSON(message.scanAutoShareWriteReq)
      : undefined);
    message.scanAutoShareWriteResp !== undefined && (obj.scanAutoShareWriteResp = message.scanAutoShareWriteResp
      ? ScanAutoShareWriteResp.toJSON(message.scanAutoShareWriteResp)
      : undefined);
    message.scanBeamLocationsReq !== undefined && (obj.scanBeamLocationsReq = message.scanBeamLocationsReq
      ? ScanBeamLocationsReq.toJSON(message.scanBeamLocationsReq)
      : undefined);
    message.scanBeamLocationsResp !== undefined && (obj.scanBeamLocationsResp = message.scanBeamLocationsResp
      ? ScanBeamLocationsResp.toJSON(message.scanBeamLocationsResp)
      : undefined);
    message.scanDeleteReq !== undefined &&
      (obj.scanDeleteReq = message.scanDeleteReq ? ScanDeleteReq.toJSON(message.scanDeleteReq) : undefined);
    message.scanDeleteResp !== undefined &&
      (obj.scanDeleteResp = message.scanDeleteResp ? ScanDeleteResp.toJSON(message.scanDeleteResp) : undefined);
    message.scanEntryMetadataReq !== undefined && (obj.scanEntryMetadataReq = message.scanEntryMetadataReq
      ? ScanEntryMetadataReq.toJSON(message.scanEntryMetadataReq)
      : undefined);
    message.scanEntryMetadataResp !== undefined && (obj.scanEntryMetadataResp = message.scanEntryMetadataResp
      ? ScanEntryMetadataResp.toJSON(message.scanEntryMetadataResp)
      : undefined);
    message.scanEntryReq !== undefined &&
      (obj.scanEntryReq = message.scanEntryReq ? ScanEntryReq.toJSON(message.scanEntryReq) : undefined);
    message.scanEntryResp !== undefined &&
      (obj.scanEntryResp = message.scanEntryResp ? ScanEntryResp.toJSON(message.scanEntryResp) : undefined);
    message.scanGetReq !== undefined &&
      (obj.scanGetReq = message.scanGetReq ? ScanGetReq.toJSON(message.scanGetReq) : undefined);
    message.scanGetResp !== undefined &&
      (obj.scanGetResp = message.scanGetResp ? ScanGetResp.toJSON(message.scanGetResp) : undefined);
    message.scanListReq !== undefined &&
      (obj.scanListReq = message.scanListReq ? ScanListReq.toJSON(message.scanListReq) : undefined);
    message.scanListResp !== undefined &&
      (obj.scanListResp = message.scanListResp ? ScanListResp.toJSON(message.scanListResp) : undefined);
    message.scanListUpd !== undefined &&
      (obj.scanListUpd = message.scanListUpd ? ScanListUpd.toJSON(message.scanListUpd) : undefined);
    message.scanMetaLabelsAndTypesReq !== undefined &&
      (obj.scanMetaLabelsAndTypesReq = message.scanMetaLabelsAndTypesReq
        ? ScanMetaLabelsAndTypesReq.toJSON(message.scanMetaLabelsAndTypesReq)
        : undefined);
    message.scanMetaLabelsAndTypesResp !== undefined &&
      (obj.scanMetaLabelsAndTypesResp = message.scanMetaLabelsAndTypesResp
        ? ScanMetaLabelsAndTypesResp.toJSON(message.scanMetaLabelsAndTypesResp)
        : undefined);
    message.scanMetaWriteReq !== undefined &&
      (obj.scanMetaWriteReq = message.scanMetaWriteReq ? ScanMetaWriteReq.toJSON(message.scanMetaWriteReq) : undefined);
    message.scanMetaWriteResp !== undefined && (obj.scanMetaWriteResp = message.scanMetaWriteResp
      ? ScanMetaWriteResp.toJSON(message.scanMetaWriteResp)
      : undefined);
    message.scanTriggerAutoQuantReq !== undefined && (obj.scanTriggerAutoQuantReq = message.scanTriggerAutoQuantReq
      ? ScanTriggerAutoQuantReq.toJSON(message.scanTriggerAutoQuantReq)
      : undefined);
    message.scanTriggerAutoQuantResp !== undefined && (obj.scanTriggerAutoQuantResp = message.scanTriggerAutoQuantResp
      ? ScanTriggerAutoQuantResp.toJSON(message.scanTriggerAutoQuantResp)
      : undefined);
    message.scanTriggerReImportReq !== undefined && (obj.scanTriggerReImportReq = message.scanTriggerReImportReq
      ? ScanTriggerReImportReq.toJSON(message.scanTriggerReImportReq)
      : undefined);
    message.scanTriggerReImportResp !== undefined && (obj.scanTriggerReImportResp = message.scanTriggerReImportResp
      ? ScanTriggerReImportResp.toJSON(message.scanTriggerReImportResp)
      : undefined);
    message.scanTriggerReImportUpd !== undefined && (obj.scanTriggerReImportUpd = message.scanTriggerReImportUpd
      ? ScanTriggerReImportUpd.toJSON(message.scanTriggerReImportUpd)
      : undefined);
    message.scanUploadReq !== undefined &&
      (obj.scanUploadReq = message.scanUploadReq ? ScanUploadReq.toJSON(message.scanUploadReq) : undefined);
    message.scanUploadResp !== undefined &&
      (obj.scanUploadResp = message.scanUploadResp ? ScanUploadResp.toJSON(message.scanUploadResp) : undefined);
    message.scanUploadUpd !== undefined &&
      (obj.scanUploadUpd = message.scanUploadUpd ? ScanUploadUpd.toJSON(message.scanUploadUpd) : undefined);
    message.screenConfigurationDeleteReq !== undefined &&
      (obj.screenConfigurationDeleteReq = message.screenConfigurationDeleteReq
        ? ScreenConfigurationDeleteReq.toJSON(message.screenConfigurationDeleteReq)
        : undefined);
    message.screenConfigurationDeleteResp !== undefined &&
      (obj.screenConfigurationDeleteResp = message.screenConfigurationDeleteResp
        ? ScreenConfigurationDeleteResp.toJSON(message.screenConfigurationDeleteResp)
        : undefined);
    message.screenConfigurationGetReq !== undefined &&
      (obj.screenConfigurationGetReq = message.screenConfigurationGetReq
        ? ScreenConfigurationGetReq.toJSON(message.screenConfigurationGetReq)
        : undefined);
    message.screenConfigurationGetResp !== undefined &&
      (obj.screenConfigurationGetResp = message.screenConfigurationGetResp
        ? ScreenConfigurationGetResp.toJSON(message.screenConfigurationGetResp)
        : undefined);
    message.screenConfigurationListReq !== undefined &&
      (obj.screenConfigurationListReq = message.screenConfigurationListReq
        ? ScreenConfigurationListReq.toJSON(message.screenConfigurationListReq)
        : undefined);
    message.screenConfigurationListResp !== undefined &&
      (obj.screenConfigurationListResp = message.screenConfigurationListResp
        ? ScreenConfigurationListResp.toJSON(message.screenConfigurationListResp)
        : undefined);
    message.screenConfigurationWriteReq !== undefined &&
      (obj.screenConfigurationWriteReq = message.screenConfigurationWriteReq
        ? ScreenConfigurationWriteReq.toJSON(message.screenConfigurationWriteReq)
        : undefined);
    message.screenConfigurationWriteResp !== undefined &&
      (obj.screenConfigurationWriteResp = message.screenConfigurationWriteResp
        ? ScreenConfigurationWriteResp.toJSON(message.screenConfigurationWriteResp)
        : undefined);
    message.selectedImagePixelsReq !== undefined && (obj.selectedImagePixelsReq = message.selectedImagePixelsReq
      ? SelectedImagePixelsReq.toJSON(message.selectedImagePixelsReq)
      : undefined);
    message.selectedImagePixelsResp !== undefined && (obj.selectedImagePixelsResp = message.selectedImagePixelsResp
      ? SelectedImagePixelsResp.toJSON(message.selectedImagePixelsResp)
      : undefined);
    message.selectedImagePixelsWriteReq !== undefined &&
      (obj.selectedImagePixelsWriteReq = message.selectedImagePixelsWriteReq
        ? SelectedImagePixelsWriteReq.toJSON(message.selectedImagePixelsWriteReq)
        : undefined);
    message.selectedImagePixelsWriteResp !== undefined &&
      (obj.selectedImagePixelsWriteResp = message.selectedImagePixelsWriteResp
        ? SelectedImagePixelsWriteResp.toJSON(message.selectedImagePixelsWriteResp)
        : undefined);
    message.selectedScanEntriesReq !== undefined && (obj.selectedScanEntriesReq = message.selectedScanEntriesReq
      ? SelectedScanEntriesReq.toJSON(message.selectedScanEntriesReq)
      : undefined);
    message.selectedScanEntriesResp !== undefined && (obj.selectedScanEntriesResp = message.selectedScanEntriesResp
      ? SelectedScanEntriesResp.toJSON(message.selectedScanEntriesResp)
      : undefined);
    message.selectedScanEntriesWriteReq !== undefined &&
      (obj.selectedScanEntriesWriteReq = message.selectedScanEntriesWriteReq
        ? SelectedScanEntriesWriteReq.toJSON(message.selectedScanEntriesWriteReq)
        : undefined);
    message.selectedScanEntriesWriteResp !== undefined &&
      (obj.selectedScanEntriesWriteResp = message.selectedScanEntriesWriteResp
        ? SelectedScanEntriesWriteResp.toJSON(message.selectedScanEntriesWriteResp)
        : undefined);
    message.sendUserNotificationReq !== undefined && (obj.sendUserNotificationReq = message.sendUserNotificationReq
      ? SendUserNotificationReq.toJSON(message.sendUserNotificationReq)
      : undefined);
    message.sendUserNotificationResp !== undefined && (obj.sendUserNotificationResp = message.sendUserNotificationResp
      ? SendUserNotificationResp.toJSON(message.sendUserNotificationResp)
      : undefined);
    message.spectrumReq !== undefined &&
      (obj.spectrumReq = message.spectrumReq ? SpectrumReq.toJSON(message.spectrumReq) : undefined);
    message.spectrumResp !== undefined &&
      (obj.spectrumResp = message.spectrumResp ? SpectrumResp.toJSON(message.spectrumResp) : undefined);
    message.tagCreateReq !== undefined &&
      (obj.tagCreateReq = message.tagCreateReq ? TagCreateReq.toJSON(message.tagCreateReq) : undefined);
    message.tagCreateResp !== undefined &&
      (obj.tagCreateResp = message.tagCreateResp ? TagCreateResp.toJSON(message.tagCreateResp) : undefined);
    message.tagDeleteReq !== undefined &&
      (obj.tagDeleteReq = message.tagDeleteReq ? TagDeleteReq.toJSON(message.tagDeleteReq) : undefined);
    message.tagDeleteResp !== undefined &&
      (obj.tagDeleteResp = message.tagDeleteResp ? TagDeleteResp.toJSON(message.tagDeleteResp) : undefined);
    message.tagListReq !== undefined &&
      (obj.tagListReq = message.tagListReq ? TagListReq.toJSON(message.tagListReq) : undefined);
    message.tagListResp !== undefined &&
      (obj.tagListResp = message.tagListResp ? TagListResp.toJSON(message.tagListResp) : undefined);
    message.userAddRoleReq !== undefined &&
      (obj.userAddRoleReq = message.userAddRoleReq ? UserAddRoleReq.toJSON(message.userAddRoleReq) : undefined);
    message.userAddRoleResp !== undefined &&
      (obj.userAddRoleResp = message.userAddRoleResp ? UserAddRoleResp.toJSON(message.userAddRoleResp) : undefined);
    message.userDeleteRoleReq !== undefined && (obj.userDeleteRoleReq = message.userDeleteRoleReq
      ? UserDeleteRoleReq.toJSON(message.userDeleteRoleReq)
      : undefined);
    message.userDeleteRoleResp !== undefined && (obj.userDeleteRoleResp = message.userDeleteRoleResp
      ? UserDeleteRoleResp.toJSON(message.userDeleteRoleResp)
      : undefined);
    message.userDetailsReq !== undefined &&
      (obj.userDetailsReq = message.userDetailsReq ? UserDetailsReq.toJSON(message.userDetailsReq) : undefined);
    message.userDetailsResp !== undefined &&
      (obj.userDetailsResp = message.userDetailsResp ? UserDetailsResp.toJSON(message.userDetailsResp) : undefined);
    message.userDetailsWriteReq !== undefined && (obj.userDetailsWriteReq = message.userDetailsWriteReq
      ? UserDetailsWriteReq.toJSON(message.userDetailsWriteReq)
      : undefined);
    message.userDetailsWriteResp !== undefined && (obj.userDetailsWriteResp = message.userDetailsWriteResp
      ? UserDetailsWriteResp.toJSON(message.userDetailsWriteResp)
      : undefined);
    message.userGroupAddAdminReq !== undefined && (obj.userGroupAddAdminReq = message.userGroupAddAdminReq
      ? UserGroupAddAdminReq.toJSON(message.userGroupAddAdminReq)
      : undefined);
    message.userGroupAddAdminResp !== undefined && (obj.userGroupAddAdminResp = message.userGroupAddAdminResp
      ? UserGroupAddAdminResp.toJSON(message.userGroupAddAdminResp)
      : undefined);
    message.userGroupAddMemberReq !== undefined && (obj.userGroupAddMemberReq = message.userGroupAddMemberReq
      ? UserGroupAddMemberReq.toJSON(message.userGroupAddMemberReq)
      : undefined);
    message.userGroupAddMemberResp !== undefined && (obj.userGroupAddMemberResp = message.userGroupAddMemberResp
      ? UserGroupAddMemberResp.toJSON(message.userGroupAddMemberResp)
      : undefined);
    message.userGroupAddViewerReq !== undefined && (obj.userGroupAddViewerReq = message.userGroupAddViewerReq
      ? UserGroupAddViewerReq.toJSON(message.userGroupAddViewerReq)
      : undefined);
    message.userGroupAddViewerResp !== undefined && (obj.userGroupAddViewerResp = message.userGroupAddViewerResp
      ? UserGroupAddViewerResp.toJSON(message.userGroupAddViewerResp)
      : undefined);
    message.userGroupCreateReq !== undefined && (obj.userGroupCreateReq = message.userGroupCreateReq
      ? UserGroupCreateReq.toJSON(message.userGroupCreateReq)
      : undefined);
    message.userGroupCreateResp !== undefined && (obj.userGroupCreateResp = message.userGroupCreateResp
      ? UserGroupCreateResp.toJSON(message.userGroupCreateResp)
      : undefined);
    message.userGroupDeleteAdminReq !== undefined && (obj.userGroupDeleteAdminReq = message.userGroupDeleteAdminReq
      ? UserGroupDeleteAdminReq.toJSON(message.userGroupDeleteAdminReq)
      : undefined);
    message.userGroupDeleteAdminResp !== undefined && (obj.userGroupDeleteAdminResp = message.userGroupDeleteAdminResp
      ? UserGroupDeleteAdminResp.toJSON(message.userGroupDeleteAdminResp)
      : undefined);
    message.userGroupDeleteMemberReq !== undefined && (obj.userGroupDeleteMemberReq = message.userGroupDeleteMemberReq
      ? UserGroupDeleteMemberReq.toJSON(message.userGroupDeleteMemberReq)
      : undefined);
    message.userGroupDeleteMemberResp !== undefined &&
      (obj.userGroupDeleteMemberResp = message.userGroupDeleteMemberResp
        ? UserGroupDeleteMemberResp.toJSON(message.userGroupDeleteMemberResp)
        : undefined);
    message.userGroupDeleteReq !== undefined && (obj.userGroupDeleteReq = message.userGroupDeleteReq
      ? UserGroupDeleteReq.toJSON(message.userGroupDeleteReq)
      : undefined);
    message.userGroupDeleteResp !== undefined && (obj.userGroupDeleteResp = message.userGroupDeleteResp
      ? UserGroupDeleteResp.toJSON(message.userGroupDeleteResp)
      : undefined);
    message.userGroupDeleteViewerReq !== undefined && (obj.userGroupDeleteViewerReq = message.userGroupDeleteViewerReq
      ? UserGroupDeleteViewerReq.toJSON(message.userGroupDeleteViewerReq)
      : undefined);
    message.userGroupDeleteViewerResp !== undefined &&
      (obj.userGroupDeleteViewerResp = message.userGroupDeleteViewerResp
        ? UserGroupDeleteViewerResp.toJSON(message.userGroupDeleteViewerResp)
        : undefined);
    message.userGroupEditDetailsReq !== undefined && (obj.userGroupEditDetailsReq = message.userGroupEditDetailsReq
      ? UserGroupEditDetailsReq.toJSON(message.userGroupEditDetailsReq)
      : undefined);
    message.userGroupEditDetailsResp !== undefined && (obj.userGroupEditDetailsResp = message.userGroupEditDetailsResp
      ? UserGroupEditDetailsResp.toJSON(message.userGroupEditDetailsResp)
      : undefined);
    message.userGroupIgnoreJoinReq !== undefined && (obj.userGroupIgnoreJoinReq = message.userGroupIgnoreJoinReq
      ? UserGroupIgnoreJoinReq.toJSON(message.userGroupIgnoreJoinReq)
      : undefined);
    message.userGroupIgnoreJoinResp !== undefined && (obj.userGroupIgnoreJoinResp = message.userGroupIgnoreJoinResp
      ? UserGroupIgnoreJoinResp.toJSON(message.userGroupIgnoreJoinResp)
      : undefined);
    message.userGroupJoinListReq !== undefined && (obj.userGroupJoinListReq = message.userGroupJoinListReq
      ? UserGroupJoinListReq.toJSON(message.userGroupJoinListReq)
      : undefined);
    message.userGroupJoinListResp !== undefined && (obj.userGroupJoinListResp = message.userGroupJoinListResp
      ? UserGroupJoinListResp.toJSON(message.userGroupJoinListResp)
      : undefined);
    message.userGroupJoinReq !== undefined &&
      (obj.userGroupJoinReq = message.userGroupJoinReq ? UserGroupJoinReq.toJSON(message.userGroupJoinReq) : undefined);
    message.userGroupJoinResp !== undefined && (obj.userGroupJoinResp = message.userGroupJoinResp
      ? UserGroupJoinResp.toJSON(message.userGroupJoinResp)
      : undefined);
    message.userGroupListJoinableReq !== undefined && (obj.userGroupListJoinableReq = message.userGroupListJoinableReq
      ? UserGroupListJoinableReq.toJSON(message.userGroupListJoinableReq)
      : undefined);
    message.userGroupListJoinableResp !== undefined &&
      (obj.userGroupListJoinableResp = message.userGroupListJoinableResp
        ? UserGroupListJoinableResp.toJSON(message.userGroupListJoinableResp)
        : undefined);
    message.userGroupListReq !== undefined &&
      (obj.userGroupListReq = message.userGroupListReq ? UserGroupListReq.toJSON(message.userGroupListReq) : undefined);
    message.userGroupListResp !== undefined && (obj.userGroupListResp = message.userGroupListResp
      ? UserGroupListResp.toJSON(message.userGroupListResp)
      : undefined);
    message.userGroupReq !== undefined &&
      (obj.userGroupReq = message.userGroupReq ? UserGroupReq.toJSON(message.userGroupReq) : undefined);
    message.userGroupResp !== undefined &&
      (obj.userGroupResp = message.userGroupResp ? UserGroupResp.toJSON(message.userGroupResp) : undefined);
    message.userImpersonateGetReq !== undefined && (obj.userImpersonateGetReq = message.userImpersonateGetReq
      ? UserImpersonateGetReq.toJSON(message.userImpersonateGetReq)
      : undefined);
    message.userImpersonateGetResp !== undefined && (obj.userImpersonateGetResp = message.userImpersonateGetResp
      ? UserImpersonateGetResp.toJSON(message.userImpersonateGetResp)
      : undefined);
    message.userImpersonateReq !== undefined && (obj.userImpersonateReq = message.userImpersonateReq
      ? UserImpersonateReq.toJSON(message.userImpersonateReq)
      : undefined);
    message.userImpersonateResp !== undefined && (obj.userImpersonateResp = message.userImpersonateResp
      ? UserImpersonateResp.toJSON(message.userImpersonateResp)
      : undefined);
    message.userListReq !== undefined &&
      (obj.userListReq = message.userListReq ? UserListReq.toJSON(message.userListReq) : undefined);
    message.userListResp !== undefined &&
      (obj.userListResp = message.userListResp ? UserListResp.toJSON(message.userListResp) : undefined);
    message.userNotificationSettingsReq !== undefined &&
      (obj.userNotificationSettingsReq = message.userNotificationSettingsReq
        ? UserNotificationSettingsReq.toJSON(message.userNotificationSettingsReq)
        : undefined);
    message.userNotificationSettingsResp !== undefined &&
      (obj.userNotificationSettingsResp = message.userNotificationSettingsResp
        ? UserNotificationSettingsResp.toJSON(message.userNotificationSettingsResp)
        : undefined);
    message.userNotificationSettingsUpd !== undefined &&
      (obj.userNotificationSettingsUpd = message.userNotificationSettingsUpd
        ? UserNotificationSettingsUpd.toJSON(message.userNotificationSettingsUpd)
        : undefined);
    message.userNotificationSettingsWriteReq !== undefined &&
      (obj.userNotificationSettingsWriteReq = message.userNotificationSettingsWriteReq
        ? UserNotificationSettingsWriteReq.toJSON(message.userNotificationSettingsWriteReq)
        : undefined);
    message.userNotificationSettingsWriteResp !== undefined &&
      (obj.userNotificationSettingsWriteResp = message.userNotificationSettingsWriteResp
        ? UserNotificationSettingsWriteResp.toJSON(message.userNotificationSettingsWriteResp)
        : undefined);
    message.userRoleListReq !== undefined &&
      (obj.userRoleListReq = message.userRoleListReq ? UserRoleListReq.toJSON(message.userRoleListReq) : undefined);
    message.userRoleListResp !== undefined &&
      (obj.userRoleListResp = message.userRoleListResp ? UserRoleListResp.toJSON(message.userRoleListResp) : undefined);
    message.userRolesListReq !== undefined &&
      (obj.userRolesListReq = message.userRolesListReq ? UserRolesListReq.toJSON(message.userRolesListReq) : undefined);
    message.userRolesListResp !== undefined && (obj.userRolesListResp = message.userRolesListResp
      ? UserRolesListResp.toJSON(message.userRolesListResp)
      : undefined);
    message.userSearchReq !== undefined &&
      (obj.userSearchReq = message.userSearchReq ? UserSearchReq.toJSON(message.userSearchReq) : undefined);
    message.userSearchResp !== undefined &&
      (obj.userSearchResp = message.userSearchResp ? UserSearchResp.toJSON(message.userSearchResp) : undefined);
    message.widgetDataGetReq !== undefined &&
      (obj.widgetDataGetReq = message.widgetDataGetReq ? WidgetDataGetReq.toJSON(message.widgetDataGetReq) : undefined);
    message.widgetDataGetResp !== undefined && (obj.widgetDataGetResp = message.widgetDataGetResp
      ? WidgetDataGetResp.toJSON(message.widgetDataGetResp)
      : undefined);
    message.widgetDataWriteReq !== undefined && (obj.widgetDataWriteReq = message.widgetDataWriteReq
      ? WidgetDataWriteReq.toJSON(message.widgetDataWriteReq)
      : undefined);
    message.widgetDataWriteResp !== undefined && (obj.widgetDataWriteResp = message.widgetDataWriteResp
      ? WidgetDataWriteResp.toJSON(message.widgetDataWriteResp)
      : undefined);
    message.zenodoDOIGetReq !== undefined &&
      (obj.zenodoDOIGetReq = message.zenodoDOIGetReq ? ZenodoDOIGetReq.toJSON(message.zenodoDOIGetReq) : undefined);
    message.zenodoDOIGetResp !== undefined &&
      (obj.zenodoDOIGetResp = message.zenodoDOIGetResp ? ZenodoDOIGetResp.toJSON(message.zenodoDOIGetResp) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WSMessage>, I>>(base?: I): WSMessage {
    return WSMessage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WSMessage>, I>>(object: I): WSMessage {
    const message = createBaseWSMessage();
    message.msgId = object.msgId ?? 0;
    message.status = object.status ?? 0;
    message.errorText = object.errorText ?? "";
    message.backupDBReq = (object.backupDBReq !== undefined && object.backupDBReq !== null)
      ? BackupDBReq.fromPartial(object.backupDBReq)
      : undefined;
    message.backupDBResp = (object.backupDBResp !== undefined && object.backupDBResp !== null)
      ? BackupDBResp.fromPartial(object.backupDBResp)
      : undefined;
    message.backupDBUpd = (object.backupDBUpd !== undefined && object.backupDBUpd !== null)
      ? BackupDBUpd.fromPartial(object.backupDBUpd)
      : undefined;
    message.dBAdminConfigGetReq = (object.dBAdminConfigGetReq !== undefined && object.dBAdminConfigGetReq !== null)
      ? DBAdminConfigGetReq.fromPartial(object.dBAdminConfigGetReq)
      : undefined;
    message.dBAdminConfigGetResp = (object.dBAdminConfigGetResp !== undefined && object.dBAdminConfigGetResp !== null)
      ? DBAdminConfigGetResp.fromPartial(object.dBAdminConfigGetResp)
      : undefined;
    message.dataModuleAddVersionReq =
      (object.dataModuleAddVersionReq !== undefined && object.dataModuleAddVersionReq !== null)
        ? DataModuleAddVersionReq.fromPartial(object.dataModuleAddVersionReq)
        : undefined;
    message.dataModuleAddVersionResp =
      (object.dataModuleAddVersionResp !== undefined && object.dataModuleAddVersionResp !== null)
        ? DataModuleAddVersionResp.fromPartial(object.dataModuleAddVersionResp)
        : undefined;
    message.dataModuleGetReq = (object.dataModuleGetReq !== undefined && object.dataModuleGetReq !== null)
      ? DataModuleGetReq.fromPartial(object.dataModuleGetReq)
      : undefined;
    message.dataModuleGetResp = (object.dataModuleGetResp !== undefined && object.dataModuleGetResp !== null)
      ? DataModuleGetResp.fromPartial(object.dataModuleGetResp)
      : undefined;
    message.dataModuleListReq = (object.dataModuleListReq !== undefined && object.dataModuleListReq !== null)
      ? DataModuleListReq.fromPartial(object.dataModuleListReq)
      : undefined;
    message.dataModuleListResp = (object.dataModuleListResp !== undefined && object.dataModuleListResp !== null)
      ? DataModuleListResp.fromPartial(object.dataModuleListResp)
      : undefined;
    message.dataModuleWriteReq = (object.dataModuleWriteReq !== undefined && object.dataModuleWriteReq !== null)
      ? DataModuleWriteReq.fromPartial(object.dataModuleWriteReq)
      : undefined;
    message.dataModuleWriteResp = (object.dataModuleWriteResp !== undefined && object.dataModuleWriteResp !== null)
      ? DataModuleWriteResp.fromPartial(object.dataModuleWriteResp)
      : undefined;
    message.detectedDiffractionPeaksReq =
      (object.detectedDiffractionPeaksReq !== undefined && object.detectedDiffractionPeaksReq !== null)
        ? DetectedDiffractionPeaksReq.fromPartial(object.detectedDiffractionPeaksReq)
        : undefined;
    message.detectedDiffractionPeaksResp =
      (object.detectedDiffractionPeaksResp !== undefined && object.detectedDiffractionPeaksResp !== null)
        ? DetectedDiffractionPeaksResp.fromPartial(object.detectedDiffractionPeaksResp)
        : undefined;
    message.detectorConfigListReq =
      (object.detectorConfigListReq !== undefined && object.detectorConfigListReq !== null)
        ? DetectorConfigListReq.fromPartial(object.detectorConfigListReq)
        : undefined;
    message.detectorConfigListResp =
      (object.detectorConfigListResp !== undefined && object.detectorConfigListResp !== null)
        ? DetectorConfigListResp.fromPartial(object.detectorConfigListResp)
        : undefined;
    message.detectorConfigReq = (object.detectorConfigReq !== undefined && object.detectorConfigReq !== null)
      ? DetectorConfigReq.fromPartial(object.detectorConfigReq)
      : undefined;
    message.detectorConfigResp = (object.detectorConfigResp !== undefined && object.detectorConfigResp !== null)
      ? DetectorConfigResp.fromPartial(object.detectorConfigResp)
      : undefined;
    message.diffractionPeakManualDeleteReq =
      (object.diffractionPeakManualDeleteReq !== undefined && object.diffractionPeakManualDeleteReq !== null)
        ? DiffractionPeakManualDeleteReq.fromPartial(object.diffractionPeakManualDeleteReq)
        : undefined;
    message.diffractionPeakManualDeleteResp =
      (object.diffractionPeakManualDeleteResp !== undefined && object.diffractionPeakManualDeleteResp !== null)
        ? DiffractionPeakManualDeleteResp.fromPartial(object.diffractionPeakManualDeleteResp)
        : undefined;
    message.diffractionPeakManualInsertReq =
      (object.diffractionPeakManualInsertReq !== undefined && object.diffractionPeakManualInsertReq !== null)
        ? DiffractionPeakManualInsertReq.fromPartial(object.diffractionPeakManualInsertReq)
        : undefined;
    message.diffractionPeakManualInsertResp =
      (object.diffractionPeakManualInsertResp !== undefined && object.diffractionPeakManualInsertResp !== null)
        ? DiffractionPeakManualInsertResp.fromPartial(object.diffractionPeakManualInsertResp)
        : undefined;
    message.diffractionPeakManualListReq =
      (object.diffractionPeakManualListReq !== undefined && object.diffractionPeakManualListReq !== null)
        ? DiffractionPeakManualListReq.fromPartial(object.diffractionPeakManualListReq)
        : undefined;
    message.diffractionPeakManualListResp =
      (object.diffractionPeakManualListResp !== undefined && object.diffractionPeakManualListResp !== null)
        ? DiffractionPeakManualListResp.fromPartial(object.diffractionPeakManualListResp)
        : undefined;
    message.diffractionPeakStatusDeleteReq =
      (object.diffractionPeakStatusDeleteReq !== undefined && object.diffractionPeakStatusDeleteReq !== null)
        ? DiffractionPeakStatusDeleteReq.fromPartial(object.diffractionPeakStatusDeleteReq)
        : undefined;
    message.diffractionPeakStatusDeleteResp =
      (object.diffractionPeakStatusDeleteResp !== undefined && object.diffractionPeakStatusDeleteResp !== null)
        ? DiffractionPeakStatusDeleteResp.fromPartial(object.diffractionPeakStatusDeleteResp)
        : undefined;
    message.diffractionPeakStatusListReq =
      (object.diffractionPeakStatusListReq !== undefined && object.diffractionPeakStatusListReq !== null)
        ? DiffractionPeakStatusListReq.fromPartial(object.diffractionPeakStatusListReq)
        : undefined;
    message.diffractionPeakStatusListResp =
      (object.diffractionPeakStatusListResp !== undefined && object.diffractionPeakStatusListResp !== null)
        ? DiffractionPeakStatusListResp.fromPartial(object.diffractionPeakStatusListResp)
        : undefined;
    message.diffractionPeakStatusWriteReq =
      (object.diffractionPeakStatusWriteReq !== undefined && object.diffractionPeakStatusWriteReq !== null)
        ? DiffractionPeakStatusWriteReq.fromPartial(object.diffractionPeakStatusWriteReq)
        : undefined;
    message.diffractionPeakStatusWriteResp =
      (object.diffractionPeakStatusWriteResp !== undefined && object.diffractionPeakStatusWriteResp !== null)
        ? DiffractionPeakStatusWriteResp.fromPartial(object.diffractionPeakStatusWriteResp)
        : undefined;
    message.elementSetDeleteReq = (object.elementSetDeleteReq !== undefined && object.elementSetDeleteReq !== null)
      ? ElementSetDeleteReq.fromPartial(object.elementSetDeleteReq)
      : undefined;
    message.elementSetDeleteResp = (object.elementSetDeleteResp !== undefined && object.elementSetDeleteResp !== null)
      ? ElementSetDeleteResp.fromPartial(object.elementSetDeleteResp)
      : undefined;
    message.elementSetGetReq = (object.elementSetGetReq !== undefined && object.elementSetGetReq !== null)
      ? ElementSetGetReq.fromPartial(object.elementSetGetReq)
      : undefined;
    message.elementSetGetResp = (object.elementSetGetResp !== undefined && object.elementSetGetResp !== null)
      ? ElementSetGetResp.fromPartial(object.elementSetGetResp)
      : undefined;
    message.elementSetListReq = (object.elementSetListReq !== undefined && object.elementSetListReq !== null)
      ? ElementSetListReq.fromPartial(object.elementSetListReq)
      : undefined;
    message.elementSetListResp = (object.elementSetListResp !== undefined && object.elementSetListResp !== null)
      ? ElementSetListResp.fromPartial(object.elementSetListResp)
      : undefined;
    message.elementSetWriteReq = (object.elementSetWriteReq !== undefined && object.elementSetWriteReq !== null)
      ? ElementSetWriteReq.fromPartial(object.elementSetWriteReq)
      : undefined;
    message.elementSetWriteResp = (object.elementSetWriteResp !== undefined && object.elementSetWriteResp !== null)
      ? ElementSetWriteResp.fromPartial(object.elementSetWriteResp)
      : undefined;
    message.exportFilesReq = (object.exportFilesReq !== undefined && object.exportFilesReq !== null)
      ? ExportFilesReq.fromPartial(object.exportFilesReq)
      : undefined;
    message.exportFilesResp = (object.exportFilesResp !== undefined && object.exportFilesResp !== null)
      ? ExportFilesResp.fromPartial(object.exportFilesResp)
      : undefined;
    message.expressionDeleteReq = (object.expressionDeleteReq !== undefined && object.expressionDeleteReq !== null)
      ? ExpressionDeleteReq.fromPartial(object.expressionDeleteReq)
      : undefined;
    message.expressionDeleteResp = (object.expressionDeleteResp !== undefined && object.expressionDeleteResp !== null)
      ? ExpressionDeleteResp.fromPartial(object.expressionDeleteResp)
      : undefined;
    message.expressionDisplaySettingsGetReq =
      (object.expressionDisplaySettingsGetReq !== undefined && object.expressionDisplaySettingsGetReq !== null)
        ? ExpressionDisplaySettingsGetReq.fromPartial(object.expressionDisplaySettingsGetReq)
        : undefined;
    message.expressionDisplaySettingsGetResp =
      (object.expressionDisplaySettingsGetResp !== undefined && object.expressionDisplaySettingsGetResp !== null)
        ? ExpressionDisplaySettingsGetResp.fromPartial(object.expressionDisplaySettingsGetResp)
        : undefined;
    message.expressionDisplaySettingsWriteReq =
      (object.expressionDisplaySettingsWriteReq !== undefined && object.expressionDisplaySettingsWriteReq !== null)
        ? ExpressionDisplaySettingsWriteReq.fromPartial(object.expressionDisplaySettingsWriteReq)
        : undefined;
    message.expressionDisplaySettingsWriteResp =
      (object.expressionDisplaySettingsWriteResp !== undefined && object.expressionDisplaySettingsWriteResp !== null)
        ? ExpressionDisplaySettingsWriteResp.fromPartial(object.expressionDisplaySettingsWriteResp)
        : undefined;
    message.expressionGetReq = (object.expressionGetReq !== undefined && object.expressionGetReq !== null)
      ? ExpressionGetReq.fromPartial(object.expressionGetReq)
      : undefined;
    message.expressionGetResp = (object.expressionGetResp !== undefined && object.expressionGetResp !== null)
      ? ExpressionGetResp.fromPartial(object.expressionGetResp)
      : undefined;
    message.expressionGroupDeleteReq =
      (object.expressionGroupDeleteReq !== undefined && object.expressionGroupDeleteReq !== null)
        ? ExpressionGroupDeleteReq.fromPartial(object.expressionGroupDeleteReq)
        : undefined;
    message.expressionGroupDeleteResp =
      (object.expressionGroupDeleteResp !== undefined && object.expressionGroupDeleteResp !== null)
        ? ExpressionGroupDeleteResp.fromPartial(object.expressionGroupDeleteResp)
        : undefined;
    message.expressionGroupGetReq =
      (object.expressionGroupGetReq !== undefined && object.expressionGroupGetReq !== null)
        ? ExpressionGroupGetReq.fromPartial(object.expressionGroupGetReq)
        : undefined;
    message.expressionGroupGetResp =
      (object.expressionGroupGetResp !== undefined && object.expressionGroupGetResp !== null)
        ? ExpressionGroupGetResp.fromPartial(object.expressionGroupGetResp)
        : undefined;
    message.expressionGroupListReq =
      (object.expressionGroupListReq !== undefined && object.expressionGroupListReq !== null)
        ? ExpressionGroupListReq.fromPartial(object.expressionGroupListReq)
        : undefined;
    message.expressionGroupListResp =
      (object.expressionGroupListResp !== undefined && object.expressionGroupListResp !== null)
        ? ExpressionGroupListResp.fromPartial(object.expressionGroupListResp)
        : undefined;
    message.expressionGroupWriteReq =
      (object.expressionGroupWriteReq !== undefined && object.expressionGroupWriteReq !== null)
        ? ExpressionGroupWriteReq.fromPartial(object.expressionGroupWriteReq)
        : undefined;
    message.expressionGroupWriteResp =
      (object.expressionGroupWriteResp !== undefined && object.expressionGroupWriteResp !== null)
        ? ExpressionGroupWriteResp.fromPartial(object.expressionGroupWriteResp)
        : undefined;
    message.expressionListReq = (object.expressionListReq !== undefined && object.expressionListReq !== null)
      ? ExpressionListReq.fromPartial(object.expressionListReq)
      : undefined;
    message.expressionListResp = (object.expressionListResp !== undefined && object.expressionListResp !== null)
      ? ExpressionListResp.fromPartial(object.expressionListResp)
      : undefined;
    message.expressionWriteExecStatReq =
      (object.expressionWriteExecStatReq !== undefined && object.expressionWriteExecStatReq !== null)
        ? ExpressionWriteExecStatReq.fromPartial(object.expressionWriteExecStatReq)
        : undefined;
    message.expressionWriteExecStatResp =
      (object.expressionWriteExecStatResp !== undefined && object.expressionWriteExecStatResp !== null)
        ? ExpressionWriteExecStatResp.fromPartial(object.expressionWriteExecStatResp)
        : undefined;
    message.expressionWriteReq = (object.expressionWriteReq !== undefined && object.expressionWriteReq !== null)
      ? ExpressionWriteReq.fromPartial(object.expressionWriteReq)
      : undefined;
    message.expressionWriteResp = (object.expressionWriteResp !== undefined && object.expressionWriteResp !== null)
      ? ExpressionWriteResp.fromPartial(object.expressionWriteResp)
      : undefined;
    message.getOwnershipReq = (object.getOwnershipReq !== undefined && object.getOwnershipReq !== null)
      ? GetOwnershipReq.fromPartial(object.getOwnershipReq)
      : undefined;
    message.getOwnershipResp = (object.getOwnershipResp !== undefined && object.getOwnershipResp !== null)
      ? GetOwnershipResp.fromPartial(object.getOwnershipResp)
      : undefined;
    message.imageBeamLocationVersionsReq =
      (object.imageBeamLocationVersionsReq !== undefined && object.imageBeamLocationVersionsReq !== null)
        ? ImageBeamLocationVersionsReq.fromPartial(object.imageBeamLocationVersionsReq)
        : undefined;
    message.imageBeamLocationVersionsResp =
      (object.imageBeamLocationVersionsResp !== undefined && object.imageBeamLocationVersionsResp !== null)
        ? ImageBeamLocationVersionsResp.fromPartial(object.imageBeamLocationVersionsResp)
        : undefined;
    message.imageBeamLocationsReq =
      (object.imageBeamLocationsReq !== undefined && object.imageBeamLocationsReq !== null)
        ? ImageBeamLocationsReq.fromPartial(object.imageBeamLocationsReq)
        : undefined;
    message.imageBeamLocationsResp =
      (object.imageBeamLocationsResp !== undefined && object.imageBeamLocationsResp !== null)
        ? ImageBeamLocationsResp.fromPartial(object.imageBeamLocationsResp)
        : undefined;
    message.imageDeleteReq = (object.imageDeleteReq !== undefined && object.imageDeleteReq !== null)
      ? ImageDeleteReq.fromPartial(object.imageDeleteReq)
      : undefined;
    message.imageDeleteResp = (object.imageDeleteResp !== undefined && object.imageDeleteResp !== null)
      ? ImageDeleteResp.fromPartial(object.imageDeleteResp)
      : undefined;
    message.imageGetDefaultReq = (object.imageGetDefaultReq !== undefined && object.imageGetDefaultReq !== null)
      ? ImageGetDefaultReq.fromPartial(object.imageGetDefaultReq)
      : undefined;
    message.imageGetDefaultResp = (object.imageGetDefaultResp !== undefined && object.imageGetDefaultResp !== null)
      ? ImageGetDefaultResp.fromPartial(object.imageGetDefaultResp)
      : undefined;
    message.imageGetReq = (object.imageGetReq !== undefined && object.imageGetReq !== null)
      ? ImageGetReq.fromPartial(object.imageGetReq)
      : undefined;
    message.imageGetResp = (object.imageGetResp !== undefined && object.imageGetResp !== null)
      ? ImageGetResp.fromPartial(object.imageGetResp)
      : undefined;
    message.imageListReq = (object.imageListReq !== undefined && object.imageListReq !== null)
      ? ImageListReq.fromPartial(object.imageListReq)
      : undefined;
    message.imageListResp = (object.imageListResp !== undefined && object.imageListResp !== null)
      ? ImageListResp.fromPartial(object.imageListResp)
      : undefined;
    message.imageListUpd = (object.imageListUpd !== undefined && object.imageListUpd !== null)
      ? ImageListUpd.fromPartial(object.imageListUpd)
      : undefined;
    message.imageSetDefaultReq = (object.imageSetDefaultReq !== undefined && object.imageSetDefaultReq !== null)
      ? ImageSetDefaultReq.fromPartial(object.imageSetDefaultReq)
      : undefined;
    message.imageSetDefaultResp = (object.imageSetDefaultResp !== undefined && object.imageSetDefaultResp !== null)
      ? ImageSetDefaultResp.fromPartial(object.imageSetDefaultResp)
      : undefined;
    message.imageSetMatchTransformReq =
      (object.imageSetMatchTransformReq !== undefined && object.imageSetMatchTransformReq !== null)
        ? ImageSetMatchTransformReq.fromPartial(object.imageSetMatchTransformReq)
        : undefined;
    message.imageSetMatchTransformResp =
      (object.imageSetMatchTransformResp !== undefined && object.imageSetMatchTransformResp !== null)
        ? ImageSetMatchTransformResp.fromPartial(object.imageSetMatchTransformResp)
        : undefined;
    message.importMarsViewerImageReq =
      (object.importMarsViewerImageReq !== undefined && object.importMarsViewerImageReq !== null)
        ? ImportMarsViewerImageReq.fromPartial(object.importMarsViewerImageReq)
        : undefined;
    message.importMarsViewerImageResp =
      (object.importMarsViewerImageResp !== undefined && object.importMarsViewerImageResp !== null)
        ? ImportMarsViewerImageResp.fromPartial(object.importMarsViewerImageResp)
        : undefined;
    message.importMarsViewerImageUpd =
      (object.importMarsViewerImageUpd !== undefined && object.importMarsViewerImageUpd !== null)
        ? ImportMarsViewerImageUpd.fromPartial(object.importMarsViewerImageUpd)
        : undefined;
    message.jobListReq = (object.jobListReq !== undefined && object.jobListReq !== null)
      ? JobListReq.fromPartial(object.jobListReq)
      : undefined;
    message.jobListResp = (object.jobListResp !== undefined && object.jobListResp !== null)
      ? JobListResp.fromPartial(object.jobListResp)
      : undefined;
    message.jobListUpd = (object.jobListUpd !== undefined && object.jobListUpd !== null)
      ? JobListUpd.fromPartial(object.jobListUpd)
      : undefined;
    message.logGetLevelReq = (object.logGetLevelReq !== undefined && object.logGetLevelReq !== null)
      ? LogGetLevelReq.fromPartial(object.logGetLevelReq)
      : undefined;
    message.logGetLevelResp = (object.logGetLevelResp !== undefined && object.logGetLevelResp !== null)
      ? LogGetLevelResp.fromPartial(object.logGetLevelResp)
      : undefined;
    message.logReadReq = (object.logReadReq !== undefined && object.logReadReq !== null)
      ? LogReadReq.fromPartial(object.logReadReq)
      : undefined;
    message.logReadResp = (object.logReadResp !== undefined && object.logReadResp !== null)
      ? LogReadResp.fromPartial(object.logReadResp)
      : undefined;
    message.logSetLevelReq = (object.logSetLevelReq !== undefined && object.logSetLevelReq !== null)
      ? LogSetLevelReq.fromPartial(object.logSetLevelReq)
      : undefined;
    message.logSetLevelResp = (object.logSetLevelResp !== undefined && object.logSetLevelResp !== null)
      ? LogSetLevelResp.fromPartial(object.logSetLevelResp)
      : undefined;
    message.memoiseGetReq = (object.memoiseGetReq !== undefined && object.memoiseGetReq !== null)
      ? MemoiseGetReq.fromPartial(object.memoiseGetReq)
      : undefined;
    message.memoiseGetResp = (object.memoiseGetResp !== undefined && object.memoiseGetResp !== null)
      ? MemoiseGetResp.fromPartial(object.memoiseGetResp)
      : undefined;
    message.memoiseWriteReq = (object.memoiseWriteReq !== undefined && object.memoiseWriteReq !== null)
      ? MemoiseWriteReq.fromPartial(object.memoiseWriteReq)
      : undefined;
    message.memoiseWriteResp = (object.memoiseWriteResp !== undefined && object.memoiseWriteResp !== null)
      ? MemoiseWriteResp.fromPartial(object.memoiseWriteResp)
      : undefined;
    message.multiQuantCompareReq = (object.multiQuantCompareReq !== undefined && object.multiQuantCompareReq !== null)
      ? MultiQuantCompareReq.fromPartial(object.multiQuantCompareReq)
      : undefined;
    message.multiQuantCompareResp =
      (object.multiQuantCompareResp !== undefined && object.multiQuantCompareResp !== null)
        ? MultiQuantCompareResp.fromPartial(object.multiQuantCompareResp)
        : undefined;
    message.notificationDismissReq =
      (object.notificationDismissReq !== undefined && object.notificationDismissReq !== null)
        ? NotificationDismissReq.fromPartial(object.notificationDismissReq)
        : undefined;
    message.notificationDismissResp =
      (object.notificationDismissResp !== undefined && object.notificationDismissResp !== null)
        ? NotificationDismissResp.fromPartial(object.notificationDismissResp)
        : undefined;
    message.notificationReq = (object.notificationReq !== undefined && object.notificationReq !== null)
      ? NotificationReq.fromPartial(object.notificationReq)
      : undefined;
    message.notificationResp = (object.notificationResp !== undefined && object.notificationResp !== null)
      ? NotificationResp.fromPartial(object.notificationResp)
      : undefined;
    message.notificationUpd = (object.notificationUpd !== undefined && object.notificationUpd !== null)
      ? NotificationUpd.fromPartial(object.notificationUpd)
      : undefined;
    message.objectEditAccessReq = (object.objectEditAccessReq !== undefined && object.objectEditAccessReq !== null)
      ? ObjectEditAccessReq.fromPartial(object.objectEditAccessReq)
      : undefined;
    message.objectEditAccessResp = (object.objectEditAccessResp !== undefined && object.objectEditAccessResp !== null)
      ? ObjectEditAccessResp.fromPartial(object.objectEditAccessResp)
      : undefined;
    message.piquantConfigListReq = (object.piquantConfigListReq !== undefined && object.piquantConfigListReq !== null)
      ? PiquantConfigListReq.fromPartial(object.piquantConfigListReq)
      : undefined;
    message.piquantConfigListResp =
      (object.piquantConfigListResp !== undefined && object.piquantConfigListResp !== null)
        ? PiquantConfigListResp.fromPartial(object.piquantConfigListResp)
        : undefined;
    message.piquantConfigVersionReq =
      (object.piquantConfigVersionReq !== undefined && object.piquantConfigVersionReq !== null)
        ? PiquantConfigVersionReq.fromPartial(object.piquantConfigVersionReq)
        : undefined;
    message.piquantConfigVersionResp =
      (object.piquantConfigVersionResp !== undefined && object.piquantConfigVersionResp !== null)
        ? PiquantConfigVersionResp.fromPartial(object.piquantConfigVersionResp)
        : undefined;
    message.piquantConfigVersionsListReq =
      (object.piquantConfigVersionsListReq !== undefined && object.piquantConfigVersionsListReq !== null)
        ? PiquantConfigVersionsListReq.fromPartial(object.piquantConfigVersionsListReq)
        : undefined;
    message.piquantConfigVersionsListResp =
      (object.piquantConfigVersionsListResp !== undefined && object.piquantConfigVersionsListResp !== null)
        ? PiquantConfigVersionsListResp.fromPartial(object.piquantConfigVersionsListResp)
        : undefined;
    message.piquantCurrentVersionReq =
      (object.piquantCurrentVersionReq !== undefined && object.piquantCurrentVersionReq !== null)
        ? PiquantCurrentVersionReq.fromPartial(object.piquantCurrentVersionReq)
        : undefined;
    message.piquantCurrentVersionResp =
      (object.piquantCurrentVersionResp !== undefined && object.piquantCurrentVersionResp !== null)
        ? PiquantCurrentVersionResp.fromPartial(object.piquantCurrentVersionResp)
        : undefined;
    message.piquantVersionListReq =
      (object.piquantVersionListReq !== undefined && object.piquantVersionListReq !== null)
        ? PiquantVersionListReq.fromPartial(object.piquantVersionListReq)
        : undefined;
    message.piquantVersionListResp =
      (object.piquantVersionListResp !== undefined && object.piquantVersionListResp !== null)
        ? PiquantVersionListResp.fromPartial(object.piquantVersionListResp)
        : undefined;
    message.piquantWriteCurrentVersionReq =
      (object.piquantWriteCurrentVersionReq !== undefined && object.piquantWriteCurrentVersionReq !== null)
        ? PiquantWriteCurrentVersionReq.fromPartial(object.piquantWriteCurrentVersionReq)
        : undefined;
    message.piquantWriteCurrentVersionResp =
      (object.piquantWriteCurrentVersionResp !== undefined && object.piquantWriteCurrentVersionResp !== null)
        ? PiquantWriteCurrentVersionResp.fromPartial(object.piquantWriteCurrentVersionResp)
        : undefined;
    message.pseudoIntensityReq = (object.pseudoIntensityReq !== undefined && object.pseudoIntensityReq !== null)
      ? PseudoIntensityReq.fromPartial(object.pseudoIntensityReq)
      : undefined;
    message.pseudoIntensityResp = (object.pseudoIntensityResp !== undefined && object.pseudoIntensityResp !== null)
      ? PseudoIntensityResp.fromPartial(object.pseudoIntensityResp)
      : undefined;
    message.publishExpressionToZenodoReq =
      (object.publishExpressionToZenodoReq !== undefined && object.publishExpressionToZenodoReq !== null)
        ? PublishExpressionToZenodoReq.fromPartial(object.publishExpressionToZenodoReq)
        : undefined;
    message.publishExpressionToZenodoResp =
      (object.publishExpressionToZenodoResp !== undefined && object.publishExpressionToZenodoResp !== null)
        ? PublishExpressionToZenodoResp.fromPartial(object.publishExpressionToZenodoResp)
        : undefined;
    message.quantBlessReq = (object.quantBlessReq !== undefined && object.quantBlessReq !== null)
      ? QuantBlessReq.fromPartial(object.quantBlessReq)
      : undefined;
    message.quantBlessResp = (object.quantBlessResp !== undefined && object.quantBlessResp !== null)
      ? QuantBlessResp.fromPartial(object.quantBlessResp)
      : undefined;
    message.quantCombineListGetReq =
      (object.quantCombineListGetReq !== undefined && object.quantCombineListGetReq !== null)
        ? QuantCombineListGetReq.fromPartial(object.quantCombineListGetReq)
        : undefined;
    message.quantCombineListGetResp =
      (object.quantCombineListGetResp !== undefined && object.quantCombineListGetResp !== null)
        ? QuantCombineListGetResp.fromPartial(object.quantCombineListGetResp)
        : undefined;
    message.quantCombineListWriteReq =
      (object.quantCombineListWriteReq !== undefined && object.quantCombineListWriteReq !== null)
        ? QuantCombineListWriteReq.fromPartial(object.quantCombineListWriteReq)
        : undefined;
    message.quantCombineListWriteResp =
      (object.quantCombineListWriteResp !== undefined && object.quantCombineListWriteResp !== null)
        ? QuantCombineListWriteResp.fromPartial(object.quantCombineListWriteResp)
        : undefined;
    message.quantCombineReq = (object.quantCombineReq !== undefined && object.quantCombineReq !== null)
      ? QuantCombineReq.fromPartial(object.quantCombineReq)
      : undefined;
    message.quantCombineResp = (object.quantCombineResp !== undefined && object.quantCombineResp !== null)
      ? QuantCombineResp.fromPartial(object.quantCombineResp)
      : undefined;
    message.quantCreateReq = (object.quantCreateReq !== undefined && object.quantCreateReq !== null)
      ? QuantCreateReq.fromPartial(object.quantCreateReq)
      : undefined;
    message.quantCreateResp = (object.quantCreateResp !== undefined && object.quantCreateResp !== null)
      ? QuantCreateResp.fromPartial(object.quantCreateResp)
      : undefined;
    message.quantCreateUpd = (object.quantCreateUpd !== undefined && object.quantCreateUpd !== null)
      ? QuantCreateUpd.fromPartial(object.quantCreateUpd)
      : undefined;
    message.quantDeleteReq = (object.quantDeleteReq !== undefined && object.quantDeleteReq !== null)
      ? QuantDeleteReq.fromPartial(object.quantDeleteReq)
      : undefined;
    message.quantDeleteResp = (object.quantDeleteResp !== undefined && object.quantDeleteResp !== null)
      ? QuantDeleteResp.fromPartial(object.quantDeleteResp)
      : undefined;
    message.quantGetReq = (object.quantGetReq !== undefined && object.quantGetReq !== null)
      ? QuantGetReq.fromPartial(object.quantGetReq)
      : undefined;
    message.quantGetResp = (object.quantGetResp !== undefined && object.quantGetResp !== null)
      ? QuantGetResp.fromPartial(object.quantGetResp)
      : undefined;
    message.quantLastOutputGetReq =
      (object.quantLastOutputGetReq !== undefined && object.quantLastOutputGetReq !== null)
        ? QuantLastOutputGetReq.fromPartial(object.quantLastOutputGetReq)
        : undefined;
    message.quantLastOutputGetResp =
      (object.quantLastOutputGetResp !== undefined && object.quantLastOutputGetResp !== null)
        ? QuantLastOutputGetResp.fromPartial(object.quantLastOutputGetResp)
        : undefined;
    message.quantListReq = (object.quantListReq !== undefined && object.quantListReq !== null)
      ? QuantListReq.fromPartial(object.quantListReq)
      : undefined;
    message.quantListResp = (object.quantListResp !== undefined && object.quantListResp !== null)
      ? QuantListResp.fromPartial(object.quantListResp)
      : undefined;
    message.quantLogGetReq = (object.quantLogGetReq !== undefined && object.quantLogGetReq !== null)
      ? QuantLogGetReq.fromPartial(object.quantLogGetReq)
      : undefined;
    message.quantLogGetResp = (object.quantLogGetResp !== undefined && object.quantLogGetResp !== null)
      ? QuantLogGetResp.fromPartial(object.quantLogGetResp)
      : undefined;
    message.quantLogListReq = (object.quantLogListReq !== undefined && object.quantLogListReq !== null)
      ? QuantLogListReq.fromPartial(object.quantLogListReq)
      : undefined;
    message.quantLogListResp = (object.quantLogListResp !== undefined && object.quantLogListResp !== null)
      ? QuantLogListResp.fromPartial(object.quantLogListResp)
      : undefined;
    message.quantPublishReq = (object.quantPublishReq !== undefined && object.quantPublishReq !== null)
      ? QuantPublishReq.fromPartial(object.quantPublishReq)
      : undefined;
    message.quantPublishResp = (object.quantPublishResp !== undefined && object.quantPublishResp !== null)
      ? QuantPublishResp.fromPartial(object.quantPublishResp)
      : undefined;
    message.quantRawDataGetReq = (object.quantRawDataGetReq !== undefined && object.quantRawDataGetReq !== null)
      ? QuantRawDataGetReq.fromPartial(object.quantRawDataGetReq)
      : undefined;
    message.quantRawDataGetResp = (object.quantRawDataGetResp !== undefined && object.quantRawDataGetResp !== null)
      ? QuantRawDataGetResp.fromPartial(object.quantRawDataGetResp)
      : undefined;
    message.quantUploadReq = (object.quantUploadReq !== undefined && object.quantUploadReq !== null)
      ? QuantUploadReq.fromPartial(object.quantUploadReq)
      : undefined;
    message.quantUploadResp = (object.quantUploadResp !== undefined && object.quantUploadResp !== null)
      ? QuantUploadResp.fromPartial(object.quantUploadResp)
      : undefined;
    message.regionOfInterestBulkDuplicateReq =
      (object.regionOfInterestBulkDuplicateReq !== undefined && object.regionOfInterestBulkDuplicateReq !== null)
        ? RegionOfInterestBulkDuplicateReq.fromPartial(object.regionOfInterestBulkDuplicateReq)
        : undefined;
    message.regionOfInterestBulkDuplicateResp =
      (object.regionOfInterestBulkDuplicateResp !== undefined && object.regionOfInterestBulkDuplicateResp !== null)
        ? RegionOfInterestBulkDuplicateResp.fromPartial(object.regionOfInterestBulkDuplicateResp)
        : undefined;
    message.regionOfInterestBulkWriteReq =
      (object.regionOfInterestBulkWriteReq !== undefined && object.regionOfInterestBulkWriteReq !== null)
        ? RegionOfInterestBulkWriteReq.fromPartial(object.regionOfInterestBulkWriteReq)
        : undefined;
    message.regionOfInterestBulkWriteResp =
      (object.regionOfInterestBulkWriteResp !== undefined && object.regionOfInterestBulkWriteResp !== null)
        ? RegionOfInterestBulkWriteResp.fromPartial(object.regionOfInterestBulkWriteResp)
        : undefined;
    message.regionOfInterestDeleteReq =
      (object.regionOfInterestDeleteReq !== undefined && object.regionOfInterestDeleteReq !== null)
        ? RegionOfInterestDeleteReq.fromPartial(object.regionOfInterestDeleteReq)
        : undefined;
    message.regionOfInterestDeleteResp =
      (object.regionOfInterestDeleteResp !== undefined && object.regionOfInterestDeleteResp !== null)
        ? RegionOfInterestDeleteResp.fromPartial(object.regionOfInterestDeleteResp)
        : undefined;
    message.regionOfInterestDisplaySettingsGetReq =
      (object.regionOfInterestDisplaySettingsGetReq !== undefined &&
          object.regionOfInterestDisplaySettingsGetReq !== null)
        ? RegionOfInterestDisplaySettingsGetReq.fromPartial(object.regionOfInterestDisplaySettingsGetReq)
        : undefined;
    message.regionOfInterestDisplaySettingsGetResp =
      (object.regionOfInterestDisplaySettingsGetResp !== undefined &&
          object.regionOfInterestDisplaySettingsGetResp !== null)
        ? RegionOfInterestDisplaySettingsGetResp.fromPartial(object.regionOfInterestDisplaySettingsGetResp)
        : undefined;
    message.regionOfInterestDisplaySettingsWriteReq =
      (object.regionOfInterestDisplaySettingsWriteReq !== undefined &&
          object.regionOfInterestDisplaySettingsWriteReq !== null)
        ? RegionOfInterestDisplaySettingsWriteReq.fromPartial(object.regionOfInterestDisplaySettingsWriteReq)
        : undefined;
    message.regionOfInterestDisplaySettingsWriteResp =
      (object.regionOfInterestDisplaySettingsWriteResp !== undefined &&
          object.regionOfInterestDisplaySettingsWriteResp !== null)
        ? RegionOfInterestDisplaySettingsWriteResp.fromPartial(object.regionOfInterestDisplaySettingsWriteResp)
        : undefined;
    message.regionOfInterestGetReq =
      (object.regionOfInterestGetReq !== undefined && object.regionOfInterestGetReq !== null)
        ? RegionOfInterestGetReq.fromPartial(object.regionOfInterestGetReq)
        : undefined;
    message.regionOfInterestGetResp =
      (object.regionOfInterestGetResp !== undefined && object.regionOfInterestGetResp !== null)
        ? RegionOfInterestGetResp.fromPartial(object.regionOfInterestGetResp)
        : undefined;
    message.regionOfInterestListReq =
      (object.regionOfInterestListReq !== undefined && object.regionOfInterestListReq !== null)
        ? RegionOfInterestListReq.fromPartial(object.regionOfInterestListReq)
        : undefined;
    message.regionOfInterestListResp =
      (object.regionOfInterestListResp !== undefined && object.regionOfInterestListResp !== null)
        ? RegionOfInterestListResp.fromPartial(object.regionOfInterestListResp)
        : undefined;
    message.regionOfInterestWriteReq =
      (object.regionOfInterestWriteReq !== undefined && object.regionOfInterestWriteReq !== null)
        ? RegionOfInterestWriteReq.fromPartial(object.regionOfInterestWriteReq)
        : undefined;
    message.regionOfInterestWriteResp =
      (object.regionOfInterestWriteResp !== undefined && object.regionOfInterestWriteResp !== null)
        ? RegionOfInterestWriteResp.fromPartial(object.regionOfInterestWriteResp)
        : undefined;
    message.restoreDBReq = (object.restoreDBReq !== undefined && object.restoreDBReq !== null)
      ? RestoreDBReq.fromPartial(object.restoreDBReq)
      : undefined;
    message.restoreDBResp = (object.restoreDBResp !== undefined && object.restoreDBResp !== null)
      ? RestoreDBResp.fromPartial(object.restoreDBResp)
      : undefined;
    message.reviewerMagicLinkCreateReq =
      (object.reviewerMagicLinkCreateReq !== undefined && object.reviewerMagicLinkCreateReq !== null)
        ? ReviewerMagicLinkCreateReq.fromPartial(object.reviewerMagicLinkCreateReq)
        : undefined;
    message.reviewerMagicLinkCreateResp =
      (object.reviewerMagicLinkCreateResp !== undefined && object.reviewerMagicLinkCreateResp !== null)
        ? ReviewerMagicLinkCreateResp.fromPartial(object.reviewerMagicLinkCreateResp)
        : undefined;
    message.reviewerMagicLinkLoginReq =
      (object.reviewerMagicLinkLoginReq !== undefined && object.reviewerMagicLinkLoginReq !== null)
        ? ReviewerMagicLinkLoginReq.fromPartial(object.reviewerMagicLinkLoginReq)
        : undefined;
    message.reviewerMagicLinkLoginResp =
      (object.reviewerMagicLinkLoginResp !== undefined && object.reviewerMagicLinkLoginResp !== null)
        ? ReviewerMagicLinkLoginResp.fromPartial(object.reviewerMagicLinkLoginResp)
        : undefined;
    message.runTestReq = (object.runTestReq !== undefined && object.runTestReq !== null)
      ? RunTestReq.fromPartial(object.runTestReq)
      : undefined;
    message.runTestResp = (object.runTestResp !== undefined && object.runTestResp !== null)
      ? RunTestResp.fromPartial(object.runTestResp)
      : undefined;
    message.scanAutoShareReq = (object.scanAutoShareReq !== undefined && object.scanAutoShareReq !== null)
      ? ScanAutoShareReq.fromPartial(object.scanAutoShareReq)
      : undefined;
    message.scanAutoShareResp = (object.scanAutoShareResp !== undefined && object.scanAutoShareResp !== null)
      ? ScanAutoShareResp.fromPartial(object.scanAutoShareResp)
      : undefined;
    message.scanAutoShareWriteReq =
      (object.scanAutoShareWriteReq !== undefined && object.scanAutoShareWriteReq !== null)
        ? ScanAutoShareWriteReq.fromPartial(object.scanAutoShareWriteReq)
        : undefined;
    message.scanAutoShareWriteResp =
      (object.scanAutoShareWriteResp !== undefined && object.scanAutoShareWriteResp !== null)
        ? ScanAutoShareWriteResp.fromPartial(object.scanAutoShareWriteResp)
        : undefined;
    message.scanBeamLocationsReq = (object.scanBeamLocationsReq !== undefined && object.scanBeamLocationsReq !== null)
      ? ScanBeamLocationsReq.fromPartial(object.scanBeamLocationsReq)
      : undefined;
    message.scanBeamLocationsResp =
      (object.scanBeamLocationsResp !== undefined && object.scanBeamLocationsResp !== null)
        ? ScanBeamLocationsResp.fromPartial(object.scanBeamLocationsResp)
        : undefined;
    message.scanDeleteReq = (object.scanDeleteReq !== undefined && object.scanDeleteReq !== null)
      ? ScanDeleteReq.fromPartial(object.scanDeleteReq)
      : undefined;
    message.scanDeleteResp = (object.scanDeleteResp !== undefined && object.scanDeleteResp !== null)
      ? ScanDeleteResp.fromPartial(object.scanDeleteResp)
      : undefined;
    message.scanEntryMetadataReq = (object.scanEntryMetadataReq !== undefined && object.scanEntryMetadataReq !== null)
      ? ScanEntryMetadataReq.fromPartial(object.scanEntryMetadataReq)
      : undefined;
    message.scanEntryMetadataResp =
      (object.scanEntryMetadataResp !== undefined && object.scanEntryMetadataResp !== null)
        ? ScanEntryMetadataResp.fromPartial(object.scanEntryMetadataResp)
        : undefined;
    message.scanEntryReq = (object.scanEntryReq !== undefined && object.scanEntryReq !== null)
      ? ScanEntryReq.fromPartial(object.scanEntryReq)
      : undefined;
    message.scanEntryResp = (object.scanEntryResp !== undefined && object.scanEntryResp !== null)
      ? ScanEntryResp.fromPartial(object.scanEntryResp)
      : undefined;
    message.scanGetReq = (object.scanGetReq !== undefined && object.scanGetReq !== null)
      ? ScanGetReq.fromPartial(object.scanGetReq)
      : undefined;
    message.scanGetResp = (object.scanGetResp !== undefined && object.scanGetResp !== null)
      ? ScanGetResp.fromPartial(object.scanGetResp)
      : undefined;
    message.scanListReq = (object.scanListReq !== undefined && object.scanListReq !== null)
      ? ScanListReq.fromPartial(object.scanListReq)
      : undefined;
    message.scanListResp = (object.scanListResp !== undefined && object.scanListResp !== null)
      ? ScanListResp.fromPartial(object.scanListResp)
      : undefined;
    message.scanListUpd = (object.scanListUpd !== undefined && object.scanListUpd !== null)
      ? ScanListUpd.fromPartial(object.scanListUpd)
      : undefined;
    message.scanMetaLabelsAndTypesReq =
      (object.scanMetaLabelsAndTypesReq !== undefined && object.scanMetaLabelsAndTypesReq !== null)
        ? ScanMetaLabelsAndTypesReq.fromPartial(object.scanMetaLabelsAndTypesReq)
        : undefined;
    message.scanMetaLabelsAndTypesResp =
      (object.scanMetaLabelsAndTypesResp !== undefined && object.scanMetaLabelsAndTypesResp !== null)
        ? ScanMetaLabelsAndTypesResp.fromPartial(object.scanMetaLabelsAndTypesResp)
        : undefined;
    message.scanMetaWriteReq = (object.scanMetaWriteReq !== undefined && object.scanMetaWriteReq !== null)
      ? ScanMetaWriteReq.fromPartial(object.scanMetaWriteReq)
      : undefined;
    message.scanMetaWriteResp = (object.scanMetaWriteResp !== undefined && object.scanMetaWriteResp !== null)
      ? ScanMetaWriteResp.fromPartial(object.scanMetaWriteResp)
      : undefined;
    message.scanTriggerAutoQuantReq =
      (object.scanTriggerAutoQuantReq !== undefined && object.scanTriggerAutoQuantReq !== null)
        ? ScanTriggerAutoQuantReq.fromPartial(object.scanTriggerAutoQuantReq)
        : undefined;
    message.scanTriggerAutoQuantResp =
      (object.scanTriggerAutoQuantResp !== undefined && object.scanTriggerAutoQuantResp !== null)
        ? ScanTriggerAutoQuantResp.fromPartial(object.scanTriggerAutoQuantResp)
        : undefined;
    message.scanTriggerReImportReq =
      (object.scanTriggerReImportReq !== undefined && object.scanTriggerReImportReq !== null)
        ? ScanTriggerReImportReq.fromPartial(object.scanTriggerReImportReq)
        : undefined;
    message.scanTriggerReImportResp =
      (object.scanTriggerReImportResp !== undefined && object.scanTriggerReImportResp !== null)
        ? ScanTriggerReImportResp.fromPartial(object.scanTriggerReImportResp)
        : undefined;
    message.scanTriggerReImportUpd =
      (object.scanTriggerReImportUpd !== undefined && object.scanTriggerReImportUpd !== null)
        ? ScanTriggerReImportUpd.fromPartial(object.scanTriggerReImportUpd)
        : undefined;
    message.scanUploadReq = (object.scanUploadReq !== undefined && object.scanUploadReq !== null)
      ? ScanUploadReq.fromPartial(object.scanUploadReq)
      : undefined;
    message.scanUploadResp = (object.scanUploadResp !== undefined && object.scanUploadResp !== null)
      ? ScanUploadResp.fromPartial(object.scanUploadResp)
      : undefined;
    message.scanUploadUpd = (object.scanUploadUpd !== undefined && object.scanUploadUpd !== null)
      ? ScanUploadUpd.fromPartial(object.scanUploadUpd)
      : undefined;
    message.screenConfigurationDeleteReq =
      (object.screenConfigurationDeleteReq !== undefined && object.screenConfigurationDeleteReq !== null)
        ? ScreenConfigurationDeleteReq.fromPartial(object.screenConfigurationDeleteReq)
        : undefined;
    message.screenConfigurationDeleteResp =
      (object.screenConfigurationDeleteResp !== undefined && object.screenConfigurationDeleteResp !== null)
        ? ScreenConfigurationDeleteResp.fromPartial(object.screenConfigurationDeleteResp)
        : undefined;
    message.screenConfigurationGetReq =
      (object.screenConfigurationGetReq !== undefined && object.screenConfigurationGetReq !== null)
        ? ScreenConfigurationGetReq.fromPartial(object.screenConfigurationGetReq)
        : undefined;
    message.screenConfigurationGetResp =
      (object.screenConfigurationGetResp !== undefined && object.screenConfigurationGetResp !== null)
        ? ScreenConfigurationGetResp.fromPartial(object.screenConfigurationGetResp)
        : undefined;
    message.screenConfigurationListReq =
      (object.screenConfigurationListReq !== undefined && object.screenConfigurationListReq !== null)
        ? ScreenConfigurationListReq.fromPartial(object.screenConfigurationListReq)
        : undefined;
    message.screenConfigurationListResp =
      (object.screenConfigurationListResp !== undefined && object.screenConfigurationListResp !== null)
        ? ScreenConfigurationListResp.fromPartial(object.screenConfigurationListResp)
        : undefined;
    message.screenConfigurationWriteReq =
      (object.screenConfigurationWriteReq !== undefined && object.screenConfigurationWriteReq !== null)
        ? ScreenConfigurationWriteReq.fromPartial(object.screenConfigurationWriteReq)
        : undefined;
    message.screenConfigurationWriteResp =
      (object.screenConfigurationWriteResp !== undefined && object.screenConfigurationWriteResp !== null)
        ? ScreenConfigurationWriteResp.fromPartial(object.screenConfigurationWriteResp)
        : undefined;
    message.selectedImagePixelsReq =
      (object.selectedImagePixelsReq !== undefined && object.selectedImagePixelsReq !== null)
        ? SelectedImagePixelsReq.fromPartial(object.selectedImagePixelsReq)
        : undefined;
    message.selectedImagePixelsResp =
      (object.selectedImagePixelsResp !== undefined && object.selectedImagePixelsResp !== null)
        ? SelectedImagePixelsResp.fromPartial(object.selectedImagePixelsResp)
        : undefined;
    message.selectedImagePixelsWriteReq =
      (object.selectedImagePixelsWriteReq !== undefined && object.selectedImagePixelsWriteReq !== null)
        ? SelectedImagePixelsWriteReq.fromPartial(object.selectedImagePixelsWriteReq)
        : undefined;
    message.selectedImagePixelsWriteResp =
      (object.selectedImagePixelsWriteResp !== undefined && object.selectedImagePixelsWriteResp !== null)
        ? SelectedImagePixelsWriteResp.fromPartial(object.selectedImagePixelsWriteResp)
        : undefined;
    message.selectedScanEntriesReq =
      (object.selectedScanEntriesReq !== undefined && object.selectedScanEntriesReq !== null)
        ? SelectedScanEntriesReq.fromPartial(object.selectedScanEntriesReq)
        : undefined;
    message.selectedScanEntriesResp =
      (object.selectedScanEntriesResp !== undefined && object.selectedScanEntriesResp !== null)
        ? SelectedScanEntriesResp.fromPartial(object.selectedScanEntriesResp)
        : undefined;
    message.selectedScanEntriesWriteReq =
      (object.selectedScanEntriesWriteReq !== undefined && object.selectedScanEntriesWriteReq !== null)
        ? SelectedScanEntriesWriteReq.fromPartial(object.selectedScanEntriesWriteReq)
        : undefined;
    message.selectedScanEntriesWriteResp =
      (object.selectedScanEntriesWriteResp !== undefined && object.selectedScanEntriesWriteResp !== null)
        ? SelectedScanEntriesWriteResp.fromPartial(object.selectedScanEntriesWriteResp)
        : undefined;
    message.sendUserNotificationReq =
      (object.sendUserNotificationReq !== undefined && object.sendUserNotificationReq !== null)
        ? SendUserNotificationReq.fromPartial(object.sendUserNotificationReq)
        : undefined;
    message.sendUserNotificationResp =
      (object.sendUserNotificationResp !== undefined && object.sendUserNotificationResp !== null)
        ? SendUserNotificationResp.fromPartial(object.sendUserNotificationResp)
        : undefined;
    message.spectrumReq = (object.spectrumReq !== undefined && object.spectrumReq !== null)
      ? SpectrumReq.fromPartial(object.spectrumReq)
      : undefined;
    message.spectrumResp = (object.spectrumResp !== undefined && object.spectrumResp !== null)
      ? SpectrumResp.fromPartial(object.spectrumResp)
      : undefined;
    message.tagCreateReq = (object.tagCreateReq !== undefined && object.tagCreateReq !== null)
      ? TagCreateReq.fromPartial(object.tagCreateReq)
      : undefined;
    message.tagCreateResp = (object.tagCreateResp !== undefined && object.tagCreateResp !== null)
      ? TagCreateResp.fromPartial(object.tagCreateResp)
      : undefined;
    message.tagDeleteReq = (object.tagDeleteReq !== undefined && object.tagDeleteReq !== null)
      ? TagDeleteReq.fromPartial(object.tagDeleteReq)
      : undefined;
    message.tagDeleteResp = (object.tagDeleteResp !== undefined && object.tagDeleteResp !== null)
      ? TagDeleteResp.fromPartial(object.tagDeleteResp)
      : undefined;
    message.tagListReq = (object.tagListReq !== undefined && object.tagListReq !== null)
      ? TagListReq.fromPartial(object.tagListReq)
      : undefined;
    message.tagListResp = (object.tagListResp !== undefined && object.tagListResp !== null)
      ? TagListResp.fromPartial(object.tagListResp)
      : undefined;
    message.userAddRoleReq = (object.userAddRoleReq !== undefined && object.userAddRoleReq !== null)
      ? UserAddRoleReq.fromPartial(object.userAddRoleReq)
      : undefined;
    message.userAddRoleResp = (object.userAddRoleResp !== undefined && object.userAddRoleResp !== null)
      ? UserAddRoleResp.fromPartial(object.userAddRoleResp)
      : undefined;
    message.userDeleteRoleReq = (object.userDeleteRoleReq !== undefined && object.userDeleteRoleReq !== null)
      ? UserDeleteRoleReq.fromPartial(object.userDeleteRoleReq)
      : undefined;
    message.userDeleteRoleResp = (object.userDeleteRoleResp !== undefined && object.userDeleteRoleResp !== null)
      ? UserDeleteRoleResp.fromPartial(object.userDeleteRoleResp)
      : undefined;
    message.userDetailsReq = (object.userDetailsReq !== undefined && object.userDetailsReq !== null)
      ? UserDetailsReq.fromPartial(object.userDetailsReq)
      : undefined;
    message.userDetailsResp = (object.userDetailsResp !== undefined && object.userDetailsResp !== null)
      ? UserDetailsResp.fromPartial(object.userDetailsResp)
      : undefined;
    message.userDetailsWriteReq = (object.userDetailsWriteReq !== undefined && object.userDetailsWriteReq !== null)
      ? UserDetailsWriteReq.fromPartial(object.userDetailsWriteReq)
      : undefined;
    message.userDetailsWriteResp = (object.userDetailsWriteResp !== undefined && object.userDetailsWriteResp !== null)
      ? UserDetailsWriteResp.fromPartial(object.userDetailsWriteResp)
      : undefined;
    message.userGroupAddAdminReq = (object.userGroupAddAdminReq !== undefined && object.userGroupAddAdminReq !== null)
      ? UserGroupAddAdminReq.fromPartial(object.userGroupAddAdminReq)
      : undefined;
    message.userGroupAddAdminResp =
      (object.userGroupAddAdminResp !== undefined && object.userGroupAddAdminResp !== null)
        ? UserGroupAddAdminResp.fromPartial(object.userGroupAddAdminResp)
        : undefined;
    message.userGroupAddMemberReq =
      (object.userGroupAddMemberReq !== undefined && object.userGroupAddMemberReq !== null)
        ? UserGroupAddMemberReq.fromPartial(object.userGroupAddMemberReq)
        : undefined;
    message.userGroupAddMemberResp =
      (object.userGroupAddMemberResp !== undefined && object.userGroupAddMemberResp !== null)
        ? UserGroupAddMemberResp.fromPartial(object.userGroupAddMemberResp)
        : undefined;
    message.userGroupAddViewerReq =
      (object.userGroupAddViewerReq !== undefined && object.userGroupAddViewerReq !== null)
        ? UserGroupAddViewerReq.fromPartial(object.userGroupAddViewerReq)
        : undefined;
    message.userGroupAddViewerResp =
      (object.userGroupAddViewerResp !== undefined && object.userGroupAddViewerResp !== null)
        ? UserGroupAddViewerResp.fromPartial(object.userGroupAddViewerResp)
        : undefined;
    message.userGroupCreateReq = (object.userGroupCreateReq !== undefined && object.userGroupCreateReq !== null)
      ? UserGroupCreateReq.fromPartial(object.userGroupCreateReq)
      : undefined;
    message.userGroupCreateResp = (object.userGroupCreateResp !== undefined && object.userGroupCreateResp !== null)
      ? UserGroupCreateResp.fromPartial(object.userGroupCreateResp)
      : undefined;
    message.userGroupDeleteAdminReq =
      (object.userGroupDeleteAdminReq !== undefined && object.userGroupDeleteAdminReq !== null)
        ? UserGroupDeleteAdminReq.fromPartial(object.userGroupDeleteAdminReq)
        : undefined;
    message.userGroupDeleteAdminResp =
      (object.userGroupDeleteAdminResp !== undefined && object.userGroupDeleteAdminResp !== null)
        ? UserGroupDeleteAdminResp.fromPartial(object.userGroupDeleteAdminResp)
        : undefined;
    message.userGroupDeleteMemberReq =
      (object.userGroupDeleteMemberReq !== undefined && object.userGroupDeleteMemberReq !== null)
        ? UserGroupDeleteMemberReq.fromPartial(object.userGroupDeleteMemberReq)
        : undefined;
    message.userGroupDeleteMemberResp =
      (object.userGroupDeleteMemberResp !== undefined && object.userGroupDeleteMemberResp !== null)
        ? UserGroupDeleteMemberResp.fromPartial(object.userGroupDeleteMemberResp)
        : undefined;
    message.userGroupDeleteReq = (object.userGroupDeleteReq !== undefined && object.userGroupDeleteReq !== null)
      ? UserGroupDeleteReq.fromPartial(object.userGroupDeleteReq)
      : undefined;
    message.userGroupDeleteResp = (object.userGroupDeleteResp !== undefined && object.userGroupDeleteResp !== null)
      ? UserGroupDeleteResp.fromPartial(object.userGroupDeleteResp)
      : undefined;
    message.userGroupDeleteViewerReq =
      (object.userGroupDeleteViewerReq !== undefined && object.userGroupDeleteViewerReq !== null)
        ? UserGroupDeleteViewerReq.fromPartial(object.userGroupDeleteViewerReq)
        : undefined;
    message.userGroupDeleteViewerResp =
      (object.userGroupDeleteViewerResp !== undefined && object.userGroupDeleteViewerResp !== null)
        ? UserGroupDeleteViewerResp.fromPartial(object.userGroupDeleteViewerResp)
        : undefined;
    message.userGroupEditDetailsReq =
      (object.userGroupEditDetailsReq !== undefined && object.userGroupEditDetailsReq !== null)
        ? UserGroupEditDetailsReq.fromPartial(object.userGroupEditDetailsReq)
        : undefined;
    message.userGroupEditDetailsResp =
      (object.userGroupEditDetailsResp !== undefined && object.userGroupEditDetailsResp !== null)
        ? UserGroupEditDetailsResp.fromPartial(object.userGroupEditDetailsResp)
        : undefined;
    message.userGroupIgnoreJoinReq =
      (object.userGroupIgnoreJoinReq !== undefined && object.userGroupIgnoreJoinReq !== null)
        ? UserGroupIgnoreJoinReq.fromPartial(object.userGroupIgnoreJoinReq)
        : undefined;
    message.userGroupIgnoreJoinResp =
      (object.userGroupIgnoreJoinResp !== undefined && object.userGroupIgnoreJoinResp !== null)
        ? UserGroupIgnoreJoinResp.fromPartial(object.userGroupIgnoreJoinResp)
        : undefined;
    message.userGroupJoinListReq = (object.userGroupJoinListReq !== undefined && object.userGroupJoinListReq !== null)
      ? UserGroupJoinListReq.fromPartial(object.userGroupJoinListReq)
      : undefined;
    message.userGroupJoinListResp =
      (object.userGroupJoinListResp !== undefined && object.userGroupJoinListResp !== null)
        ? UserGroupJoinListResp.fromPartial(object.userGroupJoinListResp)
        : undefined;
    message.userGroupJoinReq = (object.userGroupJoinReq !== undefined && object.userGroupJoinReq !== null)
      ? UserGroupJoinReq.fromPartial(object.userGroupJoinReq)
      : undefined;
    message.userGroupJoinResp = (object.userGroupJoinResp !== undefined && object.userGroupJoinResp !== null)
      ? UserGroupJoinResp.fromPartial(object.userGroupJoinResp)
      : undefined;
    message.userGroupListJoinableReq =
      (object.userGroupListJoinableReq !== undefined && object.userGroupListJoinableReq !== null)
        ? UserGroupListJoinableReq.fromPartial(object.userGroupListJoinableReq)
        : undefined;
    message.userGroupListJoinableResp =
      (object.userGroupListJoinableResp !== undefined && object.userGroupListJoinableResp !== null)
        ? UserGroupListJoinableResp.fromPartial(object.userGroupListJoinableResp)
        : undefined;
    message.userGroupListReq = (object.userGroupListReq !== undefined && object.userGroupListReq !== null)
      ? UserGroupListReq.fromPartial(object.userGroupListReq)
      : undefined;
    message.userGroupListResp = (object.userGroupListResp !== undefined && object.userGroupListResp !== null)
      ? UserGroupListResp.fromPartial(object.userGroupListResp)
      : undefined;
    message.userGroupReq = (object.userGroupReq !== undefined && object.userGroupReq !== null)
      ? UserGroupReq.fromPartial(object.userGroupReq)
      : undefined;
    message.userGroupResp = (object.userGroupResp !== undefined && object.userGroupResp !== null)
      ? UserGroupResp.fromPartial(object.userGroupResp)
      : undefined;
    message.userImpersonateGetReq =
      (object.userImpersonateGetReq !== undefined && object.userImpersonateGetReq !== null)
        ? UserImpersonateGetReq.fromPartial(object.userImpersonateGetReq)
        : undefined;
    message.userImpersonateGetResp =
      (object.userImpersonateGetResp !== undefined && object.userImpersonateGetResp !== null)
        ? UserImpersonateGetResp.fromPartial(object.userImpersonateGetResp)
        : undefined;
    message.userImpersonateReq = (object.userImpersonateReq !== undefined && object.userImpersonateReq !== null)
      ? UserImpersonateReq.fromPartial(object.userImpersonateReq)
      : undefined;
    message.userImpersonateResp = (object.userImpersonateResp !== undefined && object.userImpersonateResp !== null)
      ? UserImpersonateResp.fromPartial(object.userImpersonateResp)
      : undefined;
    message.userListReq = (object.userListReq !== undefined && object.userListReq !== null)
      ? UserListReq.fromPartial(object.userListReq)
      : undefined;
    message.userListResp = (object.userListResp !== undefined && object.userListResp !== null)
      ? UserListResp.fromPartial(object.userListResp)
      : undefined;
    message.userNotificationSettingsReq =
      (object.userNotificationSettingsReq !== undefined && object.userNotificationSettingsReq !== null)
        ? UserNotificationSettingsReq.fromPartial(object.userNotificationSettingsReq)
        : undefined;
    message.userNotificationSettingsResp =
      (object.userNotificationSettingsResp !== undefined && object.userNotificationSettingsResp !== null)
        ? UserNotificationSettingsResp.fromPartial(object.userNotificationSettingsResp)
        : undefined;
    message.userNotificationSettingsUpd =
      (object.userNotificationSettingsUpd !== undefined && object.userNotificationSettingsUpd !== null)
        ? UserNotificationSettingsUpd.fromPartial(object.userNotificationSettingsUpd)
        : undefined;
    message.userNotificationSettingsWriteReq =
      (object.userNotificationSettingsWriteReq !== undefined && object.userNotificationSettingsWriteReq !== null)
        ? UserNotificationSettingsWriteReq.fromPartial(object.userNotificationSettingsWriteReq)
        : undefined;
    message.userNotificationSettingsWriteResp =
      (object.userNotificationSettingsWriteResp !== undefined && object.userNotificationSettingsWriteResp !== null)
        ? UserNotificationSettingsWriteResp.fromPartial(object.userNotificationSettingsWriteResp)
        : undefined;
    message.userRoleListReq = (object.userRoleListReq !== undefined && object.userRoleListReq !== null)
      ? UserRoleListReq.fromPartial(object.userRoleListReq)
      : undefined;
    message.userRoleListResp = (object.userRoleListResp !== undefined && object.userRoleListResp !== null)
      ? UserRoleListResp.fromPartial(object.userRoleListResp)
      : undefined;
    message.userRolesListReq = (object.userRolesListReq !== undefined && object.userRolesListReq !== null)
      ? UserRolesListReq.fromPartial(object.userRolesListReq)
      : undefined;
    message.userRolesListResp = (object.userRolesListResp !== undefined && object.userRolesListResp !== null)
      ? UserRolesListResp.fromPartial(object.userRolesListResp)
      : undefined;
    message.userSearchReq = (object.userSearchReq !== undefined && object.userSearchReq !== null)
      ? UserSearchReq.fromPartial(object.userSearchReq)
      : undefined;
    message.userSearchResp = (object.userSearchResp !== undefined && object.userSearchResp !== null)
      ? UserSearchResp.fromPartial(object.userSearchResp)
      : undefined;
    message.widgetDataGetReq = (object.widgetDataGetReq !== undefined && object.widgetDataGetReq !== null)
      ? WidgetDataGetReq.fromPartial(object.widgetDataGetReq)
      : undefined;
    message.widgetDataGetResp = (object.widgetDataGetResp !== undefined && object.widgetDataGetResp !== null)
      ? WidgetDataGetResp.fromPartial(object.widgetDataGetResp)
      : undefined;
    message.widgetDataWriteReq = (object.widgetDataWriteReq !== undefined && object.widgetDataWriteReq !== null)
      ? WidgetDataWriteReq.fromPartial(object.widgetDataWriteReq)
      : undefined;
    message.widgetDataWriteResp = (object.widgetDataWriteResp !== undefined && object.widgetDataWriteResp !== null)
      ? WidgetDataWriteResp.fromPartial(object.widgetDataWriteResp)
      : undefined;
    message.zenodoDOIGetReq = (object.zenodoDOIGetReq !== undefined && object.zenodoDOIGetReq !== null)
      ? ZenodoDOIGetReq.fromPartial(object.zenodoDOIGetReq)
      : undefined;
    message.zenodoDOIGetResp = (object.zenodoDOIGetResp !== undefined && object.zenodoDOIGetResp !== null)
      ? ZenodoDOIGetResp.fromPartial(object.zenodoDOIGetResp)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
