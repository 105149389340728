/* eslint-disable */
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Auth0UserDetails, Auth0UserRole, UserInfo } from "./user";

export const protobufPackage = "";

/** requires(USER_ADMIN) */
export interface UserListReq {
  /** Get all users who have a role */
  roleId?:
    | string
    | undefined;
  /** Get a specific user by ID */
  userId?:
    | string
    | undefined;
  /** Get all users who have searchText in their name/surname */
  searchText?: string | undefined;
}

export interface UserListResp {
  details: Auth0UserDetails[];
}

/**
 * //////////////////////////////////
 * Get all user roles
 * requires(USER_ADMIN)
 */
export interface UserRoleListReq {
}

export interface UserRoleListResp {
  roles: Auth0UserRole[];
}

/**
 * //////////////////////////////////
 * Get roles for a given user
 * requires(USER_ADMIN)
 */
export interface UserRolesListReq {
  userId: string;
}

export interface UserRolesListResp {
  roles: Auth0UserRole[];
}

/**
 * //////////////////////////////////
 * Add a user role to a user
 * requires(USER_ADMIN)
 */
export interface UserAddRoleReq {
  userId: string;
  roleId: string;
}

/** Changing user roles, this should publish a UserDetailsUpd */
export interface UserAddRoleResp {
}

/**
 * //////////////////////////////////
 * Delete a user role for a user
 * requires(USER_ADMIN)
 */
export interface UserDeleteRoleReq {
  userId: string;
  roleId: string;
}

/** Changing user roles, this should publish a UserDetailsUpd */
export interface UserDeleteRoleResp {
}

/** requires(PIXLISE_ADMIN) */
export interface UserImpersonateReq {
  /** "Become" the user with specified id. If this is blank, this session stops impersonating */
  userId: string;
}

export interface UserImpersonateResp {
  /** Returns the currently set user in this user session */
  sessionUser: UserInfo | undefined;
}

/**
 * Allows querying if we're impersonating anyone
 * requires(NONE)
 */
export interface UserImpersonateGetReq {
}

export interface UserImpersonateGetResp {
  /** Returns the currently set user in this user session */
  sessionUser: UserInfo | undefined;
}

/** requires(NONE) */
export interface ReviewerMagicLinkCreateReq {
  accessLength: number;
  workspaceId: string;
  clientId: string;
  audience: string;
}

export interface ReviewerMagicLinkCreateResp {
  magicLink: string;
}

/** requires(NONE) */
export interface ReviewerMagicLinkLoginReq {
  magicLink: string;
  clientId: string;
  domain: string;
  audience: string;
  redirectURI: string;
}

export interface ReviewerMagicLinkLoginResp {
  userId: string;
  token: string;
  email: string;
  nonSecretPassword: string;
}

function createBaseUserListReq(): UserListReq {
  return { roleId: undefined, userId: undefined, searchText: undefined };
}

export const UserListReq = {
  encode(message: UserListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roleId !== undefined) {
      writer.uint32(10).string(message.roleId);
    }
    if (message.userId !== undefined) {
      writer.uint32(18).string(message.userId);
    }
    if (message.searchText !== undefined) {
      writer.uint32(26).string(message.searchText);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchText = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserListReq {
    return {
      roleId: isSet(object.roleId) ? String(object.roleId) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      searchText: isSet(object.searchText) ? String(object.searchText) : undefined,
    };
  },

  toJSON(message: UserListReq): unknown {
    const obj: any = {};
    message.roleId !== undefined && (obj.roleId = message.roleId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.searchText !== undefined && (obj.searchText = message.searchText);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserListReq>, I>>(base?: I): UserListReq {
    return UserListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserListReq>, I>>(object: I): UserListReq {
    const message = createBaseUserListReq();
    message.roleId = object.roleId ?? undefined;
    message.userId = object.userId ?? undefined;
    message.searchText = object.searchText ?? undefined;
    return message;
  },
};

function createBaseUserListResp(): UserListResp {
  return { details: [] };
}

export const UserListResp = {
  encode(message: UserListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.details) {
      Auth0UserDetails.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.details.push(Auth0UserDetails.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserListResp {
    return {
      details: Array.isArray(object?.details) ? object.details.map((e: any) => Auth0UserDetails.fromJSON(e)) : [],
    };
  },

  toJSON(message: UserListResp): unknown {
    const obj: any = {};
    if (message.details) {
      obj.details = message.details.map((e) => e ? Auth0UserDetails.toJSON(e) : undefined);
    } else {
      obj.details = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserListResp>, I>>(base?: I): UserListResp {
    return UserListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserListResp>, I>>(object: I): UserListResp {
    const message = createBaseUserListResp();
    message.details = object.details?.map((e) => Auth0UserDetails.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserRoleListReq(): UserRoleListReq {
  return {};
}

export const UserRoleListReq = {
  encode(_: UserRoleListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRoleListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRoleListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserRoleListReq {
    return {};
  },

  toJSON(_: UserRoleListReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserRoleListReq>, I>>(base?: I): UserRoleListReq {
    return UserRoleListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserRoleListReq>, I>>(_: I): UserRoleListReq {
    const message = createBaseUserRoleListReq();
    return message;
  },
};

function createBaseUserRoleListResp(): UserRoleListResp {
  return { roles: [] };
}

export const UserRoleListResp = {
  encode(message: UserRoleListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      Auth0UserRole.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRoleListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRoleListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(Auth0UserRole.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserRoleListResp {
    return { roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Auth0UserRole.fromJSON(e)) : [] };
  },

  toJSON(message: UserRoleListResp): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? Auth0UserRole.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserRoleListResp>, I>>(base?: I): UserRoleListResp {
    return UserRoleListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserRoleListResp>, I>>(object: I): UserRoleListResp {
    const message = createBaseUserRoleListResp();
    message.roles = object.roles?.map((e) => Auth0UserRole.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserRolesListReq(): UserRolesListReq {
  return { userId: "" };
}

export const UserRolesListReq = {
  encode(message: UserRolesListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRolesListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRolesListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserRolesListReq {
    return { userId: isSet(object.userId) ? String(object.userId) : "" };
  },

  toJSON(message: UserRolesListReq): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserRolesListReq>, I>>(base?: I): UserRolesListReq {
    return UserRolesListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserRolesListReq>, I>>(object: I): UserRolesListReq {
    const message = createBaseUserRolesListReq();
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseUserRolesListResp(): UserRolesListResp {
  return { roles: [] };
}

export const UserRolesListResp = {
  encode(message: UserRolesListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      Auth0UserRole.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRolesListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRolesListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(Auth0UserRole.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserRolesListResp {
    return { roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Auth0UserRole.fromJSON(e)) : [] };
  },

  toJSON(message: UserRolesListResp): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? Auth0UserRole.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserRolesListResp>, I>>(base?: I): UserRolesListResp {
    return UserRolesListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserRolesListResp>, I>>(object: I): UserRolesListResp {
    const message = createBaseUserRolesListResp();
    message.roles = object.roles?.map((e) => Auth0UserRole.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserAddRoleReq(): UserAddRoleReq {
  return { userId: "", roleId: "" };
}

export const UserAddRoleReq = {
  encode(message: UserAddRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.roleId !== "") {
      writer.uint32(18).string(message.roleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAddRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAddRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserAddRoleReq {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      roleId: isSet(object.roleId) ? String(object.roleId) : "",
    };
  },

  toJSON(message: UserAddRoleReq): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.roleId !== undefined && (obj.roleId = message.roleId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserAddRoleReq>, I>>(base?: I): UserAddRoleReq {
    return UserAddRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserAddRoleReq>, I>>(object: I): UserAddRoleReq {
    const message = createBaseUserAddRoleReq();
    message.userId = object.userId ?? "";
    message.roleId = object.roleId ?? "";
    return message;
  },
};

function createBaseUserAddRoleResp(): UserAddRoleResp {
  return {};
}

export const UserAddRoleResp = {
  encode(_: UserAddRoleResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAddRoleResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAddRoleResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserAddRoleResp {
    return {};
  },

  toJSON(_: UserAddRoleResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserAddRoleResp>, I>>(base?: I): UserAddRoleResp {
    return UserAddRoleResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserAddRoleResp>, I>>(_: I): UserAddRoleResp {
    const message = createBaseUserAddRoleResp();
    return message;
  },
};

function createBaseUserDeleteRoleReq(): UserDeleteRoleReq {
  return { userId: "", roleId: "" };
}

export const UserDeleteRoleReq = {
  encode(message: UserDeleteRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.roleId !== "") {
      writer.uint32(18).string(message.roleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDeleteRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDeleteRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDeleteRoleReq {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      roleId: isSet(object.roleId) ? String(object.roleId) : "",
    };
  },

  toJSON(message: UserDeleteRoleReq): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.roleId !== undefined && (obj.roleId = message.roleId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDeleteRoleReq>, I>>(base?: I): UserDeleteRoleReq {
    return UserDeleteRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDeleteRoleReq>, I>>(object: I): UserDeleteRoleReq {
    const message = createBaseUserDeleteRoleReq();
    message.userId = object.userId ?? "";
    message.roleId = object.roleId ?? "";
    return message;
  },
};

function createBaseUserDeleteRoleResp(): UserDeleteRoleResp {
  return {};
}

export const UserDeleteRoleResp = {
  encode(_: UserDeleteRoleResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDeleteRoleResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDeleteRoleResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserDeleteRoleResp {
    return {};
  },

  toJSON(_: UserDeleteRoleResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDeleteRoleResp>, I>>(base?: I): UserDeleteRoleResp {
    return UserDeleteRoleResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDeleteRoleResp>, I>>(_: I): UserDeleteRoleResp {
    const message = createBaseUserDeleteRoleResp();
    return message;
  },
};

function createBaseUserImpersonateReq(): UserImpersonateReq {
  return { userId: "" };
}

export const UserImpersonateReq = {
  encode(message: UserImpersonateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserImpersonateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserImpersonateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserImpersonateReq {
    return { userId: isSet(object.userId) ? String(object.userId) : "" };
  },

  toJSON(message: UserImpersonateReq): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserImpersonateReq>, I>>(base?: I): UserImpersonateReq {
    return UserImpersonateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserImpersonateReq>, I>>(object: I): UserImpersonateReq {
    const message = createBaseUserImpersonateReq();
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseUserImpersonateResp(): UserImpersonateResp {
  return { sessionUser: undefined };
}

export const UserImpersonateResp = {
  encode(message: UserImpersonateResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionUser !== undefined) {
      UserInfo.encode(message.sessionUser, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserImpersonateResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserImpersonateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionUser = UserInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserImpersonateResp {
    return { sessionUser: isSet(object.sessionUser) ? UserInfo.fromJSON(object.sessionUser) : undefined };
  },

  toJSON(message: UserImpersonateResp): unknown {
    const obj: any = {};
    message.sessionUser !== undefined &&
      (obj.sessionUser = message.sessionUser ? UserInfo.toJSON(message.sessionUser) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserImpersonateResp>, I>>(base?: I): UserImpersonateResp {
    return UserImpersonateResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserImpersonateResp>, I>>(object: I): UserImpersonateResp {
    const message = createBaseUserImpersonateResp();
    message.sessionUser = (object.sessionUser !== undefined && object.sessionUser !== null)
      ? UserInfo.fromPartial(object.sessionUser)
      : undefined;
    return message;
  },
};

function createBaseUserImpersonateGetReq(): UserImpersonateGetReq {
  return {};
}

export const UserImpersonateGetReq = {
  encode(_: UserImpersonateGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserImpersonateGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserImpersonateGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserImpersonateGetReq {
    return {};
  },

  toJSON(_: UserImpersonateGetReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserImpersonateGetReq>, I>>(base?: I): UserImpersonateGetReq {
    return UserImpersonateGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserImpersonateGetReq>, I>>(_: I): UserImpersonateGetReq {
    const message = createBaseUserImpersonateGetReq();
    return message;
  },
};

function createBaseUserImpersonateGetResp(): UserImpersonateGetResp {
  return { sessionUser: undefined };
}

export const UserImpersonateGetResp = {
  encode(message: UserImpersonateGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionUser !== undefined) {
      UserInfo.encode(message.sessionUser, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserImpersonateGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserImpersonateGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionUser = UserInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserImpersonateGetResp {
    return { sessionUser: isSet(object.sessionUser) ? UserInfo.fromJSON(object.sessionUser) : undefined };
  },

  toJSON(message: UserImpersonateGetResp): unknown {
    const obj: any = {};
    message.sessionUser !== undefined &&
      (obj.sessionUser = message.sessionUser ? UserInfo.toJSON(message.sessionUser) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserImpersonateGetResp>, I>>(base?: I): UserImpersonateGetResp {
    return UserImpersonateGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserImpersonateGetResp>, I>>(object: I): UserImpersonateGetResp {
    const message = createBaseUserImpersonateGetResp();
    message.sessionUser = (object.sessionUser !== undefined && object.sessionUser !== null)
      ? UserInfo.fromPartial(object.sessionUser)
      : undefined;
    return message;
  },
};

function createBaseReviewerMagicLinkCreateReq(): ReviewerMagicLinkCreateReq {
  return { accessLength: 0, workspaceId: "", clientId: "", audience: "" };
}

export const ReviewerMagicLinkCreateReq = {
  encode(message: ReviewerMagicLinkCreateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accessLength !== 0) {
      writer.uint32(8).int64(message.accessLength);
    }
    if (message.workspaceId !== "") {
      writer.uint32(18).string(message.workspaceId);
    }
    if (message.clientId !== "") {
      writer.uint32(26).string(message.clientId);
    }
    if (message.audience !== "") {
      writer.uint32(34).string(message.audience);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReviewerMagicLinkCreateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReviewerMagicLinkCreateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accessLength = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.workspaceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.audience = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReviewerMagicLinkCreateReq {
    return {
      accessLength: isSet(object.accessLength) ? Number(object.accessLength) : 0,
      workspaceId: isSet(object.workspaceId) ? String(object.workspaceId) : "",
      clientId: isSet(object.clientId) ? String(object.clientId) : "",
      audience: isSet(object.audience) ? String(object.audience) : "",
    };
  },

  toJSON(message: ReviewerMagicLinkCreateReq): unknown {
    const obj: any = {};
    message.accessLength !== undefined && (obj.accessLength = Math.round(message.accessLength));
    message.workspaceId !== undefined && (obj.workspaceId = message.workspaceId);
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.audience !== undefined && (obj.audience = message.audience);
    return obj;
  },

  create<I extends Exact<DeepPartial<ReviewerMagicLinkCreateReq>, I>>(base?: I): ReviewerMagicLinkCreateReq {
    return ReviewerMagicLinkCreateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ReviewerMagicLinkCreateReq>, I>>(object: I): ReviewerMagicLinkCreateReq {
    const message = createBaseReviewerMagicLinkCreateReq();
    message.accessLength = object.accessLength ?? 0;
    message.workspaceId = object.workspaceId ?? "";
    message.clientId = object.clientId ?? "";
    message.audience = object.audience ?? "";
    return message;
  },
};

function createBaseReviewerMagicLinkCreateResp(): ReviewerMagicLinkCreateResp {
  return { magicLink: "" };
}

export const ReviewerMagicLinkCreateResp = {
  encode(message: ReviewerMagicLinkCreateResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.magicLink !== "") {
      writer.uint32(10).string(message.magicLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReviewerMagicLinkCreateResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReviewerMagicLinkCreateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.magicLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReviewerMagicLinkCreateResp {
    return { magicLink: isSet(object.magicLink) ? String(object.magicLink) : "" };
  },

  toJSON(message: ReviewerMagicLinkCreateResp): unknown {
    const obj: any = {};
    message.magicLink !== undefined && (obj.magicLink = message.magicLink);
    return obj;
  },

  create<I extends Exact<DeepPartial<ReviewerMagicLinkCreateResp>, I>>(base?: I): ReviewerMagicLinkCreateResp {
    return ReviewerMagicLinkCreateResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ReviewerMagicLinkCreateResp>, I>>(object: I): ReviewerMagicLinkCreateResp {
    const message = createBaseReviewerMagicLinkCreateResp();
    message.magicLink = object.magicLink ?? "";
    return message;
  },
};

function createBaseReviewerMagicLinkLoginReq(): ReviewerMagicLinkLoginReq {
  return { magicLink: "", clientId: "", domain: "", audience: "", redirectURI: "" };
}

export const ReviewerMagicLinkLoginReq = {
  encode(message: ReviewerMagicLinkLoginReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.magicLink !== "") {
      writer.uint32(10).string(message.magicLink);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.domain !== "") {
      writer.uint32(26).string(message.domain);
    }
    if (message.audience !== "") {
      writer.uint32(34).string(message.audience);
    }
    if (message.redirectURI !== "") {
      writer.uint32(42).string(message.redirectURI);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReviewerMagicLinkLoginReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReviewerMagicLinkLoginReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.magicLink = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.domain = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.audience = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.redirectURI = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReviewerMagicLinkLoginReq {
    return {
      magicLink: isSet(object.magicLink) ? String(object.magicLink) : "",
      clientId: isSet(object.clientId) ? String(object.clientId) : "",
      domain: isSet(object.domain) ? String(object.domain) : "",
      audience: isSet(object.audience) ? String(object.audience) : "",
      redirectURI: isSet(object.redirectURI) ? String(object.redirectURI) : "",
    };
  },

  toJSON(message: ReviewerMagicLinkLoginReq): unknown {
    const obj: any = {};
    message.magicLink !== undefined && (obj.magicLink = message.magicLink);
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.domain !== undefined && (obj.domain = message.domain);
    message.audience !== undefined && (obj.audience = message.audience);
    message.redirectURI !== undefined && (obj.redirectURI = message.redirectURI);
    return obj;
  },

  create<I extends Exact<DeepPartial<ReviewerMagicLinkLoginReq>, I>>(base?: I): ReviewerMagicLinkLoginReq {
    return ReviewerMagicLinkLoginReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ReviewerMagicLinkLoginReq>, I>>(object: I): ReviewerMagicLinkLoginReq {
    const message = createBaseReviewerMagicLinkLoginReq();
    message.magicLink = object.magicLink ?? "";
    message.clientId = object.clientId ?? "";
    message.domain = object.domain ?? "";
    message.audience = object.audience ?? "";
    message.redirectURI = object.redirectURI ?? "";
    return message;
  },
};

function createBaseReviewerMagicLinkLoginResp(): ReviewerMagicLinkLoginResp {
  return { userId: "", token: "", email: "", nonSecretPassword: "" };
}

export const ReviewerMagicLinkLoginResp = {
  encode(message: ReviewerMagicLinkLoginResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.token !== "") {
      writer.uint32(18).string(message.token);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.nonSecretPassword !== "") {
      writer.uint32(34).string(message.nonSecretPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReviewerMagicLinkLoginResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReviewerMagicLinkLoginResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.token = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nonSecretPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReviewerMagicLinkLoginResp {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      token: isSet(object.token) ? String(object.token) : "",
      email: isSet(object.email) ? String(object.email) : "",
      nonSecretPassword: isSet(object.nonSecretPassword) ? String(object.nonSecretPassword) : "",
    };
  },

  toJSON(message: ReviewerMagicLinkLoginResp): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.token !== undefined && (obj.token = message.token);
    message.email !== undefined && (obj.email = message.email);
    message.nonSecretPassword !== undefined && (obj.nonSecretPassword = message.nonSecretPassword);
    return obj;
  },

  create<I extends Exact<DeepPartial<ReviewerMagicLinkLoginResp>, I>>(base?: I): ReviewerMagicLinkLoginResp {
    return ReviewerMagicLinkLoginResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ReviewerMagicLinkLoginResp>, I>>(object: I): ReviewerMagicLinkLoginResp {
    const message = createBaseReviewerMagicLinkLoginResp();
    message.userId = object.userId ?? "";
    message.token = object.token ?? "";
    message.email = object.email ?? "";
    message.nonSecretPassword = object.nonSecretPassword ?? "";
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
