/* eslint-disable */
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { OwnershipSummary } from "./ownership-access";
import { WidgetData } from "./widget-data";

export const protobufPackage = "";

export interface ScreenConfigurationCSS {
  templateColumns: string;
  templateRows: string;
}

export interface ScreenConfigurationRow {
  height: number;
}

export interface ScreenConfigurationColumn {
  width: number;
}

export interface WidgetLayoutConfiguration {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  type: string;
  startRow: number;
  startColumn: number;
  endRow: number;
  endColumn: number;
  /** @gotags: bson:"-" */
  data: WidgetData | undefined;
}

export interface FullScreenLayout {
  tabId: string;
  tabName: string;
  tabDescription: string;
  tags: string[];
  hidden: boolean;
  rows: ScreenConfigurationRow[];
  columns: ScreenConfigurationColumn[];
  widgets: WidgetLayoutConfiguration[];
}

export interface ScanCalibrationConfiguration {
  eVstart: number;
  eVperChannel: number;
  detector: string;
}

export interface ScanConfiguration {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  quantId: string;
  calibrations: ScanCalibrationConfiguration[];
  colour: string;
}

export interface ScreenConfiguration {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  name: string;
  description: string;
  browseTabHidden: boolean;
  codeEditorTabHidden: boolean;
  elementMapsTabHidden: boolean;
  layouts: FullScreenLayout[];
  tags: string[];
  modifiedUnixSec: number;
  scanConfigurations: { [key: string]: ScanConfiguration };
  /** @gotags: bson:"-" */
  owner:
    | OwnershipSummary
    | undefined;
  /** @gotags: bson:"snapshotParentId,omitempty" */
  snapshotParentId: string;
  /** @gotags: bson:"reviewerId,omitempty" */
  reviewerId: string;
  /** @gotags: bson:"reviewerExpirationDateUnixSec,omitempty" */
  reviewerExpirationDateUnixSec: number;
}

export interface ScreenConfiguration_ScanConfigurationsEntry {
  key: string;
  value: ScanConfiguration | undefined;
}

function createBaseScreenConfigurationCSS(): ScreenConfigurationCSS {
  return { templateColumns: "", templateRows: "" };
}

export const ScreenConfigurationCSS = {
  encode(message: ScreenConfigurationCSS, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateColumns !== "") {
      writer.uint32(10).string(message.templateColumns);
    }
    if (message.templateRows !== "") {
      writer.uint32(18).string(message.templateRows);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationCSS {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationCSS();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateColumns = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.templateRows = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationCSS {
    return {
      templateColumns: isSet(object.templateColumns) ? String(object.templateColumns) : "",
      templateRows: isSet(object.templateRows) ? String(object.templateRows) : "",
    };
  },

  toJSON(message: ScreenConfigurationCSS): unknown {
    const obj: any = {};
    message.templateColumns !== undefined && (obj.templateColumns = message.templateColumns);
    message.templateRows !== undefined && (obj.templateRows = message.templateRows);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationCSS>, I>>(base?: I): ScreenConfigurationCSS {
    return ScreenConfigurationCSS.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationCSS>, I>>(object: I): ScreenConfigurationCSS {
    const message = createBaseScreenConfigurationCSS();
    message.templateColumns = object.templateColumns ?? "";
    message.templateRows = object.templateRows ?? "";
    return message;
  },
};

function createBaseScreenConfigurationRow(): ScreenConfigurationRow {
  return { height: 0 };
}

export const ScreenConfigurationRow = {
  encode(message: ScreenConfigurationRow, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.height !== 0) {
      writer.uint32(8).int32(message.height);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationRow {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationRow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.height = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationRow {
    return { height: isSet(object.height) ? Number(object.height) : 0 };
  },

  toJSON(message: ScreenConfigurationRow): unknown {
    const obj: any = {};
    message.height !== undefined && (obj.height = Math.round(message.height));
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationRow>, I>>(base?: I): ScreenConfigurationRow {
    return ScreenConfigurationRow.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationRow>, I>>(object: I): ScreenConfigurationRow {
    const message = createBaseScreenConfigurationRow();
    message.height = object.height ?? 0;
    return message;
  },
};

function createBaseScreenConfigurationColumn(): ScreenConfigurationColumn {
  return { width: 0 };
}

export const ScreenConfigurationColumn = {
  encode(message: ScreenConfigurationColumn, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.width !== 0) {
      writer.uint32(8).int32(message.width);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationColumn {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationColumn();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.width = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationColumn {
    return { width: isSet(object.width) ? Number(object.width) : 0 };
  },

  toJSON(message: ScreenConfigurationColumn): unknown {
    const obj: any = {};
    message.width !== undefined && (obj.width = Math.round(message.width));
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationColumn>, I>>(base?: I): ScreenConfigurationColumn {
    return ScreenConfigurationColumn.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationColumn>, I>>(object: I): ScreenConfigurationColumn {
    const message = createBaseScreenConfigurationColumn();
    message.width = object.width ?? 0;
    return message;
  },
};

function createBaseWidgetLayoutConfiguration(): WidgetLayoutConfiguration {
  return { id: "", type: "", startRow: 0, startColumn: 0, endRow: 0, endColumn: 0, data: undefined };
}

export const WidgetLayoutConfiguration = {
  encode(message: WidgetLayoutConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    if (message.startRow !== 0) {
      writer.uint32(24).int32(message.startRow);
    }
    if (message.startColumn !== 0) {
      writer.uint32(32).int32(message.startColumn);
    }
    if (message.endRow !== 0) {
      writer.uint32(40).int32(message.endRow);
    }
    if (message.endColumn !== 0) {
      writer.uint32(48).int32(message.endColumn);
    }
    if (message.data !== undefined) {
      WidgetData.encode(message.data, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WidgetLayoutConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWidgetLayoutConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.startRow = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.startColumn = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.endRow = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.endColumn = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.data = WidgetData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WidgetLayoutConfiguration {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      type: isSet(object.type) ? String(object.type) : "",
      startRow: isSet(object.startRow) ? Number(object.startRow) : 0,
      startColumn: isSet(object.startColumn) ? Number(object.startColumn) : 0,
      endRow: isSet(object.endRow) ? Number(object.endRow) : 0,
      endColumn: isSet(object.endColumn) ? Number(object.endColumn) : 0,
      data: isSet(object.data) ? WidgetData.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: WidgetLayoutConfiguration): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = message.type);
    message.startRow !== undefined && (obj.startRow = Math.round(message.startRow));
    message.startColumn !== undefined && (obj.startColumn = Math.round(message.startColumn));
    message.endRow !== undefined && (obj.endRow = Math.round(message.endRow));
    message.endColumn !== undefined && (obj.endColumn = Math.round(message.endColumn));
    message.data !== undefined && (obj.data = message.data ? WidgetData.toJSON(message.data) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WidgetLayoutConfiguration>, I>>(base?: I): WidgetLayoutConfiguration {
    return WidgetLayoutConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WidgetLayoutConfiguration>, I>>(object: I): WidgetLayoutConfiguration {
    const message = createBaseWidgetLayoutConfiguration();
    message.id = object.id ?? "";
    message.type = object.type ?? "";
    message.startRow = object.startRow ?? 0;
    message.startColumn = object.startColumn ?? 0;
    message.endRow = object.endRow ?? 0;
    message.endColumn = object.endColumn ?? 0;
    message.data = (object.data !== undefined && object.data !== null)
      ? WidgetData.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseFullScreenLayout(): FullScreenLayout {
  return { tabId: "", tabName: "", tabDescription: "", tags: [], hidden: false, rows: [], columns: [], widgets: [] };
}

export const FullScreenLayout = {
  encode(message: FullScreenLayout, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tabId !== "") {
      writer.uint32(10).string(message.tabId);
    }
    if (message.tabName !== "") {
      writer.uint32(18).string(message.tabName);
    }
    if (message.tabDescription !== "") {
      writer.uint32(26).string(message.tabDescription);
    }
    for (const v of message.tags) {
      writer.uint32(58).string(v!);
    }
    if (message.hidden === true) {
      writer.uint32(64).bool(message.hidden);
    }
    for (const v of message.rows) {
      ScreenConfigurationRow.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.columns) {
      ScreenConfigurationColumn.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.widgets) {
      WidgetLayoutConfiguration.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FullScreenLayout {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFullScreenLayout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tabId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tabName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tabDescription = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.hidden = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rows.push(ScreenConfigurationRow.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.columns.push(ScreenConfigurationColumn.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.widgets.push(WidgetLayoutConfiguration.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FullScreenLayout {
    return {
      tabId: isSet(object.tabId) ? String(object.tabId) : "",
      tabName: isSet(object.tabName) ? String(object.tabName) : "",
      tabDescription: isSet(object.tabDescription) ? String(object.tabDescription) : "",
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
      hidden: isSet(object.hidden) ? Boolean(object.hidden) : false,
      rows: Array.isArray(object?.rows) ? object.rows.map((e: any) => ScreenConfigurationRow.fromJSON(e)) : [],
      columns: Array.isArray(object?.columns)
        ? object.columns.map((e: any) => ScreenConfigurationColumn.fromJSON(e))
        : [],
      widgets: Array.isArray(object?.widgets)
        ? object.widgets.map((e: any) => WidgetLayoutConfiguration.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FullScreenLayout): unknown {
    const obj: any = {};
    message.tabId !== undefined && (obj.tabId = message.tabId);
    message.tabName !== undefined && (obj.tabName = message.tabName);
    message.tabDescription !== undefined && (obj.tabDescription = message.tabDescription);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.hidden !== undefined && (obj.hidden = message.hidden);
    if (message.rows) {
      obj.rows = message.rows.map((e) => e ? ScreenConfigurationRow.toJSON(e) : undefined);
    } else {
      obj.rows = [];
    }
    if (message.columns) {
      obj.columns = message.columns.map((e) => e ? ScreenConfigurationColumn.toJSON(e) : undefined);
    } else {
      obj.columns = [];
    }
    if (message.widgets) {
      obj.widgets = message.widgets.map((e) => e ? WidgetLayoutConfiguration.toJSON(e) : undefined);
    } else {
      obj.widgets = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FullScreenLayout>, I>>(base?: I): FullScreenLayout {
    return FullScreenLayout.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FullScreenLayout>, I>>(object: I): FullScreenLayout {
    const message = createBaseFullScreenLayout();
    message.tabId = object.tabId ?? "";
    message.tabName = object.tabName ?? "";
    message.tabDescription = object.tabDescription ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.hidden = object.hidden ?? false;
    message.rows = object.rows?.map((e) => ScreenConfigurationRow.fromPartial(e)) || [];
    message.columns = object.columns?.map((e) => ScreenConfigurationColumn.fromPartial(e)) || [];
    message.widgets = object.widgets?.map((e) => WidgetLayoutConfiguration.fromPartial(e)) || [];
    return message;
  },
};

function createBaseScanCalibrationConfiguration(): ScanCalibrationConfiguration {
  return { eVstart: 0, eVperChannel: 0, detector: "" };
}

export const ScanCalibrationConfiguration = {
  encode(message: ScanCalibrationConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.eVstart !== 0) {
      writer.uint32(9).double(message.eVstart);
    }
    if (message.eVperChannel !== 0) {
      writer.uint32(17).double(message.eVperChannel);
    }
    if (message.detector !== "") {
      writer.uint32(26).string(message.detector);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanCalibrationConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanCalibrationConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.eVstart = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.eVperChannel = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.detector = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanCalibrationConfiguration {
    return {
      eVstart: isSet(object.eVstart) ? Number(object.eVstart) : 0,
      eVperChannel: isSet(object.eVperChannel) ? Number(object.eVperChannel) : 0,
      detector: isSet(object.detector) ? String(object.detector) : "",
    };
  },

  toJSON(message: ScanCalibrationConfiguration): unknown {
    const obj: any = {};
    message.eVstart !== undefined && (obj.eVstart = message.eVstart);
    message.eVperChannel !== undefined && (obj.eVperChannel = message.eVperChannel);
    message.detector !== undefined && (obj.detector = message.detector);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanCalibrationConfiguration>, I>>(base?: I): ScanCalibrationConfiguration {
    return ScanCalibrationConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanCalibrationConfiguration>, I>>(object: I): ScanCalibrationConfiguration {
    const message = createBaseScanCalibrationConfiguration();
    message.eVstart = object.eVstart ?? 0;
    message.eVperChannel = object.eVperChannel ?? 0;
    message.detector = object.detector ?? "";
    return message;
  },
};

function createBaseScanConfiguration(): ScanConfiguration {
  return { id: "", quantId: "", calibrations: [], colour: "" };
}

export const ScanConfiguration = {
  encode(message: ScanConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.quantId !== "") {
      writer.uint32(18).string(message.quantId);
    }
    for (const v of message.calibrations) {
      ScanCalibrationConfiguration.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.colour !== "") {
      writer.uint32(34).string(message.colour);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quantId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.calibrations.push(ScanCalibrationConfiguration.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.colour = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanConfiguration {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      quantId: isSet(object.quantId) ? String(object.quantId) : "",
      calibrations: Array.isArray(object?.calibrations)
        ? object.calibrations.map((e: any) => ScanCalibrationConfiguration.fromJSON(e))
        : [],
      colour: isSet(object.colour) ? String(object.colour) : "",
    };
  },

  toJSON(message: ScanConfiguration): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.quantId !== undefined && (obj.quantId = message.quantId);
    if (message.calibrations) {
      obj.calibrations = message.calibrations.map((e) => e ? ScanCalibrationConfiguration.toJSON(e) : undefined);
    } else {
      obj.calibrations = [];
    }
    message.colour !== undefined && (obj.colour = message.colour);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanConfiguration>, I>>(base?: I): ScanConfiguration {
    return ScanConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanConfiguration>, I>>(object: I): ScanConfiguration {
    const message = createBaseScanConfiguration();
    message.id = object.id ?? "";
    message.quantId = object.quantId ?? "";
    message.calibrations = object.calibrations?.map((e) => ScanCalibrationConfiguration.fromPartial(e)) || [];
    message.colour = object.colour ?? "";
    return message;
  },
};

function createBaseScreenConfiguration(): ScreenConfiguration {
  return {
    id: "",
    name: "",
    description: "",
    browseTabHidden: false,
    codeEditorTabHidden: false,
    elementMapsTabHidden: false,
    layouts: [],
    tags: [],
    modifiedUnixSec: 0,
    scanConfigurations: {},
    owner: undefined,
    snapshotParentId: "",
    reviewerId: "",
    reviewerExpirationDateUnixSec: 0,
  };
}

export const ScreenConfiguration = {
  encode(message: ScreenConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.browseTabHidden === true) {
      writer.uint32(88).bool(message.browseTabHidden);
    }
    if (message.codeEditorTabHidden === true) {
      writer.uint32(96).bool(message.codeEditorTabHidden);
    }
    if (message.elementMapsTabHidden === true) {
      writer.uint32(104).bool(message.elementMapsTabHidden);
    }
    for (const v of message.layouts) {
      FullScreenLayout.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(58).string(v!);
    }
    if (message.modifiedUnixSec !== 0) {
      writer.uint32(64).uint32(message.modifiedUnixSec);
    }
    Object.entries(message.scanConfigurations).forEach(([key, value]) => {
      ScreenConfiguration_ScanConfigurationsEntry.encode({ key: key as any, value }, writer.uint32(74).fork()).ldelim();
    });
    if (message.owner !== undefined) {
      OwnershipSummary.encode(message.owner, writer.uint32(82).fork()).ldelim();
    }
    if (message.snapshotParentId !== "") {
      writer.uint32(114).string(message.snapshotParentId);
    }
    if (message.reviewerId !== "") {
      writer.uint32(122).string(message.reviewerId);
    }
    if (message.reviewerExpirationDateUnixSec !== 0) {
      writer.uint32(48).int64(message.reviewerExpirationDateUnixSec);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.browseTabHidden = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.codeEditorTabHidden = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.elementMapsTabHidden = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.layouts.push(FullScreenLayout.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.modifiedUnixSec = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          const entry9 = ScreenConfiguration_ScanConfigurationsEntry.decode(reader, reader.uint32());
          if (entry9.value !== undefined) {
            message.scanConfigurations[entry9.key] = entry9.value;
          }
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.owner = OwnershipSummary.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.snapshotParentId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.reviewerId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.reviewerExpirationDateUnixSec = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfiguration {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      browseTabHidden: isSet(object.browseTabHidden) ? Boolean(object.browseTabHidden) : false,
      codeEditorTabHidden: isSet(object.codeEditorTabHidden) ? Boolean(object.codeEditorTabHidden) : false,
      elementMapsTabHidden: isSet(object.elementMapsTabHidden) ? Boolean(object.elementMapsTabHidden) : false,
      layouts: Array.isArray(object?.layouts) ? object.layouts.map((e: any) => FullScreenLayout.fromJSON(e)) : [],
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
      modifiedUnixSec: isSet(object.modifiedUnixSec) ? Number(object.modifiedUnixSec) : 0,
      scanConfigurations: isObject(object.scanConfigurations)
        ? Object.entries(object.scanConfigurations).reduce<{ [key: string]: ScanConfiguration }>(
          (acc, [key, value]) => {
            acc[key] = ScanConfiguration.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      owner: isSet(object.owner) ? OwnershipSummary.fromJSON(object.owner) : undefined,
      snapshotParentId: isSet(object.snapshotParentId) ? String(object.snapshotParentId) : "",
      reviewerId: isSet(object.reviewerId) ? String(object.reviewerId) : "",
      reviewerExpirationDateUnixSec: isSet(object.reviewerExpirationDateUnixSec)
        ? Number(object.reviewerExpirationDateUnixSec)
        : 0,
    };
  },

  toJSON(message: ScreenConfiguration): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.browseTabHidden !== undefined && (obj.browseTabHidden = message.browseTabHidden);
    message.codeEditorTabHidden !== undefined && (obj.codeEditorTabHidden = message.codeEditorTabHidden);
    message.elementMapsTabHidden !== undefined && (obj.elementMapsTabHidden = message.elementMapsTabHidden);
    if (message.layouts) {
      obj.layouts = message.layouts.map((e) => e ? FullScreenLayout.toJSON(e) : undefined);
    } else {
      obj.layouts = [];
    }
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.modifiedUnixSec !== undefined && (obj.modifiedUnixSec = Math.round(message.modifiedUnixSec));
    obj.scanConfigurations = {};
    if (message.scanConfigurations) {
      Object.entries(message.scanConfigurations).forEach(([k, v]) => {
        obj.scanConfigurations[k] = ScanConfiguration.toJSON(v);
      });
    }
    message.owner !== undefined && (obj.owner = message.owner ? OwnershipSummary.toJSON(message.owner) : undefined);
    message.snapshotParentId !== undefined && (obj.snapshotParentId = message.snapshotParentId);
    message.reviewerId !== undefined && (obj.reviewerId = message.reviewerId);
    message.reviewerExpirationDateUnixSec !== undefined &&
      (obj.reviewerExpirationDateUnixSec = Math.round(message.reviewerExpirationDateUnixSec));
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfiguration>, I>>(base?: I): ScreenConfiguration {
    return ScreenConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfiguration>, I>>(object: I): ScreenConfiguration {
    const message = createBaseScreenConfiguration();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.browseTabHidden = object.browseTabHidden ?? false;
    message.codeEditorTabHidden = object.codeEditorTabHidden ?? false;
    message.elementMapsTabHidden = object.elementMapsTabHidden ?? false;
    message.layouts = object.layouts?.map((e) => FullScreenLayout.fromPartial(e)) || [];
    message.tags = object.tags?.map((e) => e) || [];
    message.modifiedUnixSec = object.modifiedUnixSec ?? 0;
    message.scanConfigurations = Object.entries(object.scanConfigurations ?? {}).reduce<
      { [key: string]: ScanConfiguration }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ScanConfiguration.fromPartial(value);
      }
      return acc;
    }, {});
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? OwnershipSummary.fromPartial(object.owner)
      : undefined;
    message.snapshotParentId = object.snapshotParentId ?? "";
    message.reviewerId = object.reviewerId ?? "";
    message.reviewerExpirationDateUnixSec = object.reviewerExpirationDateUnixSec ?? 0;
    return message;
  },
};

function createBaseScreenConfiguration_ScanConfigurationsEntry(): ScreenConfiguration_ScanConfigurationsEntry {
  return { key: "", value: undefined };
}

export const ScreenConfiguration_ScanConfigurationsEntry = {
  encode(message: ScreenConfiguration_ScanConfigurationsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ScanConfiguration.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfiguration_ScanConfigurationsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfiguration_ScanConfigurationsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ScanConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfiguration_ScanConfigurationsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ScanConfiguration.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ScreenConfiguration_ScanConfigurationsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ScanConfiguration.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfiguration_ScanConfigurationsEntry>, I>>(
    base?: I,
  ): ScreenConfiguration_ScanConfigurationsEntry {
    return ScreenConfiguration_ScanConfigurationsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfiguration_ScanConfigurationsEntry>, I>>(
    object: I,
  ): ScreenConfiguration_ScanConfigurationsEntry {
    const message = createBaseScreenConfiguration_ScanConfigurationsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ScanConfiguration.fromPartial(object.value)
      : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
