/* eslint-disable */
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface UserInfo {
  id: string;
  /** @gotags: bson:"name,omitempty" */
  name: string;
  /** @gotags: bson:"email,omitempty" */
  email: string;
  /** NOTE: this can be a link or a data://<base64 image> */
  iconURL: string;
  /** @gotags: bson:"reviewerworkspaceid,omitempty" */
  reviewerWorkspaceId: string;
  /** @gotags: bson:"expirationunixsec,omitempty" */
  expirationDateUnixSec: number;
  /** @gotaags: bson:"nonsecretpassword,omitempty" */
  nonSecretPassword: string;
}

export interface UserDetails {
  info: UserInfo | undefined;
  dataCollectionVersion: string;
  permissions: string[];
}

export interface Auth0UserRole {
  id: string;
  name: string;
  description: string;
}

export interface Auth0UserDetails {
  auth0User: UserInfo | undefined;
  pixliseUser: UserInfo | undefined;
  createdUnixSec: number;
  lastLoginUnixSec: number;
}

function createBaseUserInfo(): UserInfo {
  return {
    id: "",
    name: "",
    email: "",
    iconURL: "",
    reviewerWorkspaceId: "",
    expirationDateUnixSec: 0,
    nonSecretPassword: "",
  };
}

export const UserInfo = {
  encode(message: UserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.iconURL !== "") {
      writer.uint32(34).string(message.iconURL);
    }
    if (message.reviewerWorkspaceId !== "") {
      writer.uint32(42).string(message.reviewerWorkspaceId);
    }
    if (message.expirationDateUnixSec !== 0) {
      writer.uint32(48).int64(message.expirationDateUnixSec);
    }
    if (message.nonSecretPassword !== "") {
      writer.uint32(58).string(message.nonSecretPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.iconURL = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.reviewerWorkspaceId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.expirationDateUnixSec = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.nonSecretPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInfo {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      iconURL: isSet(object.iconURL) ? String(object.iconURL) : "",
      reviewerWorkspaceId: isSet(object.reviewerWorkspaceId) ? String(object.reviewerWorkspaceId) : "",
      expirationDateUnixSec: isSet(object.expirationDateUnixSec) ? Number(object.expirationDateUnixSec) : 0,
      nonSecretPassword: isSet(object.nonSecretPassword) ? String(object.nonSecretPassword) : "",
    };
  },

  toJSON(message: UserInfo): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.email !== undefined && (obj.email = message.email);
    message.iconURL !== undefined && (obj.iconURL = message.iconURL);
    message.reviewerWorkspaceId !== undefined && (obj.reviewerWorkspaceId = message.reviewerWorkspaceId);
    message.expirationDateUnixSec !== undefined &&
      (obj.expirationDateUnixSec = Math.round(message.expirationDateUnixSec));
    message.nonSecretPassword !== undefined && (obj.nonSecretPassword = message.nonSecretPassword);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInfo>, I>>(base?: I): UserInfo {
    return UserInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserInfo>, I>>(object: I): UserInfo {
    const message = createBaseUserInfo();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.iconURL = object.iconURL ?? "";
    message.reviewerWorkspaceId = object.reviewerWorkspaceId ?? "";
    message.expirationDateUnixSec = object.expirationDateUnixSec ?? 0;
    message.nonSecretPassword = object.nonSecretPassword ?? "";
    return message;
  },
};

function createBaseUserDetails(): UserDetails {
  return { info: undefined, dataCollectionVersion: "", permissions: [] };
}

export const UserDetails = {
  encode(message: UserDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.info !== undefined) {
      UserInfo.encode(message.info, writer.uint32(10).fork()).ldelim();
    }
    if (message.dataCollectionVersion !== "") {
      writer.uint32(42).string(message.dataCollectionVersion);
    }
    for (const v of message.permissions) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.info = UserInfo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dataCollectionVersion = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.permissions.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDetails {
    return {
      info: isSet(object.info) ? UserInfo.fromJSON(object.info) : undefined,
      dataCollectionVersion: isSet(object.dataCollectionVersion) ? String(object.dataCollectionVersion) : "",
      permissions: Array.isArray(object?.permissions) ? object.permissions.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: UserDetails): unknown {
    const obj: any = {};
    message.info !== undefined && (obj.info = message.info ? UserInfo.toJSON(message.info) : undefined);
    message.dataCollectionVersion !== undefined && (obj.dataCollectionVersion = message.dataCollectionVersion);
    if (message.permissions) {
      obj.permissions = message.permissions.map((e) => e);
    } else {
      obj.permissions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDetails>, I>>(base?: I): UserDetails {
    return UserDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDetails>, I>>(object: I): UserDetails {
    const message = createBaseUserDetails();
    message.info = (object.info !== undefined && object.info !== null) ? UserInfo.fromPartial(object.info) : undefined;
    message.dataCollectionVersion = object.dataCollectionVersion ?? "";
    message.permissions = object.permissions?.map((e) => e) || [];
    return message;
  },
};

function createBaseAuth0UserRole(): Auth0UserRole {
  return { id: "", name: "", description: "" };
}

export const Auth0UserRole = {
  encode(message: Auth0UserRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Auth0UserRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuth0UserRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Auth0UserRole {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: Auth0UserRole): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<Auth0UserRole>, I>>(base?: I): Auth0UserRole {
    return Auth0UserRole.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Auth0UserRole>, I>>(object: I): Auth0UserRole {
    const message = createBaseAuth0UserRole();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseAuth0UserDetails(): Auth0UserDetails {
  return { auth0User: undefined, pixliseUser: undefined, createdUnixSec: 0, lastLoginUnixSec: 0 };
}

export const Auth0UserDetails = {
  encode(message: Auth0UserDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.auth0User !== undefined) {
      UserInfo.encode(message.auth0User, writer.uint32(10).fork()).ldelim();
    }
    if (message.pixliseUser !== undefined) {
      UserInfo.encode(message.pixliseUser, writer.uint32(18).fork()).ldelim();
    }
    if (message.createdUnixSec !== 0) {
      writer.uint32(24).uint32(message.createdUnixSec);
    }
    if (message.lastLoginUnixSec !== 0) {
      writer.uint32(32).uint32(message.lastLoginUnixSec);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Auth0UserDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuth0UserDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.auth0User = UserInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pixliseUser = UserInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.createdUnixSec = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lastLoginUnixSec = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Auth0UserDetails {
    return {
      auth0User: isSet(object.auth0User) ? UserInfo.fromJSON(object.auth0User) : undefined,
      pixliseUser: isSet(object.pixliseUser) ? UserInfo.fromJSON(object.pixliseUser) : undefined,
      createdUnixSec: isSet(object.createdUnixSec) ? Number(object.createdUnixSec) : 0,
      lastLoginUnixSec: isSet(object.lastLoginUnixSec) ? Number(object.lastLoginUnixSec) : 0,
    };
  },

  toJSON(message: Auth0UserDetails): unknown {
    const obj: any = {};
    message.auth0User !== undefined &&
      (obj.auth0User = message.auth0User ? UserInfo.toJSON(message.auth0User) : undefined);
    message.pixliseUser !== undefined &&
      (obj.pixliseUser = message.pixliseUser ? UserInfo.toJSON(message.pixliseUser) : undefined);
    message.createdUnixSec !== undefined && (obj.createdUnixSec = Math.round(message.createdUnixSec));
    message.lastLoginUnixSec !== undefined && (obj.lastLoginUnixSec = Math.round(message.lastLoginUnixSec));
    return obj;
  },

  create<I extends Exact<DeepPartial<Auth0UserDetails>, I>>(base?: I): Auth0UserDetails {
    return Auth0UserDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Auth0UserDetails>, I>>(object: I): Auth0UserDetails {
    const message = createBaseAuth0UserDetails();
    message.auth0User = (object.auth0User !== undefined && object.auth0User !== null)
      ? UserInfo.fromPartial(object.auth0User)
      : undefined;
    message.pixliseUser = (object.pixliseUser !== undefined && object.pixliseUser !== null)
      ? UserInfo.fromPartial(object.pixliseUser)
      : undefined;
    message.createdUnixSec = object.createdUnixSec ?? 0;
    message.lastLoginUnixSec = object.lastLoginUnixSec ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
